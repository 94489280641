import React from "react";
import ListItem from "./Item";
import SportIcon from "../../Sport/Components/Icon";
import WatchingButton from "Components/Watching/Button";

const List = ({ listings, history, currentUser, adminView }) => {
  return listings.map(listing => (
    <ListItem
      adminView={adminView}
      currentUser={currentUser}
      history={history}
      listing={listing}
      ticket={listing.cachedTicket}
      key={listing.id}
      bids={(listing?.bids?.items ?? []).filter(
        bid => bid.status !== "CANCELLED" && bid.status !== "REJECTED"
      )}
      HeaderEndAdornment={
        <>
          <SportIcon sport={listing.cachedTicket.sport.abbrev.toLowerCase()} />
          {currentUser?.groups?.includes?.("Admins") && (
            <WatchingButton listing={listing} />
          )}
        </>
      }
      view="public"
    />
  ));
};

export default List;
