import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import Form from "../../Form";
import Notifications from "shared/api/Notifications";

const fields = {
  title: {
    label: "Title",
    regex: /\w+/,
    required: true,
    type: "text",
    errorMessage: "You are required enter a title",
  },
  body: {
    label: "Body",
    regex: /\w+/,
    required: true,
    multiline: true,
    type: "text",
    errorMessage: "You are required enter a body",
  },
};

export default ({ notification }) => {
  const [formData, setFormData] = useState(notification || {});
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === "active" ? !!value : value,
    });

  const [_createNotification] = useMutation(Notifications.mutations.create, {
    variables: {
      input: {
        ...formData,
      },
    },
  });

  const handleSubmit = () =>
    Promise.all([setSubmitting(true), _createNotification()])
      .catch(e => [console.log(e), window.alert(e.message)])
      .finally(() => Promise.all([setSubmitting(false), setFormData({})]));

  return (
    <Form
      submitting={submitting}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={handleSubmit}
      buttonLabel={"Send"}
    />
  );
};
