import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import Form from "../../Form";
import ExchangeTransaction from "shared/api/ExchangeTransaction";
import { withoutKeys } from "gunner-react";
import { convertMoneyTextToPennies } from "shared/Util/money";

export default ({ exchangeTransaction }) => {
  const [formData, setFormData] = useState(exchangeTransaction || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: ["featuredAt"].includes(field)
        ? !!value
          ? new Date().toISOString()
          : null
        : field === "active"
        ? !!value
        : value,
    });

  const [_createExchangeTransaction] = useMutation(
    ExchangeTransaction.mutations.create,
    {
      variables: {
        input: {
          ...formData,
          amount: convertMoneyTextToPennies(formData.amount),
        },
      },
      refetchQueries: [
        {
          query: ExchangeTransaction.queries.list,
          variables: {
            filter: {
              providerId: {
                eq: formData.providerId,
              },
            },
          },
        },
      ],
      optimisticResponse: {
        __typename: "Mutation",
        createExchangeTransaction: {
          ...formData,
          __typename: "ExchangeTransaction",
        },
      },
    }
  );

  const [_updateExchangeTransaction] = useMutation(
    ExchangeTransaction.mutations.update,
    {
      variables: {
        input: {
          ...withoutKeys(formData, ["__typename", "sport", "group"]),
        },
      },
      refetchQueries: [
        {
          query: ExchangeTransaction.queries.list,
          variables: { limit: 100, sportId: formData.sportId },
        },
      ],
      optimisticResponse: {
        __typename: "Mutation",
        updateExchangeTransaction: {
          ...formData,
          __typename: "ExchangeTransaction",
        },
      },
    }
  );

  //   sportId: ID!

  //   activeAt: AWSDateTime!
  //   featuredAt: AWSDateTime!

  const fields = {
    amount: {
      label: "Amount",
      regex: /\d+/,
      required: true,
      errorMessage: "You are required enter a name",
      type: "money",
    },
  };

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id
        ? _updateExchangeTransaction
        : _createExchangeTransaction)() //if this is an existing exchangeTransaction, update it, otherwise, create it
        .then(
          ({
            data: {
              [!!formData.id
                ? "updateExchangeTransaction"
                : "createExchangeTransaction"]: exchangeTransaction,
            },
          }) => setSubmitSuccess(true)
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [doSubmit]);

  return !!submitSuccess ? (
    <Redirect
      push
      to={`/admin/exchange/providers/${formData.providerId}/transactions`}
    />
  ) : (
    <Form
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  );
};
