import React from "react";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  CartesianGrid,
} from "recharts";
import { Box } from "@material-ui/core";
import moment from "moment-timezone";
// import suggestedPriceInPennies from 'shared/Util/suggestedPriceInPennies';

const spacer = arr => (arr.length > 100 ? 5 : arr.length > 20 ? 3 : 1);

const suggestedPriceInPennies = ({
  ticketCollectsAmount,
  currentCalculatedOdds,
}) => ticketCollectsAmount / (currentCalculatedOdds + 1);

const yAxisTickFormatter = (ticketCollectsAmount, optionEventOdds) => data =>
  !!ticketCollectsAmount
    ? `$${(data / 100.0).toFixed(2)}`
    : optionEventOdds.find(item => item.oddsCalculated === data)
        ?.oddsFormated || "0";

const customToolTip = ticketCollectsAmount => data =>
  !!data?.payload?.[0] &&
  (!!ticketCollectsAmount
    ? `${moment(data.payload[0].payload.createdAt).format("MMMM Do YYYY")} - ${
        data.payload[0].payload.oddsFormated
      }: $${(
        suggestedPriceInPennies({
          ticketCollectsAmount,
          currentCalculatedOdds: data.payload[0].payload.oddsCalculated,
        }) / 100.0
      ).toFixed(2)}`
    : `${moment(data.payload[0].payload.createdAt).format("MMMM Do YYYY")}: ${
        data.payload[0].payload.oddsFormated
      }`);

export default ({ optionEventOdds, ticketCollectsAmount }) => (
  <>
    <Box height={300}>
      <ResponsiveContainer>
        <AreaChart
          // strokeWidth={2}
          data={optionEventOdds
            .slice()
            .reverse()
            .filter((item, index, arr) => index % spacer(arr) === 0)
            .slice()
            .reverse()
            .map(item => ({
              ...item,
              name: moment(item.createdAt)
                .tz("America/New_York")
                .format("M/D/YYYY"),
              value: !!ticketCollectsAmount
                ? suggestedPriceInPennies({
                    ticketCollectsAmount,
                    currentCalculatedOdds: item.oddsCalculated,
                  })
                : item.oddsCalculated,
              label: !!ticketCollectsAmount
                ? `$${(
                    suggestedPriceInPennies({
                      ticketCollectsAmount,
                      currentCalculatedOdds: item.oddsCalculated,
                    }) / 100.0
                  ).toFixed(2)}`
                : item.oddsFormated,
            }))}
          // margin={{
          //   top: 10, right: 30, left: 0, bottom: 0,
          // }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            type="number"
            dataKey="value"
            tickFormatter={yAxisTickFormatter(
              ticketCollectsAmount,
              optionEventOdds
            )}
          />
          <Tooltip content={customToolTip(ticketCollectsAmount)} />
          <Area
            name={!!ticketCollectsAmount ? "Value" : "Odds"}
            strokeWidth={2}
            type="monotone"
            dataKey="value"
            stroke="#50b44d"
            dot={true}
            fill="transparent"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
    {/* <Box height={300}>
      <ResponsiveContainer>
        <LineChart 
          data={
            optionEventOdds
              .filter((item, index, arr) => index % spacer(arr) === 0)
              .map(item => ({
                ...item, 
                name: moment(item.createdAt).format("YYYY-MM-DD"), 
                value: !!ticketCollectsAmount ? suggestedPriceInPennies({ticketCollectsAmount, currentCalculatedOdds: item.oddsCalculated}) : item.oddsCalculated, 
                label: !!ticketCollectsAmount ? `$${(suggestedPriceInPennies({ticketCollectsAmount, currentCalculatedOdds: item.oddsCalculated}) / 100.0).toFixed(2)}` : item.oddsFormated
              }))
          }
        >
          <XAxis dataKey="name" /> 

          <YAxis 
            type="number" 
            // domain={['dataMin - 1', 'dataMax + 1']} 
            dataKey="value" 
            tickFormatter={yAxisTickFormatter(ticketCollectsAmount, optionEventOdds)} 
            // ticks={
            //   optionEventOdds
            //     .filter((item, index, arr) => index % spacer(arr) === 0)
            //     .map(item => !!ticketCollectsAmount ? suggestedPriceInPennies({ticketCollectsAmount, currentCalculatedOdds: item.oddsCalculated}) : item.oddsCalculated)
            // }
          />
          <Tooltip content={customToolTip(ticketCollectsAmount)} />
          
          <Line name={!!ticketCollectsAmount ? "Value" : "Odds"} type="linear" dataKey="value" stroke="#50b44d" dot={true} />

        </LineChart>
      </ResponsiveContainer>
    </Box> */}
  </>
);
