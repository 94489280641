import { Cache } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

export default ({ history, match: { params } }) => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    Promise.all([
      Cache.setItem("referralCode", (params.referralCode ?? "").trim()),
      Cache.setItem("bonusCode", (params.bonusCode ?? "").trim()),
    ]).then(setRedirectTo("/sign-up"));
  }, [params.referralCode, params.bonusCode]);

  return !!redirectTo ? <Redirect push={false} to={redirectTo} /> : null;
};

//Fq_-lZd8
