import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import useCanEditListing from "react-shared/Hooks/useCanEditListing";
import Ticket from "shared/api/Ticket";
import Listing from "shared/api/Listing";

const EditButton = ({ listingId }) => {
  const canEditListing = useCanEditListing({ listingId });
  const { data: { getListing } = {} } = useQuery(Listing.queries.get, {
    variables: { id: listingId },
  });

  return !canEditListing || !getListing ? null : (
    <Button
      onClick={evt => evt.stopPropagation()}
      component={Link}
      to={`/tickets/${getListing.listingTicketId}/listings/${listingId}/edit`}
      variant={"contained"}
      size="small"
      color="secondary"
    >
      Edit
    </Button>
  );
};

export default EditButton;
