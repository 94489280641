import React from "react";
import { useQuery } from "@apollo/client";
import Form from "../Form";
import User from "shared/api/User";
import { CircularProgress } from "@material-ui/core";

const UserEditContainer = ({ userId }) => {
  const { loading, data: { getUser: user } = {} } = useQuery(User.queries.get, {
    variables: { withPrivate: true, id: userId },
  });
  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Form user={user} />
  );
};

export default UserEditContainer;
