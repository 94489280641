import React from "react";
import FanduelListMarketTypesView from "./FanduelListMarketTypesView";
import FanduelListMarketTypesStyles from "./FanduelListMarketTypesStyles";
import useStyles from "Hooks/useStyles";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export default ({ eventTypeId }) => {
  const { theme, styles } = useStyles({ Styles: FanduelListMarketTypesStyles });
  const query = gql`
    query FanDuelListMarketTypes($eventTypeIds: [Int!]!) {
      fanDuelListMarketTypes(eventTypeIds: $eventTypeIds) {
        marketType
        marketCount
      }
    }
  `;

  const result = useQuery(query, {
    variables: {
      eventTypeIds: [eventTypeId],
    },
  });

  console.log("LLLLL", result);

  return (
    <FanduelListMarketTypesView
      query={query}
      theme={theme}
      styles={styles}
      items={result?.data?.fanDuelListMarketTypes}
      eventTypeId={eventTypeId}
    />
  );
};
