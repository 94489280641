import React from "react";
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";

export default ({ items }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Market Count</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {(items ?? []).map(item => (
        <TableRow key={item.eventType.id}>
          <TableCell>{item.eventType.id}</TableCell>
          <TableCell>{item.eventType.name}</TableCell>
          <TableCell>{item.marketCount}</TableCell>
          <TableCell>
            <Button
              component={Link}
              to={`/admin/fanduel?eventTypeId=${item.eventType.id}`}
              fullWidth
              size={"small"}
              variant={"contained"}
              color={"secondary"}
            >
              Market types
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
