import React from "react";

import Form from "../Form";

export default ({}) => (
  <Form
    provider={{
      allowOnPropSwap: false,
      allowOnOtherProviders: false,
      allowAcrossLocales: false,
      balance: 0,
      buyerFee: 0,
      sellerFee: 0,
    }}
  />
);
