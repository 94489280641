import React from "react";
import Slider from "react-slick";
import RecentlySoldList from "../RecentlySold/List";
import FeaturedList from "../Featured/List";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

export default ({ classes }) => (
  <div className={classes.featuredList}>
    <Slider
      dots={true}
      infinite={true}
      speed={500}
      slidesToShow={1}
      slidesToScroll={1}
      autoplay={true}
      autoplaySpeed={6000}
      nextArrow={<></>}
      prevArrow={<></>}
      pauseOnDotsHover
      pauseOnHover
    >
      <FeaturedList />
      <RecentlySoldList />
    </Slider>
  </div>
);
