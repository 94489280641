import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import classNames from "classnames";

import Nav from "./Nav";
import { Hidden, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  header: {
    "& .container": {
      [theme.breakpoints.up("md")]: {
        padding: 0,
      },
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    "& .slug": {
      textTransform: "uppercase",
      color: theme.palette.common.white,
    },
    "& .button": {
      backgroundColor: theme.palette.action.active,
      marginLeft: theme.spacing(2),
      color: theme.palette.secondary.main,
    },
    "& .buyButton": {
      backgroundColor: theme.palette.action.disabled,
      color: theme.palette.common.white,
    },
  },
}));

const Header = ({ currentUser }) => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <Nav currentUser={currentUser} />
      <Container fixed className={"container"}>
        <Grid container spacing={0}>
          <Grid item xs={4} md={2}>
            <Link to={"/"}>
              <img
                className={"responsive-media"}
                alt="PropSwap"
                src={require("../../assets/css/nav-logo.png")}
              />
            </Link>
          </Grid>
          <Grid
            item
            xs={8}
            md={10}
            container
            justify={"flex-end"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Hidden smDown>
              <Typography variant="body1" className={"slug"}>
                I Would Like To
              </Typography>
            </Hidden>
            <Button
              variant={"contained"}
              component={Link}
              to={"/"}
              className={classNames("button", "buyButton")}
            >
              Buy
            </Button>
            <Button
              variant={"contained"}
              component={Link}
              to={"/pages/sell"}
              className={"button"}
            >
              Sell
            </Button>
          </Grid>
        </Grid>
      </Container>
    </header>
  );
};

export default Header;
