import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  Box,
} from "@material-ui/core";
import { Redirect, Link } from "react-router-dom";

import { useMutation } from "@apollo/client";

import Listing from "shared/api/Listing";
import Ticket from "shared/api/Ticket";
import Modal from "../../Layout/Modal";
import Disclosure from "../../Ticket/Components/Disclosure";
import useHasAvailableFunds from "react-shared/Hooks/useHasAvailableFunds";

// const useStyles = makeStyles(theme => ({

// }));

const BuyButton = ({
  listing,
  onSuccess = listing => listing,
  onFailure = () => null,
  onClick,
  disabled,
  currentUser = null,
  ...rest
}) => {
  const [doBuy, setDoBuy] = useState(false);
  const [buyConfirm, setBuyConfirm] = useState(null);
  const [canBuy, setCanBuy] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [agree, setAgree] = useState(false);
  const [mobileAgree, setMobileAgree] = useState(false);
  const [sendToDepositScreen, setSendToDepositScreen] = useState(false);

  const hasEnoughFunds = useHasAvailableFunds({
    userId: currentUser?.id,
    amountInCents: listing.askingPrice,
  });

  const [_buyListing] = useMutation(Listing.mutations.buy, {
    variables: {
      input: {
        id: listing.id,
        boughtFrom: "WEB",
      },
    },
    refetchQueries: [
      {
        query: Listing.queries.get,
        variables: { id: listing.id, bidLimit: 1000 },
      },
      {
        query: Ticket.queries.get,
        variables: { id: listing.listingTicketId },
      },
    ],
  });

  useEffect(() => {
    setCanBuy(
      !currentUser
        ? false
        : currentUser.id === listing.listingSellerId
        ? false
        : listing.status !== "ACTIVE"
        ? false
        : true
    );
  }, [(currentUser || {}.id, listing.updatedAt)]);

  useEffect(() => {
    buyConfirm &&
      Promise.resolve(setSubmitting(true)).then(() =>
        _buyListing()
          .then(({ data: { buyListing: listing } }) => [
            setBuyConfirm(false),
            setSubmitting(false),
            setDoBuy(false),
            setCanBuy(false),
            onSuccess(listing),
          ])
          .catch(e => [
            console.log(e),
            window.alert(e.message),
            setSubmitting(false),
            setBuyConfirm(false),
            onFailure(),
          ])
      );
  }, [buyConfirm]);

  // useEffect(() => {
  //   !!doBuy &&
  //   listing.askingPrice > (currentUser.balance || 0) &&
  //   Promise.all([
  //     window.alert("You don't have enough money in your wallet to purchase this listing."),
  //     setDoBuy(false)
  //   ])
  // }, [doBuy]);

  return !canBuy && !onClick ? null : (
    <>
      {sendToDepositScreen && <Redirect to="/account/deposit" />}
      {!!doBuy &&
        (hasEnoughFunds ? (
          <Modal
            title={
              <Box display="flex" justifyContent="space-between" mr={2} ml={2}>
                <div>
                  <strong>Buy:</strong>&nbsp;
                  <Box color="secondary.main" component="span">
                    <NumberFormat
                      fixedDecimalScale
                      decimalScale={2}
                      value={parseFloat(listing.askingPrice || 0) / 100.0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Box>
                </div>
                <div>
                  <strong>Odds:</strong> {listing.newOdds}
                </div>
              </Box>
            }
            subTitle={
              <>
                <strong>Available Balance:</strong>&nbsp;
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={
                    parseFloat(
                      currentUser.balance + currentUser.promotionalBalance
                    ) / 100.0
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                <br />
                <strong>Remaining Balance:</strong>&nbsp;
                <Box color="secondary.main" component="span">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={
                      parseFloat(
                        currentUser.balance +
                          currentUser.promotionalBalance -
                          listing.askingPrice
                      ) / 100.0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Box>
              </>
            }
            body={
              <form noValidate autoComplete="off">
                <Disclosure
                  ticket={listing.ticket}
                  onAgree={setMobileAgree}
                  agree={mobileAgree}
                />
                <FormControl>
                  <FormGroup
                    onChange={({ target: { checked } }) => setAgree(!!checked)}
                    row
                  >
                    <FormControlLabel
                      control={<Checkbox checked={!!agree} color="primary" />}
                      label={
                        <>
                          I have read and agree to the{" "}
                          <Link target="_blank" to="/pages/terms">
                            Terms and Conditions
                          </Link>
                        </>
                      }
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </form>
            }
            onClose={() => setDoBuy(false)}
            submitting={submitting}
            opened={!!doBuy}
            saveButton={{
              text: "Purchase",
              disabled: !agree || !mobileAgree,
              onClick: () => setBuyConfirm(true),
              ButtonProps: {
                fullWidth: true,
              },
            }}
          />
        ) : (
          <Modal
            title={
              <span>
                Current Balance:&nbsp;
                <Box color="secondary.main" component="span">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={parseFloat(currentUser.balance || 0) / 100.0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Box>
              </span>
            }
            subTitle={
              <span>
                Ticket Price:
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={parseFloat(listing.askingPrice || 0) / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            }
            body={
              <Typography paragraph>
                You do not have enough money in your account to purchase this
                ticket.
              </Typography>
            }
            onClose={() => setDoBuy(false)}
            submitting={submitting}
            opened={!!doBuy}
            saveButton={{
              text: "Deposit",
              onClick: () => setSendToDepositScreen(true),
              ButtonProps: {
                fullWidth: true,
              },
            }}
          />
        ))}
      <Button
        disabled={!!doBuy || !!buyConfirm || !!disabled}
        onClick={evt =>
          !!onClick ? onClick() : [evt.stopPropagation(), setDoBuy(true)]
        }
        // size="small"
        color="secondary"
        variant="contained"
        {...rest}
      >
        Buy
      </Button>
    </>
  );
};

export default BuyButton;
