/* eslint-disable */

import React, { useState } from "react";

import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "./Components/Tabs";
import { STATES } from "shared/Config";
import { Auth } from "gunner-react/web";
import { Cache } from "aws-amplify";

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(-2),
    padding: theme.spacing(2),
    textAlign: "center",
    "& p": {
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `2px solid ${theme.palette.background.default}`,
    },
  },
}));

const signUpOrder = [
  "username",
  "password",
  "custom:mobile",
  "given_name",
  "family_name",
  "address",
  "custom:address2",
  "custom:city",
  "custom:state",
  "custom:zip",
  "birthdate",
  "custom:referral_code",
  "custom:bonus_code",
];

export default ({ history }) => {
  const classes = useStyles();
  const [authState, setAuthState] = useState(null);
  const referralCode = Cache.getItem("referralCode");
  const bonusCode = Cache.getItem("bonusCode");

  const customFields = {
    "custom:mobile": {
      label: "Mobile Phone",
      required: true,
      type: "phone",
      initialValue: "",
      validations: {
        "Valid phone number":
          /^\+\d\d?\d? \(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      },
    },
    family_name: {
      label: "Last Name",
      required: true,
      type: "text",
      initialValue: "",
      validations: {
        "Can't be blank": /^.+$/,
      },
    },
    given_name: {
      label: "First Name",
      required: true,
      type: "text",
      initialValue: "",
      validations: {
        "Can't be blank": /^.+$/,
      },
    },
    address: {
      label: "Address",
      required: true,
      type: "text",
      initialValue: "",
      validations: {
        "Can't be blank": /^.+$/,
      },
    },
    birthdate: {
      label: "Birthdate",
      required: true,
      type: "date",
      initialValue: "",
      validations: {
        "Must be a valid date": /^\d\d\/\d\d\/\d\d\d\d$/,
      },
    },
    "custom:address2": {
      label: "Address 2",
      required: false,
      type: "text",
      initialValue: "",
      validations: {},
    },
    "custom:city": {
      label: "City",
      required: true,
      type: "text",
      initialValue: "",
      validations: {
        "Can't be blank": /^.+$/,
      },
    },
    "custom:zip": {
      label: "Zip",
      required: true,
      type: "text",
      initialValue: "",
      validations: {
        "Must be a valid zip code": /^\d{5}$|^\d{5}-\d{4}$/,
      },
    },
    "custom:state": {
      label: "State",
      required: true,
      type: "text",
      initialValue: "",
      validations: {},
      options: STATES.map(([label, value]) => ({ label, value })),
    },
    "custom:referral_code": {
      label: "Referral Code (Optional)",
      required: false,
      type: !!referralCode ? "hidden" : "text",
      initialValue: referralCode,
      validations: {},
    },
    "custom:bonus_code": {
      label: "Bonus Code (Optional)",
      required: false,
      type: !!bonusCode ? "hidden" : "text",
      initialValue: bonusCode,
      validations: {},
    },
  };

  return (
    <Grid container spacing={0} justify={"center"}>
      <Grid item xs={12} md={6}>
        <Tabs
          history={history}
          currentPath={location.pathname}
          onChange={path => [
            !!setAuthState &&
              setAuthState((path ?? "").toString().replace(/\W/g, "")),
            history.push(path),
          ]}
        />
        <div className={classes.content}>
          <img src={require("../../assets/css/logo-alt.png")} alt="logo" />
          <Typography paragraph>
            Log In or Register to Buy or Sell tickets on PropSwap.
          </Typography>
          <Auth
            signUpOrder={signUpOrder}
            customFields={customFields}
            newAuthState={authState}
          />
        </div>
      </Grid>
    </Grid>
  );
};
