import React from "react";
import PageContainer from "Components/Layout/PageContainer";
import ContentCell from "Components/Layout/ContentCell";
import Tabs from "Components/Admin/Components/Tabs";
import ConversionTemplateNew from "Components/ConversionTemplate/New";

export default ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/campaigns"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <ConversionTemplateNew sportId={params.sportId} />
      </ContentCell>
    </PageContainer>
  </>
);
