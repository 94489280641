import React, { useCallback, useEffect, useMemo, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useQuery } from "@apollo/client";
import Listing from "shared/api/Listing";
import { commissionWaiverRedemption } from "shared/Config";

export default ({ listingId, onChange }) => {
  const { data: { getListing } = {} } = useQuery(Listing.queries.get, {
    skip: !listingId,
    variables: {
      id: listingId,
    },
  });

  const [isOn, setIsOn] = useState(!!getListing?.commissionWaiverApplied);

  const handleChange = useCallback(() => setIsOn(isOn => !isOn), [isOn]);

  useEffect(() => {
    setIsOn(!!getListing?.commissionWaiverApplied);
  }, [getListing]);

  useEffect(() => {
    !!onChange && onChange(isOn);
  }, [isOn]);

  return (
    <FormControlLabel
      control={
        <Switch checked={isOn} onChange={handleChange} color="primary" />
      }
      label={`Waive PropSwap's 10% Commission, if sold (Cost: ${Number(
        commissionWaiverRedemption
      ).toLocaleString()} Points)`}
    />
  );
};
