import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  MenuItem
} from "@material-ui/core";
import React from "react";

import TicketSettledListLitem from "./Item"

const TicketSettledListView = ({ tickets, result, onResultChange, possibleResults }) =>
  <>
    <TextField
      fullWidth
      label={"Result"}
      select
      onChange={({ target: { value } }) => onResultChange(value)}
      value={result}
      variant={"outlined"}
      SelectProps={{ displayEmpty: true }}
      InputLabelProps={{ shrink: true }}
    >
      {
        possibleResults.map(pr => <MenuItem key={pr} value={pr}>{pr}</MenuItem>)
      }
    </TextField>
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Sport</TableCell>
            <TableCell align="center">Subject</TableCell>
            <TableCell align="center">Event</TableCell>
            <TableCell align="center">Result</TableCell>
            <TableCell align="center">User</TableCell>
            <TableCell align="center">Ticket</TableCell>
            <TableCell align="center">Settled?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.map(ticket => <TicketSettledListLitem key={ticket.id} ticket={ticket} />)}
        </TableBody>
      </Table>
    </TableContainer>
  </>

export default TicketSettledListView;