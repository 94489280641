import { useMutation, useQuery } from "@apollo/client";
import { CurrentUserContext } from "gunner-react";
import React, { useContext } from "react";
import Watching from "shared/api/Watching";
import WatchingButtonView from "./WatchingButtonView";

export default ({ listing }) => {
  const currentUser = useContext(CurrentUserContext);
  const query = {
    query: Watching.queries.listByUserIdAndListingId,
    variables: {
      limit: 1,
      userId: currentUser?.id,
      listingIdCreatedAt: {
        beginsWith: {
          listingId: listing?.id,
        },
      },
    },
  };

  const { data: { listWatchingsByUserIdAndListingId: { items } = {} } = {} } =
    useQuery(query.query, {
      variables: query.variables,
      skip: !listing?.id || !currentUser?.id,
    });

  const watching = items?.[0];

  const [createWatching] = useMutation(Watching.mutations.create, {
    refetchQueries: [
      query,
      {
        query: Watching.queries.listByUserId,
        variables: {
          userId: currentUser?.id,
          limit: 100,
        },
      },
    ],
    variables: {
      input: {
        listingId: listing?.id,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      createWatching: {
        listingId: listing?.id,
        userId: currentUser?.id,
        __typename: "Watching",
      },
    },
  });

  const [deleteWatching] = useMutation(Watching.mutations.delete, {
    refetchQueries: [
      query,
      {
        query: Watching.queries.listByUserId,
        variables: {
          userId: currentUser?.id,
          limit: 100,
        },
      },
    ],
    variables: {
      input: {
        id: watching?.id,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteWatching: {
        ...watching,
        __typename: "Watching",
      },
    },
  });

  return !currentUser ? null : (
    <WatchingButtonView
      onClick={!!watching ? deleteWatching : createWatching}
      watching={watching}
    />
  );
};
