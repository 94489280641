import React from "react";
import { Grid } from "@material-ui/core";
import { TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MagnifyIcon from "mdi-material-ui/Magnify";

const useStyles = makeStyles(theme => ({
  searchField: {
    "& .input": {
      padding: theme.spacing(1),
    },
    "& .adornment": {
      margin: "0 !important",
    },
  },
}));

const Search = ({ onChange, gridProps = { xs: 6, md: 4 } }) => {
  const classes = useStyles();

  return (
    <Grid item {...gridProps}>
      <TextField
        className={classes.searchField}
        id="filled-search"
        label={false}
        type="search"
        placeholder="Search"
        margin="none"
        variant="filled"
        // onBlur={({ target: { value } }) => console.log(value) || onChange(value)}
        onChange={({ target: { value } }) => console.log(value) || onChange(value)}
        // value={searchString}
        fullWidth
        inputProps={{
          className: "input",
        }}
        // eslint-disable-next-line
        InputProps={{
          startAdornment: (
            <InputAdornment className="adornment" position="start">
              <MagnifyIcon />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
      />
    </Grid>
  );
};

export default Search;
