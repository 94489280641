import gql from "graphql-tag";
import { ticketSettledListItemFragment } from "./Item/TicketSettledItemView";
import environment from "environment";
export const ticketSettledListItemQuery = gql`
  query TicketSettledListItemQuery(
    $isSettled: String!
    $result: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByIsSettledAndResult(
      isSettled: $isSettled
      result: $result
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...TicketSettledListItemFragment
      }
      nextToken
    }
  }
  ${ticketSettledListItemFragment}
`;