import Listing from "shared/api/Listing";
import { CurrentUserContext } from "gunner-react";
import { useContext } from "react";
import { useDataObject } from "gunner-react";

export default ({ listingId, listing: passedListing }) => {
  const currentUser = useContext(CurrentUserContext);

  const listing = useDataObject({
    objectId: listingId,
    object: passedListing,
    query: Listing.queries.get,
    dataKey: "getListing",
  });

  return (
    !!currentUser?.id &&
    !!listing?.id &&
    listing?.listingSellerId === currentUser?.id &&
    ["ACTIVE", "INACTIVE", "NEW"].includes(listing?.status)
  );
};
