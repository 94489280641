import React, { useContext } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import { CurrentUserContext } from "gunner-react";
import Tabs from "../Components/Tabs";
import ContentCell from "../../Layout/ContentCell";
import PageHeader from "../../Layout/PageHeader";
import AdCell from "../../Layout/AdCell";
import PageContainer from "../../Layout/PageContainer";
import { useQuery } from "@apollo/client";
import User from "shared/api/User";
import { useEffect } from "react";
import { useState } from "react";
import { Auth } from "aws-amplify";
import awsmobile from "../../../aws-exports";
import { CognitoIdentityServiceProvider } from "aws-sdk/clients/all";
import UserVerificationModal from "../../User/Verification/Modal/UserVerificationModal";

const cognitoClient = () =>
  Auth.currentCredentials().then(credentials =>
    Promise.resolve(
      new CognitoIdentityServiceProvider({
        apiVersion: "2016-04-18",
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      })
    )
  );

const useStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    padding: theme.spacing(0, 3),
    "& h2 a": {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.highlight,
      textTransform: "none",
    },
    "& > div, & > ul": {
      flex: 1,
    },
    "& li div": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& p": {
      flex: 2,
      // display: "flex",
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.text.primary,
      "& a": {
        fontSize: "inherit",
        lineHeight: "inherit",
        color: theme.palette.text.highlight,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: "none",
      },
    },
    "& *:last-child": {
      flex: 13,
      fontWeight: theme.typography.fontWeightLight,
    },
  },
}));

const Birthday = ({ cognitoBirthDate }) => {
  // eslint-disable-next-line
  const [month, day, year] = cognitoBirthDate?.split?.(/[\-\/]/) ?? [
    "",
    "",
    "",
  ];
  // eslint-disable-next-line
  return `${month?.replace?.(/^0/, "")} / ${day?.replace?.(
    /^0/,
    ""
  )} / ${year?.replace?.(/^0/, "")}`;
};

const Settings = ({
  history,
  match: { params = {} },
  location: { search = "" } = {},
}) => {
  const classes = useStyles();
  const userId = params.userId;
  const currentUser = useContext(CurrentUserContext);
  const { data: { getUser: user = {} } = {} } = useQuery(User.queries.get, {
    variables: { withPrivate: true, id: userId || currentUser.id },
  });
  const [cognitoAttributes, setCognitoAttributes] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    currentUser.id === user.id
      ? Auth.currentAuthenticatedUser({ bypassCache: true }).then(cognitoUser =>
          setCognitoAttributes({
            ...cognitoUser.attributes,
            admin: currentUser.groups.includes("Admins"),
          })
        )
      : !user.email
      ? console.log(null)
      : cognitoClient().then(client =>
          client
            .listUsers({
              UserPoolId: awsmobile.aws_user_pools_id,
              Limit: 1,
              Filter: `email = "${user.email}"`,
            })
            .promise()
            .then(({ Users }) => Users[0])
            .then(cognitoUser =>
              Promise.all([
                cognitoUser.Attributes,
                client
                  .adminListGroupsForUser({
                    UserPoolId: awsmobile.aws_user_pools_id,
                    Username: cognitoUser.Username,
                  })
                  .promise(),
              ])
            )
            .then(([UserAttributes, { Groups }]) =>
              UserAttributes.reduce(
                (obj, currentValue) => ({
                  ...obj,
                  [currentValue.Name]: currentValue.Value,
                }),
                { admin: !!Groups.find(group => group.GroupName === "Admins") }
              )
            )
            .then(attributes => setCognitoAttributes(attributes))
        );
  }, [JSON.stringify(currentUser), JSON.stringify(user)]);

  return !cognitoAttributes ? null : (
    <>
      <UserVerificationModal closeBehavior="close" show={showModal} />
      <Tabs
        history={history}
        currentPath={"/account/settings"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell>
          <PageHeader alt>
            Personal Information&nbsp;
            <Typography component="small" variant="caption">
              <Link to={"/account/settings/edit"}>Edit</Link>
            </Typography>
          </PageHeader>
          <div className={classes.content}>
            <List dense>
              <ListItem>
                <ListItemText disableTypography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    Name
                  </Typography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    {cognitoAttributes.given_name}{" "}
                    {cognitoAttributes.family_name}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText disableTypography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    User
                  </Typography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    @{currentUser.displayName}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText disableTypography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    Password{" "}
                  </Typography>
                  <Typography component={"p"} variant={"subtitle1"}>
                    <Link to={"/account/settings/edit"}>Change Password</Link>
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText disableTypography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    Birthday
                  </Typography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    <Birthday cognitoBirthDate={cognitoAttributes.birthdate} />
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText disableTypography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    Email
                  </Typography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    {cognitoAttributes.email}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText disableTypography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    Address
                  </Typography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    {cognitoAttributes.address} <br />
                    {!!cognitoAttributes["custom:address2"] && (
                      <>
                        {cognitoAttributes["custom:address2"]}
                        <br />
                      </>
                    )}
                    {cognitoAttributes["custom:city"]},{" "}
                    {cognitoAttributes["custom:state"]} <br />
                    {cognitoAttributes["custom:zip"]}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText disableTypography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    Verified?
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      label={!!currentUser.verifiedAt ? "Yes" : "No"}
                      control={
                        <Switch
                          size="small"
                          checked={!!currentUser.verifiedAt}
                          onChange={() =>
                            !currentUser.verifiedAt && setShowModal(true)
                          }
                        />
                      }
                    />
                  </FormGroup>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText disableTypography>
                  <Typography component={"p"} variant={"subtitle2"}>
                    Referral Code
                  </Typography>
                  <Typography
                    style={{ textTransform: "none" }}
                    component={"p"}
                    variant={"subtitle2"}
                  >
                    {currentUser.referralCode}
                    <br />
                    https://propswap.com/invite/{currentUser.referralCode}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </ContentCell>
        <AdCell>
          <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};

export default Settings;
