import React, { useState, useEffect, useContext } from "react";
import { Box, Button, TextField } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import Promotion from "shared/api/Promotion";
import Redemption from "shared/api/Redemption";
import { CurrentUserContext } from "gunner-react";

const PromoCodeContainer = ({ promotion, onSuccess, method }) => {
  const currentUser = useContext(CurrentUserContext);
  const [showInput, setShowInput] = useState(true);
  const [code, setCode] = useState(promotion?.code ?? "");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [checkCode, setCheckCode] = useState(false);

  const {
    error,
    loading,
    data: { getPromotionByCode: { items: promotions = [] } = {} } = {},
  } = useQuery(Promotion.queries.getByCode, {
    variables: {
      code: code || "<>",
    },
  });

  const {
    error: redemptionError,
    data: {
      getRedemptionByUserIdAndPromotionId: { items: redemptions = [] } = {},
    } = {},
  } = useQuery(Redemption.queries.getByUserIdAndPromotionId, {
    variables: {
      redemptionUserId: currentUser.id,
    },
    pollInterval: 3000,
  });

  !!error && console.log(error);
  !!redemptionError && console.log(redemptionError);

  // useEffect(() => {
  //   method !== 'card' &&
  //   setErrorMessage("Promo Codes are only valid for credit card deposits at this time.")
  // }, [method])

  useEffect(() => {
    setCheckCode(false);
    setErrorMessage("");
    setSuccessMessage("");
  }, [code]);

  useEffect(() => {
    !!checkCode &&
      !loading &&
      (!promotions.length
        ? setErrorMessage("Invalid code.")
        : !!redemptions.find(
            redemption =>
              redemption.promotion.code === code ||
              redemption.redemptionCampaignId ===
                promotions[0].promotionCampaignId
          )
        ? setErrorMessage("This code has already been used.")
        : promotions[0].isExpired || promotions[0].campaign.isExpired
        ? setErrorMessage("That code has expired")
        : onSuccess(promotions[0]) ||
          setSuccessMessage(
            "Code Applied! Funds will be added to your Bonus Balance."
          ));
  }, [
    loading,
    JSON.stringify(promotions),
    JSON.stringify(redemptions),
    checkCode,
    code,
  ]);

  return showInput ? (
    <Box display="flex" flex="1" mt={-2}>
      <Box display="flex" flex="1">
        <TextField
          label={null}
          variant="outlined"
          // InputLabelProps={{
          //   shrink: true
          // }}
          value={code}
          placeholder="ENTER CODE"
          onChange={({ target: { value } }) => setCode(value.toUpperCase())}
          error={!!errorMessage}
          helperText={errorMessage || successMessage || ""}
          fullWidth
        />
      </Box>
      <Box height="56px" mt={2} ml={-2} display="flex">
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => setCheckCode(true)}
        >
          Apply
        </Button>
      </Box>
    </Box>
  ) : (
    <Button
      onClick={() => setShowInput(true)}
      color="primary"
      fullWidth
      variant="text"
      size="small"
    >
      Have a Promo Code?
    </Button>
  );
};

export default PromoCodeContainer;
