import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import Listing from "shared/api/Listing";
import formatOdds from "shared/Util/formatOdds";

export default ({ ticketId, placeholder }) => {
  const {
    loading: listingsLoading,
    error: listingsError,
    data: { listingsByTicketId: { items = [] } = {} } = {},
  } = useQuery(Listing.queries.listByTicketId, {
    // POLL OFF pollInterval: 3000,
    notifyOnNetworkStatusChange: false,
    variables: {
      limit: 100,
      listingTicketId: ticketId,
    },
  });

  const listing = useMemo(
    () =>
      !!items
        ? items.slice().sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))[0]
        : null,
    [JSON.stringify(items)]
  );

  const listingOdds =
    !!listing && listing.status !== "NEW"
      ? formatOdds(
          listing.ticket.collectAmount,
          listing.salesPrice ?? listing.askingPrice
        )
      : placeholder;

  return useMemo(() => <>{listingOdds}</>, [listingOdds]);
};
