import React from "react";
import CampaignList from "../../../../Components/Campaign/List";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const CampaignListRoute = ({ history }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/campaigns"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <Grid container spacing={4} justify={"flex-end"}>
          <Grid item xs={12} md={3}>
            <Button
              component={Link}
              to={`/admin/notifications/new`}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Send Notification
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              component={Link}
              to={`/admin/conversionTemplates`}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Referrals
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              component={Link}
              to={`/admin/rebates`}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Rebates
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              component={Link}
              to={`/admin/campaigns/new`}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Add Campaign
            </Button>
          </Grid>
        </Grid>
        <CampaignList />
      </ContentCell>
    </PageContainer>
  </>
);

export default CampaignListRoute;
