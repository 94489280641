import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid, MenuItem } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  mailingAddress: {
    "& .fieldsWrapper > div": {
      marginBottom: theme.spacing(2),
    },
  },
}));

const STATES = [
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arizona", "AZ"],
  ["Arkansas", "AR"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["District Of Columbia", "DC"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
];

const MailingAddress = ({ mailingInfo, onChange }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.mailingAddress}>
      <Grid item xs={12} className="fieldsWrapper">
        <TextField
          className="formControl"
          onChange={({ target: { value } }) => onChange("name", value)}
          fullWidth
          label={"Name"}
          value={mailingInfo.name}
          variant={"outlined"}
        />
        <TextField
          className="formControl"
          onChange={({ target: { value } }) => onChange("address_1", value)}
          fullWidth
          label={"Address"}
          value={mailingInfo.address_1}
          variant={"outlined"}
        />
        <TextField
          className="formControl"
          onChange={({ target: { value } }) => onChange("address_2", value)}
          fullWidth
          label={"Address 2"}
          value={mailingInfo.address_2 ?? ""}
          variant={"outlined"}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className="formControl"
          onChange={({ target: { value } }) => onChange("city", value)}
          fullWidth
          label={"City"}
          value={mailingInfo.city}
          variant={"outlined"}
        />
        <TextField
          className="formControl"
          onChange={({ target: { value } }) => onChange("state", value)}
          fullWidth
          label={"State"}
          value={mailingInfo.state.toUpperCase()}
          select
          variant={"outlined"}
        >
          {STATES.map(state => (
            <MenuItem key={state[1]} value={state[1]}>
              {state[0]}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className="formControl"
          onChange={({ target: { value } }) => onChange("zip", value)}
          fullWidth
          label={"Zip"}
          value={mailingInfo.zip}
          variant={"outlined"}
        />
      </Grid>
    </Grid>
  );
};

export default MailingAddress;
