import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import { CurrentUserContext } from "gunner-react";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  tableCellNoWrap: {
    whiteSpace: "nowrap",
  },
}));

const CustomTableRow = ({ item, children, ...rest }) => {
  return (
    <TableRow {...rest}>
      <>{children(item)}</>
    </TableRow>
  );
};

const DataTable = ({
  fields,
  search,
  items,
  defaultOrderBy = null,
  defaultSort = order => (a, b) =>
    a.createdAt > b.createdAt
      ? order === "desc"
        ? -1
        : 1
      : order === "asc"
      ? -1
      : 1,
  getRowProps = item => ({}),
  Row = CustomTableRow,
  keyField = "id",
}) => {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState("desc");
  const currentUser = useContext(CurrentUserContext);

  const handleHeaderCellClick = label =>
    label === orderBy
      ? setOrder(order === "desc" ? "asc" : "desc")
      : setOrderBy(label);

  const selectedField = Object.entries(fields).find(
    ([label, field]) => label === orderBy
  );
  const sortFunc = !!selectedField
    ? selectedField[1].sort(order)
    : defaultSort(order);

  return (
    <div className={classes.root}>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {Object.entries(fields).map(([label, field]) => (
              <TableCell
                className={classes.tableCellNoWrap}
                key={label}
                sortDirection={orderBy === label ? order : false}
              >
                {!field.sort ? (
                  !!field.hideLabel ? (
                    ""
                  ) : (
                    label
                  )
                ) : (
                  <TableSortLabel
                    active={orderBy === label}
                    direction={order}
                    onClick={handleHeaderCellClick.bind(null, label)}
                  >
                    {!!field.hideLabel ? "" : label}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items
            .slice()
            .sort(sortFunc)
            .filter(
              item =>
                !!Object.entries(fields)
                  .map(([label, field]) =>
                    !!field.searchValue
                      ? field.searchValue(item)
                      : field.value(item)
                  )
                  .join(", ")
                  .includes(search || "")
            )
            .map(item => (
              <Row item={item} hover key={item[keyField]}>
                {item =>
                  Object.entries(fields).map(([label, field]) => (
                    <TableCell
                      key={label}
                      className={
                        !!field.nowrap ? classes.tableCellNoWrap : null
                      }
                    >
                      {field.value(item, currentUser)}
                    </TableCell>
                  ))
                }
              </Row>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DataTable;
