import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import HelpCircleOutlineIcon from "mdi-material-ui/HelpCircleOutline";
import ScoreboardOutlineIcon from "mdi-material-ui/Counter";
// import BellIcon from 'mdi-material-ui/BellOutline';
import AccountSupervisorIcon from "mdi-material-ui/AccountSupervisor";
import Container from "@material-ui/core/Container";
import NumberFormat from "react-number-format";
import classNames from "classnames";

import MyAccountButton from "./MyAccountButton";
import { Typography } from "@material-ui/core";
import Moneybag from "./Icons/Moneybag";
import ReactGA from "react-ga";
import { Cache } from "aws-amplify";

const useStyles = makeStyles(theme => ({
  nav: {
    backgroundImage: `url(${require("../../assets/css/nav-background.png")})`,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 0),
    "& .wrapper": {
      [theme.breakpoints.up("md")]: {
        padding: 0,
      },
      "& ul": {
        listStyleType: "none",
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        padding: 0,
        margin: 0,
        paddingTop: theme.spacing(0.5),
      },
    },
    "& .item": {
      display: "flex",
      textTransform: "uppercase",
      marginLeft: theme.spacing(2),
      color: theme.palette.common.white,
      "&:hover": {
        color: theme.palette.secondary.main,
      },
      "& span": {
        display: "flex",
      },
      "& p": {
        color: theme.palette.common.white,
        "& .more": {
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },
        "& .alt": {
          color: theme.palette.secondary.main,
        },
      },
      "& a": {
        display: "flex",
        color: "inherit",
        "&:hover": {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
}));

const NavItem = ({
  component = Link,
  alt = false,
  ContentLarge,
  ContentFull,
  url = "/",
  onClick,
  eventLabel,
}) => {
  return (
    <li className={"item"}>
      <Typography variant="body1">
        {!!eventLabel ? (
          <ReactGA.OutboundLink eventLabel={eventLabel} to={url}>
            {ContentFull}
            &nbsp;
            {!!ContentLarge && (
              <span
                className={classNames("more", { alt: !!alt })}
                to={url}
                onClick={onClick}
              >
                {ContentLarge}
              </span>
            )}
          </ReactGA.OutboundLink>
        ) : (
          <Typography
            href={component === "a" ? url : undefined}
            component={component}
            to={url}
            onClick={onClick}
          >
            {ContentFull}
            &nbsp;
            {!!ContentLarge && (
              <span
                className={classNames("more", { alt: !!alt })}
                to={url}
                onClick={onClick}
              >
                {ContentLarge}
              </span>
            )}
          </Typography>
        )}
      </Typography>
    </li>
  );
};

const Nav = ({ currentUser }) => {
  const classes = useStyles();
  !!currentUser && Cache.removeItem("referralCode");
  !!currentUser && Cache.removeItem("bonusCode");

  return (
    <nav className={classes.nav} justify="flex-end">
      <Container fixed className={"wrapper"}>
        <ul>
          <NavItem
            url="/pages/faq"
            ContentLarge={"Help"}
            ContentFull={<HelpCircleOutlineIcon />}
          />
          {/* <NavItem eventLabel="gracenote" component="a" url="https://propswap.sports.gracenote.com/football/nfl/scores" ContentLarge={"Scores"} ContentFull={<ScoreboardOutlineIcon />} /> */}
          {!!currentUser && (
            <>
              {!!currentUser.groups.includes("Admins") && (
                <NavItem
                  url={"/admin/users"}
                  ContentLarge={"Admin"}
                  ContentFull={<AccountSupervisorIcon />}
                />
              )}
              {/* <NavItem ContentLarge={"Alerts"} ContentFull={<BellIcon />} /> */}
              {/* <NavItem ContentLarge={"Watch"} ContentFull={<BinocularsIcon className={classes.navListItemIcon} />} /> */}
              <NavItem
                alt
                url={"/account/deposit"}
                ContentLarge={
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={
                      parseFloat(
                        (currentUser.balance || 0) +
                          (currentUser.promotionalBalance || 0) +
                          (currentUser.pendingBalance || 0)
                      ) / 100.0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                }
                ContentFull={<Moneybag />}
              />
              <NavItem
                component="span"
                ContentFull={
                  <MyAccountButton
                    currentUser={currentUser}
                    className={classes.navListItemLink}
                  />
                }
              />
            </>
          )}
          {!currentUser && (
            <>
              <NavItem ContentFull={"Login/Register"} url="/sign-in" />
            </>
          )}
        </ul>
      </Container>
    </nav>
  );
};

export default Nav;
