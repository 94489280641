import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import { Hidden, Typography, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuDownIcon from "mdi-material-ui/MenuDown";

const useStyles = makeStyles(theme => ({
  filterOptions: {
    "& label": {
      display: "flex",
      cursor: "pointer",
    },
    "& a": {
      cursor: "pointer",
    },
  },
}));

export default ({ betType, onChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(
    event => setAnchorEl(event.currentTarget),
    []
  );

  const handleClose = useCallback(
    filter => () => [!!onChange && onChange(filter), setAnchorEl(null)],
    []
  );

  return (
    <Grid className={classes.filterOptions} item xs={3} md={5}>
      <Hidden mdUp>
        <div onClick={handleClick}>
          <Typography variant={"subtitle1"} component="label">
            Type&nbsp;
            <Hidden smDown>Range</Hidden>
            <MenuDownIcon />
          </Typography>
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={handleClose("All")}>All</MenuItem>
          <MenuItem onClick={handleClose("PARLAY")}>Parlays</MenuItem>
          <MenuItem onClick={handleClose("NO-PARLAY")}>No Parlays</MenuItem>
        </Menu>
      </Hidden>
      <Hidden smDown>
        <Grid container alignItems="center" item xs={12}>
          <Typography variant={"subtitle1"} component="label">
            Bet Type:&nbsp;&nbsp;
          </Typography>
          <Typography
            onClick={handleClose("All")}
            component={betType === "All" ? "span" : "a"}
          >
            All
          </Typography>
          &nbsp;&nbsp;
          <Typography
            onClick={handleClose("PARLAY")}
            component={betType === "PARLAY" ? "span" : "a"}
          >
            Parlays
          </Typography>
          &nbsp;&nbsp;
          <Typography
            onClick={handleClose("NO-PARLAY")}
            component={betType === "NO-PARLAY" ? "span" : "a"}
          >
            No Parlays
          </Typography>
          &nbsp;&nbsp;
        </Grid>
      </Hidden>
    </Grid>
  );
};
