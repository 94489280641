import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

// import useEventListStyles from "./EventListStyles";
import DataTable from "../../DataTable";

const fields = {
  "PropSwap Id": {
    value: item => item.id,
    sort: order => (a, b) =>
      a.id > b.id ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  Group: {
    value: item =>
      !!item.group?.name ? (
        <Link to={`/admin/sports/${item.sportId}/groups/${item.groupId}/edit`}>
          {item.group?.name}
        </Link>
      ) : (
        ""
      ),
    sort: order => (a, b) =>
      (a.group?.name ?? "") > (b.group?.name ?? "")
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Description: {
    value: item => item.description,
    sort: order => (a, b) =>
      a.description > b.description
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Views: {
    value: item => item.viewCount,
    sort: order => (a, b) =>
      a.viewCount > b.viewCount
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Active: {
    value: item => (item.isActive?.toLowerCase?.() === "x" ? "Yes" : "No"),
    sort: order => (a, b) =>
      !!a.isActive > !!b.isActive
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  "FanDuel Event Id": {
    value: item => item.fanDuelEventId,
    sort: order => (a, b) =>
      a.fanDuelEventId > b.fanDuelEventId
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  "FanDuel Market Id": {
    value: item => item.fanDuelMarketId,
    sort: order => (a, b) =>
      a.fanDuelMarketId > b.fanDuelMarketId
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Featured: {
    value: item => (!!item.featuredAt ? "Yes" : "No"),
    sort: order => (a, b) =>
      a.featuredAt > b.featuredAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  "Rebate Active?": {
    value: item => (!!item.rebateActive ? "Yes" : "No"),
    sort: order => (a, b) =>
      a.rebateActive > b.rebateActive
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Odds: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/events/${item.id}/odds`}
      >
        Odds
      </Button>
    ),
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/sports/${item.sportId}/events/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
  Options: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/events/${item.id}/options`}
      >
        Options
      </Button>
    ),
  },
};

const EventListView = ({ showInactive, events }) => (
  <DataTable
    fields={fields}
    items={events}
  />
);

export default EventListView;
