export const nonMutationFields = [
  "sport",
  "user",
  "originalOwner",
  "locale",
  "listings",
  "event",
  "option",
  "event",
  "sportsbook",
  "cachedBets",
  "__typename",
];

export const adminOnlyFields = [
  "source",
  "result",
  "inPropswapsPossession",
  "reviewed",
];
