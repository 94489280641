import React from "react";
import EventOptionsListView from "./EventOptionsListView";
import { gql, useQuery } from "@apollo/client";
import { getEventQuery, optionGroupForEventOptionsFragment } from "./Item/EventOptionLIstItemContainer";



const listOptionGroupsForEventOptionsQuery = gql`
  query ListOptionGroupsForEventOptionsQuery($groupId: ID!) {
    listOptionGroupsByGroupId(groupId: $groupId) {
      items {
        ...OptionGroupForEventOptionsFragment
      }
    }
  }
  ${optionGroupForEventOptionsFragment}
`;

export default ({ eventId }) => {
  const { data: { getEvent } = {}} = useQuery(getEventQuery, {
    skip: !eventId,
    variables: {
      id: eventId
    }
  });

  const { data: { listOptionGroupsByGroupId: { items: optionGroups = [] } = {} } = {} } = useQuery(listOptionGroupsForEventOptionsQuery, {
    skip: !getEvent?.groupId,
    variables: {
      groupId: getEvent?.groupId,
      limit: 200
    }
  });

  return <EventOptionsListView event={getEvent} optionGroups={optionGroups} />
}