import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import RedemptionListView from "./RedemptionListView";
import Redemption from "shared/api/Redemption";
import { Button, CircularProgress } from "@material-ui/core";

export default ({ promotionId }) => {
  const [token, setToken] = useState(null);
  const [redemptions, setRedemptions] = useState([]);
  const {
    error,
    loading,
    data: {
      listRedemptionsByPromotionIdCreatedAt: { nextToken, items } = {},
    } = {},
  } = useQuery(Redemption.queries.listByPromotionIdCreatedAt, {
    variables: {
      limit: 100,
      redemptionPromotionId: promotionId,
      nextToken: token,
    },
  });

  useEffect(() => {
    console.log(items);
    setRedemptions(redemptions => [
      ...redemptions,
      ...(items ?? []).filter(item => !redemptions.find(r => r.id === item.id)),
    ]);
  }, [items]);

  !!error && console.log(error);

  return (
    <>
      <RedemptionListView redemptions={redemptions} />
      {!!nextToken && !loading && nextToken !== token && (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => setToken(nextToken)}
        >
          Load More
        </Button>
      )}
      {(!!loading || nextToken !== token) && (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
