import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import User from "shared/api/User";
import { CircularProgress } from "@material-ui/core";

const UserLinkContainer = ({ id, skipLoading = false }) => {
  const { loading, data: { getUser: user } = {} } = useQuery(User.queries.get, {
    variables: { id },
  });

  return loading ? (
    !skipLoading && <CircularProgress size="small" color="secondary" />
  ) : !!user ? (
    <Link to={`/admin/users/${id}/edit`}>{user.displayName}</Link>
  ) : null;
};

export default UserLinkContainer;
