import React, { useContext, useState, useEffect } from "react";
import { CurrentUserContext } from "gunner-react";
import Modal from "../../../Layout/Modal";
import { useMutation } from "@apollo/client";
import User from "shared/api/User";
import { Box, TextField, Typography } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { useRef } from "react";
import { Auth } from "aws-amplify";

export default ({ show, closeBehavior = "close" }) => {
  const ssnField = useRef(null);
  const history = useHistory();
  const currentUser = useContext(CurrentUserContext);
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [ssn, setSsn] = useState(null);
  const [firstName, setFirstName] = useState(
    currentUser?.attributes?.given_name
  );
  const [lastName, setLastName] = useState(
    currentUser?.attributes?.family_name
  );
  const [zip, setZip] = useState(`${currentUser?.attributes?.["custom:zip"]}`);
  const [address, setAddress] = useState(
    `${currentUser?.attributes?.address} ${
      currentUser?.attributes?.["custom:address2"] || ""
    }`
  );
  const [state, setState] = useState(
    `${currentUser?.attributes?.["custom:state"]}`
  );
  const [city, setCity] = useState(
    `${currentUser?.attributes?.["custom:city"]}`
  );
  const [mobile, setMobile] = useState(
    `${currentUser?.attributes?.["custom:mobile"]}`
  );
  const [birthday, setBirthday] = useState(
    `${currentUser?.attributes?.["birthdate"]}`
  );
  const [email, setEmail] = useState(`${currentUser?.email}`);
  const [verifyUser, { error, data: { verifyUser: user } = {} }] = useMutation(
    User.mutations.verify,
    {
      skip: !ssn,
      variables: {
        input: {
          id: currentUser?.id,
          ssn,
          firstName,
          lastName,
          zip,
        },
      },
      refetchQueries: [
        {
          query: User.queries.get,
          variables: {
            id: currentUser?.id,
          },
        },
      ],
    }
  );

  const isValid = (ssn || "").replace(/\D/g, "").length === 4;

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(cognitoUser => [
      setFirstName(cognitoUser?.attributes?.given_name),
      setLastName(cognitoUser?.attributes?.family_name),
      setZip(`${cognitoUser?.attributes?.["custom:zip"]}`),
      setAddress(
        `${cognitoUser?.attributes?.address} ${
          cognitoUser?.attributes?.["custom:address2"] || ""
        }`
      ),
      setState(`${cognitoUser?.attributes?.["custom:state"]}`),
      setCity(`${cognitoUser?.attributes?.["custom:city"]}`),
      setMobile(`${cognitoUser?.attributes?.["custom:mobile"]}`),
      setBirthday(`${cognitoUser?.attributes?.["birthdate"]}`),
      setEmail(`${cognitoUser?.attributes?.email}`),
    ]);
  }, []);

  useEffect(() => {
    setShowModal(!!show);
  }, [show]);

  useEffect(() => {
    !!ssnField?.current && ssnField.current.focus();
  }, [ssnField]);

  useEffect(() => {
    !!submitting && verifyUser().then(console.log).catch(console.log);
  }, [submitting]);

  useEffect(() => {
    !!user &&
      (!!user.verifiedAt
        ? Promise.all([setSubmitting(false), setShowModal(false)])
        : Promise.all([
            setSubmitting(false),
            setErrorMessage(`Your ID could not be verified. Please review the information and confirm all items are correct and up-to-date. If you need to make a change, you can Edit your Personal Information and try again. 

          If you need further assistance please contact info@propswap.com`),
          ]));
  }, [!!user]);

  useEffect(() => {
    !!error &&
      Promise.all([
        setSubmitting(false),
        setErrorMessage(`Your ID could not be verified. Please review the information and confirm all items are correct and up-to-date. If you need to make a change, you can Edit your Personal Information and try again. 

      If you need further assistance please contact info@propswap.com`),
      ]);
  }, [!!error]);

  !!error && console.log(error);

  return (
    <Modal
      title="Please Confirm Your Identity"
      body={
        <>
          <Box component="form">
            <TextField
              ref={ssnField}
              margin="normal"
              required
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Enter Last 4 Digits of Social Security Number"
              helperText={
                <>
                  {errorMessage ? (
                    <>
                      Your ID could not be verified. Please review the
                      information and confirm all items are correct and
                      up-to-date. If you need to make a change, you can{" "}
                      <Link to="/account/settings/edit">Edit</Link> your
                      Personal Information and try again. If you need further
                      assistance please contact info@propswap.com
                    </>
                  ) : (
                    <>
                      Before you can Buy or Sell you must complete this step.
                      PropSwap does NOT store your SSN. We only use it to
                      prevent fraud by verifying your identity. <br />
                      <br />
                      If any of the below information is incorrect, you may
                      change it on{" "}
                      <Link to="/account/settings/edit">
                        your settings page
                      </Link>
                      .
                    </>
                  )}
                </>
              }
              error={!!errorMessage}
              onChange={({ target: { value } }) => setSsn(value)}
              autoFocus={true}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              disabled
              value={firstName}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="First Name"
              helperText={errorMessage ? errorMessage : ""}
              error={!!errorMessage}
              onChange={({ target: { value } }) => setFirstName(value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              disabled
              value={lastName}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Last Name"
              helperText={errorMessage ? errorMessage : ""}
              error={!!errorMessage}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              disabled
              value={city}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="City"
              helperText={errorMessage ? errorMessage : ""}
              error={!!errorMessage}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              disabled
              value={address}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Address"
              helperText={errorMessage ? errorMessage : ""}
              error={!!errorMessage}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              disabled
              value={state}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="State"
              helperText={errorMessage ? errorMessage : ""}
              error={!!errorMessage}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              disabled
              value={zip}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Zip"
              helperText={errorMessage ? errorMessage : ""}
              error={!!errorMessage}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              disabled
              value={birthday}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Date of Birth"
              helperText={errorMessage ? errorMessage : ""}
              error={!!errorMessage}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              disabled
              value={email}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Email"
              helperText={errorMessage ? errorMessage : ""}
              error={!!errorMessage}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              disabled
              value={mobile}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Mobile"
              helperText={errorMessage ? errorMessage : ""}
              error={!!errorMessage}
            />
          </Box>
        </>
      }
      onClose={() =>
        closeBehavior === "close" ? setShowModal(false) : history.goBack()
      }
      submitting={!!submitting}
      opened={!!showModal}
      saveButton={{
        text: "Submit",
        disabled: !isValid || !!submitting,
        onClick: () => setSubmitting(true),
        ButtonProps: {
          fullWidth: true,
        },
      }}
    />
  );
};
