import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import NumberFormat from "react-number-format";
// import Button from '@material-ui/core/Button';
// import ButtonGroup from '@material-ui/core/ButtonGroup';

import { CurrentUserContext } from "gunner-react";

import Listing from "shared/api/Listing";
import Ticket from "shared/api/Ticket";
import formatOdds from "shared/Util/formatOdds";
import Modal from "../../Layout/Modal";
import NumberFormatCustom from "../../Layout/NumberFormatCustom";
import { commissionAdjust, commissionWaiverRedemption } from "shared/Config";
import ListingWaiverToggle from "Components/Listing/WaiverToggle";

const New = ({ onClose, history, match: { params } }) => {
  const currentUser = useContext(CurrentUserContext);
  const [modalOpen, setModalOpen] = useState(true);
  const [saving, setSaving] = useState(false);
  const [newAskingPrice, setNewAskingPrice] = useState(null);
  const [newMinimumBidPrice, setNewMinimumBidPrice] = useState(0);
  const [commissionWaiverApplied, setCommissionWaiverApplied] = useState(false);

  const { loading, data: { getTicket: ticket = {} } = {} } = useQuery(
    Ticket.queries.get,
    {
      variables: {
        id: params.ticketId,
      },
    }
  );

  const newListingData = {
    listingTicketId: ticket.id,
    newOdds: formatOdds(parseFloat(ticket.collectAmount), newAskingPrice),
    newOddsCalculated:
      parseFloat(ticket.collectAmount) / parseFloat(newAskingPrice),
    listingSellerId: currentUser.id,
    askingPrice: newAskingPrice,
    minimumBidPrice: newMinimumBidPrice || 0,
    commissionWaiverApplied,
  };

  const payout =
    (!commissionWaiverApplied
      ? parseFloat(newAskingPrice) * commissionAdjust
      : parseFloat(newAskingPrice) > 100000
      ? 100000 + (parseFloat(newAskingPrice) - 100000) * commissionAdjust
      : parseFloat(newAskingPrice)) / 100.0;

  const [_createListing] = useMutation(Listing.mutations.create, {
    variables: {
      input: {
        ...newListingData,
      },
    },
    refetchQueries: [
      {
        query: Ticket.queries.listByUserIdWithListingsAndBids,
        variables: {
          ticketUserId: currentUser.id,
          sortDirection: "DESC",
          limit: 500,

          listingsLimit: 1,
          listingsSortDirection: "DESC",

          bidsLimit: 1000,
          bidsSortDirection: "DESC",
        },
      },
      {
        query: Listing.queries.listBySellerIdWithBids,
        variables: {
          listingSellerId: currentUser.id,
          sortDirection: "DESC",
          limit: 500,
          status: {
            eq: "ACTIVE",
          },

          bidsLimit: 1000,
          bidsSortDirection: "DESC",
        },
      },
    ],
  });

  useEffect(() => {
    !modalOpen && onClose();
  }, [modalOpen]);

  useEffect(() => {
    !!saving &&
      _createListing()
        .then(({ data: { createNewListing } }) => [
          setModalOpen(false),
          setSaving(false),
        ])
        .catch(error => [
          console.log(error),
          window.alert(error.message),
          setModalOpen(true),
          setSaving(false),
        ]);
  }, [saving]);

  return (
    <Modal
      opened={!!modalOpen}
      title={
        !!loading ? null : (
          <>
            {ticket.subject} <br />
            <Box color="secondary.main" component="span">
              {ticket.eventDescription}
            </Box>
          </>
        )
      }
      body={
        !!loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <div style={{ paddingTop: 8 }}>
            <Grid container alignItems="center" justify="center" spacing={2}>
              <Grid item xs={12}>
                <form noValidate autoComplete="off">
                  <Box mb={4}>
                    <TextField
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputMode: "decimal",
                        type: "text",
                      }}
                      onChange={({ target: { value } }) =>
                        setNewAskingPrice(parseInt(value) * 100)
                      }
                      value={
                        !!newAskingPrice
                          ? parseFloat(newAskingPrice / 100.0)
                          : ""
                      }
                      label="Sell Price"
                      fullWidth
                      variant="outlined"
                      helperText={`If Sold, you will receive ${
                        commissionWaiverApplied ? "100%" : "90%"
                      } of the Sale Price.`}
                      inputMode="decimal"
                      inputProps={{ inputMode: "decimal" }}
                      type="text"
                    />
                  </Box>
                  <Box mb={4}>
                    <TextField
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputMode: "decimal",
                        type: "text",
                      }}
                      onChange={({ target: { value } }) =>
                        setNewMinimumBidPrice(
                          parseInt(parseFloat(value) * 100.0)
                        )
                      }
                      value={parseFloat(newMinimumBidPrice) / 100.0}
                      label="Minimum Bid"
                      fullWidth
                      variant="outlined"
                      inputMode="decimal"
                      inputProps={{ inputMode: "decimal" }}
                      type="text"
                    />
                  </Box>
                </form>
                {currentUser?.groups?.includes?.("Admins") &&
                  currentUser?.availablePoints >=
                    commissionWaiverRedemption && (
                    <ListingWaiverToggle
                      onChange={setCommissionWaiverApplied}
                    />
                  )}
              </Grid>

              <Grid container item xs={12} justify="center">
                <Grid item xs={6}>
                  <Typography variant={"subtitle2"}>Payout</Typography>
                  <Typography variant="h6">
                    {!!newAskingPrice && (
                      <NumberFormat
                        fixedDecimalScale
                        decimalScale={2}
                        value={Math.abs(payout)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={"subtitle2"}>Odds</Typography>
                  <Typography variant="h6">
                    {!!newAskingPrice && newListingData.newOdds}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )
      }
      saving={saving}
      onClose={() => setModalOpen(false)}
      saveButton={{
        disabled:
          !!saving ||
          !ticket ||
          !parseInt(newAskingPrice || 0) ||
          ticket.collectAmount < parseInt(newAskingPrice || 0) ||
          parseInt(newMinimumBidPrice || 0) >= parseInt(newAskingPrice || 0),
        text: !!saving ? "Listing..." : "List",
        onClick: () => setSaving(true),
        ButtonProps: {
          fullWidth: true,
        },
      }}
    />
  );
};

export default New;
