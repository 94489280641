import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import AdCell from "../../Layout/AdCell";
import ContentCell from "../../Layout/ContentCell";
import PageContainer from "../../Layout/PageContainer";
import { Typography, Grid, Button, Box } from "@material-ui/core";
import PageHeader from "../../Layout/PageHeader";

const useStyles = makeStyles(theme => ({
  step: {
    display: "flex",
    marginTop: theme.spacing(2),
    "& .iconWrapper": {
      width: 65,
      marginRight: theme.spacing(2),
      "& img": {
        width: "100%",
        height: "auto",
      },
    },
    "& .contentWrapper": {
      flex: 1,
    },
  },
}));

const Step = ({ heading, body, number }) => {
  const classes = useStyles();
  return (
    <div className={classes.step}>
      <div className="iconWrapper">
        <img
          src={require(`../../../assets/css/step${number}.png`)}
          alt="Step"
        />
      </div>
      <div className="contentWrapper">
        <Typography variant={`h5`}>
          Step {number}: {heading}
        </Typography>
        <Typography variant={"body1"} paragraph>
          {body}
        </Typography>
      </div>
    </div>
  );
};

const Sell = () => {
  return (
    <>
      <PageContainer>
        <ContentCell>
          <Grid container spacing={4} justify={"center"}>
            <Grid
              item
              xs={12}
              container
              justify={"flex-end"}
              alignItems={"center"}
            >
              <Box mr={1}>
                <Typography variant="subtitle1">
                  I don't need any selling tips.
                </Typography>
              </Box>
              <Button
                variant={"contained"}
                color={"secondary"}
                component={Link}
                to={"/tickets/new"}
              >
                Continue
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box p={4} bgcolor="background">
                <div className="video-responsive">
                  <iframe
                    title="1"
                    width="1280"
                    height="720"
                    src="https://www.youtube.com/embed/speGSaNAiOo"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <Box pt={2} bgcolor="background">
                  <Typography variant="h5">Intro to PropSwap</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <PageHeader alt>How to sell your ticket</PageHeader>
            </Grid>
            <Grid item xs={12}>
              <Step
                number={1}
                heading={"Want to Sell?"}
                body={
                  <>
                    <Link to={`/tickets/new`}>Click Here</Link> to list your
                    ticket for sale. If you are a new user, you must register
                    for an account first.
                  </>
                }
              />
              <Step
                number={2}
                heading={"List Your Ticket"}
                body={`Snap a photo of your slip, enter the ticket info, set your Sale Price, enter your credit card info (for insurance purposes only) and click "Create". You're all set!`}
              />
              <Step
                number={3}
                heading={"Control Your Ticket"}
                body={
                  <>
                    You can always change the Sale Price or Unlist your ticket
                    by logging into{" "}
                    <Link to={"/account/tickets"}>My Tickets</Link> and clicking
                    the "EDIT" button.
                  </>
                }
              />
              <Step
                number={4}
                heading={"Get Paid"}
                body={`Once it sells, we'll let you know via email with directions on how to mail in your slip to our office. In order to receive your money, you must mail in your slip within 7 calendar days.`}
              />
            </Grid>
            <Grid container item xs={12} lg={6} justify={"center"}>
              <Button
                fullWidth
                variant={"contained"}
                color={"secondary"}
                size="large"
                component={Link}
                to={"/tickets/new"}
              >
                Start Selling
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Box p={4} mt={4} bgcolor="background">
                <div className="video-responsive">
                  <iframe
                    title="2"
                    width="1280"
                    height="720"
                    src="https://www.youtube.com/embed/wYYkoyQMhK8"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <Box pt={2} bgcolor="background">
                  <Typography variant="h5">
                    How to Make Money with PropSwap
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <PageHeader alt>Real Life Stories</PageHeader>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant={"subtitle1"}>Fail</Typography>
                <Typography variant="body1" paragraph>
                  Last year, Tommy a Texas Tech bettor declined $125,000 for his
                  200/1 Red Raiders to Win the NCAA Basketball Championship
                  ticket, that he only spent $1,500 on. Texas Tech went on to
                  lose by 8 in the final game.
                </Typography>
              </Box>
              <Box>
                <Typography variant={"subtitle1"}>Win!</Typography>
                <Typography variant="body1" paragraph>
                  Brad from Philadelphia sold his New Orleans Saints Super Bowl
                  Futures ticket for $57,500 (collect of $168,000) days before
                  the NFC Championship game vs. the Rams. Brad used the money to
                  take his wife on a trip to Paris. True story.
                </Typography>
              </Box>
              <Box>
                <Typography variant={"subtitle1"}>Fail</Typography>
                <Typography variant="body1" paragraph>
                  Eddie the Alabama fan decided not to use PropSwap before the
                  Heisman Trophy ceremony when he had a chance to sell his Tua
                  Tagovailoa ticket for $20,000. Instead he turned down the
                  guaranteed cash, and betting slip turned into a drink coaster.
                  Whoops!
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ContentCell>
        <AdCell>
          <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};

export default Sell;
