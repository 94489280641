import gql from "graphql-tag";
import environment from "environment";

const Redirect = {};

Redirect.fragments = {
  public: gql`
    fragment RedirectPublic on Redirect {
      id
      updatedAt
      createdAt

      source
      destination
    }
  `,
};

Redirect.mutations = {
  update: gql`
    mutation UpdateRedirect($input: UpdateRedirectInput!) {
      updateRedirect(input: $input) {
        ...RedirectPublic
      }
    }
    ${Redirect.fragments.public}
  `,
  create: gql`
    mutation CreateRedirect($input: CreateRedirectInput!) {
      createRedirect(input: $input) {
        ...RedirectPublic
      }
    }
    ${Redirect.fragments.public}
  `,
  delete: gql`
    mutation DeleteRedirect($input: DeleteRedirectInput!) {
      deleteRedirect(input: $input) {
        ...RedirectPublic
      }
    }
    ${Redirect.fragments.public}
  `,
};

Redirect.queries = {
  listBySource: gql`
    query ListRedirectsBySource(
      $source: String!
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelRedirectFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listRedirectsBySource(
        source: $source
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...RedirectPublic
        }
        nextToken
      }
    }
    ${Redirect.fragments.public}
  `,
  get: gql`
    query GetRedirect($id: ID!) {
      getRedirect(id: $id) {
        ...RedirectPublic
      }
    }
    ${Redirect.fragments.public}
  `,
  list: gql`
    query ListRedirects(
      $filter: ModelRedirectFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listRedirects(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...RedirectPublic
        }
        nextToken
      }
    }
    ${Redirect.fragments.public}
  `,
};

export default Redirect;
