import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import {
  MenuItem,
  TextField,
  Grid,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import HelpLabelContainer from "../../Layout/HelpLabel";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: "332px",
  },
  buttonsContainer: {
    marginTop: theme.spacing(4),
  },
}));

const AchForm = ({ submitting, onCancel, onSubmit, cardHolderName, zip }) => {
  const classes = useStyles();
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [bankInfo, setBankInfo] = useState({});

  const handleSubmit = () => onSubmit(bankInfo);

  useEffect(() => {
    setIsFormValid(
      !Object.values(bankInfo).find(value => !value) &&
        !!Object.values(bankInfo).length
    );
  }, [Object.values(bankInfo).join(" ")]);

  return (
    <div className={classes.root}>
      <form className={classes.container} noValidate autoComplete="off">
        <TextField
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={event => event.preventDefault()}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={"Account #"}
          helperText={
            <HelpLabelContainer
              modalTitle="Account and Routing Numbers"
              ModalBody={
                <Box flex={1}>
                  <img
                    src={require("../../../assets/images/checks.jpg")}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </Box>
              }
            />
          }
          className={classes.textField}
          value={bankInfo.accountNumber || ""}
          onChange={({ target: { value } }) =>
            setBankInfo({
              ...bankInfo,
              accountNumber: value,
            })
          }
          type={showPassword ? "text" : "password"}
          margin="normal"
        />
        <TextField
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          label="Routing #"
          helperText={
            <HelpLabelContainer
              modalTitle="Account and Routing Numbers"
              ModalBody={
                <Box flex={1}>
                  <img
                    src={require("../../../assets/images/checks.jpg")}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </Box>
              }
            />
          }
          className={classes.textField}
          value={bankInfo.routingNumber || ""}
          onChange={({ target: { value } }) =>
            setBankInfo({
              ...bankInfo,
              routingNumber: value,
            })
          }
          margin="normal"
        />
        <TextField
          fullWidth
          select
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          label="Account Type"
          className={classes.textField}
          value={bankInfo.accountType || ""}
          onChange={({ target: { value } }) =>
            setBankInfo({
              ...bankInfo,
              accountType: value,
            })
          }
          margin="normal"
        >
          <MenuItem value=""></MenuItem>
          <MenuItem value={`CONSUMER_CHECKING`}>Consumer Checking</MenuItem>
          <MenuItem value={`CONSUMER_SAVINGS`}>Consumer Savings</MenuItem>
          <MenuItem value={`CORPORATE_CHECKING`}>Corporate Checking</MenuItem>
          <MenuItem value={`CORPORATE_SAVINGS`}>Corporate Savings</MenuItem>
        </TextField>

        <FormHelperText>
          Please note: Funds deposited via ACH may take up to 5 business days to
          be available in your PropSwap account.
        </FormHelperText>

        <Grid className={classes.buttonsContainer} container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              disabled={submitting || !isFormValid}
              variant={"contained"}
              color={"secondary"}
              onClick={handleSubmit}
            >
              {!!submitting ? "Submitting" : "Add"}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            {!!onCancel && (
              <Button fullWidth onClick={onCancel} disabled={submitting}>
                Cancel
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AchForm;
