import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Hidden, Typography, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuDownIcon from "mdi-material-ui/MenuDown";

const useStyles = makeStyles(theme => ({
  sortOptions: {
    "& label": {
      display: "flex",
      cursor: "pointer",
    },
  },
}));

const SortOptions = ({
  sortDirection,
  onChangeField,
  onChangeDirection,
  sortField,
  adminView,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleChange = ({ field, direction }) => [
    onChangeField(field),
    onChangeDirection(direction),
    handleClose(),
  ];

  return (
    <Grid item xs={3} md={2} className={classes.sortOptions}>
      <div onClick={handleClick}>
        <Typography variant={"subtitle1"} component="label">
          Sort&nbsp;
          <Hidden smDown>By</Hidden>
          <MenuDownIcon />
        </Typography>
      </div>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            handleChange({ field: "updatedAt", direction: "DESC" })
          }
        >
          Most Recent
        </MenuItem>
        <MenuItem
          onClick={() => handleChange({ field: "expiresAt", direction: "ASC" })}
        >
          Expiring Soonest
        </MenuItem>
        {!!adminView && (
          <MenuItem
            onClick={() =>
              handleChange({ field: "salesPrice", direction: "DESC" })
            }
          >
            Sold For (High to Low)
          </MenuItem>
        )}
        {!!adminView && (
          <MenuItem
            onClick={() =>
              handleChange({ field: "salesPrice", direction: "ASC" })
            }
          >
            Sold For (Low to High)
          </MenuItem>
        )}
        <MenuItem
          onClick={() =>
            handleChange({ field: "askingPrice", direction: "DESC" })
          }
        >
          Price (High to Low)
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleChange({ field: "askingPrice", direction: "ASC" })
          }
        >
          Price (Low to High)
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleChange({ field: "ticketCollectAmount", direction: "DESC" })
          }
        >
          Collects (High to Low)
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleChange({ field: "ticketCollectAmount", direction: "ASC" })
          }
        >
          Collects (Low to High)
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleChange({ field: "newOddsCalculated", direction: "DESC" })
          }
        >
          Odds (High to Low)
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleChange({ field: "newOddsCalculated", direction: "ASC" })
          }
        >
          Odds (Low to High)
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default SortOptions;
