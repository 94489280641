import React from "react";

import NumberFormat from "react-number-format";

import { Link } from "react-router-dom";
import moment from "moment";

import { Box } from "@material-ui/core";
import TicketListView from "./TicketListView";
import { FieldCheckbox, FieldSelectBox, SelectCheckbox } from "../Form/index";

const fields = {
  Sport: {
    value: item => item.sport.abbrev,
    sort: order => (a, b) =>
      a.sport.abbrev > b.sport.abbrev
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  "Bet Type": {
    value: item => item.betType,
    sort: order => (a, b) =>
      a.betType > b.betType
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Event: {
    value: item => (
      <Box width="100px">
        <Link to={`/tickets/${item.id}`}>{item.eventDescription}</Link>
      </Box>
    ),
    sort: null,
    searchValue: item => item.eventDescription,
  },
  Subject: {
    value: item => (
      <Box width="100px">
        <Link to={`/tickets/${item.id}`}>{item.subject}</Link>
      </Box>
    ),
    sort: null,
    searchValue: item => item.subject,
  },
  Collects: {
    value: item => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        value={parseFloat(item.collectAmount) / 100.0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.collectAmount > b.collectAmount
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  Odds: {
    value: item => item.odds,
    sort: order => (a, b) =>
      a.odds > b.odds ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  Updated: {
    value: item => moment(item.updatedAt).format("MM/DD/YYYY H:mm"),
    sort: order => (a, b) =>
      a.updatedAt > b.updatedAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  // "Mobile?": {
  //   value: item => <FieldCheckbox ticket={item} field="isMobile" label="" />,
  //   sort: null
  // },
  // "Possession?": {
  //   value: item => <FieldCheckbox ticket={item} field="inPropswapsPossession" label="" />,
  //   sort: null
  // },
  // "Reviewed?": {
  //   value: item => <FieldCheckbox ticket={item} field="reviewed" label="" />,
  //   sort: null
  // },
  Select: {
    value: item => <SelectCheckbox ticket={item} label="" />,
    sort: null,
  },
  Result: {
    sort: null,
    value: item => (
      <FieldSelectBox
        options={["WIN", "LOSS", "PUSH", "UNDECIDED"]}
        field="result"
        ticket={item}
      />
    ),
  },
};

export default ({
  searchString,
  result,
  reviewed,
  inPropswapsPossession,
  isMobile,
  onItemsChange,
  clientFilter,
}) => {
  return (
    <TicketListView
      clientFilter={clientFilter}
      searchString={searchString}
      fields={fields}
      result={result}
      reviewed={reviewed}
      inPropswapsPossession={inPropswapsPossession}
      isMobile={isMobile}
      onItemsChange={onItemsChange}
    />
  );
};
