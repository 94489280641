import { useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import Option from "shared/api/Option";

const OptionsContext = React.createContext();

const OptionsProvider = ({ children }) => {
  // const [options, setOptions] = useState([]);
  // const [token, setToken] = useState(null);

  // const { data: { listOptions: { nextToken, items } = {} } = {} } = useQuery(
  //   Option.queries.list,
  //   {
  //     variables: {
  //       limit: 100,
  //       nextToken: token,
  //       filter: {
  //         active: {
  //           eq: true,
  //         },
  //       },
  //     },
  //   }
  // );

  // useEffect(() => {
  //   !!nextToken && setToken(nextToken);
  // }, [nextToken]);

  // useEffect(() => {
  //   setOptions(options => [...options, ...(items ?? [])]);
  // }, [items]);

  return (
    <OptionsContext.Provider value={[]}>
      {children}
    </OptionsContext.Provider>
  );
};

export { OptionsContext, OptionsProvider };
