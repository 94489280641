import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { Link } from 'react-router-dom';

import { CurrentUserContext } from "gunner-react";
import Tabs from "../Components/Tabs";
import ContentCell from "../../Layout/ContentCell";
import PageHeader from "../../Layout/PageHeader";
import AdCell from "../../Layout/AdCell";
import PageContainer from "../../Layout/PageContainer";
import CognitoEdit from "../../Cognito/Edit";
import UserConvertPointsToCash from "Components/User/ConvertPointsToCash";

const useStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    padding: theme.spacing(0, 3),
    "& h2 a": {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.highlight,
      textTransform: "none",
    },
    "& > div, & > ul": {
      flex: 1,
    },
    "& li div": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& p": {
      flex: 2,
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.text.primary,
      "& a": {
        fontSize: "inherit",
        lineHeight: "inherit",
        color: theme.palette.text.highlight,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: "none",
      },
    },
    "& p:last-child": {
      flex: 13,
      fontWeight: theme.typography.fontWeightLight,
    },
  },
}));

const Birthday = ({ cognitoBirthDate }) => {
  // eslint-disable-next-line
  const [month, day, year] = cognitoBirthDate.split(/[\-\/]/);
  // eslint-disable-next-line
  return `${month.replace(/^0/, "")} / ${day.replace(
    /^0/,
    ""
  )} / ${year.replace(/^0/, "")}`;
};

const SettingsEdit = ({ history, location: { search = "" } = {} }) => {
  const classes = useStyles();
  const currentUser = useContext(CurrentUserContext);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/account/settings"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell>
          <PageHeader alt>
            Personal Information&nbsp;
            {/* <Typography component="small" variant="caption"><Link to={'/account/edit'}>Edit</Link></Typography> */}
            {/* <Typography component="small" variant="caption">@{currentUser.displayName}</Typography> */}
          </PageHeader>
          <CognitoEdit />
          {!!currentUser && currentUser.groups.includes("Admins") && (
            <UserConvertPointsToCash />
          )}
        </ContentCell>
        <AdCell>
          <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};

export default SettingsEdit;
