import React from "react";
import Activity from "shared/api/Activity";
import { DataTable } from "gunner-react/web";
import moment from "moment";
import UserLink from "../../User/Link";
import { Button } from "@material-ui/core";

const fields = {
  Type: {
    value: item => item.type,
    nowrap: true,
    sort: order => (a, b) =>
      a.type > b.type ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
    rowProps: {},
  },
  Title: {
    value: item => `${item.title}: ${item.body}`,
    nowrap: true,
    sort: null,
    rowProps: {},
  },
  At: {
    value: item => moment(item.createdAt).format("MM/DD/YYYY H:mm"),
    nowrap: true,
    sort: order => (a, b) =>
      a.createdAt > b.createdAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  User: {
    value: item => <UserLink id={item.userId} />,
    sort: null,
  },
  Data: {
    value: item => (
      <Button
        onClick={() => window.alert(JSON.stringify(JSON.parse(item.data)))}
        color="secondary"
        variant="contained"
        size="small"
      >
        View
      </Button>
    ),
    sort: null,
  },
};

export default ({ model, modelId }) => (
  <DataTable
    fields={fields}
    search={null}
    dataListParams={{
      query: Activity.queries.listByModelAndModelId,
      dataKey: "activitiesByModelAndModelId",
      variables: {
        model,
        modelId: {
          eq: modelId,
        },
      },
    }}
  />
);
