import React from "react";
import AdFormContainer from "../Form/AdFormContainer";

export default ({ ad, query, mutation, dataKey }) => (
  <AdFormContainer
    ad={ad}
    query={query}
    mutation={mutation}
    dataKey={dataKey}
  />
);
