import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
// import classNames from 'classnames';

// import Nav from "./Nav"
import { Hidden, Typography } from "@material-ui/core";

import { version } from "../../../package.json"

const useStyles = makeStyles(theme => ({
  footer: {
    margin: theme.spacing(6, 0),
    "& a": {
      ...theme.typography.h6,
      display: "block",
      textTransform: "uppercase",
      color: theme.palette.background.default,
      padding: theme.spacing(0.5, 0),
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
    "& p": {
      color: theme.palette.common.white,
      padding: theme.spacing(0.5, 0),
    },
  },
}));

const Footer = ({ currentUser }) => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container fixed className={classes.headerContainer}>
        <Grid container spacing={0} justify={"space-around"}>
          <Grid item xs={12} md={3}>
            <Link to={"/"}>Contact</Link>
            {/* <Typography component={'p'} variant={'caption'}>
              7582 Las Vegas Blvd S. Ste 347<br />
              Las Vegas, NV<br />
              89123
            </Typography> */}
            <Typography component={"p"} variant={"caption"}>
              info@propswap.com
            </Typography>
            {/* <Typography component={'p'} variant={'caption'}>
              (844) 776-7792
            </Typography> */}
          </Grid>
          <Grid item xs={12} md={3}>
            <Hidden smDown>
              <Link to={"/pages/faq"}>FAQs</Link>
              <Link to={"/pages/about"}>About PropSwap</Link>
              {/* <Link to={'/'}>
                Careers
              </Link>
              <Link to={'/'}>
                Responsible Gaming
              </Link> */}
            </Hidden>
          </Grid>
          <Grid item xs={12} md={3}>
            <Hidden smDown>
              {!currentUser && <Link to={"/sign-in"}>Login / Register</Link>}
              {!!currentUser && <Link to={"/sign-out"}>Sign Out</Link>}
              <Link to={"/account/settings"}>My Account</Link>
              <Link to={"/"}>Buy</Link>
              <Link to={"/tickets/new"}>Sell</Link>
            </Hidden>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <Hidden smDown> */}
            <Link to={"/"}>My Tickets</Link>
            {/* <Link to={'/'}>
                Alerts
              </Link> */}
            {/* <Link to={'/'}>
                Watch
              </Link> */}
            {/* <Link to={'/'}>
                Help
              </Link> */}
            <Link to={"/"}>
              Screen Size:&nbsp;
              <Hidden only={["xs", "sm", "md", "lg"]}>XL</Hidden>
              <Hidden only={["xs", "sm", "md", "xl"]}>LG</Hidden>
              <Hidden only={["xs", "sm", "lg", "xl"]}>MD</Hidden>
              <Hidden only={["xs", "md", "lg", "xl"]}>SM</Hidden>
              <Hidden only={["sm", "md", "lg", "xl"]}>XS</Hidden>
            </Link>
            <Link to={"/"}>Version: {version}</Link>
            {/* </Hidden> */}
          </Grid>
          <Grid item xs={12}>
            <Typography component={"p"} variant={"caption"}>
              Must be 21 years of age or older. Please bet responsibly. Gaming
              problem? Call 1-800-GAMBLER.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
