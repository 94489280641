import React from "react";
import FanduelListEventsView from "./FanduelListEventsView";
import FanduelListEventsStyles from "./FanduelListEventsStyles";
import useStyles from "Hooks/useStyles";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export default () => {
  const { theme, styles } = useStyles({ Styles: FanduelListEventsStyles });
  const query = gql`
    query FanDuelListEventTypes {
      fanDuelListEventTypes {
        eventType {
          id
          name
        }
        marketCount
      }
    }
  `;

  const result = useQuery(query);

  return (
    <FanduelListEventsView
      query={query}
      theme={theme}
      styles={styles}
      items={result?.data?.fanDuelListEventTypes}
    />
  );
};
