import React from "react";

import NumberFormat from "react-number-format";

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    fixedDecimalScale={false}
    allowEmptyFormatting={false}
    decimalScale={2}
    getInputRef={inputRef}
    allowNegative={false}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    thousandSeparator
    prefix="$"
  />
);

export default NumberFormatCustom;
