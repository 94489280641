import React from "react";
import environment from "environment";
import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import gql from "graphql-tag";

const query = gql`
  query ListAdsByPosition(
    $active: StringBoolean!
    $position: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdsByPosition(
      active: $active
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        lightModeImageUrl
        darkModeImageUrl
        url
        position
        active
      }
      nextToken
    }
  }
`;

const mutation = gql`
  mutation DeleteAd($input: DeleteAdInput!) {
    deleteAd(input: $input) {
      id
      createdAt
      updatedAt
      lightModeImageUrl
      darkModeImageUrl
      url
      position
      active
    }
  }
`;

export default ({
  id,
  buttonProps = { size: "small", color: "secondary" },
}) => {
  const [deleteAd] = useMutation(mutation, {
    variables: {
      input: {
        id,
      },
    },
    refetchQueries: [
      {
        query,
        variables: {
          active: "YES",
        },
      },
      {
        query,
        variables: {
          active: "NO",
        },
      },
    ],
  });

  const handleDelete = () => !!window.confirm("Are you sure?") && deleteAd();

  return (
    <Button onClick={handleDelete} {...buttonProps}>
      Delete
    </Button>
  );
};
