import React from "react";

//import TicketOdds from '../../Odds';

import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import SellerBidCard from "../../../Bid/Components/SellerCard";

// import Rating from '../../Rating';
// import BuyButton from './BuyButton';
// import BidButton from '../../Bid/Components/CreateButton';

import { Box } from "@material-ui/core";

import TicketOptionsButton from "../../OptionsButton";
import TicketOddsContainer from "../../Odds";
import TicketLatestSuggestedPrice from "Components/Ticket/LatestSuggestedPrice";
import moment from "moment";
import WatchingButton from "Components/Watching/Button";

export default ({
  listing,
  bid,
  ticket,
  highBid,
  theme,
  classes = {},
  canEditListing,
  canCreateListing,
  onEditPress,
  onCreatePress,
  onBidPress,
  status,
  currentUser,
  onShippedChanged,
}) => (
  <Grid key={ticket.id} container spacing={4}>
    <Grid
      item
      xs={12}
      md={["ACTIVE", "PENDING"].includes(status) ? 8 : 12}
      lg={["ACTIVE", "PENDING"].includes(status) ? 9 : 12}
      style={{ marginBottom: -32 }}
    >
      <Card className={classes.listing} elevation={0}>
        <CardHeader
          className={"header"}
          disableTypography={true}
          title={
            <Grid container spacing={0} justify={"space-around"}>
              <Grid
                item
                xs={6}
                container
                justify="flex-start"
                alignItems="center"
                style={{ position: "relative" }}
              >
                <Typography variant={"subtitle1"}>
                  {ticket.sport.abbrev}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                justify="flex-end"
                alignItems="center"
              >
                <Typography
                  component={"p"}
                  align={"right"}
                  variant={"subtitle1"}
                >
                  {ticket.betType}
                </Typography>
                {currentUser?.id === ticket.ticketUserId && (
                  <TicketOptionsButton ticket={ticket} listing={listing} />
                )}
                {currentUser?.groups?.includes?.("Admins") && !!listing && (
                  <WatchingButton listing={listing} />
                )}
              </Grid>
            </Grid>
          }
        />
        <CardContent className={"content"}>
          {status === "ACTIVE" && (
            <Box ml={-2} mr={2}>
              <img
                src={
                  listing?.status === "ACTIVE"
                    ? require("../../../../assets/css/for-sale-icon.png")
                    : require("../../../../assets/css/not-for-sale-icon.png")
                }
                alt="Sale"
              />
            </Box>
          )}
          <Grid container spacing={0}>
            <Grid className={"titleRow"} item xs={12}>
              <Typography variant={"h6"} component={"h3"}>
                {!listing ? (
                  <Link to={`/tickets/${ticket.id}`}>{ticket.subject}</Link>
                ) : (
                  <Link to={`/tickets/${ticket.id}/listings/${listing.id}`}>
                    {ticket.subject}
                  </Link>
                )}
                &nbsp;&nbsp;
              </Typography>
              <Typography variant={"h6"} component={"h3"}>
                {ticket.eventDescription}
              </Typography>
            </Grid>
            <Grid container spacing={0} item xs={12}>
              <Grid item xs={3} lg={2}>
                <Typography variant={"subtitle2"}>Odds</Typography>
                <Typography variant={"body2"}>
                  <TicketOddsContainer
                    placeholder={!!listing ? listing.newOdds : ticket.odds}
                    listing={listing}
                  />
                </Typography>
              </Grid>
              <Grid item xs={3} lg={2}>
                <Typography variant={"subtitle2"}>Collect</Typography>
                <NumberFormat
                  renderText={text => (
                    <Typography
                      variant={"body2"}
                      style={text.length > 10 ? { fontSize: "0.775rem" } : null}
                    >
                      {text}
                    </Typography>
                  )}
                  fixedDecimalScale
                  decimalScale={2}
                  value={parseFloat(ticket.collectAmount) / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Grid>
              {!!listing && listing.status !== "NEW" && (
                <Grid item xs={3} lg={2}>
                  <Typography variant={"subtitle2"}>Price</Typography>
                  <Typography variant={"body2"}>
                    <NumberFormat
                      fixedDecimalScale
                      decimalScale={2}
                      value={
                        parseFloat(listing.salesPrice || listing.askingPrice) /
                        100.0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Typography>
                </Grid>
              )}
              {!!listing && listing.status !== "NEW" && (
                <Grid item xs={3} lg={2}>
                  <Typography variant={"subtitle2"}>
                    Min/High&nbsp;Bid
                  </Typography>
                  <Typography variant={"body2"}>
                    {!highBid ? (
                      !!listing?.minimumBidPrice ? (
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={parseFloat(listing?.minimumBidPrice) / 100.0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      ) : (
                        <>N/A</>
                      )
                    ) : (
                      <NumberFormat
                        fixedDecimalScale
                        decimalScale={2}
                        value={parseFloat(highBid.offer) / 100.0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    )}
                  </Typography>
                </Grid>
              )}
              {listing.listingSellerId === currentUser?.id &&
                !!["PENDING"].includes(listing?.status) && (
                  <Grid item xs={3} lg={2}>
                    <Typography variant={"subtitle2"}>Shipped?</Typography>
                    <Typography variant={"body2"}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={!!ticket.hasShipped}
                              checked={!!ticket.hasShipped}
                              onChange={onShippedChanged}
                              value={true}
                            />
                          }
                        />
                      </FormGroup>
                    </Typography>
                  </Grid>
                )}
              {!!ticket.ticketOptionId &&
                !!ticket.ticketEventId &&
                !!["ACTIVE", "NEW", "INACTIVE"].includes(listing?.status) && (
                  <Grid item xs={3} lg={2}>
                    <Typography variant={"subtitle2"}>Suggested</Typography>
                    <Typography variant={"body2"}>
                      <TicketLatestSuggestedPrice ticket={ticket} />
                    </Typography>
                  </Grid>
                )}
            </Grid>
            {!!listing.expiresAt && ["ACTIVE"].includes(listing?.status) && (
              <Grid container spacing={0} item xs={12}>
                <Grid item xs={12}>
                  <Typography variant={"body2"}>
                    {`${
                      listing.expiresAt >= new Date().toISOString()
                        ? "Expires"
                        : "Expired"
                    } ${moment(listing.expiresAt).fromNow()}`}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          {
            //!!ContentEndAdornment && ContentEndAdornment
          }
        </CardContent>
        {/* {
          !!Actions && 
          <CardActions>
            {Actions}
          </CardActions>
        } */}
      </Card>
    </Grid>
    {status === "ACTIVE" && (
      <Grid
        item
        xs={12}
        md={["ACTIVE", "PENDING"].includes(status) ? 4 : 12}
        lg={["ACTIVE", "PENDING"].includes(status) ? 3 : 12}
      >
        <SellerBidCard
          currentUser={currentUser}
          listing={listing}
          ticket={ticket}
          bidCount={listing?.bidCount ?? 0}
          activeBid={highBid}
        />
      </Grid>
    )}
  </Grid>
);
