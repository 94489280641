import { useTheme } from "@material-ui/core/styles";
// import GlobalStyles from 'Components/Styles';
import { useMemo } from "react";

export default ({ Styles } = {}) => {
  const theme = useTheme();
  const styles = useMemo(() => ({}), [theme]);

  return useMemo(
    () => ({
      styles,
      theme,
    }),
    [theme, styles]
  );
};
