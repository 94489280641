import React, { useState, useEffect } from "react";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
import { Link, Button } from "@material-ui/core";
import DataTable from "Components/DataTable";
import Tabs from "Components/Admin/Components/Tabs";
import BovadoOddsLookUp from "Components/Bovado/OddsLookUp";

export default ({ history, location: { search = "" } = {} }) => {
  const fieldsMap = {
    global: {
      ID: {
        value: item => (
          <Link onClick={() => setSportId(item.id)}>{item.id}</Link>
        ),
        sort: order => (a, b) =>
          a.id > b.id ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
      },
      Name: {
        value: item => item.name,
        sort: order => (a, b) =>
          a.name > b.name
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      Description: {
        value: item => item.description,
        sort: order => (a, b) =>
          a.description > b.description
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
    },
    sport: {
      ID: {
        value: item => (
          <Link onClick={() => setLeagueId(item.id)}>{item.id}</Link>
        ),
        sort: order => (a, b) =>
          a.id > b.id ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
      },
      Name: {
        value: item => item.name,
        sort: order => (a, b) =>
          a.name > b.name
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
    },
    league: {
      ID: {
        value: item => (
          <Link onClick={() => setCouponId(item.id)}>{item.id}</Link>
        ),
        sort: order => (a, b) =>
          a.id > b.id ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
      },
      Name: {
        value: item => item.name,
        sort: order => (a, b) =>
          a.name > b.name
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
    },
    coupon: {
      ID: {
        value: item => item.id,
        sort: order => (a, b) =>
          a.id > b.id ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
      },
      Name: {
        value: item => item.name,
        sort: order => (a, b) =>
          a.name > b.name
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      Groups: {
        value: item => (
          <>
            {item.marketgroups.map(group => (
              <React.Fragment key={group.idfwmarketgroup}>
                <Link onClick={() => setGroupId(group.idfwmarketgroup)}>
                  {group.name}
                </Link>
                <br />
              </React.Fragment>
            ))}
          </>
        ),
        sort: order => (a, b) =>
          a.name > b.name
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
    },
    group: {
      ID: {
        value: item => (
          <Link onClick={() => setEventId(item.id)}>{item.id}</Link>
        ),
        sort: order => (a, b) =>
          a.id > b.id ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
      },
      Name: {
        value: item => item.eventname,
        sort: order => (a, b) =>
          a.name > b.name
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
    },
    group: {
      ID: {
        value: item => (
          <Link onClick={() => setEventId(item.id)}>{item.id}</Link>
        ),
        sort: order => (a, b) =>
          a.id > b.id ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
      },
      Name: {
        value: item => item.eventname,
        sort: order => (a, b) =>
          a.name > b.name
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
    },
    event: {
      ID: {
        value: item => (
          <Link onClick={() => setMarketId(item.id)}>{item.id}</Link>
        ),
        sort: order => (a, b) =>
          a.id > b.id ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
      },
      Name: {
        value: item => item.name,
        sort: order => (a, b) =>
          a.name > b.name
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
    },
    market: {
      ID: {
        value: item => item.id,
        sort: order => (a, b) =>
          a.id > b.id ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
      },
      "Market ID": {
        value: item => item.idfomarket,
        sort: order => (a, b) =>
          a.idfomarket > b.idfomarket
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      "Event ID": {
        value: item => eventId,
        sort: order => (a, b) =>
          eventId > eventId
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      "Group ID": {
        value: item => groupId,
        sort: order => (a, b) =>
          groupId > groupId
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      "Participant Id": {
        value: item => item.idfoparticipant,
        sort: order => (a, b) =>
          a.idfoparticipant > b.idfoparticipant
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      "Participant Name": {
        value: item => item.name,
        sort: order => (a, b) =>
          a.name > b.name
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      Price: {
        value: item => item.price,
        sort: order => (a, b) =>
          a.price > b.price
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      "Short Name": {
        value: item => item.shortname,
        sort: order => (a, b) =>
          a.shortname > b.shortname
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      currenthandicap: {
        value: item => item.currenthandicap,
        sort: order => (a, b) =>
          a.currenthandicap > b.currenthandicap
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      currentmatchhandicap: {
        value: item => item.currentmatchhandicap,
        sort: order => (a, b) =>
          a.currentmatchhandicap > b.currentmatchhandicap
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      ewfactor: {
        value: item => item.ewfactor,
        sort: order => (a, b) =>
          a.ewfactor > b.ewfactor
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      hadvalue: {
        value: item => item.hadvalue,
        sort: order => (a, b) =>
          a.hadvalue > b.hadvalue
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      lowerband: {
        value: item => item.lowerband,
        sort: order => (a, b) =>
          a.lowerband > b.lowerband
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      upperband: {
        value: item => item.upperband,
        sort: order => (a, b) =>
          a.upperband > b.upperband
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      sppricedown: {
        value: item => item.sppricedown,
        sort: order => (a, b) =>
          a.sppricedown > b.sppricedown
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      upperband: {
        value: item => item.sppriceup,
        sort: order => (a, b) =>
          a.sppriceup > b.sppriceup
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      "Current Price Down": {
        value: item => item.currentpricedown,
        sort: order => (a, b) =>
          a.currentpricedown > b.currentpricedown
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      "Current Price Up": {
        value: item => item.currentpriceup,
        sort: order => (a, b) =>
          a.currentpriceup > b.currentpriceup
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      "Current Score": {
        value: item => item.currentscore,
        sort: order => (a, b) =>
          a.currentscore > b.currentscore
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      "Estimated Price Down": {
        value: item => item.estimatedpricedown,
        sort: order => (a, b) =>
          a.estimatedpricedown > b.estimatedpricedown
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
      "Estimated Price Up": {
        value: item => item.estimatedpriceup,
        sort: order => (a, b) =>
          a.estimatedpriceup > b.estimatedpriceup
            ? order === "desc"
              ? -1
              : 1
            : order === "asc"
            ? -1
            : 1,
      },
    },
  };
  const [items, setItems] = useState([]);
  const [eventItems, setEventItems] = useState([]);
  const [sportId, setSportId] = useState(null);
  const [leagueId, setLeagueId] = useState(null);
  const [couponId, setCouponId] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [marketId, setMarketId] = useState(null);

  const handleBack = () =>
    !!marketId
      ? setMarketId(null)
      : !!eventId
      ? setEventId(null)
      : !!groupId
      ? setGroupId(null)
      : !!couponId
      ? setCouponId(null)
      : !!leagueId
      ? setLeagueId(null)
      : setSportId(null);

  const fields =
    fieldsMap[
      !!marketId
        ? "market"
        : !!eventId
        ? "event"
        : !!groupId
        ? "group"
        : !!couponId
        ? "coupon"
        : !!leagueId
        ? "league"
        : !!sportId
        ? "sport"
        : "global"
    ];
  const scopedItems = !!marketId
    ? eventItems
        .find(item => item["idfoevent"] === eventId)
        .markets.find(item => item["idfomarket"] === marketId)
        .selections.map(item => ({ ...item, id: item.idfoselection }))
    : !!eventId
    ? eventItems
        .find(item => item["idfoevent"] === eventId)
        .markets.map(item => ({ ...item, id: item.idfomarket }))
    : !!groupId
    ? eventItems
    : !!couponId
    ? items
        .find(item => item["idfwbonavigation"] === sportId)
        .bonavigationnodes.find(item => item["idfwbonavigation"] === leagueId)
        .bonavigationnodes.find(item => item["idfwbonavigation"] === couponId)
        .bonavigationnodes.map(item => ({ ...item, id: item.idfwbonavigation }))
    : !!leagueId
    ? items
        .find(item => item["idfwbonavigation"] === sportId)
        .bonavigationnodes.find(item => item["idfwbonavigation"] === leagueId)
        .bonavigationnodes.map(item => ({ ...item, id: item.idfwbonavigation }))
    : !!sportId
    ? items
        .find(item => item["idfwbonavigation"] === sportId)
        .bonavigationnodes.map(item => ({ ...item, id: item.idfwbonavigation }))
    : items;

  useEffect(() => {
    fetch("https://sportsbook.fanduel.com/cache/psbonav/1/UK/top.json")
      .then(response => response.json())
      .then(json =>
        setItems(
          (json.bonavigationnodes ?? []).map(item => ({
            ...item,
            id: item.idfwbonavigation,
          }))
        )
      );
  }, []);

  useEffect(() => {
    !!groupId &&
      fetch(`https://sportsbook.fanduel.com/cache/psmg/UK/${groupId}.json`)
        .then(response => response.json())
        .then(json =>
          setEventItems(
            (json.events ?? []).map(item => ({ ...item, id: item.idfoevent }))
          )
        );
  }, [groupId]);

  console.log(scopedItems);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/odds"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <BovadoOddsLookUp />
          {!!sportId && <Button onClick={handleBack}>Back</Button>}
          <DataTable fields={fields} items={scopedItems} />
        </ContentCell>
      </PageContainer>
    </>
  );
};
