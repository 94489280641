import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Box } from "@material-ui/core";
import NumberFormat from "react-number-format";
import PromoCode from "../PromoCode";
import { ALLOWED_STATES } from "shared/Config";

const useStyles = makeStyles(theme => ({
  summary: {
    display: "flex",
    flexDirection: "column",
    "& > div": {
      display: "flex",
      flex: 1,
    },
    "& .label": {
      flex: 2,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& .value": {
      flex: 1,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& .total": {
      borderTop: "1px solid gray",
      paddingTop: theme.spacing(1),
    },
  },
}));

const Summary = ({
  bonusAmount: passedBonusAmount,
  paymentType,
  currentUser,
  submitting,
  amount = "2500",
  valid = false,
  onSubmit,
  promotion,
  onPromotionSet,
} = {}) => {
  const classes = useStyles();
  const creditCardFeePercent = 0.03;
  const achFeePercent = 0.015;
  const fee =
    (paymentType === "ach" ? achFeePercent : creditCardFeePercent) *
    parseFloat(amount);
  const total = fee + parseFloat(amount);

  const bonusAmount = !!passedBonusAmount
    ? passedBonusAmount
    : !!promotion
    ? Math.min(
        parseInt(amount),
        promotion.type === "DEPOSIT_MATCH_100" ? 10000 : 50000
      )
    : 0;

  // useEffect(() => {
  //   onPromotionSet(promotion);
  // }, [promotion?.id])

  return (
    <div className={classes.summary}>
      <div>
        <Typography paragraph className={"label"}>
          Deposit Amount
        </Typography>
        <Typography paragraph className={"value"}>
          <NumberFormat
            fixedDecimalScale
            decimalScale={2}
            value={parseFloat(amount) / 100.0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </Typography>
      </div>
      <div>
        <Typography paragraph className={"label"}>
          Service Fee
        </Typography>
        <Typography paragraph className={"value"}>
          <NumberFormat
            fixedDecimalScale
            decimalScale={2}
            value={parseFloat(fee) / 100.0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </Typography>
      </div>
      {!!bonusAmount && (
        <div>
          <Typography color="secondary" paragraph className={"label"}>
            Bonus Amount
          </Typography>
          <Typography color="secondary" paragraph className={"value"}>
            <NumberFormat
              fixedDecimalScale
              decimalScale={2}
              value={parseFloat(bonusAmount) / 100.0}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </Typography>
        </div>
      )}
      <div className={"total"}>
        <Typography paragraph className={"label"}>
          Amount to be Charged
        </Typography>
        <Typography paragraph className={"value"}>
          <NumberFormat
            fixedDecimalScale
            decimalScale={2}
            value={parseFloat(total) / 100.0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </Typography>
      </div>
      <div>
        <Typography paragraph className={"label"}>
          New Available Balance
        </Typography>
        <Typography paragraph className={"value"}>
          <NumberFormat
            fixedDecimalScale
            decimalScale={2}
            value={
              parseFloat(
                parseInt(amount ?? 0) +
                  parseInt(bonusAmount ?? 0) +
                  parseInt(currentUser?.balance ?? 0) +
                  parseInt(currentUser?.promotionalBalance ?? 0)
              ) / 100.0
            }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </Typography>
      </div>
      <Button
        onClick={onSubmit}
        disabled={!valid || !amount || !!submitting}
        fullWidth
        variant="contained"
        color="secondary"
      >
        {!!submitting ? "Please Wait" : "Deposit"}
      </Button>
      {/* <PromoCode promotion={promotion} onSuccess={onPromotionSet} /> */}
      <Box mt={2} component="section">
        <Typography variant="body2">
          <strong>
            You MUST be in one of the following states to place a deposit.
          </strong>
        </Typography>
        <Typography variant="body2">
          {ALLOWED_STATES.map(state => state).join(", ")}
        </Typography>
      </Box>
    </div>
  );
};

export default Summary;
