import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import Form from "../../Form";
import Locale from "shared/api/Locale";
import { withoutKeys } from "gunner-react";
import { Box, Typography } from "@material-ui/core";
import { STATES } from "shared/Config";
import LocaleSportsbookSelect from "../Sportsbook/Select";

const fields = {
  state: {
    label: "State",
    regex: /\w+/,
    required: true,
    type: "text",
    options: STATES.map(([name, abbrev]) => ({ value: name, label: name })),
    errorMessage: "You are required to select a state",
    helperText: "Please Select the State for this locale",
  },
  name: {
    label: "Name",
    regex: /\w+/,
    required: true,
    type: "text",
    errorMessage: "You are required enter a name",
  },
  mailingAddress: {
    label: "Mailing Address",
    regex: /\w+/,
    required: true,
    type: "text",
    rows: 4,
    errorMessage: "You are required to enter a mailing address",
  },
  additionalEmails: {
    label: "Emails",
    regex: /\w+/,
    required: false,
    type: "text",
    rows: 4,
    helperText:
      "(Optional) Add a comma-separated list of email addresses that will be notified whenever a ticket sells",
  },
};

export default ({ locale }) => {
  const [formData, setFormData] = useState(locale || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: value,
    });

  const [_createLocale] = useMutation(Locale.mutations.create, {
    variables: {
      input: {
        ...formData,
      },
    },
    refetchQueries: [
      {
        query: Locale.queries.list,
        variables: { limit: 100 },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      createLocale: {
        ...formData,
        __typename: "Locale",
      },
    },
  });

  const [_updateLocale] = useMutation(Locale.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, ["__typename"]),
      },
    },
    refetchQueries: [
      {
        query: Locale.queries.list,
        variables: { limit: 100 },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updateLocale: {
        ...formData,
        __typename: "Locale",
      },
    },
  });

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateLocale : _createLocale)() //if this is an existing listing, update it, otherwise, create it
        .then(
          ({
            data: {
              [!!formData.id ? "updateListing" : "createListing"]: listing,
            },
          }) => setSubmitSuccess(true)
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [doSubmit]);

  return !!submitSuccess ? (
    <Redirect push to={"/admin/locales"} />
  ) : (
    <>
      <Form
        submitting={false}
        fields={fields}
        onChange={handleChange}
        data={formData}
        onSubmit={() => setDoSubmit(true)}
        buttonLabel={"Submit"}
      />
      <Box mb={2}>
        <Typography variant="h4">Select Sportsbooks</Typography>
        <LocaleSportsbookSelect localeId={locale?.id} />
      </Box>
    </>
  );
};
