import React from "react";

import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

import Form from "../Form";

import Locale from "shared/api/Locale";

const LocaleEditContainer = ({ id }) => {
  const { loading, data: { getLocale: locale } = {} } = useQuery(
    Locale.queries.get,
    { variables: { id: id } }
  );

  return !!loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Form locale={locale} />
  );
};

export default LocaleEditContainer;
