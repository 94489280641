import React from "react";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export default ({ onClick, watching }) => (
  <IconButton size={"small"} onClick={onClick}>
    {!watching ? <VisibilityIcon /> : <VisibilityOffIcon />}
  </IconButton>
);
