import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import Redirect from "shared/api/Redirect";

export default ({
  id,
  buttonProps = { size: "small", color: "secondary" },
}) => {
  const [_deleteRedirect] = useMutation(Redirect.mutations.delete, {
    variables: {
      input: {
        id,
      },
    },
    refetchQueries: [
      {
        query: Redirect.queries.list,
      },
    ],
  });

  const handleDelete = () =>
    !!window.confirm("Are you sure?") && _deleteRedirect();

  return (
    <Button onClick={handleDelete} {...buttonProps}>
      Delete
    </Button>
  );
};
