import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import Locale from "shared/api/Locale";

const LocaleDeleteButtonContainer = ({
  id,
  buttonProps = { size: "small", color: "secondary" },
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [doDelete, setDoDelete] = useState(false);
  const [_deleteLocale] = useMutation(Locale.mutations.delete, {
    variables: {
      input: {
        id,
      },
    },
    refetchQueries: [
      {
        query: Locale.queries.list,
        variables: { limit: 100 },
      },
    ],
  });

  useEffect(() => {
    !!doDelete &&
      _deleteLocale()
        .then(() => [setDoDelete(false), setShowConfirm(false)])
        .catch(console.error);
  }, [doDelete]);

  useEffect(() => {
    !!showConfirm &&
      Promise.resolve(
        window.confirm(
          "Are you sure? Any tickets with this locale will be orphaned which can result in errors"
        )
      ).then(confirmed =>
        confirmed ? setDoDelete(true) : setShowConfirm(false)
      );
  }, [showConfirm]);

  return (
    <Button onClick={() => setShowConfirm(true)} {...buttonProps}>
      Delete
    </Button>
  );
};

export default LocaleDeleteButtonContainer;
