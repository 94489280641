import React from "react";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

import Form from "../Form";

import Event from "shared/api/Event";

const EventEditContainer = ({ id }) => {
  const { loading, data: { getEvent: event } = {} } = useQuery(
    Event.queries.get,
    { variables: { id: id } }
  );

  return !!loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Form event={event} />
  );
};

export default EventEditContainer;
