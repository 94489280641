import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import { Box } from "@material-ui/core";
import { useMutation } from "@apollo/client";

import Bid from "shared/api/Bid";
import Listing from "shared/api/Listing";
import Modal from "../../Layout/Modal";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    "& .content": {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5, 1),
    "& button": {
      margin: 0,
      padding: 0,
    },
  },
  cardSubHeader: {
    display: "flex",
    justifyContent: "flex-start",
    position: "relative",
  },
  bidContent: {
    paddingBottom: 0,
    "& p": {
      fontSize: "0.55rem",
    },
    "& button": {
      padding: 0,
    },
    "& .iconBar": {
      marginTop: theme.spacing(2),
    },
    "& .buttonCell": {
      "flex-direction": "column",
      "align-items": "center",
      "justify-content": "flex-start",
    },
  },
}));

const BidCard = ({
  listing,
  ticket,
  currentUser,
  bidCount,
  activeBid,
  bid,
  history,
  status,
}) => {
  const classes = useStyles();
  const [modalType, setModalType] = useState(null);
  const [updatingBid, setUpdatingBid] = useState(false);
  // const [counter, setCounter] = useState(null);
  const counter = null;
  const [promptCancelBid, setPromptCancelBid] = useState(false);
  const [doCancel, setDoCancel] = useState(false);

  const [_updateBidStatus] = useMutation(Bid.mutations.updateBidStatus, {
    variables: {
      input: {
        id: !!listing && !!activeBid ? activeBid.id : "<>",
        status: modalType === "reject" ? "REJECTED" : "ACCEPTED",
      },
    },
    refetchQueries: [
      {
        query: Listing.queries.listBySellerIdWithBids,
        variables: {
          listingSellerId: currentUser.id,
          sortDirection: "DESC",
          limit: 500,
          status: {
            eq: "ACTIVE",
          },
        },
      },
    ],
  });

  const [_cancelBid] = useMutation(Bid.mutations.updateBidStatus, {
    variables: {
      input: {
        id: bid.id,
        status: "CANCELLED",
      },
    },
    refetchQueries: [
      {
        query: Bid.queries.listByBidderIdWithListingAndTicket,
        variables: {
          bidBidderId: currentUser.id,
          statusUpdatedAt: {
            beginsWith: {
              status: "PENDING",
            },
          },
          sortDirection: "DESC",
          limit: 1000,
          bidsSortDirection: "DESC",
        },
      },
    ],
  });

  const [_counterBid] = useMutation(Bid.mutations.counter, {
    variables: {
      id: !!listing && !!activeBid ? activeBid.id : "<>",
      counter,
    },
    refetchQueries: [
      {
        query: Listing.queries.listBySellerIdWithBids,
        variables: {
          listingSellerId: currentUser.id,
          sortDirection: "DESC",
          limit: 500,
          status: {
            eq: "ACTIVE",
          },
        },
      },
    ],
  });

  useEffect(() => {
    !!doCancel &&
      _cancelBid()
        .then(() => [setDoCancel(false), setPromptCancelBid(false)])
        .catch(err => [
          console.log(err),
          window.alert(err.message),
          setDoCancel(false),
        ]);
  }, [doCancel]);

  useEffect(() => {
    !!updatingBid &&
      !!modalType &&
      (modalType === "counter" ? _counterBid() : _updateBidStatus())
        .then(() => [setUpdatingBid(false), setModalType(null)])
        .catch(err => [
          setUpdatingBid(false),
          window.alert(err.message),
          console.log(err),
        ]);
  }, [updatingBid, modalType]);

  return (
    <>
      <Modal
        body="Are you sure you want to remove your bid?"
        title={
          <>
            {ticket.subject} <br />
            <Box color="secondary.main" component="span">
              {ticket.eventDescription}
            </Box>
          </>
        }
        onClose={() => setPromptCancelBid(false)}
        submitting={!!doCancel}
        opened={!!promptCancelBid}
        saveButton={{
          disabled: !!doCancel,
          onClick: () => setDoCancel(true),
          text: "Remove Bid",
          ButtonProps: {
            fullWidth: true,
          },
        }}
      />
      <Card className={classes.card} elevation={0}>
        <CardHeader
          className={classes.cardHeader}
          disableTypography={true}
          title={
            <Grid
              container
              spacing={0}
              justify={"space-around"}
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                <div className={classes.cardSubHeader}>
                  <Box>
                    <Typography component={"p"} variant={"subtitle1"}>
                      <strong>{bidCount}</strong> Bid{bidCount === 1 ? "" : "s"}
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={6}>
                {bid?.status === "PENDING" && (
                  <Typography
                    align="right"
                    style={{ cursor: "pointer" }}
                    component={"p"}
                    variant="caption"
                    onClick={() => setPromptCancelBid(true)}
                  >
                    Cancel
                  </Typography>
                )}
              </Grid>
            </Grid>
          }
        />
        <CardContent className={classes.bidContent}>
          <Grid container spacing={0}>
            <Grid item xs={6} style={{ marginBottom: 8 }}>
              <Box>
                <Typography variant={"subtitle2"}>Your Bid</Typography>
                <Typography variant={"caption"} component="p">
                  {moment(bid.createdAt).format("MMM D, YYYY")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              container
              alignItems={"center"}
              justify={"flex-end"}
            >
              <Box>
                <Typography align="right" component={"h6"}>
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={parseFloat(bid.offer) / 100.0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Typography>
              </Box>
            </Grid>
            {status === "PENDING" ? (
              !!bid && !!bid.counterd ? ( //TODO: Change when real counter is implemented
                <Grid item xs={12} container>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant={"subtitle2"}>Countered</Typography>
                      <Typography variant={"caption"} component="p">
                        {moment(bid.updatedAt).format("MMM D, YYYY")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    alignItems={"center"}
                    justify={"flex-end"}
                  >
                    <Box>
                      <Typography component={"h6"}>
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={parseFloat(bid.counter) / 100.0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ) : !!activeBid ? (
                <>
                  <Grid item xs={6}>
                    <Box>
                      <Typography variant={"subtitle2"}>Current</Typography>
                      <Typography variant={"caption"} component="p">
                        {moment(activeBid.createdAt).format("MMM D, YYYY")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    alignItems={"center"}
                    justify={"flex-end"}
                  >
                    <Box>
                      <Typography align="right" component={"h6"}>
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={parseFloat(activeBid.offer) / 100.0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={1}>
                      {activeBid.bidBidderId === currentUser.id ? (
                        <Typography
                          align={"center"}
                          paragraph
                          style={{ color: "green" }}
                          variant={"subtitle2"}
                        >
                          You are the highest bidder
                        </Typography>
                      ) : (
                        <Typography
                          align={"center"}
                          paragraph
                          style={{ color: "red" }}
                          variant={"subtitle2"}
                        >
                          You were outbid
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </>
              ) : null
            ) : listing.status === "ACTIVE" ? (
              <>
                <Grid item xs={6}>
                  <Box>
                    <Typography variant={"subtitle2"}>Current</Typography>
                    {!!activeBid && (
                      <Typography variant={"caption"} component="p">
                        {moment(activeBid.createdAt).format("MMM D, YYYY")}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems={"center"}
                  justify={"flex-end"}
                >
                  <Box>
                    <Typography align="right" component={"h6"}>
                      {!!activeBid && (
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={parseFloat(activeBid.offer) / 100.0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <Box>
                    <Typography variant={"subtitle2"}>Sold</Typography>
                    <Typography variant={"caption"} component="p">
                      {moment(listing.updatedAt).format("MMM D, YYYY")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems={"center"}
                  justify={"flex-end"}
                >
                  <Box>
                    <Typography align="right" component={"h6"}>
                      {!!activeBid && activeBid.status === "ACCEPTED" ? (
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={
                            parseFloat(activeBid.counter || activeBid.offer) /
                            100.0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      ) : (
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={
                            parseFloat(
                              listing.salesPrice || listing.askingPrice
                            ) / 100.0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default BidCard;
