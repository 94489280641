import React from "react";
import { useQuery } from "@apollo/client";
import ContentCell from "../../Layout/ContentCell";
import PageContainer from "../../Layout/PageContainer";
import AdminTabs from "../../Admin/Components/Tabs";
import Listing from "shared/api/Listing";

export default ({
  history,
  match: { params = {} } = {},
  location: { search = "", pathname } = {},
}) => {
  const {
    error,
    data: { listRecommendedListingsByUser: { items } = {} } = {},
  } = useQuery(Listing.queries.listRecommendedByUser, {
    variables: { userId: params.userId },
  });

  return (
    <>
      <AdminTabs
        history={history}
        currentPath={"/admin/users"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <ul>
            {(items ?? []).map(listing => (
              <li key={listing.id}>
                {listing.ticket.subject} {listing.ticket.eventDescription} -{" "}
                {listing.score}
              </li>
            ))}
          </ul>
        </ContentCell>
      </PageContainer>
    </>
  );
};
