import React from "react";
import LinkEdit from "Components/Link/Edit";
import PageContainer from "Components/Layout/PageContainer";
import ContentCell from "Components/Layout/ContentCell";
import Tabs from "Components/Admin/Components/Tabs";

export default ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/links"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <LinkEdit id={params.id} />
      </ContentCell>
    </PageContainer>
  </>
);
