import gql from "graphql-tag";

const Campaign = {};

Campaign.fragments = {
  public: gql`
    fragment CampaignPublic on Campaign {
      id
      createdAt
      updatedAt
      name
      isExpired
      expiresAt
    }
  `,
};

Campaign.mutations = {
  update: gql`
    mutation UpdateCampaign($input: UpdateCampaignInput!) {
      updateCampaign(input: $input) {
        ...CampaignPublic
      }
    }
    ${Campaign.fragments.public}
  `,
  create: gql`
    mutation CreateCampaign($input: CreateCampaignInput!) {
      createCampaign(input: $input) {
        ...CampaignPublic
      }
    }
    ${Campaign.fragments.public}
  `,
};

Campaign.queries = {
  get: gql`
    query GetCampaign($id: ID!) {
      getCampaign(id: $id) {
        ...CampaignPublic
      }
    }
    ${Campaign.fragments.public}
  `,
  list: gql`
    query ListCampaigns(
      $filter: ModelCampaignFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...CampaignPublic
        }
        nextToken
      }
    }
    ${Campaign.fragments.public}
  `,
  
};

export default Campaign;
