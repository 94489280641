import React from "react";
import { DataTable } from "gunner-react/web";
import ConversionTemplate from "shared/api/ConversionTemplate";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const fields = {
  "Bonus Code": {
    value: item => item.bonusCode ?? "Default",
    nowrap: true,
    sort: order => (a, b) =>
      a.bonusCode > b.bonusCode
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  "Active?": {
    value: item => (!!item.active ? "Yes" : "No"),
    nowrap: true,
    sort: order => (a, b) =>
      a.active > b.active
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/conversionTemplates/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
};

export default ({ showInactive }) => (
  <DataTable
    fields={fields}
    search={null}
    dataListParams={{
      query: ConversionTemplate.queries.list,
      dataKey: "listConversionTemplates",
      variables: {
        ...(!!showInactive ? {} : { filter: { active: { eq: true } } }),
      },
    }}
  />
);
