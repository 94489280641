import React from "react";
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Box,
  Typography,
} from "@material-ui/core";

export default ({ theme, items }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Market Id</TableCell>
        <TableCell>Event Id</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Start Time</TableCell>
        <TableCell>Info</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {(items ?? []).map((item, i) => (
        <React.Fragment key={i}>
          <TableRow
            style={
              i % 2 === 0 ? { backgroundColor: theme.palette.action.hover } : {}
            }
          >
            <TableCell>{item.marketId}</TableCell>
            <TableCell>{item.eventId}</TableCell>
            <TableCell>{item.marketName}</TableCell>
            <TableCell>{item.marketType}</TableCell>
            <TableCell>{item.marketStartTime}</TableCell>
            <TableCell>
              In Play? {item.inplay ? "Yes" : "No"} <br />
              Live Price Available? {item.livePriceAvailable
                ? "Yes"
                : "No"}{" "}
              <br />
              Guranteed Price Available?{" "}
              {item.guaranteedPriceAvailable ? "Yes" : "No"}
            </TableCell>
          </TableRow>
          <TableRow
            style={
              i % 2 === 0 ? { backgroundColor: theme.palette.action.hover } : {}
            }
          >
            <TableCell colSpan={6}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Result</TableCell>
                    <TableCell>Handicap</TableCell>
                    <TableCell>Odds</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.runnerDetails
                    .sort((a, b) =>
                      a.selectionName > b.selectionName ? 1 : -1
                    )
                    .map(details => (
                      <TableRow key={details.selectionId}>
                        <TableCell>{details.selectionName}</TableCell>
                        <TableCell>{details.selectionId}</TableCell>
                        <TableCell>{details.runnerStatus}</TableCell>
                        <TableCell>
                          {details.runnerResult?.runnerResultType}
                        </TableCell>
                        <TableCell>{details.handicap}</TableCell>
                        <TableCell>
                          {details.winRunnerOdds.numerator}/
                          {details.winRunnerOdds.denominator}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </TableBody>
  </Table>
);
