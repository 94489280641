import React from "react";
import { FormGroup, FormControlLabel, Switch } from "@material-ui/core";

export default ({ onChange, showInactive }) => (
  <FormGroup>
    <FormControlLabel
      control={
        <Switch
          checked={showInactive}
          onChange={() => onChange(!showInactive)}
        />
      }
      label="Show Inactive"
    />
  </FormGroup>
);
