import React from "react";
import { useQuery } from "@apollo/client";
import CampaignListView from "./CampaignListView";
import Campaign from "shared/api/Campaign";
import { CircularProgress } from "@material-ui/core";

const CampaignListContainer = () => {
  const {
    loading,
    data: { listCampaigns: { items: campaigns = [] } = {} } = {},
  } = useQuery(Campaign.queries.list, { variables: { limit: 100 } });
  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <CampaignListView campaigns={campaigns} />
  );
};

export default CampaignListContainer;
