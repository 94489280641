import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { ListItem, ListItemText, Typography, Button } from "@material-ui/core";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

const Design = () => {
  const theme = useTheme();
  return (
    <Paper>
      <Box p={4}>
        <Typography paragraph>
          The following is the design library, based on{" "}
          <a href="https://material-ui.com/">Material UI</a> used for this app.
        </Typography>
        <Typography variant={"h2"}>Colors</Typography>
        {Object.entries(theme.palette)
          .filter(
            ([key, value]) =>
              typeof value === "string" || typeof value === "object"
          )
          .filter(
            ([key, value]) =>
              typeof value === "object" || /[#(rgba)]/.test(value)
          )
          .map(([key, value], i) => (
            <List key={key} subheader={key}>
              {typeof value === "string" && (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: value }}>&nbsp;</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={key} secondary={value} />
                </ListItem>
              )}
              {typeof value === "object" &&
                Object.entries(value).map(([key, value], i) => (
                  <ListItem key={key}>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: value }}>&nbsp;</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={key} secondary={value} />
                  </ListItem>
                ))}
            </List>
          ))}
        <Typography variant={"h2"}>Typography</Typography>
        {Object.entries(theme.typography)
          .filter(
            ([key, value]) =>
              typeof value === "string" ||
              typeof value === "object" ||
              typeof value === "number"
          )
          .map(([key, value]) =>
            typeof value === "string" || typeof value === "number" ? (
              <List key={key}>
                <ListItem>
                  <ListItemText primary={key} secondary={value.toString()} />
                </ListItem>
              </List>
            ) : (
              <List
                key={key}
                subheader={<Typography variant={key}>{key}</Typography>}
              >
                {[
                  "initial",
                  "primary",
                  "secondary",
                  "textPrimary",
                  "textSecondary",
                  "error",
                ].map((color, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant={key} color={color}>
                          {color}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant={key}>
                        <a href="http://betxllc.com">Link</a>
                      </Typography>
                    }
                  />
                </ListItem>
                {Object.entries(value).map(([key, value], i) => (
                  <ListItem key={key}>
                    <ListItemText primary={key} secondary={value} />
                  </ListItem>
                ))}
                <ListItem>
                  <ListItemText
                    primary={"fontSize (in px)"}
                    secondary={`${parseFloat(value["fontSize"]) * 16}px`}
                  />
                </ListItem>
              </List>
            )
          )}
        <Typography variant={"h2"}>Buttons</Typography>
        {["default", "primary", "secondary"].map((color, i) => (
          <List key={i} subheader={`Color: ${color}`}>
            {["small", "medium", "large"].map((size, i) => (
              <ListItem key={i}>
                <List subheader={`Size: ${size}`}>
                  {["text", "outlined", "contained"].map((style, i) => (
                    <ListItem key={i}>
                      <Button variant={style} color={color} size={size}>
                        {style}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            ))}
          </List>
        ))}
        <Typography variant={"h2"}>Misc</Typography>
        <List subheader={"Shape"}>
          <ListItem>
            <ListItemText
              primary={"borderRadius"}
              secondary={theme.shape.borderRadius}
            />
          </ListItem>
        </List>
      </Box>
    </Paper>
  );
};

export default Design;
