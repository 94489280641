import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";
// import usePromotionListStyles from "./PromotionListStyles";
import DataTable from "../../DataTable";

const fields = {
  Code: {
    value: item => item.code,
    sort: order => (a, b) =>
      a.code > b.code ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  Type: {
    value: item => item.type,
    sort: order => (a, b) =>
      a.type > b.type ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  "Expired?": {
    value: item => (item.isExpired ? "Yes" : "No"),
    sort: order => (a, b) =>
      a.isExpired > b.isExpired
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Created: {
    value: item => moment(item.createdAt).format("MM/DD/YYYY H:mm"),
    sort: order => (a, b) =>
      a.createdAt > b.createdAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Redemptions: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/promotions/${item.id}/redemptions`}
      >
        Redemptions
      </Button>
    ),
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/campaigns/${item.promotionCampaignId}/promotions/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
};

const PromotionListView = ({ promotions }) => (
  <DataTable fields={fields} items={promotions} />
);

export default PromotionListView;
