import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import Form from "../../Form";
import Option from "shared/api/Option";
import { withoutKeys } from "gunner-react";

const fields = {
  name: {
    label: "Name",
    regex: /\w+/,
    required: true,
    type: "text",
    errorMessage: "You are required enter a name",
  },
  aliases: {
    label: "Aliases",
    regex: /\w+/,
    required: false,
    type: "text",
  },
  viewCount: {
    label: "Views",
    regex: /\w+/,
    required: false,
    type: "text",
  },
  bovadoParticipantId: {
    label: "Bovado Participant Id",
    regex: /\w+/,
    required: false,
    type: "text",
    errorMessage: "",
  },
  bovadoParticipantName: {
    label: "Bovado Participant Name",
    regex: /\w+/,
    required: false,
    type: "text",
    errorMessage: "",
  },
  fanDuelSelectionId: {
    label: "FanDuel Selection Id",
    regex: /\w+/,
    required: false,
    type: "text",
    errorMessage: "",
  },
  sharpSportsContestantId: {
    label: "SharpSports Contestant Id",
    regex: /\w+/,
    required: false,
    type: "text",
    errorMessage: "",
  },
};

const OptionFormContainer = ({ option }) => {
  const [formData, setFormData] = useState(option || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: value,
    });

  const [_createOption] = useMutation(Option.mutations.create, {
    variables: {
      input: {
        ...formData,
      },
    },
    refetchQueries: [
      {
        query: Option.queries.listBySportId,
        variables: {
          sportId: formData.sportId,
          limit: 2000,
        },
      },
      {
        query: Option.queries.listBySportId,
        variables: {
          sportId: formData.sportId,
          limit: 2000,
          filter: {
            active: {
              eq: true,
            },
          },
        },
      },
      {
        query: Option.queries.listBySportId,
        variables: {
          sportId: formData.sportId,
          limit: 2000,
          filter: {
            active: {
              eq: false,
            },
          },
        },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      createOption: {
        ...formData,
        __typename: "Option",
      },
    },
  });

  const [_updateOption] = useMutation(Option.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, ["__typename", "sport"]),
      },
    },
    refetchQueries: [
      {
        query: Option.queries.listBySportId,
        variables: {
          sportId: formData.sportId,
          limit: 2000,
        },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updateOption: {
        ...formData,
        __typename: "Option",
      },
    },
  });

  useEffect(() => {
    !!submitSuccess &&
      (() => [handleChange("name", ""), setSubmitSuccess(false)])();
  }, [submitSuccess]);

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateOption : _createOption)() //if this is an existing option, update it, otherwise, create it
        .then(
          ({
            data: { [!!formData.id ? "updateOption" : "createOption"]: option },
          }) => [setDoSubmit(false), setSubmitSuccess(true)]
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [doSubmit]);

  return (
    <Form
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  );
};

export default OptionFormContainer;
