import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import SportIcon from "../../../../Sport/Components/Icon";
import { Hidden, Box } from "@material-ui/core";
import formatOdds from "shared/Util/formatOdds";

export default ({ listing, ticket, classes }) => (
  <Card className={classes.listing} elevation={0}>
    <CardHeader
      className={"header"}
      disableTypography={true}
      title={
        <Grid container spacing={0} justify={"space-around"}>
          <Grid item xs={6} container justify="flex-start" alignItems="center">
            <Typography variant={"subtitle1"}>{ticket.sport.abbrev}</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end" alignItems="center">
            <Typography component={"p"} align={"right"} variant={"subtitle1"}>
              {ticket.betType}
            </Typography>
            <Hidden mdDown>
              <SportIcon sport={ticket.sport.abbrev.toLowerCase()} />
            </Hidden>
          </Grid>
        </Grid>
      }
    />
    <CardContent>
      <Grid container spacing={1}>
        <Grid className={"titleRow"} item xs={12}>
          <Typography noWrap variant={"h6"} component={"h3"}>
            <Link
              to={`/tickets/${listing.listingTicketId}/listings/${listing.id}`}
            >
              {ticket.subject}
            </Link>
          </Typography>
          <Typography noWrap variant={"subtitle2"} component={"h4"}>
            {ticket.eventDescription}
          </Typography>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography noWrap variant={"subtitle2"}>
                Odds
              </Typography>
              <Typography noWrap variant={"body2"}>
                {formatOdds(ticket.collectAmount, listing.salesPrice)}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography noWrap variant={"subtitle2"}>
                Sold For
              </Typography>
              <Typography noWrap variant={"body2"}>
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={parseFloat(listing.salesPrice) / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography noWrap variant={"subtitle2"}>
                Collect
              </Typography>
              <Typography noWrap variant={"body2"}>
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={parseFloat(ticket.collectAmount) / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
