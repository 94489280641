import React, { useContext, useMemo } from "react";
import Transaction from "shared/api/Transaction";
import { DataTableNew as DataTable } from "gunner-react/web";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
// import AdCell from '../Components/Layout/AdCell'
import useClippy from "use-clippy";
// import TicketListItem from '../Components/Ticket/List/Item'
import Tabs from "../Components/Account/Components/AltTabs";
import { CurrentUserContext } from "gunner-react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { formatPennies } from "shared/Util/money";
import moment from "moment";
import { Link } from "@material-ui/icons";

// const ConvertWatchingItemToTicketItem = ({object, id, filter = item => true}) =>
//   <TicketListItem object={{...object.listing.ticket, listing: object.listing}} id={object.listing.listingTicketId} filter={filter} />

// const ConvertListingItemToTicketItem = args =>
//   console.log(args) ||
//   null

const fields = {
  Date: {
    value: item => moment(item.createdAt).format("MM/DD/YYYY"),
    // nowrap: true,
    sort: order => (a, b) =>
      a.createdAt > b.createdAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  "Bonus Amount": {
    value: item => `$${formatPennies(item.amount)}`,
    // nowrap: true,
    sort: order => (a, b) =>
      a.amount > b.amount
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
};

export default ({ history, location: { search = "" } = {} }) => {
  const currentUser = useContext(CurrentUserContext);
  const [clipboard, setClipboard] = useClippy();

  const query = useMemo(
    () => ({
      query: Transaction.queries.listByUserId,
      dataKey: "transactionsByUserId",
      variables: {
        transactionUserId: currentUser.id,
        filter: {
          source: {
            eq: "referral",
          },
        },
      },
    }),
    [currentUser.id]
  );

  // const entry = useQuery(query.query, {
  //   variables: query.variables
  // })

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/account/referrals"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Grid justify="center" container spacing={1}>
            <Grid item xs={12}>
              <Typography
                align="center"
                color="secondary"
                style={{
                  fontFamily: "'Bungee', cursive",
                  textTransform: "none",
                }}
                variant="h4"
              >
                Give a friend $25, Get a $25 Bonus in return!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="center"
                style={{
                  fontFamily: "'Open Sans', sans-serif",
                  textTransform: "none",
                }}
                variant="h5"
              >
                Invite your friends to PropSwap
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                alignContent="center"
                alignItems="center"
                justify="center"
                container
                spacing={0}
              >
                <Grid item xs={12} md={6}>
                  <Box
                    mt={-1}
                    component={TextField}
                    variant="outlined"
                    fullWidth
                    value={`https://${window.location.host}/invite/${currentUser.referralCode}`}
                    disableUnderline
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{ cursor: "copy" }}
                            onClick={() =>
                              setClipboard(
                                `https://propswap.com/invite/${currentUser.referralCode}`
                              )
                            }
                          >
                            <Link />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardHeader
                  title="How it Works"
                  titleTypographyProps={{
                    style: {
                      fontWeight: "bold",
                      fontFamily: "'Open Sans', sans-serif",
                    },
                  }}
                />
                <Divider />
                <CardContent>
                  <Typography
                    style={{ fontFamily: "'Open Sans', sans-serif" }}
                    paragraph
                  >
                    1) Join
                  </Typography>
                  <Typography
                    style={{ fontFamily: "'Open Sans', sans-serif" }}
                    paragraph
                  >
                    Your friend needs to use your referral link to Sign Up
                  </Typography>
                  <Typography
                    paragraph
                    style={{ fontFamily: "'Open Sans', sans-serif" }}
                  >
                    2) Deposit $5
                  </Typography>
                  <Typography
                    paragraph
                    style={{ fontFamily: "'Open Sans', sans-serif" }}
                  >
                    Within 24 hours of signing up, your friend just needs to
                    Deposit $5
                  </Typography>
                  <Typography
                    style={{ fontFamily: "'Open Sans', sans-serif" }}
                    paragraph
                  >
                    3){" "}
                    <Typography
                      style={{ display: "inline", fontWeight: "bold" }}
                      color="secondary"
                    >
                      Get $25
                    </Typography>
                  </Typography>
                  <Typography
                    style={{ fontFamily: "'Open Sans', sans-serif" }}
                    paragraph
                  >
                    You each get $25 in Bonus Cash!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  fontFamily: "'Open Sans', sans-serif",
                }}
                variant="h6"
              >
                Your Referrals / Total Bonus: $
                {formatPennies(currentUser.totalReferralBonus)}
              </Typography>
              <Divider />
              <DataTable
                fields={fields}
                dataListParams={{
                  query,
                  refetchCount: 1,
                }}
              />
            </Grid>
          </Grid>
        </ContentCell>
      </PageContainer>
    </>
  );
};
