import React, { useState, useEffect, useContext, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import NumberFormat from "react-number-format";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import SimpleModal from "../../../Components/SimpleModal";

import { withoutKeys } from "gunner-react";
import Detail from "../Screens/Detail";
import { CurrentUserContext } from "gunner-react";
import { Box, Button, Typography } from "@material-ui/core";
import { useMutation, useApolloClient } from "@apollo/client";
import Transaction from "shared/api/Transaction";
import Bluesnap from "shared/api/Bluesnap";
import Modal from "../../Layout/Modal";
import useCanRequestRefund from "react-shared/Hooks/useCanRequestRefund";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
  },
  tableCellNoWrap: {
    whiteSpace: "nowrap",
  },
}));

const dataFields = {
  Date: {
    value: item => moment(item.updatedAt).format("MM/DD/YYYY"),
    sort: order => (a, b) =>
      a.updatedAt > b.updatedAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Type: {
    value: item => (item.type === "ECP" ? "ACH" : item.type),
    sort: order => (a, b) =>
      a.type > b.type ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  Amount: {
    value: item => (
      <NumberFormat
        style={
          item.amount < 0 && item.type !== "BUY" ? { color: "#d32f2f" } : {}
        }
        fixedDecimalScale
        decimalScale={2}
        value={
          item.type === "BUY"
            ? Math.abs(parseFloat(item.amount) / 100.0)
            : parseFloat(item.amount) / 100.0
        }
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.amount > b.amount
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Status: {
    value: item => item.status,
    sort: order => (a, b) =>
      a.status > b.status
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Balance: {
    value: item =>
      item.status === "PENDING" || !item.balanceSnapshot ? (
        ""
      ) : (
        <NumberFormat
          fixedDecimalScale
          decimalScale={2}
          value={parseFloat(item.balanceSnapshot || 0) / 100.0}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      ),
    sort: order => (a, b) =>
      a.balanceSnapshot > b.balanceSnapshot
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  Promotional: {
    value: item => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        value={parseFloat(item.promotionalBalanceSnapshot || 0) / 100.0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.promotionalBalanceSnapshot > b.promotionalBalanceSnapshot
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  Pending: {
    value: item =>
      item.status === "APPROVED" || !item.pendingBalanceSnapshot ? (
        ""
      ) : (
        <NumberFormat
          fixedDecimalScale
          decimalScale={2}
          value={parseFloat(item.pendingBalanceSnapshot || 0) / 100.0}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      ),
    sort: order => (a, b) =>
      a.pendingBalanceSnapshot > b.pendingBalanceSnapshot
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  Approve: {
    value: item =>
      item.status === "PENDING" ? (
        <UpdateTransactionButton
          status="APPROVED"
          userId={item.transactionUserId}
          id={item.id}
        />
      ) : (
        ""
      ),
  },
  Reject: {
    value: item =>
      item.status === "PENDING" ? (
        <UpdateTransactionButton
          status="REJECTED"
          userId={item.transactionUserId}
          id={item.id}
        />
      ) : (
        ""
      ),
  },
};

const TransactionDetail = ({ transaction }) => {
  const canRequestRefund = useCanRequestRefund({
    transactionId: transaction?.id,
  });
  const [refundTransaction] = useMutation(Bluesnap.mutations.refundTransaction);
  const transactionId = transaction?.id;

  const handleRefundClick = useCallback(
    () =>
      window.confirm("Are you sure you want to request a refund?") &&
      refundTransaction({
        variables: {
          transactionId,
        },
      })
        .then(answer => window.alert("Your transaction has been refunded!"))
        .then(() => window.location.reload()),
    [transactionId]
  );

  return (
    <>
      <Typography paragraph>{transaction?.note ?? "No Notes"}</Typography>
      {!!canRequestRefund && (
        <Box mt={2}>
          <Button
            onClick={handleRefundClick}
            fullWidth
            size={"small"}
            variant={"contained"}
          >
            Initiate Full Refund
          </Button>
        </Box>
      )}
    </>
  );
};

const UpdateTransactionButton = ({ status, id, userId }) => {
  const client = useApolloClient();
  const cachedTransaction = client.readFragment({
    id: `Transaction:${id}`,
    fragment: Transaction.fragments.public,
  });
  const [doSubmit, setDoSubmit] = useState(false);
  const [updateTransaction] = useMutation(Transaction.mutations.update, {
    variables: {
      input: {
        id,
        status,
      },
    },
    update: (store, { data: { updateTransaction: transaction } }) =>
      store.writeFragment({
        id: `Transaction:${id}`,
        fragment: Transaction.fragments.public,
        data: {
          ...transaction,
        },
      }),
    optimisticResponse: {
      ...cachedTransaction,
      status,
    },
    refetchQueries: [
      {
        query: Transaction.queries.listByUserId,
        variables: {
          transactionUserId: userId,
          limit: 50,
          sortDirection: "DESC",
        },
      },
    ],
  });

  useEffect(() => {
    !!doSubmit &&
      updateTransaction()
        .then(() => setDoSubmit(false))
        .catch(err => [
          console.log(err),
          setDoSubmit(false),
          window.alert(err.message),
        ]);
  }, [doSubmit]);

  return !!doSubmit ? null : (
    <Button
      onClick={() => window.confirm("Are you sure?") && setDoSubmit(true)}
      size="small"
      color="secondary"
      variant="contained"
    >
      {status === "APPROVED" ? "Approve" : "Reject"}
    </Button>
  );
};

const TransactionsTable = ({ filter, items }) => {
  const currentUser = useContext(CurrentUserContext);
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState(false);
  const [order, setOrder] = useState("desc");
  const [fields, setFields] = useState(dataFields);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const handleHeaderCellClick = label =>
    label === orderBy
      ? setOrder(order === "desc" ? "asc" : "desc")
      : setOrderBy(label);

  const defaultSort = order => (a, b) =>
    a.updatedAt > b.updatedAt
      ? order === "desc"
        ? -1
        : 1
      : order === "asc"
      ? -1
      : 1;
  const selectedField = Object.entries(fields).find(
    ([label, field]) => label === orderBy
  );
  const sortFunc = !!selectedField
    ? (selectedField[1] ?? []).slice().sort(order)
    : defaultSort(order);

  const handleClick = item => () =>
    item.type === "BUY" || item.type === "SELL"
      ? setSelectedItem(item)
      : item.type === "PROMO" || item.type === "MANUAL" || item.type === "CC"
      ? setSelectedTransaction(item)
      : null;

  useEffect(() => {
    //ONLY ADMINS SHOULD BE ABLE TO SEE THESE FIELDS
    !currentUser.groups.includes("Admins") &&
      setFields(withoutKeys(fields, ["Approve", "Reject"]));
  }, [currentUser]);

  return (
    <div className={classes.root}>
      <SimpleModal
        opened={!!selectedItem}
        body={
          <Detail
            onClose={() => setSelectedItem(null)}
            match={{ params: { id: !!selectedItem ? selectedItem.id : null } }}
          />
        }
        saving={false}
        onClose={() => setSelectedItem(null)}
      />

      <Modal
        title={"Transaction Details"}
        body={<TransactionDetail transaction={selectedTransaction} />}
        onClose={() => setSelectedTransaction(null)}
        submitting={false}
        opened={!!selectedTransaction}
        saveButton={{
          text: "Close",
          onClick: () => setSelectedTransaction(null),
          ButtonProps: {
            fullWidth: true,
          },
        }}
      />

      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {Object.entries(fields).map(([label, field]) => (
              <TableCell
                className={classes.tableCellNoWrap}
                key={label}
                sortDirection={orderBy === label ? order : false}
              >
                <TableSortLabel
                  active={orderBy === label}
                  direction={order}
                  onClick={handleHeaderCellClick.bind(null, label)}
                >
                  {label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items
            .slice()
            .sort(sortFunc)
            .filter(
              item =>
                !!Object.entries(fields)
                  .map(([label, field]) => field.value(item))
                  .join(", ")
                  .includes(filter || "")
            )
            .map(item => (
              <TableRow
                hover
                key={item.id}
                style={item.type !== "CHECK" ? { cursor: "pointer" } : {}}
                onClick={handleClick(item)}
              >
                {Object.entries(fields).map(([label, field]) => (
                  <TableCell
                    key={label}
                    className={!!field.nowrap ? classes.tableCellNoWrap : null}
                  >
                    {field.value(item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TransactionsTable;
