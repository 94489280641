import React from "react";
import DataTable from "../../DataTable";
import UserLink from "../../User/Link";
import NumberFormat from "react-number-format";
import moment from "moment";

const fields = {
  Bidder: {
    value: item => <UserLink id={item.bidBidderId} />,
    sort: null,
  },
  Offer: {
    value: item => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        value={parseFloat(item.offer) / 100.0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.offer > b.offer
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Status: {
    value: item => item.status,
    sort: order => (a, b) =>
      a.status > b.status
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Created: {
    value: item => moment(item.createdAt).format("MM/DD/YYYY H:mm"),
    sort: order => (a, b) =>
      a.createdAt > b.createdAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Updated: {
    value: item => moment(item.updatedAt).format("MM/DD/YYYY H:mm"),
    sort: order => (a, b) =>
      a.updatedAt > b.updatedAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
};

const BidListView = ({ bids }) => <DataTable fields={fields} items={bids} />;

export default BidListView;
