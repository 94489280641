import React from "react";

import Tabs from "Components/Admin/Components/Tabs";
import ContentCell from "Components/Layout/ContentCell";
import PageContainer from "Components/Layout/PageContainer";
import ExchangeTransactionList from "Components/ExchangeTransaction/List";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

export default ({
  match: { params = {} },
  history,
  location: { search = "" } = {},
}) => {
  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/exchange/providers"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Grid container spacing={4} justify={"flex-end"}>
            <Grid item xs={12} md={3}>
              <Button
                component={Link}
                to={`/admin/exchange/providers/${params.providerId}/transactions/new`}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Add Transaction
              </Button>
            </Grid>
          </Grid>

          <ExchangeTransactionList providerId={params.providerId} />
        </ContentCell>
      </PageContainer>
    </>
  );
};
