import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation, useQuery } from "@apollo/client";
import AlertCircleIcon from "mdi-material-ui/AlertCircle";
import { Redirect, useLocation } from "react-router-dom";
import MaskedInput from "react-text-mask";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { OutlinedInput } from "@material-ui/core";

import Tabs from "../Components/Tabs";

import AdCell from "../../Layout/AdCell";
import ContentCell from "../../Layout/ContentCell";
import PageHeader from "../../Layout/PageHeader";
import PageContainer from "../../Layout/PageContainer";
import { Grid, Typography, Box, TextField } from "@material-ui/core";
import Amount from "../../Withdraw/Components/Amount";
import Summary from "../../Withdraw/Components/Summary";
import Method from "../../Withdraw/Components/Method";
import Modal from "../../Layout/Modal";

import { CurrentUserContext } from "gunner-react";
import Transaction from "shared/api/Transaction";
import Interchecks from "shared/api/Interchecks";
import MailingAddress from "../../Withdraw/Components/MailingAddress";
import AccountInfo from "../../Withdraw/Components/AccountInfo";

import getGraphQLError from "shared/Util/getGraphQLError";
import { withoutKeys } from "gunner-react";
import { ACH_WITHDRAW_FEE } from "shared/Config";

const TextMaskCustom = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={rawValue => [
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      // placeholderChar={'\u2000'}
      showMask
      guide
    />
  );
};

const useTotalWithdrawn = user => {
  const {
    error: achError,
    data: { transactionsByUserIdAndType: { items: achItems = [] } = {} } = {},
  } = useQuery(Transaction.queries.listByUserIdAndType, {
    variables: {
      transactionUserId: user.id,
      type: {
        eq: "ACH_STANDARD",
      },
      limit: 200,
    },
  });

  const {
    error: checkError,
    data: { transactionsByUserIdAndType: { items: checkItems = [] } = {} } = {},
  } = useQuery(Transaction.queries.listByUserIdAndType, {
    variables: {
      transactionUserId: user.id,
      type: {
        eq: "CHECK",
      },
      limit: 200,
    },
  });

  const achTotal = achItems
    .map(item => item.amount)
    .reduce((total, currentValue) => total + currentValue, 0);
  const checkTotal = checkItems
    .map(item => item.amount)
    .reduce((total, currentValue) => total + currentValue, 0);

  return Math.abs(achTotal + checkTotal);
};

const useStyles = makeStyles(theme => ({
  content: {
    backgroundImage: `url(${require("../../../assets/css/deposit-bg.png")})`,
    backgroundColor: "transparent",
    backgroundAttachment: "scroll",
    backgroundPosition: "top left",
    backgroundRepeat: "repeat",
    marginTop: theme.spacing(2),
    "& h4": {
      fontSize: "1.125rem",
      textTransform: "uppercase",
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(2),
    },
  },
  contentCell: {
    marginBottom: theme.spacing(4),
    "& > div": {
      backgroundColor: "rgba(242,242,242, 0.8)",
      padding: theme.spacing(2, 2),
    },
    "& > .leftColumn": {
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(2),
      },
    },
    "& > .rightColumn": {
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(2),
      },
    },
  },
  summaryHeader: {
    "&:after": {
      background: "gray",
      height: 1,
      content: '""',
      display: "block",
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginTop: theme.spacing(2),
    },
  },
}));

const useQueryParms = () => new URLSearchParams(useLocation().search);

const Withdraw = ({ history }) => {
  const classes = useStyles();
  const currentUser = useContext(CurrentUserContext);
  const queryParams = useQueryParms();

  const [amount, setAmount] = useState("500");
  const [submitting, setSubmitting] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [showModal, setShowModal] = useState(!!queryParams.get("success"));
  const [showW9Modal, setShowW9Modal] = useState(false);
  const [method, setMethod] = useState("CHECK");
  const [recipientSsn, setRecipientSsn] = useState(undefined);
  const fee = method === "CHECK" ? 0 : ACH_WITHDRAW_FEE;
  const [accountInfo, setAccountInfo] = useState({
    type: "",
    accountNumber: "",
    routingNumber: "",
  });

  const [mailingInfo, setMailingInfo] = useState({
    name: `${currentUser.attributes.given_name} ${currentUser.attributes.family_name}`,
    address_1: `${currentUser.attributes.address}`,
    // address_2: `${currentUser.attributes['custom:address2'] ?? ''}`,
    city: `${currentUser.attributes["custom:city"]}`,
    state: `${currentUser.attributes["custom:state"]}`.toUpperCase(),
    zip: `${currentUser.attributes["custom:zip"]}`,
  });

  const w9Threshold = 60000;
  const totalWithdrawn = useTotalWithdrawn(currentUser);
  const needsW9 =
    currentUser.groups.includes("Admins") &&
    !currentUser.interchecksRecipientW9Verified &&
    totalWithdrawn + parseInt(amount) >= w9Threshold; //TODO: LIFT GATING

  const isValid =
    (!needsW9 || !!recipientSsn) &&
    !!amount &&
    amount >= 500 &&
    !Object.values(
      withoutKeys(method === "CHECK" ? mailingInfo : accountInfo, ["address_2"])
    ).some(value => !value) &&
    parseInt(amount) + parseInt(fee) <= currentUser.balance &&
    (method === "CHECK" ||
      accountInfo?.accountNumber === accountInfo?.accountNumberConfirm);

  const [_createInterchecksPayment] = useMutation(
    Interchecks.mutations.createPayment,
    {
      variables: {
        input: {
          userId: currentUser.id,
          recipient: {
            recipient_email: currentUser.email,
            recipient_first_name: mailingInfo.name.split(" ")[0],
            recipient_last_name: mailingInfo.name.split(" ").slice(1).join(" "),
            recipient_address: withoutKeys(mailingInfo, ["name"]),
            recipient_tin: recipientSsn,
            payments: [
              {
                payment_amount: parseFloat(amount) / 100.0,
              },
            ],
            payout: {
              method,
              email: currentUser.email,
              ...(method === "ACH_STANDARD"
                ? {
                    account_type: accountInfo.type,
                    account_number: accountInfo.accountNumber,
                    routing_number: accountInfo.routingNumber,
                  }
                : {
                    address:
                      mailingInfo.address_1 +
                      " " +
                      (mailingInfo.address_2 ?? ""),
                    city: mailingInfo.city,
                    state: mailingInfo.state.toUpperCase(),
                    zip: mailingInfo.zip,
                  }),
            },
          },
        },
      },
    }
  );

  useEffect(() => {
    !!showModal && history.push("?success=1");
  }, [history, showModal]);

  useEffect(() => {
    setShowW9Modal(needsW9);
  }, [needsW9]);

  useEffect(() => {
    !!submitting &&
      _createInterchecksPayment()
        .then(() => [setShowModal(true), setSubmitting(false)])
        .catch(error => [
          console.log("error1!!!!", JSON.stringify(getGraphQLError(error))),
          getGraphQLError(error).level === 0
            ? window.alert(
                "An error occured during processing. Please contact info@propswap.com to ensure you were credited for your transaction."
              )
            : window.alert(
                "We could not complete the transaction. Please try again later or contact support."
              ),
          setSubmitting(false),
        ]);
  }, [submitting]);

  return !!redirectTo ? (
    <Redirect push to={redirectTo} />
  ) : (
    <>
      <Modal
        title="Success!"
        body={
          <div style={{ overflow: "hidden" }}>
            <Grid container alignItems="center" justify="center" spacing={4}>
              <Grid container item xs={12} justify="center">
                <AlertCircleIcon style={{ fontSize: 80 }} />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" variant="body1" paragraph>
                  {method === "ACH_STANDARD"
                    ? "You have successfully requested funds via Direct Deposit. Please allow up to 3-5 business days for your request to be processed."
                    : "You have successfully requested funds be sent to you."}
                </Typography>
              </Grid>
            </Grid>
          </div>
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={{
          text: "OK",
          onClick: () => [
            setShowModal(false),
            setRedirectTo(`/account/transactions`),
          ],
          ButtonProps: {
            fullWidth: true,
          },
        }}
      />
      <Modal
        title="Information Needed for W-9"
        body={
          <div style={{ overflow: "hidden" }}>
            <Grid container alignItems="center" justify="center" spacing={4}>
              <Grid container item xs={12} justify="center">
                <AlertCircleIcon style={{ fontSize: 80 }} />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" variant="body1" paragraph>
                  All customers who withdraw $600 or more in a calendar year
                  must provide their Social Security Number for tax purposes.
                  This information is needed one-time only.
                </Typography>
                <FormControl
                  required
                  margin="normal"
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel shrink>
                    Enter your Social Security Number
                  </InputLabel>
                  <OutlinedInput
                    value={recipientSsn ?? ""}
                    onChange={({ target: { value } }) =>
                      setRecipientSsn(value.replace(/\D/g, ""))
                    }
                    inputComponent={TextMaskCustom}
                  />
                  <FormHelperText>
                    Please note: PropSwap does not store your SSN.
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        }
        onClose={() => setShowW9Modal(false)}
        submitting={false}
        opened={!!showW9Modal}
        saveButton={{
          disabled: (recipientSsn ?? "").replace(/\D/g, "").length !== 9,
          text: "OK",
          onClick: () => setShowW9Modal(false),
          ButtonProps: {
            fullWidth: true,
          },
        }}
      />
      <Tabs
        history={history}
        currentPath={"/account/withdraw"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell>
          <PageHeader>Withdraw</PageHeader>
          <Grid container className={classes.content} spacing={4}>
            <Grid item xs={12} md={7}>
              <Grid item xs={12} className={classes.contentCell}>
                <div>
                  <Typography variant="h4">1. Payout Amount</Typography>
                  <Amount onChange={setAmount} amount={amount} />
                </div>
              </Grid>
              <Grid item xs={12} container spacing={0}>
                <Grid item md={6} xs={12} className={classes.contentCell}>
                  <div className={"leftColumn"}>
                    <Typography variant="h4">2. Payout Method</Typography>
                    <Method
                      method={method}
                      onChange={method => setMethod(method)}
                    />
                  </div>
                </Grid>
                <Grid item md={6} xs={12} className={classes.contentCell}>
                  <div className={"rightColumn"}>
                    <Typography variant="h4">
                      3.{" "}
                      {method === "ACH_STANDARD"
                        ? "Account Info"
                        : "Mailing Address"}
                    </Typography>
                    {method === "ACH_STANDARD" ? (
                      <AccountInfo
                        accountInfo={accountInfo}
                        onChange={(field, value) =>
                          setAccountInfo({ ...accountInfo, [field]: value })
                        }
                      />
                    ) : (
                      <MailingAddress
                        mailingInfo={mailingInfo}
                        onChange={(field, value) =>
                          setMailingInfo({ ...mailingInfo, [field]: value })
                        }
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid item xs={12} className={classes.contentCell}>
                <div>
                  <Typography className={classes.summaryHeader} variant="h4">
                    Order Summary
                  </Typography>
                  <Summary
                    method={method}
                    submitting={submitting}
                    onSubmit={() => setSubmitting(true)}
                    amount={amount}
                    valid={isValid}
                    currentUser={currentUser}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </ContentCell>
        <AdCell>
          <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};

export default Withdraw;
