import React from "react";
import { DataTable } from "gunner-react/web";

export default ({ fields, query, dataKey, variables }) => (
  <DataTable
    fields={fields}
    search={null}
    dataListParams={{
      query,
      dataKey,
      variables,
    }}
  />
);
