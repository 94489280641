import React, { useState, useEffect } from "react";

import Locale from "shared/api/Locale";
import Sportsbook from "shared/api/Sportsbook";
import SportsbookLocale from "shared/api/SportsbookLocale";
import { useQuery, useMutation } from "@apollo/client";

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Box,
} from "@material-ui/core";

const SportsbookCheckbox = ({ sportsbook, localeId }) => {
  const id = `${sportsbook.id}-${localeId}`;
  const { data: { getSportsbookLocale } = {} } = useQuery(
    SportsbookLocale.queries.get,
    { variables: { id } }
  );
  const [action, setAction] = useState(null);

  const [createSportsbookLocale] = useMutation(
    SportsbookLocale.mutations.create,
    {
      variables: {
        input: {
          id,
          localeId,
          sportsbookId: sportsbook.id,
        },
      },
      refetchQueries: [
        {
          query: SportsbookLocale.queries.get,
          variables: { id },
        },
      ],
    }
  );

  const [deleteSportsbookLocale] = useMutation(
    SportsbookLocale.mutations.delete,
    {
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: [
        {
          query: SportsbookLocale.queries.get,
          variables: { id },
        },
      ],
    }
  );

  useEffect(() => {
    action === "create" &&
      createSportsbookLocale()
        .then(() => setAction(null))
        .catch(e => [
          window.alert(JSON.stringify(e)),
          console.log(e),
          setAction(null),
        ]);

    action === "delete" &&
      deleteSportsbookLocale()
        .then(() => setAction(null))
        .catch(e => [
          window.alert(JSON.stringify(e)),
          console.log(e),
          setAction(null),
        ]);
  }, [action]);

  return (
    <>
      <Box flex display="flex">
        <FormControl>
          <FormGroup
            onChange={({ target: { checked } }) =>
              setAction(!!checked ? "create" : "delete")
            }
            row
          >
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!!action}
                  checked={!!getSportsbookLocale}
                  color="primary"
                />
              }
              label={`${sportsbook.name}`}
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </Box>
    </>
  );
};

export default ({ localeId }) => {
  const { data: { listSportsbooks: { items = [] } = {} } = {} } = useQuery(
    Sportsbook.queries.list,
    {
      variables: {
        limit: 200,
      },
    }
  );

  return items
    .slice()
    .map(sportsbook => (
      <SportsbookCheckbox
        key={sportsbook.id}
        localeId={localeId}
        sportsbook={sportsbook}
      />
    ));
};
