import React from "react";
import OptionEventOdds from "shared/api/OptionEventOdds";
import { DataTable } from "gunner-react/web";
import { Link } from "react-router-dom";

const fields = {
  Option: {
    value: item => (
      <Link to={`/admin/events/${item.eventId}/options/${item.optionId}/odds`}>
        {item.option.name}
      </Link>
    ),
    nowrap: true,
    sort: order => (a, b) =>
      console.log(a) || a.option.name > b.option.name
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Odds: {
    value: item => item.oddsFormated,
    nowrap: true,
    sort: order => (a, b) =>
      a.oddsCalculated > b.oddsCalculated
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
};

export default ({ eventId, date }) => (
  <DataTable
    fields={fields}
    search={null}
    dataListParams={{
      query: OptionEventOdds.queries.listByEventIdAndCreatedAt,
      dataKey: "listOptionEventOddsByEventIdAndCreatedAt",
      variables: {
        eventId,
        createdAt: {
          beginsWith: date,
        },
      },
    }}
  />
);
