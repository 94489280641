
import React from "react";
import LayoutTabs from "../../Layout/Tabs";

const Tabs = ({ history, currentPath }) => {
  const options = [
    { label: `Tickets`, value: "/admin/tickets" },
    { label: `Listings`, value: "/admin/listings" },
    { label: `Bets`, value: "/admin/bets" },
    { label: `Settled`, value: "/admin/settled" },
    { label: `Users`, value: "/admin/users" },
    { label: `Locales`, value: "/admin/locales" },
    { label: `Sports`, value: "/admin/sports" },
    { label: `Campaigns`, value: "/admin/campaigns" },
    { label: `Odds`, value: "/admin/odds" },
    { label: `Reports`, value: "/reports/locale/transactions" },
    { label: `Sportsbooks`, value: "/admin/sportsbooks" },
    { label: `Redirects`, value: "/admin/redirects" },
    { label: `Links`, value: "/admin/links" },
    { label: `Ads`, value: "/admin/ads" },
    { label: `Searches`, value: "/admin/searches" },
    { label: `FanDuel`, value: "/admin/fanduel" },
    { label: `Exchange`, value: "/admin/exchange/providers" },
  ];

  return (
    <LayoutTabs
      options={options}
      selectedValue={currentPath}
      onChange={path => history.push(path)}
    />
  );
};

export default Tabs;
