import React, { useState, useEffect } from "react";
import Listing from "shared/api/Listing";
import Locale from "shared/api/Locale";
import moment from "moment";
import { Box, TextField, MenuItem, Grid, Button } from "@material-ui/core";
import { uniqWith, eqBy } from "ramda";
import { DataTable } from "gunner-react/web";
import { convertArrayToCSV } from "convert-array-to-csv";

import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import ContentCell from "Components/Layout/ContentCell";
import PageContainer from "Components/Layout/PageContainer";
import { useQuery } from "@apollo/client";
import { useCallback } from "react";
import { downloadCsv } from "gunner-react";
import { useContext } from "react";
import { CurrentUserContext } from "gunner-react/dist/Contexts/CurrentUser";
import formatOdds from "shared/Util/formatOdds";

const fields = {
  Seller: {
    value: item => item.listingSellerId,
    sort: order => (a, b) =>
      a.listingSellerId > b.listingSellerId
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Buyer: {
    value: item => item.listingBuyerId,
    sort: order => (a, b) =>
      a.listingBuyerId > b.listingBuyerId
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  At: {
    value: item =>
      !!item.soldAt ? moment(item.soldAt).format("MM/DD/YYYY H:mm") : "",
    nowrap: true,
    sort: order => (a, b) =>
      a.soldAt > b.soldAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Subject: {
    value: item => (
      <Box width="100px">
        <Link to={`/tickets/${item.ticket.id}`}>{item.ticket.subject}</Link>
      </Box>
    ),
    sort: null,
    searchValue: item => item.ticket.subject,
  },
  Event: {
    value: item => (
      <Box width="100px">
        <Link to={`/tickets/${item.ticket.id}`}>
          {item.ticket.eventDescription}
        </Link>
      </Box>
    ),
    sort: null,
    searchValue: item => item.ticket.eventDescription,
  },
  Bet: {
    value: item => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        value={parseFloat(item.ticket.betAmount) / 100.0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.ticket.betAmount > b.ticket.betAmount
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  Collects: {
    value: item => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        value={parseFloat(item.ticket.collectAmount) / 100.0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.ticket.collectAmount > b.ticket.collectAmount
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  Price: {
    value: item => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        value={parseFloat(item.salesPrice || item.askingPrice) / 100.0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.salesPrice > b.salesPrice
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  Odds: {
    value: item =>
      formatOdds(
        parseFloat(item.ticket.collectAmount),
        parseFloat(item.salesPrice || item.askingPrice)
      ),
    sort: null,
  },
};

const parseLocaleFromCurrentUser = currentUser =>
  console.log(currentUser?.groups?.find?.(group => /Locale\-/.test(group))) ||
  currentUser?.groups
    ?.find?.(group => /Locale\-/.test(group))
    ?.split?.("Locale-")?.[1];

export default ({ history, location: { search = "" } = {} }) => {
  // const searchParams = queryString.parse(search);
  const [searchString, setSearchString] = useState("");
  const [doDownload, setDoDownload] = useState(false);
  const { data: { listLocales: { items: locales = [] } = {} } = {} } = useQuery(
    Locale.queries.list,
    { variables: { limit: 100 } }
  );
  const [items, setItems] = useState([]);
  const currentUser = useContext(CurrentUserContext);
  const scopedLocale = parseLocaleFromCurrentUser(currentUser);

  const download = useCallback(
    () =>
      Promise.all([
        setDoDownload(true),
        Promise.resolve(
          downloadCsv(
            convertArrayToCSV(
              items.map(
                item => ({
                  sellerId: item.listingSellerId,
                  buyerId: item.listingBuyerId,
                  soldAt: item.soldAt,
                  subject: item.ticket.subject,
                  event: item.ticket.eventDescription,
                  betAmount: parseFloat(item.ticket.betAmount) / 100.0,
                  collectAmount: parseFloat(item.ticket.collectAmount) / 100.0,
                  salesPrice: parseFloat(item.salesPrice) / 100.0,
                }),
                {
                  header: [
                    "Seller",
                    "Buyer",
                    "Date",
                    "Subject",
                    "Event",
                    "Bet",
                    "Collects",
                    "Sales Price",
                  ],
                  separator: ",",
                }
              )
            )
          )
        ),
      ]).then(() => setDoDownload(false)),
    [JSON.stringify(items)]
  );

  useEffect(() => {
    !!scopedLocale && setSearchString(scopedLocale);
  }, [scopedLocale]);

  console.log(
    `${decodeURIComponent(searchString?.replace?.(/\-/g, "")) || ""}${
      / /.test(decodeURIComponent(searchString)) ? "" : ".*"
    }`
  );

  return !scopedLocale && !currentUser?.groups?.includes?.("Admins") ? null : (
    <>
      <PageContainer>
        <ContentCell fullWidth>
          <Grid container spacing={2} alignItems="center">
            {!scopedLocale && (
              <Grid item xs={12} md={6}>
                <TextField
                  className="formControl"
                  fullWidth
                  select
                  label={"Select Locale"}
                  onChange={({ target: { value } }) => setSearchString(value)}
                  value={searchString || ""}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                >
                  <MenuItem value="">Select</MenuItem>
                  {uniqWith(eqBy(locale => `${locale.state} / ${locale.name}`))(
                    locales
                  )
                    .slice()
                    .sort((a, b) => (a.state > b.state ? 1 : -1))
                    .filter(
                      locale =>
                        !/undefined/.test(`${locale.state} / ${locale.name}`)
                    )
                    .map(locale => (
                      <MenuItem key={locale.id} value={locale.id}>
                        {locale.state === locale.name
                          ? locale.state
                          : `${locale.state} / ${locale.name}`}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Button
                onClick={download}
                variant="contained"
                color="secondary"
                disabled={!!doDownload}
              >
                {!!doDownload ? "Preparing..." : "Download"}
              </Button>
            </Grid>
          </Grid>

          <DataTable
            fields={fields}
            search={null}
            dataListParams={{
              // noPoll: true,
              onItemsChange: items => setItems(items),
              useButton: true,
              query: Listing.queries.searchRaw,
              dataKey: "searchListingsRaw",
              variables: {
                sort: {
                  direction: "desc",
                  field: "updatedAt",
                },
                filter: JSON.stringify({
                  and: [
                    {
                      "cachedTicket.ticketLocaleId": {
                        eq: `${decodeURIComponent(searchString)}`,
                      },
                      listingSellerId: {
                        exists: true,
                      },
                      listingBuyerId: {
                        exists: true,
                      },
                    },
                  ],
                }),
              },
            }}
          />
        </ContentCell>
      </PageContainer>
    </>
  );
};
