import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect as DomRedirect } from "react-router-dom";

import Form from "../../Form";
import Redirect from "shared/api/Redirect";
import { withoutKeys } from "gunner-react";

export default ({ redirect }) => {
  const [formData, setFormData] = useState(redirect || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: ["featuredAt"].includes(field)
        ? !!value
          ? new Date().toISOString()
          : null
        : field === "active"
        ? !!value
        : value,
    });

  const [_createRedirect] = useMutation(Redirect.mutations.create, {
    variables: {
      input: {
        ...formData,
      },
    },
    refetchQueries: [
      {
        query: Redirect.queries.list,
        variables: { limit: 100, sportId: formData.sportId },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      createRedirect: {
        ...formData,
        __typename: "Redirect",
      },
    },
  });

  const [_updateRedirect] = useMutation(Redirect.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, ["__typename", "sport", "group"]),
      },
    },
    refetchQueries: [
      {
        query: Redirect.queries.list,
        variables: { limit: 100, sportId: formData.sportId },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updateRedirect: {
        ...formData,
        __typename: "Redirect",
      },
    },
  });

  //   sportId: ID!

  //   activeAt: AWSDateTime!
  //   featuredAt: AWSDateTime!

  const fields = {
    source: {
      label: "Source",
      regex: /\w+/,
      required: true,
      errorMessage: "You are required enter a name",
      value: value => value?.toLowerCase?.(),
    },
    destination: {
      label: "Destination",
      regex: /.+/,
      required: true,
      errorMessage: "You are required enter a name",
    },
  };

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateRedirect : _createRedirect)() //if this is an existing redirect, update it, otherwise, create it
        .then(
          ({
            data: {
              [!!formData.id ? "updateRedirect" : "createRedirect"]: redirect,
            },
          }) => setSubmitSuccess(true)
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [doSubmit]);

  return !!submitSuccess ? (
    <DomRedirect push to={`/admin/redirects`} />
  ) : (
    <Form
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  );
};
