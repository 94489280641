import React, { useState, useEffect, useContext, useCallback } from "react";
import queryString from "query-string";
import Listing from "shared/api/Listing";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
import InnerTabs from "../Components/Layout/InnerTabs";
import { Grid, Box } from "@material-ui/core";
import AdCell from "../Components/Layout/AdCell";
import TicketListItem from "../Components/Ticket/List/Item";
import Tabs from "../Components/Account/Components/AltTabs";
// import TicketListHelpModal from '../Components/Ticket/List/HelpModal'
import ForSaleFilter from "../Components/Listing/Components/ForSaleFilter";
import { CurrentUserContext } from "gunner-react";
import { DataList } from "gunner-react/web";
import SortOptions from "Components/Listing/Components/SortOptions";
import ListingPendingBidsFilter from "Components/Listing/PendingBidsFilter";

const ConvertListingItemToTicketItem = ({
  object: shallowListing,
  id: passedListingId,
  filter = item => true,
  status,
}) => (
  <TicketListItem
    object={{ ...shallowListing.ticket, listing: shallowListing }}
    id={shallowListing.listingTicketId}
    filter={filter}
    status={status}
  />
);

export default ({ history, location: { search = "" } = {} }) => {
  const currentUser = useContext(CurrentUserContext);
  const searchParams = queryString.parse(search);
  const [status, setStatus] = useState(searchParams.status || "ACTIVE");
  const [saleFilter, setSaleFilter] = useState("All");
  const [pendingFilter, setPendingFilter] = useState("All");
  const [sortDirection, setSortDirection] = useState(
    searchParams.sortDirection || "DESC"
  );
  const [sortField, setSortField] = useState(
    searchParams.sortField || "createdAt"
  );

  const clientFilter = useCallback(
    (status, saleFilter) => item =>
      status === "ACTIVE"
        ? saleFilter === "All"
          ? ["ACTIVE", "INACTIVE", "NEW"].includes(item.status)
          : saleFilter === "Not for Sale"
          ? ["INACTIVE", "NEW"].includes(item.status)
          : ["ACTIVE"].includes(item.status)
        : status === "PENDING"
        ? item.status === "PENDING"
        : status === "SOLD"
        ? item.status === "SOLD"
        : item.status === "ARCHIVED",
    []
  );

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/account/tickets"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell>
          <Grid container spacing={6}>
            <Grid item xs={12} container>
              <InnerTabs
                items={[
                  { label: "Active", value: "ACTIVE" },
                  { label: "Pending", value: "PENDING" },
                  { label: "Sold", value: "SOLD" },
                  { label: "Archived", value: "ARCHIVED" },
                ]}
                selected={status}
                onChange={status => setStatus(status)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            {status === "ACTIVE" ? (
              <ForSaleFilter onChange={setSaleFilter} saleFilter={saleFilter} />
            ) : (
              <Grid item xs={3} md={4} />
            )}
            <SortOptions
              onChangeField={setSortField}
              onChangeDirection={setSortDirection}
              sortDirection={sortDirection}
              sortField={sortField}
            />
            {status === "ACTIVE" && (
              <ListingPendingBidsFilter
                onChange={setPendingFilter}
                pendingFilter={pendingFilter}
              />
            )}

            {/* <Search
              searchString={searchString}
              onChange={setSearchString}
            /> */}
          </Grid>
          {/* {
            !!isLoading ? (
              "Loading..."
            ) : (
              ['ACTIVE', "PENDING", "SOLD", "ARCHIVED"].map(s =>
                <Box key={s} hidden={status !== s}>
                  <DataListView
                    objects={hydratedTickets.filter(clientFilter(s, saleFilter))}
                    onEndReached={() => null}
                    RenderComponent={TicketListItem}
                    viewVariables={{
                      status,
                    }}
                    clientFilter={clientFilter(s, saleFilter)}
                    clientSort={(a, b) => (a.listing ?? a).updatedAt > (b.listing ?? b).updatedAt ? -1 : 1}
                  />
                </Box>
              )
            )
          } */}
          <DataList
            clientFilter={clientFilter(status, saleFilter)}
            RenderComponent={ConvertListingItemToTicketItem}
            query={Listing.queries.search}
            dataKey={"searchListings"}
            viewVariables={{
              status,
            }}
            variables={{
              bidsSortDirection: "DESC",
              sort: {
                direction: sortDirection.toLowerCase(),
                field: sortField,
              },
              filter: {
                and: [
                  {
                    listingSellerId: {
                      eq: currentUser.id,
                    },
                  },
                  {
                    [status === "ACTIVE" && pendingFilter !== "All"
                      ? "pendingBidsCount"
                      : "id"]: {
                      [status === "ACTIVE" && pendingFilter !== "All"
                        ? "gt"
                        : "exists"]:
                        status === "ACTIVE" && pendingFilter !== "All"
                          ? 0
                          : true,
                    },
                  },
                  status === "ACTIVE"
                    ? {
                        or: [
                          {
                            status: {
                              eq:
                                saleFilter === "All" ||
                                saleFilter === "For Sale"
                                  ? "ACTIVE"
                                  : "NONSENSE",
                            },
                          },
                          {
                            status: {
                              eq:
                                saleFilter === "All" ||
                                saleFilter === "Not for Sale"
                                  ? "NEW"
                                  : "NONSENSE",
                            },
                          },
                          {
                            status: {
                              eq:
                                saleFilter === "All" ||
                                saleFilter === "Not for Sale"
                                  ? "INACTIVE"
                                  : "NONSENSE",
                            },
                          },
                        ],
                      }
                    : {
                        status: {
                          eq: status,
                        },
                      },
                ],
              },
            }}
            subscriptionCreateDataKey={"onCreateListing"}
            // subscriptionCreateVariables={{}}
            subscriptionCreateMutation={Listing.subscriptions.onCreate}
            subscriptionUpdateDataKey={"onUpdateListing"}
            subscriptionUpdateVariables={{}}
            subscriptionUpdateMutation={Listing.subscriptions.onUpdate}
            subscriptionUpdateQuery={Listing.queries.get}
          />
        </ContentCell>
        <AdCell>
          <img src={require("../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
      {/* <TicketListHelpModal /> */}
    </>
  );
};
