import { CurrentUserContext } from "gunner-react";
import { useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";
import Referral from "shared/api/Referral";

export default () => {
  const currentUser = useContext(CurrentUserContext);

  const { data: { listReferralsByCodeAndReferreeId: { items } = {} } = {} } =
    useQuery(Referral.queries.listByCodeAndReferreeId, {
      skip: !currentUser?.attributes?.["custom:referral_code"],
      variables: {
        code: currentUser?.attributes?.["custom:referral_code"],
        referreeId: {
          eq: currentUser?.id,
        },
        filter: {
          and: [
            {
              expired: {
                ne: true,
              },
            },
            {
              redeemed: {
                ne: true,
              },
            },
          ],
        },
      },
    });

  const referral = useMemo(
    () => items?.find?.(item => !!item.bonusCode) ?? items?.[0],
    [items]
  );

  return referral;
};
