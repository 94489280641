import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

const Disclosure = ({ ticket, onAgree, agree }) => {
  onAgree(agree || !!ticket.isMobile || !!ticket.inPropswapsPossession);

  return (
    <>
      {!!ticket.isMobile ? (
        <FormControl>
          <FormGroup
            onChange={({ target: { checked } }) => onAgree(!!checked)}
            row
          >
            <FormControlLabel
              control={<Checkbox checked={!!agree} color="primary" />}
              label="I understand I am purchasing this mobile ticket from a 3-star Seller. In the event this ticket should win, the Seller has 48 hours to send the full Collect Amount to PropSwap, which will then be added to my Balance."
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      ) : !ticket.isMobile && !ticket.inPropswapsPossession ? (
        <FormControl>
          <FormGroup
            onChange={({ target: { checked } }) => onAgree(!!checked)}
            row
          >
            <FormControlLabel
              control={<Checkbox checked={!!agree} color="primary" />}
              label="I understand PropSwap does not possess this ticket, and in the event the Seller fails to send the ticket to PropSwap within 7 calendar days, I will receive a full 100% refund"
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      ) : null}
    </>
  );
};

export default Disclosure;
