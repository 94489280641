import React from "react";
import { Grid } from "@material-ui/core";

const ContentCell = ({ children, fullWidth }) => {
  return (
    <Grid item xs={12} md={!!fullWidth ? 12 : 9}>
      {children}
    </Grid>
  );
};

export default ContentCell;
