import React from "react";
// import { DataTable } from 'gunner-react/web'
import { DataTableNew as DataTable } from "gunner-react/web";
import Listing from "shared/api/Listing";
import Ticket from "shared/api/Ticket";
import { withoutBlanks } from "gunner-react";

// export default ({
//   searchString,
//   fields,
//   result,
//   reviewed,
//   inPropswapsPossession,
//   isMobile,
//   onItemsChange,
// }) =>
//   <DataTable
//     fields={fields}
//     search={null}
//     dataListParams={{
//       // doClear: true,
//       onItemsChange,
//       clientFilter: item =>
//         (item.ticket.result === result || !result) &&
//         Object.entries(withoutBlanks({
//           reviewed,
//           inPropswapsPossession,
//           isMobile,
//         })).every(([key, value]) => !!item.ticket[key] === !!value)
//       ,
//       useButton: true,
//       query: {
//         query: Listing.queries.search,
//         dataKey: "searchListings",
//         variables: {
//           limit: 100,
//           filter: {
//             and: [
//               {
//                 searchString: {
//                   [!!searchString && / /.test(decodeURIComponent(searchString)) ? "match" : !!searchString ? 'regexp' : "exists"]: !!searchString ? `${decodeURIComponent(searchString) || ""}${/ /.test(decodeURIComponent(searchString)) ? '' : '.*'}`.toLowerCase() : true
//                 }
//               },
//             ]
//           }
//         }
//       }
//     }}
//   />

const isNullishOrBlank = value =>
  value === "" || value === undefined || value === null;

export default ({
  searchString,
  fields,
  result,
  reviewed,
  inPropswapsPossession,
  isMobile,
  onItemsChange,
  clientFilter,
}) => (
  <DataTable
    fields={fields}
    search={null}
    dataListParams={{
      // pollInterval: 5000,
      // doClear: true,
      onItemsChange,
      clientFilter,
      useButton: true,
      query: {
        query: Ticket.queries.listByResultUpdatedAt,
        dataKey: "ticketsByResultUpdatedAt",
        variables: {
          limit: !!searchString ? 2000 : 100,
          sortDirection: result === "UNDECIDED" ? "DESC" : "ASC",
          result,
          ...(isNullishOrBlank(isMobile) &&
          isNullishOrBlank(inPropswapsPossession) &&
          isNullishOrBlank(reviewed)
            ? {}
            : {
                filter: {
                  and: [
                    {
                      ...(isNullishOrBlank(isMobile)
                        ? {}
                        : {
                            isMobile: {
                              eq: isMobile,
                            },
                          }),
                      ...(isNullishOrBlank(inPropswapsPossession)
                        ? {}
                        : {
                            inPropswapsPossession: {
                              eq: inPropswapsPossession,
                            },
                          }),
                      ...(isNullishOrBlank(reviewed)
                        ? {}
                        : {
                            reviewed: {
                              eq: reviewed,
                            },
                          }),
                    },
                  ],
                },
              }),
        },
      },
    }}
  />
);
