import gql from "graphql-tag";
import environment from "environment";
// import Ticket from "./Ticket"

const Link = {};

Link.fragments = {
  public: gql`
    fragment LinkPublic on Link {
      id
      updatedAt
      createdAt

      title
      description
      photoUrl
      position
      url
      active
    }
  `,
};

Link.mutations = {
  update: gql`
    mutation UpdateLink($input: UpdateLinkInput!) {
      updateLink(input: $input) {
        ...LinkPublic
      }
    }
    ${Link.fragments.public}
  `,
  create: gql`
    mutation CreateLink($input: CreateLinkInput!) {
      createLink(input: $input) {
        ...LinkPublic
      }
    }
    ${Link.fragments.public}
  `,
  delete: gql`
    mutation DeleteLink($input: DeleteLinkInput!) {
      deleteLink(input: $input) {
        ...LinkPublic
      }
    }
    ${Link.fragments.public}
  `,
};

Link.queries = {
  listByPosition: gql`
    query ListLinksByPosition(
      $active: StringBoolean!
      $position: ModelIntKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelLinkFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listLinksByPosition(
        active: $active
        position: $position
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...LinkPublic
        }
        nextToken
      }
    }
    ${Link.fragments.public}
  `,
  get: gql`
    query GetLink($id: ID!) {
      getLink(id: $id) {
        ...LinkPublic
      }
    }
    ${Link.fragments.public}
  `,
  list: gql`
    query ListLinks(
      $filter: ModelLinkFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...LinkPublic
        }
        nextToken
      }
    }
    ${Link.fragments.public}
  `,
};

export default Link;
