import React from "react";

import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

import Form from "../Form";

import Group from "shared/api/Group";

const GroupEditContainer = ({ id }) => {
  const { loading, data: { getGroup: group } = {} } = useQuery(
    Group.queries.get,
    { variables: { id: id } }
  );

  return !!loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Form group={group} />
  );
};

export default GroupEditContainer;
