import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Hidden, Typography, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuDownIcon from "mdi-material-ui/MenuDown";

const useStyles = makeStyles(theme => ({
  filterOptions: {
    "& label": {
      display: "flex",
      cursor: "pointer",
    },
    "& a": {
      cursor: "pointer",
    },
  },
}));

const PriceRangeFilter = ({ priceRange, onChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = filter => [
    !!onChange && onChange(filter),
    setAnchorEl(null),
  ];

  return (
    <Grid className={classes.filterOptions} item xs={3} md={7}>
      <Hidden mdUp>
        <div onClick={handleClick}>
          <Typography variant={"subtitle1"} component="label">
            Price&nbsp;
            <Hidden smDown>Range</Hidden>
            <MenuDownIcon />
          </Typography>
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleClose([0, 10000000 * 100])}>
            All
          </MenuItem>
          <MenuItem onClick={() => handleClose([0, 100 * 100])}>
            $0-$100
          </MenuItem>
          <MenuItem onClick={() => handleClose([101 * 100, 1000 * 100])}>
            $101-$1,000
          </MenuItem>
          <MenuItem onClick={() => handleClose([1001 * 100, 10000000 * 100])}>
            $1,001+
          </MenuItem>
        </Menu>
      </Hidden>
      <Hidden smDown>
        <Grid container alignItems="center" item xs={12}>
          <Typography variant={"subtitle1"} component="label">
            Price Range:&nbsp;&nbsp;
          </Typography>
          <Typography
            onClick={() => handleClose([0, 10000000 * 100])}
            component={
              priceRange[0] === 0 && priceRange[1] === 10000000 * 100
                ? "span"
                : "a"
            }
          >
            All
          </Typography>
          &nbsp;&nbsp;
          <Typography
            onClick={() => handleClose([0, 100 * 100])}
            component={
              priceRange[0] === 0 && priceRange[1] === 100 * 100 ? "span" : "a"
            }
          >
            $0-$100
          </Typography>
          &nbsp;&nbsp;
          <Typography
            onClick={() => handleClose([101 * 100, 1000 * 100])}
            component={priceRange[0] === 101 * 100 ? "span" : "a"}
          >
            $101-$1,000
          </Typography>
          &nbsp;&nbsp;
          <Typography
            onClick={() => handleClose([1001 * 100, 10000000 * 100])}
            component={priceRange[0] === 1001 * 100 ? "span" : "a"}
          >
            $1,001+
          </Typography>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default PriceRangeFilter;
