import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import Form from "../../Form";
import Promotion from "shared/api/Promotion";
import { withoutKeys } from "gunner-react";

const fields = {
  code: {
    label: "Code",
    regex: /\w+/,
    required: true,
    type: "text",
    errorMessage: "You are required enter a code",
  },
  isExpired: {
    label: "Expired?",
    regex: /\w+/,
    type: "checkbox",
    errorMessage: "Required",
    value: value => !!value,
  },
  type: {
    label: "Type",
    regex: /\w+/,
    required: true,
    type: "text",
    errorMessage: "You are required to select a type",
    options: [
      { value: "DEPOSIT_MATCH_100", label: "Match Deposit ($100 Max)" },
      { value: "DEPOSIT_MATCH_500", label: "Match Deposit ($500 Max)" },
      { value: "LISTING_FEE_WAIVER", label: "Waive Listing Fee" },
    ],
  },
};

const PromotionFormContainer = ({ promotion, campaignId }) => {
  const [formData, setFormData] = useState({
    ...(promotion || {}),
    promotionCampaignId: campaignId,
  });
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === "isExpired" ? !!value : value,
    });

  const [_createPromotion] = useMutation(Promotion.mutations.create, {
    variables: {
      input: {
        ...withoutKeys(formData, ["campaign"]),
        code: (formData.code || "").toUpperCase(),
      },
    },
    refetchQueries: [
      {
        query: Promotion.queries.listByCampaignId,
        variables: { limit: 100, promotionCampaignId: campaignId },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      createPromotion: {
        ...formData,
        code: (formData.code || "").toUpperCase(),
        __typename: "Promotion",
      },
    },
  });

  const [_updatePromotion] = useMutation(Promotion.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, ["__typename", "campaign"]),
      },
    },
    refetchQueries: [
      {
        query: Promotion.queries.list,
        variables: { limit: 100 },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updatePromotion: {
        ...formData,
        __typename: "Promotion",
      },
    },
  });

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updatePromotion : _createPromotion)() //if this is an existing promotion, update it, otherwise, create it
        .then(
          ({
            data: {
              [!!formData.id ? "updatePromotion" : "createPromotion"]:
                promotion,
            },
          }) => setSubmitSuccess(true)
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [doSubmit]);

  return !!submitSuccess ? (
    <Redirect push to={`/admin/campaigns/${campaignId}/promotions`} />
  ) : (
    <Form
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  );
};

export default PromotionFormContainer;
