import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import classNames from "classnames";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  innerTabs: {
    display: "flex",
    overflow: "auto",
    "& .tab": {
      cursor: "pointer",
      flex: 1,
      padding: theme.spacing(1, 2),
      background: theme.palette.background.default,
      border: `1px solid ${theme.palette.divider}`,
    },
    "& .label": {
      fontWeight: "inherit",
      textTransform: "uppercase",
    },
    "& .tab:hover": {
      background: theme.palette.background.paper,
    },
    "& .tab.selected": {
      borderColor: theme.palette.text.primary,
      cursor: "default",
      fontWeight: theme.typography.fontWeightBold,
      background: theme.palette.background.paper,
    },
  },
}));

const InnerTabs = ({ selected, items, onChange }) => {
  const classes = useStyles();
  return (
    <nav className={classes.innerTabs}>
      {items.map(({ label, value }, i) => (
        <div
          className={classNames("tab", { selected: selected === value })}
          key={i}
        >
          <Typography
            noWrap
            onClick={() => onChange(value)}
            className={"label"}
            variant={"h5"}
            component="span"
          >
            {label}
          </Typography>
        </div>
      ))}
    </nav>
  );
};

export default InnerTabs;
