import React, { useState, useRef } from "react";
import FileUploaderView from "./FileUploaderView";

export default ({ onFile, ...rest }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const inputEl = useRef(null);

  const handleClick = () => inputEl.current.click();

  const handleSelect = evt =>
    Promise.resolve({
      reader: new FileReader(),
      file: evt.target.files[0],
    }).then(({ reader, file }) => [
      (reader.onload = e => onFile(e.target.result)),
      reader.readAsText(file),
    ]);

  return (
    <>
      <FileUploaderView onClick={handleClick} {...rest} />
      <input
        style={{ visibility: "hidden" }}
        type="file"
        ref={inputEl}
        accept=".csv"
        onChange={handleSelect}
      />
    </>
  );
};
