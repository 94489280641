import React, { useContext, useMemo } from "react";
import Redirect from "shared/api/Redirect";
import { DataTableNew as DataTable } from "gunner-react/web";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
// import AdCell from '../Components/Layout/AdCell'
import Tabs from "Components/Admin/Components/Tabs";
import { CurrentUserContext } from "gunner-react";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import RedirectDeleteButton from "Components/Redirect/DeleteButton";

// const ConvertWatchingItemToTicketItem = ({object, id, filter = item => true}) =>
//   <TicketListItem object={{...object.listing.ticket, listing: object.listing}} id={object.listing.listingTicketId} filter={filter} />

// const ConvertListingItemToTicketItem = args =>
//   console.log(args) ||
//   null

const fields = {
  Source: {
    value: item => item.source,
    // nowrap: true,
    sort: null,
    rowProps: {},
  },
  Destination: {
    value: item => item.destination,
    // nowrap: true,
    sort: null,
    rowProps: {},
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/redirects/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
  Delete: {
    hideLabel: true,
    sort: null,
    value: item => <RedirectDeleteButton id={item.id} />,
  },
};

export default ({ history, location: { search = "" } = {} }) => {
  const currentUser = useContext(CurrentUserContext);

  const query = useMemo(
    () => ({
      query: Redirect.queries.list,
      dataKey: "listRedirects",
    }),
    []
  );

  // const entry = useQuery(query.query, {
  //   variables: query.variables
  // })

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/redirects"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Grid container spacing={4} justify={"flex-end"}>
            <Grid item xs={12} md={3}>
              <Button
                component={Link}
                to={`/admin/redirects/new`}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Add Redirect
              </Button>
            </Grid>
          </Grid>
          <DataTable
            fields={fields}
            dataListParams={{
              query,
            }}
          />
        </ContentCell>
      </PageContainer>
    </>
  );
};
