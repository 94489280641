export const nonMutationFields = [
  "version",
  "ticket",
  "seller",
  "buyer",
  "bids",
  "__typename",
];

export const adminOnlyFields = ["ratingOverride", "status", "featuredAt"];
