import React, { useState } from "react";

import HelpIcon from "@material-ui/icons/Help";

import Modal from "../Modal";

export default ({ ModalBody, modalTitle }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <HelpIcon
        style={{ position: "absolute", top: -12, right: 0 }}
        onClick={() => setShowModal(true)}
      />
      <Modal
        title={modalTitle}
        body={ModalBody}
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={{
          text: "OK",
          onClick: () => setShowModal(false),
          ButtonProps: {
            fullWidth: true,
          },
        }}
      />
    </>
  );
};
