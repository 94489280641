import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

// import useSportListStyles from "./SportListStyles";
import DataTable from "../../DataTable";

const fields = {
  Name: {
    value: item => item.name,
    sort: order => (a, b) =>
      a.name > b.name ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  Abbrev: {
    value: item => item.abbrev,
    sort: order => (a, b) =>
      a.name > b.name ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  Position: {
    value: item => item.position,
    sort: order => (a, b) =>
      a.position > b.position
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  CompetitionId: {
    value: item => item.fanDuelCompetitionId,
    sort: order => (a, b) =>
      a.fanDuelCompetitionId > b.fanDuelCompetitionId
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Groups: {
    hideLabel: true,
    sort: null,
    value: item => <Link to={`/admin/sports/${item.id}/groups`}>Groups</Link>,
  },
  Events: {
    hideLabel: true,
    sort: null,
    value: item => <Link to={`/admin/sports/${item.id}/events`}>Events</Link>,
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/sports/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
};

const SportListView = ({ sports }) => (
  <DataTable fields={fields} items={sports} />
);

export default SportListView;
