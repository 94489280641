import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import { convertMoneyTextToPennies, formatPennies } from "shared/Util/money";
import { Form } from "gunner-react/web";
import ConversionTemplate from "shared/api/ConversionTemplate";
import { withoutKeys } from "gunner-react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

export default ({ conversionTemplate }) => {
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(conversionTemplate || {});
  const [mappings, setMappings] = useState(formData?.mapping ?? []);
  const [mappingData, setMappingData] = useState({});

  const [redirectTo, setRedirectTo] = useState(null);

  const handleAddMapping = () =>
    Promise.resolve(
      setMappings(mappings => [
        ...(mappings ?? []),
        ...[
          {
            type: "DEPOSIT",
            threshold: convertMoneyTextToPennies(mappingData.threshold),
            referrerBonus: convertMoneyTextToPennies(mappingData.referrerBonus),
            referreeBonus: convertMoneyTextToPennies(mappingData.referreeBonus),
          },
        ],
      ])
    ).then(() => setMappingData({}));

  const handleMappingChange = (field, value) =>
    setMappingData(mappingData => ({
      ...mappingData,
      [field]: value,
    }));

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: ["featuredAt"].includes(field)
        ? !!value
          ? new Date().toISOString()
          : null
        : field === "active"
        ? !!value
        : value,
    });

  const [_createConversionTemplate] = useMutation(
    ConversionTemplate.mutations.create,
    {
      variables: {
        input: {
          ...formData,
          mapping: mappings.map(mapping =>
            withoutKeys(mapping, ["__typename"])
          ),
        },
      },
      refetchQueries: [
        {
          query: ConversionTemplate.queries.list,
          variables: { limit: 100, sportId: formData.sportId },
        },
      ],
      optimisticResponse: {
        __typename: "Mutation",
        createConversionTemplate: {
          ...formData,
          mapping: mappings.map(mapping =>
            withoutKeys(mapping, ["__typename"])
          ),
          __typename: "ConversionTemplate",
        },
      },
    }
  );

  const [_updateConversionTemplate] = useMutation(
    ConversionTemplate.mutations.update,
    {
      variables: {
        input: {
          ...withoutKeys(formData, ["__typename", "sport", "group"]),
          mapping: mappings.map(mapping =>
            withoutKeys(mapping, ["__typename"])
          ),
        },
      },
      refetchQueries: [
        {
          query: ConversionTemplate.queries.list,
          variables: { limit: 100, sportId: formData.sportId },
        },
      ],
      optimisticResponse: {
        __typename: "Mutation",
        updateConversionTemplate: {
          ...formData,
          __typename: "ConversionTemplate",
          mapping: mappings.map(mapping =>
            withoutKeys(mapping, ["__typename"])
          ),
        },
      },
    }
  );

  const handleSubmit = () =>
    Promise.all([
      setSubmitting(true),
      (!!formData.id ? _updateConversionTemplate : _createConversionTemplate)(),
    ])
      .then(() => setRedirectTo(`/admin/conversionTemplates`))
      .catch(e => [console.log(e), window.alert(e.message)])
      .finally(() => setSubmitting(false));

  const fields = {
    bonusCode: {
      label: "Bonus Code",
      regex: /\w+/,
      required: false,
      value: value => (value ?? "").toUpperCase(),
      errorMessage: "You are required enter a name",
    },
    active: {
      label: "Active?",
      regex: /\w+/,
      required: false,
      type: "checkbox",
    },
  };

  const mappingFields = {
    threshold: {
      label: "Threshold",
      regex: /\d+/,
      required: true,
      type: "money",
      errorMessage: "You are required enter a threshold",
    },
    referrerBonus: {
      label: "Referrer Bonus",
      regex: /\d+/,
      required: true,
      type: "money",
      errorMessage: "You are required enter something",
    },
    referreeBonus: {
      label: "Referree Bonus",
      regex: /\d+/,
      required: true,
      type: "money",
      errorMessage: "You are required enter something",
    },
  };

  const handleDeleteMapping = index => () =>
    setMappings(mappings => mappings.filter((mapping, i) => i !== index));

  return !!redirectTo ? (
    <Redirect push to={redirectTo} />
  ) : (
    <>
      <List dense>
        {mappings.map((mapping, i) => (
          <ListItem key={i}>
            <ListItemText
              primary={`$${formatPennies(mapping.threshold)}`}
              secondary={`Refferrer Bonus: $${formatPennies(
                mapping.referrerBonus
              )} / Referree Bonus: $${formatPennies(mapping.referreeBonus)}`}
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={handleDeleteMapping(i)}
                aria-label="delete"
                size="small"
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Box mb={4} mt={4}>
        <Form
          extraIsValid={!!mappings?.length}
          submitting={submitting}
          fields={fields}
          onChange={handleChange}
          data={formData}
          onSubmit={handleSubmit}
          buttonLabel={"Submit"}
        />
      </Box>
      <Divider />
      <Box mt={4}>
        <Form
          submitting={false}
          fields={mappingFields}
          onChange={handleMappingChange}
          data={mappingData}
          onSubmit={handleAddMapping}
          buttonLabel={"Add Mapping"}
        />
      </Box>
    </>
  );
};
