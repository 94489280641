import React from "react";
import RedirectNew from "Components/Redirect/New";
import PageContainer from "Components/Layout/PageContainer";
import ContentCell from "Components/Layout/ContentCell";
import Tabs from "Components/Admin/Components/Tabs";

export default ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/redirects"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <RedirectNew />
      </ContentCell>
    </PageContainer>
  </>
);
