import gql from "graphql-tag";
import environment from "environment";
import User from "shared/api/User";

const Device = {};

Device.fragments = {
  public: gql`
    fragment DevicePublic on Device {
      id
      userId
      type
      updatedAt
      createdAt
      token
      user {
        ...UserPublic
      }
    }
    ${User.fragments.public}
  `,
};

Device.mutations = {
  update: gql`
    mutation UpdateDevice($input: UpdateDeviceInput!) {
      updateDevice(input: $input) {
        ...DevicePublic
      }
    }
    ${Device.fragments.public}
  `,
  create: gql`
    mutation CreateDevice($input: CreateDeviceInput!) {
      createDevice(input: $input) {
        ...DevicePublic
      }
    }
    ${Device.fragments.public}
  `,
};

Device.queries = {
  listByUserId: gql`
    query ListDevicesByUserId(
      $userId: ID!
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelDeviceFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listDevicesByUserId(
        userId: $userId
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...DevicePublic
        }
        nextToken
      }
    }
    ${Device.fragments.public}
  `,
  listByTokenAndType: gql`
    query ListDevicesByTokenAndType(
      $token: String!
      $type: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelDeviceFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listDevicesByTokenAndType(
        token: $token
        type: $type
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...DevicePublic
        }
        nextToken
      }
    }
    ${Device.fragments.public}
  `,
  get: gql`
    query GetDevice($id: ID!) {
      getDevice(id: $id) {
        ...DevicePublic
      }
    }
    ${Device.fragments.public}
  `,
  list: gql`
    query ListDevices(
      $filter: ModelDeviceFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...DevicePublic
        }
        nextToken
      }
    }
    ${Device.fragments.public}
  `,
};

export default Device;
