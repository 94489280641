/* global bluesnap */
/* global $ */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import {
//   FormControl,
//   FormControlLabel,
//   Radio,
//   FormGroup,
//   RadioGroup,
//   Checkbox,
//   CircularProgress
// } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: "332px",
  },
}));

const changeImpactedElement = (tagId, removeClass, addClass) => {
  removeClass = removeClass || "";
  addClass = addClass || "";
  $("[data-bluesnap=" + tagId + "]")
    .removeClass(removeClass)
    .addClass(addClass);
};

const cardUrl = {
  AmericanExpress: "https://files.readme.io/97e7acc-Amex.png",
  CarteBleau: "https://files.readme.io/5da1081-cb.png",
  DinersClub: "https://files.readme.io/8c73810-Diners_Club.png",
  Discover: "https://files.readme.io/caea86d-Discover.png",
  JCB: "https://files.readme.io/e076aed-JCB.png",
  MaestroUK: "https://files.readme.io/daeabbd-Maestro.png",
  MasterCard: "https://files.readme.io/5b7b3de-Mastercard.png",
  Solo: "https://sandbox.bluesnap.com/services/hosted-payment-fields/cc-types/solo.png",
  Visa: "https://files.readme.io/9018c4f-Visa.png",
};

const NewCustomerForm = ({
  setCardHolderName,
  setZip,
  submitting,
  onCancel,
  bluesnapToken,
  onSubmit,
  cardHolderName,
  zip,
}) => {
  const classes = useStyles();
  const [valids, setValids] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  // const [storeCard, setStoreCard] = useState(true);

  const handleSubmit = () =>
    bluesnap.submitCredentials(callback =>
      !!callback.error
        ? console.log(callback.error)
        : onSubmit(callback.cardData)
    );

  const bsObj = {
    onFieldEventHandler: {
      onFocus: tagId => {
        // Handle focus
        changeImpactedElement(tagId, "", "hosted-field-focus");
      },
      onBlur: tagId => {
        // Handle blur
        changeImpactedElement(tagId, "hosted-field-focus");
      },
      onError: (tagId, errorCode, errorDescription) => {
        setValids({
          ...valids,
          tagId: false,
        });
        // Handle a change in validation by displaying help text
        $("#" + tagId + "-help")
          .removeClass("helper-text-green")
          .text(errorCode + " - " + errorDescription);
      },
      onType: (tagId, cardType, cardData) => {
        // get card type from cardType and display card image
        $("#card-logo > img").attr("src", cardUrl[cardType]);
        // if (null != cardData) {
        //    $("#" + tagId + "-help").addClass('helper-text-green').text(JSON.stringify(cardData));
        // }
      },
      onValid: tagId => {
        setValids({
          ...valids,
          tagId: true,
        });
        // Handle a change in validation by removing any help text
        $("#" + tagId + "-help").text("");
      },
    },
    style: {
      // Styling all inputs
      input: {
        "font-size": "14px",
        "font-family":
          "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
        "line-height": "1.42857143",
        color: "#555",
      },
      // Styling input state
      ":focus": {
        color: "#555",
      },
    },
    ccnPlaceHolder: "1234567890123456", //for example
    cvvPlaceHolder: "123", //for example
    expPlaceHolder: "MM/YY", //for example
    expDropDownSelector: false, //set to true for exp. date dropdown
  };

  useEffect(() => {
    !!bluesnapToken &&
      bluesnap.hostedPaymentFieldsCreation(bluesnapToken, bsObj);
  }, [bluesnapToken]);

  useEffect(() => {
    setIsFormValid(
      !Object.values(valids).find(value => !value) && !!zip && !!cardHolderName
    );
  }, [Object.values(valids).join(" "), !!zip, !!cardHolderName]);

  return (
    <div className={classes.root}>
      <>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-material-design/0.3.0/css/material-fullpalette.css"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-material-design/0.3.0/css/material.css"
          crossOrigin="anonymous"
        />
      </>
      {
        <form id="checkout-form">
          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="cardholder-name">Cardholder Name</label>
              <input
                onChange={({ target: { value } }) => setCardHolderName(value)}
                value={cardHolderName || ""}
                type="text"
                className="form-control"
                id="cardholder-name"
              />
              <span className="helper-text"></span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-xs-9">
              <label htmlFor="card-number">Card Number</label>
              <div className="input-group">
                <div
                  className="form-control"
                  id="card-number"
                  data-bluesnap="ccn"
                ></div>
                <div id="card-logo" className="input-group-addon">
                  <img
                    alt="Credit Card"
                    src="https://files.readme.io/d1a25b4-generic-card.png"
                    height="20px"
                  />
                </div>
              </div>
              <span className="helper-text" id="ccn-help"></span>
            </div>
            <div className="form-group col-xs-3">
              <label htmlFor="cvv">CVV</label>
              <div className="form-control" id="cvv" data-bluesnap="cvv"></div>
              <span className="helper-text" id="cvv-help"></span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-xs-6">
              <label htmlFor="exp-date">Exp</label>
              <div
                className="form-control"
                id="exp-date"
                data-bluesnap="exp"
              ></div>
              <span className="helper-text" id="exp-help"></span>
            </div>
            <div className="form-group col-xs-6">
              <label htmlFor="cardholder-code">Billing Zip Code</label>
              <input
                onChange={({ target: { value } }) => setZip(value)}
                value={zip || ""}
                type="text"
                className="form-control"
                id="cardholder-code"
              />
              <span className="helper-text"></span>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-md-6">
              <Button
                fullWidth
                disabled={submitting || !isFormValid}
                variant={"contained"}
                color={"secondary"}
                onClick={handleSubmit}
              >
                {!!submitting ? "Submitting" : "Add Card"}
              </Button>
            </div>
            <div className="col-xs-12 col-md-6">
              {!!onCancel && (
                <Button disabled={submitting} fullWidth onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </div>
          </div>
        </form>
      }
    </div>
  );
};

export default NewCustomerForm;
