import React from "react";

import Form from "../Form";
import { useQuery } from "@apollo/client";
import Sportsbook from "shared/api/Sportsbook";

export default ({ id }) => {
  const { data: { getSportsbook } = {} } = useQuery(Sportsbook.queries.get, {
    variables: { id },
  });

  return !getSportsbook ? null : <Form sportsbook={getSportsbook} />;
};
