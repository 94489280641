import React from "react";

import Form from "../Form";
import { useQuery } from "@apollo/client";
import Provider from "shared/api/Provider";

export default ({ id }) => {
  const { data: { getProvider } = {} } = useQuery(Provider.queries.get, {
    variables: { id },
  });

  return !getProvider ? null : <Form provider={getProvider} />;
};
