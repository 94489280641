import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Hidden, Typography, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuDownIcon from "mdi-material-ui/MenuDown";

const useStyles = makeStyles(theme => ({
  forSaleFilter: {
    "& label": {
      display: "flex",
      cursor: "pointer",
    },
  },
  // container: {
  //   marginBottom: theme.spacing(2),
  // }
}));

export default ({ onChange, pendingFilter }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = (event, filter) => setAnchorEl(null);

  const handleSelect = filter => () => [handleClose(), onChange(filter)];

  return (
    <Grid className={classes.forSaleFilter} item xs={3} md={4}>
      <div onClick={handleClick}>
        <Typography variant={"subtitle1"} component={"label"}>
          Bids
          <Hidden smDown>: {pendingFilter}</Hidden>
          <MenuDownIcon />
        </Typography>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSelect("All")}>All</MenuItem>
        <MenuItem onClick={handleSelect("Show Only Bids")}>
          Show Only Bids
        </MenuItem>
      </Menu>
    </Grid>
  );
};
