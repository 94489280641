import React from "react";
import RedemptionList from "../../../../Components/Redemption/List";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const RedemptionListRoute = ({
  history,
  match: {
    params: { promotionId },
  },
}) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/campaigns"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <RedemptionList promotionId={promotionId} />
      </ContentCell>
    </PageContainer>
  </>
);

export default RedemptionListRoute;
