import React from "react";
import PageContainer from "../../Layout/PageContainer";
import ContentCell from "../../Layout/ContentCell";

const BuyerTerms = () => {
  return (
    <PageContainer>
      <ContentCell fullWidth>
        <img
          src={require("../../../assets/css/logo-terms.png")}
          alt="Ad"
          style={{ width: 300, height: "auto" }}
        />
        <p>
          <strong>Happiness is Hedging</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>PropSwap LLC Website</strong>
        </p>
        <p>
          <strong>Terms of Use</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Acceptance of Terms</u>
          </strong>
        </p>
        <p>
          PLEASE READ THE FOLLOWING TERMS OF USE AND PRIVACY POLICY CAREFULLY
          BEFORE USING THE PropSwap Website (the “website”).
        </p>
        <p>
          <br /> The terms of this Agreement (“Terms of Use”) you are reading
          are a legal agreement that governs your relationship with PropSwap LLC
          (hereinafter referred to as “PropSwap” or the “Company”) regarding
          your use of the Website. You agree that you read, understood, accept
          and agree to be bound by these Terms of Use. If you do not agree to
          these Terms of Use, do not use or access the Website.
        </p>
        <p>&nbsp;</p>
        <p>
          By registering with, using or otherwise accessing the Website, you
          agree that you read, understood, accept and agree to be bound by these
          Terms of Use and the Privacy Policy referenced below (“Privacy
          Policy”). If you do not accept these Terms of Use and/or the Privacy
          Policy, you are not authorized to use the Website. The Company may
          modify these Terms of Use and the Privacy Policy at any time and such
          modification will be effective upon the date the Terms of Use and
          Privacy Policy are posted to propswap.com/terms. You are responsible
          for reviewing these Terms of Use for modifications prior to accessing
          or using the Website. By accessing or using the Website, you
          acknowledge and agree that you shall be bound by any such revisions.
          If you do not wish to be bound by the modified Terms of Use and/or
          Privacy Policy, you must cease use of the Website.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>PropSwap </u>
          </strong>
        </p>
        <p>
          By installing, registering with, using or otherwise accessing the
          Website, you agree and understand that the Company does not operate an
          “off-track pari-mutuel system” or a “pari-mutuel system of wagering”
          nor does it offer “off-track pari-mutuel wagering” as defined in
          Section 464.005 of the Nevada Revised Statutes (“NRS”). You further
          agree and understand that the Company is not a “sports pool” as
          defined in Section 463.0193 of the NRS, nor does it accept any
          “wagers” as defined in Section 463.01962 of the NRS, or pay out any
          wagers. For these reasons, PropSwap is not licensed by Nevada Gaming
          Commission.
        </p>
        <p>&nbsp;</p>
        <p>
          The Company’s Website facilitates the sale of a sports betting ticket
          (a “Ticket”), issued by a licensed Nevada sports pool at a Nevada
          gaming establishment (a “Sportsbook”), between the owner of the Ticket
          (the “Seller”) and a buyer (the “Buyer”) who purchases that Ticket
          from the Seller. You agree and understand that PropSwap does not set
          the price for any wagers made at any Sportsbook, that the Company does
          not accept or set the price for a Ticket, and that the Buyer and
          Seller will accept and set the prices themselves. You also agree and
          understand that the Company serves only as the intermediary between
          the Buyer and the Seller and does not partake in any winnings or any
          monies from a wager.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>User Accounts</u>
          </strong>
        </p>
        <p>
          To use the Website, you must register with the Company and create an
          account (a “User Account”). To establish a User Account, you must
          provide PropSwap with the following information: (i) your first and
          last name; (ii) your email address; (iii) your state of residency;
          (iv) your date of birth; and your credit card (collectively, the
          “Registration Information”). You hereby explicitly consent that
          Company may verify your Registration Information with your credit card
          issuer. Should your Registration Information fail the Company’s
          verification process (for example, if PropSwap suspects that the
          Registration Information has been tampered with, or is in any way
          provided to mislead or misrepresent), the Company shall be under no
          obligation to accept such Registration Information as accurate, and
          shall be under no obligation to provide feedback on the exact nature
          of the Company’s findings with regards to the Registration
          Information. Once PropSwap has verified the Registration Information,
          the Company will activate your User Account.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          You represent and warrant that all information you supply to PropSwap
          is complete and accurate. Knowingly submitting incomplete or
          inaccurate information will result in immediate termination of your
          User Account and ability to access and utilize the Website.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>License and Limitations on Use</u>
          </strong>
        </p>
        <p>
          Upon activation of your User Account, and subject to your agreement
          and compliance with the Terms of Use and Privacy Policy, PropSwap
          grants you a non-exclusive, non-transferable, revocable limited
          license to access and use the Website solely for your own
          non-commercial purposes.
        </p>
        <p>&nbsp;</p>
        <p>
          In furtherance of the foregoing, and as an example and not as a
          limitation, you agree that you will not:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            Create an account with, access or otherwise use the Website if you
            are under the age of 18;
          </li>
          <li>
            Restrict or inhibit any other person from use of the Website or
            interfere with or disrupt the operation of the Website or the
            servers or networks used to make the Website available or violate
            any requirements, procedures, policies, or regulations of such
            networks;
          </li>
          <li>
            Create false personas, multiple identities, multiple User Accounts,
            set up an account on behalf of someone other than yourself, use bots
            or other automated software programs to defraud PropSwap or any
            third party or take any other action which otherwise violates these
            Terms of Use;
          </li>
          <li>
            Attempt to obtain passwords or other private information from other
            users including personally identifiable information;
          </li>
          <li>
            Use the Website if you have previously been removed or banned by
            PropSwap;
          </li>
          <li>
            Rent, lease, sell, trade, or otherwise transfer to, or share your
            account with, anyone;
          </li>
          <li>
            Use the Website to post or transmit any virus, worm, Trojan Horse,
            easter egg, time bomb, spyware, or other computer code, file, or
            program that is harmful or invasive or may or is intended to damage
            or hijack the operation of, or to monitor the use of, any hardware,
            software, or equipment;
          </li>
          <li>
            Attempt to use the Website on or through any device or service that
            is not authorized by PropSwap;
          </li>
          <li>
            Use the Website in any way as a money transfer system or for any
            unlawful or fraudulent activity or prohibited transaction (including
            money laundering) under U.S. Federal laws and/or the laws of the
            State of Nevada; or
          </li>
          <li>
            Modify, adapt, translate, reverse engineer, or disassemble any
            portion of the Website.
          </li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Age</u>
          </strong>
        </p>
        <p>
          You represent and warrant that you are an adult over 18 years of age
          and have full right and authority to use the Website and to be bound
          by and comply with these Terms of Use. Failure to comply with this
          condition will result in the termination of your User Account. If you
          purchase a Ticket through the Website, you further agree that you are
          capable of presenting the Ticket at the counter of the gaming
          establishment that issued the Ticket in order to redeem any monies
          collectable under the authority of the Ticket
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Access; User Names and Passwords</u>
          </strong>
        </p>
        <p>
          You are responsible for maintaining the confidentiality of any
          password you may use to access the Website, and agree not to transfer
          your password or user login information (“Account Information”), or
          lend or otherwise transfer your use of or access to the Website, to
          any third party. You are solely responsible for all interaction with
          the Website that occurs in connection with your Account Information.
          You agree to immediately notify PropSwap of any unauthorized use of
          your password or User Name or any other breach of security related to
          your account or the Website, and to ensure that you “log off” or exit
          from your User Account when not using the Website. The Company is not
          liable for any loss or damage arising from your failure to comply with
          any of the foregoing obligations.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Location of Buyer</u>
          </strong>
        </p>
        <p>
          Buyers may only purchase a Ticket through the App if Buyer is
          currently located within one of the following Approved States:
          Arkansas, California, Colorado, Georgia, Connecticut, Indiana, Iowa,
          Kentucky, Massachusetts, Mississippi, Nevada, New Jersey, New York,
          Ohio, Pennsylvania, West Virginia. You acknowledge and agree that you
          will be physically present in one of these Approved States during any
          purchase of a Ticket and that you will comply with PropSwap’s
          requirements in connection with verifying your physical location at
          the time of purchase. You hereby consent to your location being
          verified before any purchase of a Ticket. Use of any software,
          programs or services that interfere with PropSwap’s verification of
          your location is prohibited and will result in rejection or
          termination of any attempt to purchase a Ticket. The Company reserves
          the right to void any Ticket purchase it believes was made outside of
          an Approved State.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Ticket Purchases</u>
          </strong>
        </p>
        <p>
          Buyer agrees that any payment made by a Buyer for a Ticket is
          non-refundable for any and all reasons including but not limited to
          cancellation of the sporting event, lack of cooperation by the
          Sportsbook issuing the Ticket, inability of the Sportsbook issuing the
          Ticket to recognize the Ticket or to otherwise decide not to cash the
          Ticket. Buyer understands that the Seller may change the price of the
          Ticket prior to the Buyer purchasing the Ticket.
        </p>
        <p>&nbsp;</p>
        <p>
          Buyer agrees that Buyer will pay to the Company, as an intermediary
          between Buyer and Seller, the total price set by the Seller for the
          Ticket. Forms of accepted payment shall be determined by the Company
          in its sole discretion. You acknowledge that the Company shall be paid
          a percentage of the sale price, which is set solely by Seller. Upon
          completion of a sale of a Ticket the Company shall deliver the Ticket
          to the Buyer.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Ticket Redemption </u>
          </strong>
        </p>
        <p>
          Buyer agrees that BUYER WILL be responsible to present the Ticket to
          the SPORTSBOOK that issued the ticket in order to redeem any monies
          collectable under the authority of the Ticket. THE COMPANY WILL use
          standard postal mail to ship the Ticket to the Buyer’s address
          (submitted during the Registration period). THE COMPANY WILL CHARGE
          The Buyer A HANDLING FEE TO SHIP THE TICKET TO THE BUYER.
        </p>
        <p>&nbsp;</p>
        <p>
          THE BUYER may, upon request, HAVE THE Company present the Ticket to
          the SPORTSBOOK that issued the ticket in order to redeem any monies
          collectable under the authority of the Ticket. THERE IS NO FEE
          ASSOCIATED WITH THIS FREE SERVICE. MONIES OWED TO THE BUYER UNDER THE
          AUTHORITY OF THE TICKET WILL BE CREDITED TO THE BUYER’s USER ACCOUNT.
          THE COMPANY WILL CHARGE A HANDLING FEE TO WITHDRAW FUNDS FROM THE
          BUYER’s USER ACCOUNT. WITHDRAWN FUNDS WILL BE PROVIDED TO THE BUYER BY
          CHECK, WHICH THE COMPANY WILL SEND THROUGH standard postal mail to the
          Buyer’s address (submitted during the Registration period).
        </p>
        <p>&nbsp;</p>
        <p>
          Buyer agrees that Buyer will provide all required information and
          complete all documents and forms necessary in connection with the
          purchase of a Ticket, including without limitation, the preparation of
          any report required by federal or Nevada law.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Voluntary Self-Exclusion or Involuntary Excluded Individuals</u>
          </strong>
        </p>
        <p>
          If you are self or involuntarily excluded from any casino, you may
          receive a winning ticket from that casino and be unable to redeem that
          ticket at the respective casino.
        </p>
        <p>&nbsp;</p>

        <p>
          <strong>
            <u>Event Grading and Cancellations</u>
          </strong>
        </p>

        <p>
          All tickets purchased on PropSwap are subject to the House Rules set
          forth by the issuing sportsbook of the ticket.
        </p>

        <p>
          Result determinations of tickets with regards to win, loss or push are
          ate the sole discretion of the issuing sportsbook.
        </p>

        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Payment Processing </u>
          </strong>
        </p>
        <p>
          You are solely responsible for paying all monies owed as a result of
          your use of the Website. You agree that you will not make or attempt
          to make any charge-backs, and/or deny or reverse any payment that you
          have made and you will reimburse the Company for any charge-backs,
          denial or reversal of payments you make and any loss suffered by the
          Company or Seller as a consequence thereof. The Company may, at its
          sole discretion, cease to provide access to the Website or withhold
          payment to certain users or to users paying with certain credit cards.
        </p>
        <p>&nbsp;</p>
        <p>
          The Company reserves the right to use third party electronic payment
          processors and/or financial institutions to process payments made by
          you or to you in connection with your use of the Website. To the
          extent that they do not conflict with these Terms of Use, You agree to
          be bound by the terms and conditions of such third party electronic
          payment processors and/or financial institutions.
        </p>
        <p>&nbsp;</p>
        <p>
          In the case of suspected or fraudulent payment, including use of
          stolen credit cards, or any other fraudulent activity, the Company
          reserves the right to block your User Account. The Company shall be
          entitled to inform any relevant authorities or entities (including
          credit reference agencies) of any payment fraud or other unlawful
          activity, and may employ collection services to recover payments.
          However, under no circumstances shall the Company be liable for any
          unauthorized use of credit cards, irrespective of whether or not the
          credit cards were reported stolen.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Taxes and Fees</u>
          </strong>
        </p>
        <p>
          You agree that you are solely responsible for any state or federal
          taxes, and any and all fees that might be due arising from your use of
          the Website.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Intellectual Property Ownership</u>
          </strong>
        </p>
        <p>
          PropSwap and/or its licensors and affiliates own all right, title, and
          interest, including copyrights, trademarks and other intellectual
          property rights, in and to all the App. You hereby acknowledge that
          you do not acquire any ownership rights by using the Website or by
          accessing any of the Website.
        </p>
        <p>&nbsp;</p>
        <p>
          PropSwap retains all rights in the content within the Website
          (including, but not limited to, applications, software, designs,
          graphics, texts, information, pictures, video, sound, music, and other
          files, and their selection and arrangement) (collectively the
          “Content”). The Content is protected by applicable copyright, trade
          dress, patent, and trademark laws, international conventions, and
          other laws protecting intellectual property and related proprietary
          rights. You may only use the Content in connection with your use of
          the Website for personal, noncommercial purposes. The Content may not
          be used by you in any other manner, or for any purpose, without our
          express written permission and/or the consent of any third party the
          Company deems necessary, except as provided for herein. Any
          unauthorized use by you of the Content may violate copyright laws,
          trademark laws, the laws of privacy and publicity, and other
          applicable regulations and statutes.
        </p>
        <p>&nbsp;</p>
        <p>
          Further, by accessing or using the Website, you acknowledge and agree
          that any name, logo, trademark, brand, or service mark (“Trademarks”)
          used with the Website is owned or licensed by PropSwap and may not be
          used by you without our prior written approval. Nothing contained in
          the Website should be construed as granting by implication, estoppel,
          or otherwise, any license or right to use any such Trademarks without
          our written consent and/or consent of such third party that owns the
          Trademarks.
        </p>
        <p>&nbsp;</p>
        <p>
          Any communications or materials you transmit to PropSwap by electronic
          mail or otherwise, including any data, questions, comments,
          suggestions, or the like, are and will be treated as, non-confidential
          and non-proprietary. Anything you transmit or post may be used by the
          Company or its affiliates for any purpose, including, but not limited
          to, reproduction, disclosure, transmission, publication, broadcast and
          posting. Furthermore, PropSwap is free to use any ideas, concepts,
          know-how or techniques contained in any communication you send to
          PropSwap for any purpose whatsoever including, but not limited to,
          developing, manufacturing and marketing products using such
          information, without any compensation to you.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Termination</u>
          </strong>
          <br /> You agree that the Company, in its sole discretion, may
          terminate your access to or use of the Website, at any time and for
          any reason, including without limitation if PropSwap believes that you
          have violated or acted inconsistently with the letter or intent of
          these Terms of Use. Upon any such termination, your right to use some
          portions, if not all, of the Website will immediately cease. You agree
          that any termination of your access to or use of the Website may occur
          without prior notice, and that the Company may immediately deactivate
          or delete your User Account and all related information and files
          associated with it, and/or bar any further access to such information
          or files. You agree that the Company will not be liable to you or any
          third party for any reason arising from or related to any termination
          of your access to the Website or to any such information or files.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Limitation of Liability</u>
          </strong>
        </p>
        <p>
          BY ACCESSING OR USING THE WEBSITE YOU ACKNOWLEDGE AND AGREE THAT YOUR
          USE IS AT YOUR OWN RISK AND THAT NONE OF THE PARTIES INVOLVED IN
          CREATING, PRODUCING, OR DELIVERING THE APP OR PROPSWAP, ITS
          AFFILIATES, SUBSIDIARIES OR ANY OF THEIR EMPLOYEES, AGENTS OR
          CONTRACTORS (COLLECTIVELY “RELEASED PARTIES”) ARE LIABLE FOR ANY
          DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE
          DAMAGES, OR ANY OTHER LOSSES, COSTS, OR EXPENSES OF ANY KIND
          (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOSS OF DATA, LEGAL
          FEES, EXPERT FEES, COST OF PROCURING SUBSTITUTE SERVICES, LOST
          OPPORTUNITY, OR OTHER DISBURSEMENTS) WHICH MAY ARISE, DIRECTLY OR
          INDIRECTLY, THROUGH THE ACCESS TO, USE OF, RELIANCE ON ANY MATERIAL OR
          CONTENT ON THE WEBSITE, OR BROWSING OF THE WEBSITE OR THROUGH YOUR
          DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO OR AUDIO FROM
          THE WEBSITE, EVEN IF PROPSWAP HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. WITHOUT LIMITING THE FOREGOING, RELEASED PARTIES ASSUME
          NO RESPONSIBILITY, AND WILL NOT BE LIABLE, FOR ANY DAMAGES RELATING TO
          OR CAUSED BY ANY VIRUSES, BUGS, HUMAN ACTION OR INACTION OF ANY
          COMPUTER SYSTEM, TELEPHONY, WIRE OR WIRELESS COMMUNICATION TECHNOLOGY,
          HARDWARE, SOFTWARE OR PROGRAM MALFUNCTIONS, OR ANY OTHER ERRORS,
          FAILURES OR DELAYS IN COMPUTER TRANSMISSIONS OR NETWORK CONNECTIONS ON
          ACCOUNT OF YOUR ACCESS TO OR USE OF THE WEBSITE. RELEASED PARTIES
          CANNOT AND DO NOT GUARANTEE CONTINUOUS, UNINTERRUPTED, OR SECURE
          ACCESS TO THE WEBSITE.
        </p>
        <p>&nbsp;</p>
        <p>
          PropSwap is not liable for any potential winnings or other lost
          opportunity from any unfinished Ticket purchase interrupted by a
          malfunction or disruption of the WEBSITE.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Release of Liability</u>
          </strong>
        </p>
        <p>
          To the fullest extent permitted by law, you agree to release,
          discharge, defend, indemnify and hold Released Parties harmless from
          and against all claims, damages, losses, liability, costs and expenses
          (including without limitation attorneys fees) arising out of (i) your
          use of, access to, or activities in connection with the App; or, (ii)
          any violation of these Terms of Use by you or through your User
          Account. This defense and indemnification obligation will survive
          these Terms of Use and your use of the Website.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Disclaimer of Warranties</u>
          </strong>
        </p>
        <p>
          THE WEBSITE, IN WHOLE AND IN PART (INCLUDING, WITHOUT LIMITATION, ALL
          CONTENT, AND USER MATERIALS), ARE PROVIDED, TRANSMITTED, DISTRIBUTED,
          AND MADE AVAILABLE “AS IS” WITHOUT EXPRESS OR IMPLIED WARRANTIES OF
          ANY KIND, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE, IMPLIED
          WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR
          NON-INFRINGEMENT. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
          THE COMPANY MAKES NO WARRANTY: (I) THAT THE WEBSITE WILL BE
          UNINTERRUPTED OR ERROR FREE; (II) THAT DEFECTS OR ERRORS IN THE
          WEBSITE WILL BE CORRECTED; (III) THAT THE WEBSITE WILL BE FREE FROM
          VIRUSES OR OTHER HARMFUL COMPONENTS; (IV) AS TO THE QUALITY, ACCURACY,
          COMPLETENESS AND VALIDITY OF ANY INFORMATION OR MATERIALS IN
          CONNECTION WITH THE WEBSITE; (V) THAT YOUR USE OF THE WEBSITE WILL
          MEET YOUR REQUIREMENTS; OR, (VI) THAT TRANSMISSIONS OR DATA WILL BE
          SECURE.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Exceptions</u>
          </strong>
          <br /> SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER, EXCLUSION OR
          LIMITATION OF CERTAIN WARRANTIES, LIABILITIES AND DAMAGES, SO SOME OF
          THE ABOVE DISCLAIMERS, EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO
          YOU. IN SUCH JURISDICTIONS, OUR LIABILITY WILL BE LIMITED TO THE
          FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Hacking or Tampering With the Website</u>
          </strong>
        </p>
        <p>
          By accessing or using the Website, you agree that PropSwap is not
          responsible for any damage, loss, or injury resulting from hacking,
          tampering, or other unauthorized access or use of the Website. Any
          attempt to gain unauthorized access to the Website, interfere with
          procedures or performance of the Website, or deliberately damage or
          undermine the Website is subject to civil and/or criminal prosecution
          and will result in immediate termination of your User Account. Any
          attempt to access or use any portion of the Website by means of
          automatic, macro, programmed, or similar methods, or to otherwise
          commit fraud with regard to the Website, will result in civil and/or
          criminal prosecution and termination of your User Account.
        </p>
        <p>Without limiting the generality of the foregoing, you must not:</p>
        <p>&nbsp;</p>
        <ol>
          <li>
            Copy, redistribute, publish, reverse engineer, decompile,
            disassemble, modify, translate or make any attempt to access the
            source code to create derivative works of the source code, or
            otherwise;
          </li>
          <li>
            Sell, assign, sublicense, transfer, distribute, lease or grant a
            security interest in the Website;
          </li>
          <li>
            Make the Website available to any third party through a computer
            network or otherwise;
          </li>
          <li>
            Export the Website to any country (whether by physical or electronic
            means);
          </li>
          <li>
            Collect, compile, aggregate, display, market, sell or otherwise
            distribute data or statistics obtained from the Website;
          </li>
          <li>
            Use cheats, automation software (bots), hacks, or any other
            unauthorized third-party software designed to modify the Website or
            collect data from the Website, including without limitation,
            modifications or collections of data that violate these Terms of
            Use;
          </li>
          <li>
            Use the Website in a manner prohibited by applicable laws,
            regulations and/or these Terms of Use.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Disputes with Others</u>
          </strong>
        </p>
        <p>
          PropSwap reserves the right, but has no obligation, to monitor and/or
          manage disputes between you and other users of the Website. If you
          have a dispute with other users, you release and hereby agree to
          indemnify PropSwap from claims, demands, and damages (actual and
          consequential) of every kind and nature, known and unknown, accrued or
          uncured, arising out of or in any way connected with such dispute.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Legality</u>
          </strong>
          <br /> You are subject to all laws of the jurisdiction in which you
          reside and from which you access the Website and are solely
          responsible for obeying those laws. You agree PropSwap cannot be held
          liable if laws applicable to you restrict or prohibit your use of the
          Website. Except for use of the Website within the territorial
          jurisdiction of Nevada PropSwap makes no representations or
          warranties, implicit or explicit, as to your legal right to access or
          participate in the Website nor shall any person affiliated, or
          claiming affiliation, with the Company have authority to make any such
          representations or warranties. PropSwap reserves the right to monitor
          the location from which you access the Website and to block access
          from any jurisdiction in which participation is illegal or restricted.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Updates to the Website</u>
          </strong>
        </p>
        <p>
          The Company reserves the right, at any time and from time to time,
          temporarily or permanently, with or without notice, in whole or in
          part, to: modify or discontinue the Website; modify or remove any of
          the information contained in the Website; limit the Website’s
          availability to any person, geographic area, or jurisdiction; charge
          fees in connection with the use of the Website; modify and/or waive
          any fees charged in connection with the Website; and/or offer
          opportunities to some or all users of the Website. You agree that
          PropSwap will not be liable to you or to any third party for any
          modification, suspension or discontinuance of the Website, in whole or
          in part, or of any Website content. Your continued use of the Website
          after such changes will indicate your acceptance of such changes.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Governing Law</u>
          </strong>
        </p>
        <p>
          These Terms of Use are governed by and construed in accordance with
          the laws of the State of Nevada without regard to its principles of
          conflicts of law. Any dispute, controversy or claim arising out of,
          relating to or in connection with these Terms of Use and/or the
          Website shall be finally resolved by arbitration. The tribunal shall
          have the power to rule on any challenge to its own jurisdiction or to
          the validity or enforceability of any portion of the Terms of Use to
          arbitrate. The parties agree to arbitrate solely on an individual
          basis, and that these Terms of Use do not permit class arbitration or
          any claims brought as a plaintiff or class member in any class or
          representative arbitration proceeding. The arbitral tribunal may not
          consolidate more than one person’s claims, and may not otherwise
          preside over any form of a representative or class proceeding.
          Arbitration shall be conducted in Clark County, Nevada administered by
          the American Arbitration Association in accordance with its Commercial
          Arbitration Rules and judgment on the award rendered by the
          arbitrator(s) may be entered in any state or federal court in Clark
          County, Nevada having jurisdiction thereof. You and PropSwap agree
          that any action, regardless of form, arising out of or related to the
          Website and/or these Terms of Use must commence within one year after
          the cause of action accrues, otherwise such action is permanently
          barred. Each party shall bear its own costs in the arbitration
          proceeding. In the event the prohibition on class arbitration is
          deemed invalid or unenforceable, then the entire agreement to
          arbitrate will be null and void.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Entire Agreement</u>
          </strong>
        </p>
        <p>
          These Terms of Use, in connection with the Privacy Policy, constitute
          the entire agreement between you and PropSwap and cannot be modified
          by you. These Terms of Use cannot be modified on an individual basis
          by any person affiliated, or claiming affiliation, with PropSwap.
          Nothing in this subsection will prevent the Company from modifying
          these Terms of Use.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Severability</u>
          </strong>
          <br /> If any portion of these Terms of Use is deemed void or
          unenforceable, then that provision shall be deemed severable from
          these Terms of Use and shall not affect the validity and
          enforceability of the remaining provisions.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Waiver</u>
          </strong>
        </p>
        <p>
          Any waiver of a breach or default hereunder shall not be deemed a
          waiver of a subsequent breach or default of either the same provision
          or any other provision of these Terms of Use.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Headings</u>
          </strong>
          <u>
            <br />{" "}
          </u>
          The headings titles in these Terms of Use are provided solely for
          convenience and have no legal or contractual significance.
        </p>
        <p>
          <strong>
            <u>Force Majeure</u>
          </strong>
        </p>
        <p>
          You agree that PropSwap will not be liable in any amount for failure
          to perform any obligation under these Terms of Use if such failure is
          caused by the occurrence of any unforeseen contingency beyond its
          reasonable control, including, without limitation, Internet outages,
          communications outages, fire, flood, or war.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>How To Contact PropSwap</u>
          </strong>
        </p>
        <p>
          If you have any questions about these Terms of Use, please contact the
          Company at:
        </p>
        <p>&nbsp;</p>
        <p>PropSwap LLC</p>
        <p>P.O. Box 231641</p>
        <p>Las Vegas, NV 89105</p>
        <p>Email:&nbsp;info@propswap.com</p>
        <p>&nbsp;</p>
        <p>Last updated September 2016</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>PropSwap LLC Mobile Device Application</strong>
        </p>
        <p>
          <strong>Privacy Policy</strong>
        </p>
        <p>
          <strong>
            <em>&nbsp;</em>
          </strong>
        </p>
        <p>
          PropSwap LLC (“PropSwap” or the “Company”) values you as a customer
          and takes your personal privacy seriously. By registering with, using
          or otherwise accessing PropSwap’s Website (the “Website”), the Company
          may collect from you certain information that may be considered
          private or confidential. This Privacy Policy explains PropSwap’s
          practices and policies for collecting, using and sharing information
          collected from or about you as a result of your use of the Website.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Acceptance of Terms</u>
          </strong>
        </p>
        <p>
          PLEASE READ THE FOLLOWING PRIVACY POLICY AND THE COMPANY’S TERMS OF
          USE CAREFULLY BEFORE USING THE WEBSITE.
        </p>
        <p>&nbsp;</p>
        <p>
          By installing, registering with, using or otherwise accessing the
          Website, you agree that you read, understood, accept and agree to be
          bound by this Privacy Policy and the Company’s Terms of Use for the
          Website. If you do not accept this Privacy Policy and/or the Terms of
          Use you are not authorized to use the Website. The Company may modify
          this Privacy Policy and the Terms of Use at any time and such
          modification will be effective upon the date the Privacy Policy and
          Terms of Use and are posted to propswap.com/terms. You are responsible
          for reviewing the Privacy Policy and the Terms of Use for
          modifications prior to accessing or using the Website. By accessing or
          using the Website, you acknowledge and agree that you shall be bound
          by any such revisions. If you do not wish to be bound by the modified
          Privacy Policy and/or Terms of Use, you must cease use of the Website.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Types of Information PropSwap Collects</u>
          </strong>
        </p>
        <p>
          PropSwap collects and uses information as necessary to administer the
          Website and provide you with the products and services you request.
          The Company may collect and maintain Passive Information and Personal
          Information needed for these purposes.
        </p>
        <p>
          <strong>
            <em>&nbsp;</em>
          </strong>
        </p>
        <p>
          <strong>
            <em>Passive Information</em>
          </strong>
        </p>
        <p>
          “Passive Information” refers to information that is automatically
          collected from your device(s). The Company may collect passive
          information from you during your use of the Website, including your IP
          address, referral data, browser type, browser language, platform type,
          and type of mobile or other computing device. Your browser or device
          typically sends passive information to our servers without your
          intervention whenever you utilize the Website. In addition, PropSwap
          utilizes CoreLocation technology to determine your physical location
          before you purchase any Ticket through the Website.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <em>Personal Information</em>
          </strong>
        </p>
        <p>
          “Personal Information,” which is sometimes referred to as personally
          identifiable information, means information that is specific to you
          and can be used to contact or identify you. To use the Website, you
          must register with the Company and create an account (a “User
          Account”). To establish a User Account, you must provide PropSwap with
          the following Person Information: (i) your first and last name; (ii)
          your email address; (iii) your state of residency; (iv) your date of
          birth; and, (v) photographic copies of your government-issued photo
          identification and credit card.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>How PropSwap Uses Your Information </u>
          </strong>
        </p>
        <p>
          <strong>
            <em>Use of Passive Information</em>
          </strong>
        </p>
        <p>
          PropSwap collects passive information for purposes of tracking and
          analyzing user preferences and trends in order to improve the quality
          and design of the Website and to create new features, promotions, and
          functionalities; for editorial and feedback purposes; for marketing
          and promotional purposes; for content improvement; and to customize
          the Website. This information may also be shared with advertisers and
          our business partners on an aggregated anonymous basis.
        </p>
        <p>
          <strong>
            <em>&nbsp;</em>
          </strong>
        </p>
        <p>
          <strong>
            <em>Use of Personal Information</em>
          </strong>
        </p>
        <p>PropSwap will use Personal Information in the following ways:</p>
        <p>&nbsp;</p>
        <ol>
          <li>To facilitate your use of the Website;</li>
          <li>To process and respond to your inquiries;</li>
          <li>
            To improve the App, including diagnosis or problems with the
            Website;
          </li>
          <li>
            To help the Company better understand demographic characteristics of
            Website users;
          </li>
          <li>
            To offer you other opportunities that may be of interest to you.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          In addition, the Company may use Personal Information to communicate
          with you through email regarding new products, new features, product
          updates and enhancements or special offers or promotions for the
          Website. These email communications may also include offers from The
          Company’s marketing partners, although such partners will not be
          provided with your Personal Information.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Information Disclosure</u>
          </strong>
        </p>
        <p>
          As permitted by law, the Company may share the information collected
          from and about you with non-affiliated third parties. PropSwap works
          with companies that help facilitate the Website and the Company’s
          services. These companies provide services such as processing credit
          card payments and sending emails on the Company’s behalf. In some
          cases, these companies have access to those portions of your Personal
          Information that are necessary in order to provide services to you on
          the Company’s behalf. They are not permitted to use your information
          for their own purposes.
        </p>
        <p>&nbsp;</p>
        <p>
          PropSwap will not disclose your Personal Information to third parties
          except in the following circumstances:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            The Company may disclose Personal Information in response to legal
            process; for example, in response to a court order or a subpoena;
          </li>
          <li>
            To comply with legal, regulatory or administrative requirements of
            any governmental authorities;
          </li>
          <li>
            The Company may disclose such information in response to a law
            enforcement agency’s request, or where we believe it is necessary to
            investigate, prevent, or take action regarding illegal activities,
            suspected fraud, situations involving potential threats to the
            physical safety of any person, violations of our terms of use, or as
            otherwise required by law;
          </li>
          <li>
            The Company may transfer information about you if PropSwap is
            acquired by or merged with another company or in the unlikely event
            that the Company is dissolved;
          </li>
          <li>
            If PropSwap needs to share your information to provide the product
            or service you have requested;
          </li>
          <li>
            If PropSwap needs to send the information to companies who work on
            behalf of the Company to provide a product or service to you in
            conjunction with your use of the Website (unless otherwise
            specified, these companies do not have any right to use the Personal
            Information provided to them beyond what is necessary to assist the
            Company); or
          </li>
          <li>
            PropSwap may provide personal information to third parties for
            analytics purposes. Such Personal Information may not be used by the
            third party for any purpose other than analysis.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>PropSwap Emails</u>
          </strong>
        </p>
        <p>
          Emails sent to you by the Company may include a web beacon that tells
          PropSwap whether and at what time you have received or opened the
          email, or clicked a link within the email. If you do not want the
          Company to collect this information from our marketing emails, you can
          opt out of receiving PropSwap marketing emails.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>PropSwap Online Advertising</u>
          </strong>
        </p>
        <p>
          The Company utilizes online advertising, including displaying PropSwap
          advertisements to you across the Internet on websites and in
          applications. PropSwap collects information about which advertisements
          are displayed, which advertisements are clicked on, and on which web
          page the advertisement was displayed.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>California Do Not Track Disclosures</u>
          </strong>
        </p>
        <p>
          The California Online Privacy Protection Act also known as CalOPPA
          requires that online privacy notices disclose how a site responds to
          “Do Not Track” signals (“DNT”). There is no consensus yet on how the
          companies you encounter should interpret DNT signals. As a result,
          most sites, including PropSwap’s site, do not respond when they
          receive a DNT signal. This Privacy Policy explains other ways in which
          you can protect your privacy.
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>How to Access, Update, and Correct Personal Information</u>
          </strong>
        </p>
        <p>
          In order to ensure that all of your Personal Information is correct
          and up to date, login to your User Account through the Website. Under
          the “Account” icon, click on the “Profile” button, where you can
          access and edit your Personal Information.
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>How You Can Manage Your Information</u>
          </strong>
        </p>
        <p>
          If you are interested in more information about tailored advertising
          and your choices to prevent third parties from delivering tailored web
          and mobile web advertising, you may visit the following websites:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>Network Advertising Initiative Consumer Opt-Out Page;&nbsp;</li>
          <li>
            Digital Advertising Alliance Opt-Out Page for U.S.-based
            advertising;
          </li>
          <li>Your Online Choices UK website for EU-based advertising</li>
        </ul>
        <p>&nbsp;</p>
        <p>
          These opt-out tools are provided by third parties, not PropSwap and
          may not be available with respect to all advertising that is presented
          to you. The Company does not control or operate these tools or the
          choices that advertisers and others provide through these tools.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Opt Out Page</u>
          </strong>
        </p>
        <p>
          If you wish to change how PropSwap uses the Personal Information that
          you provide, you may contact the Company at&nbsp;info@propswap.com. In
          the event you want to opt-out of PropSwap emails, you may do so at any
          time by clicking on the unsubscribe links located within each PropSwap
          email. Please note that opting out may affect your ability to receive
          information from PropSwap or to have access to all of the services
          provided in the App.
        </p>
        <p>&nbsp;</p>
        <p>
          You may also request that your PropSwap User Account be disabled by
          emailing&nbsp;info@propswap.com.
        </p>
        <p>&nbsp;</p>
        <p>
          You may “opt out” of the use of your Personal Information for third
          party analytics purposes by emailing (using the same email address as
          when you registered) your request to&nbsp;info@propswap.com.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>Security</u>
          </strong>
          <br /> The Company will only collect and use Passive Information and
          Personal Information in accordance with this Privacy Policy to the
          extent deemed reasonably necessary to serve PropSwap’s legitimate
          business purposes, and the Company will maintain appropriate
          administrative, technical, and physical security safeguards to ensure
          the security, integrity, accuracy and privacy of the information you
          have provided. Please be aware, however, that no information system is
          totally secure, and therefore PropSwap cannot guarantee against all
          potential security breaches. Thus, while PropSwap will use
          commercially reasonable security in accordance with industry standards
          and monitoring controls in accordance with general industry standards
          to secure information you provide to the Company consistent with legal
          requirements, PropSwap cannot guarantee that such information will not
          be unlawfully or illegitimately obtained by unauthorized parties.
        </p>
        <p>&nbsp;</p>
        <p>
          Please be aware that if you are accessing or attempting to use the App
          or otherwise communicating with PropSwap, your Personal Information
          may be transferred, stored or processed across national borders
          because our servers and the companies the Company has selected to help
          run the App are located in different countries around the world, where
          the data protection laws might not be as comprehensive as those in
          your country.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Third Party Web Sites</u>
          </strong>
        </p>
        <p>
          The Website may contain links to other websites, including those of
          advertisers. PropSwap is not responsible for the security measures,
          privacy practices, or content of third-party web sites. Once you leave
          the Website using any of these links, the privacy practices described
          in this Privacy Policy do not apply and the Company recommends that
          you examine the privacy notices that govern the other sites.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Age Restrictions</u>
          </strong>
        </p>
        <p>
          You must be age 18 or older to access or utilize the Website. As such,
          PropSwap does not knowingly solicit information from, or market to,
          any person under the age of 18. In particular, consistent with the
          Children’s Online Privacy Protection Act, PropSwap will not knowingly
          collect any information from children under the age of 13. If you
          become aware that a member of your family or any person under your
          responsibility under the age of 18 has provided the Company with
          information without your consent, please contact PropSwap using the
          contact information as indicated in this Privacy Policy in order to
          delete that information as quickly as possible.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>PropSwap’s Right to Contact You</u>
          </strong>
        </p>
        <p>
          The Company reserves the right to contact users regarding this Privacy
          Policy, or any other policies or agreements relevant to users.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>How To Contact PropSwap</u>
          </strong>
        </p>
        <p>
          If you have any concerns about this Privacy Policy or PropSwap’s
          privacy practices, please contact the Company at:
        </p>
        <p>&nbsp;</p>
        <p>PropSwap LLC</p>
        <p>7582 Las Vegas Blvd. S.</p>
        <p>Suite # 347</p>
        <p>Las Vegas, NV 89123</p>
        <p>Email:&nbsp;info@propswap.com</p>
        <p>&nbsp;</p>
        <p>Last updated April 2019</p>
      </ContentCell>
    </PageContainer>
  );
};

export default BuyerTerms;
