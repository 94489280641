import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";

import PhotoUpload from "./PhotoUpload";

const countCharacters = characters =>
  !characters ? 0 : characters.replace(/\D/g, "").length;

const PhoneFormatCustom = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={rawValue =>
        !countCharacters(rawValue)
          ? []
          : // ) : countCharacters(rawValue) <= 11 ? (
            //   ['+', /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
            // ) : countCharacters(rawValue) === 12 ? (
            //   ['+', /\d/, /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
            // ['+', /\d/, /\d/, /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
            [
              "+",
              "1",
              " ",
              "(",
              /[1-9]/,
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]
      }
      placeholderChar={"\u2000"}
      placeholder="+1 (555) 555-5555"
      showMask
      guide
    />
  );
};

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    fixedDecimalScale
    decimalScale={2}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    thousandSeparator
    prefix="$"
  />
);

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    minWidth: "332px",
  },
  buttonsContainer: {
    marginTop: theme.spacing(4),
  },
}));

const Form = ({
  data = {},
  onChange = data => null,
  onSubmit = data => null,
  buttonLabel = "Submit",
  fields = {},
  submitting = false,
  invalid = false,
}) => {
  const classes = useStyles();
  const [isValid, setIsValid] = useState(false);
  const [dirties, setDirties] = useState([]);

  const handleChange = (field, { target: { value, checked } }) => [
    onChange(field, value),
    setDirties([...dirties, field]),
  ];

  useEffect(() => {
    setIsValid(
      Object.entries(fields).every(([key, value]) =>
        !!value.customValidator
          ? !!value.customValidator(data[key])
          : !!value.required
          ? !!data[key] && value.regex.test(data[key])
          : !data[key]
          ? true
          : value.regex.test(data[key])
      )
    );
  }, [Object.values(data).join(""), Object.values(fields).length]);

  return (
    <form className={classes.container} noValidate autoComplete="off">
      {Object.entries(fields).map(([key, value], i) => (
        <React.Fragment key={i}>
          {value.type === "media" ? (
            <FormControl key={i} fullWidth>
              <PhotoUpload
                onUpload={photoUrl =>
                  handleChange(key, { target: { value: photoUrl } })
                }
                photoUrl={
                  !!value.value ? value.value(data[key] || "") : data[key] || ""
                }
              />
              <FormHelperText component={"div"}>
                {dirties.includes(key) && !fields[key].regex.test(data[key])
                  ? value.errorMessage || ""
                  : value.helperText || ""}
              </FormHelperText>
            </FormControl>
          ) : value.type === "checkbox" ? (
            <FormControl variant="outlined" key={i} fullWidth>
              <InputLabel variant="outlined" htmlFor="my-input">
                {value.label}
              </InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !!value.value
                        ? !!value.value(data[key] || "")
                        : !!(data[key] === null || data[key] === undefined
                            ? ""
                            : data[key])
                    }
                    onChange={({ target: { checked, value } }) =>
                      handleChange(key, {
                        target: { value: checked ? value : null },
                      })
                    }
                    value={new Date().toISOString()}
                  />
                }
                label={
                  <Typography variant={`caption`}>
                    {value.checkboxLabel}
                  </Typography>
                }
              />
              <FormHelperText component={"div"}>
                {dirties.includes(key) && !fields[key].regex.test(data[key])
                  ? value.errorMessage || ""
                  : value.helperText || ""}
              </FormHelperText>
            </FormControl>
          ) : (
            <TextField
              variant="outlined"
              autoFocus={!!value.autoFocus}
              multiline={!!fields[key].rows}
              rows={fields[key].rows}
              key={i}
              error={
                !dirties.includes(key)
                  ? false
                  : !!value.customValidator
                  ? !value.customValidator(data[key])
                  : !fields[key].regex.test(data[key])
              }
              required={value.required}
              disabled={value.disabled}
              helperText={
                (
                  !dirties.includes(key)
                    ? false
                    : !!value.customValidator
                    ? !value.customValidator(data[key])
                    : !fields[key].regex.test(data[key])
                )
                  ? value.errorMessage || ""
                  : value.helperText || ""
              }
              onChange={handleChange.bind(null, key)}
              label={value.label}
              value={
                !!value.value
                  ? value.value(data[key] || "")
                  : data[key] === null || data[key] === undefined
                  ? ""
                  : data[key]
              }
              placeholder={value.placeholder || ""}
              type={value.type === "money" ? "text" : value.type || "text"}
              margin="normal"
              fullWidth
              inputMode={
                value.type === "money" ? "decimal" : value.inputMode ?? "text"
              }
              select={!!value.options}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={
                value.type === "money"
                  ? {
                      inputComponent: NumberFormatCustom,
                      inputMode: "decimal",
                    }
                  : value.type === "phone"
                  ? {
                      inputComponent: PhoneFormatCustom,
                    }
                  : {}
              }
            >
              {(value.options || []).map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          {!!value.extra && value.extra}
        </React.Fragment>
      ))}
      <Button
        disabled={!isValid || !!invalid || !!submitting}
        onClick={onSubmit}
        variant="contained"
        color="secondary"
      >
        {buttonLabel}
      </Button>
    </form>
  );
};

export default Form;
