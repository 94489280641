import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import LayoutTabs from "../../Layout/Tabs";

const Tabs = ({ history, currentPath }) => {
  const theme = useTheme();
  const shortenLabels = useMediaQuery(theme.breakpoints.down("md"));
  const options = [
    {
      label: shortenLabels ? "Settings" : "Account Settings",
      value: "/account/settings",
    },
    {
      label: shortenLabels ? "Transactions" : "Transaction History",
      value: "/account/transactions",
    },
    { label: "Deposit", value: "/account/deposit" },
    { label: "Withdraw", value: "/account/withdraw" },
  ];

  return (
    <LayoutTabs
      options={options}
      selectedValue={currentPath}
      onChange={path => history.push(path)}
    />
  );
};

export default Tabs;
