import React, { useCallback, useState } from "react";

import { downloadCsv } from "gunner-react";
import { convertArrayToCSV } from "convert-array-to-csv";
import Tabs from "Components/Admin/Components/Tabs";
import ContentCell from "Components/Layout/ContentCell";
import PageContainer from "Components/Layout/PageContainer";
import PageHeader from "Components/Layout/PageHeader";
import { useQuery } from "@apollo/client";
import Option from "shared/api/Option";
import OptionEventOdds from "shared/api/OptionEventOdds";
import EventOptionOddsGraph from "Components/Event/Option/Odds/Graph";
import { Box, Button } from "@material-ui/core";
import moment from "moment";

export default ({
  match: { params = {} },
  history,
  location: { search = "" } = {},
}) => {
  const [doDownload, setDoDownload] = useState(false);
  const { data: { getOption: option } = {} } = useQuery(Option.queries.get, {
    variables: {
      id: params.id,
    },
  });

  const {
    data: { listOptionEventOddsByEventIdAndOptionId: { items } = {} } = {},
  } = useQuery(OptionEventOdds.queries.listByEventIdAndOptionId, {
    variables: {
      limit: 365,
      eventId: params.eventId,
      optionIdCreatedAt: {
        beginsWith: {
          optionId: params.id,
        },
      },
    },
  });

  const download = useCallback(
    () =>
      Promise.all([
        setDoDownload(true),
        Promise.resolve(
          downloadCsv(
            convertArrayToCSV(
              items.map(
                item => ({
                  date: moment(item.updatedAt).format("MM/DD/YYYY"),
                  odds: item.oddsCalculated,
                }),
                {
                  header: ["Date", "Odds"],
                  separator: ",",
                }
              )
            )
          )
        ),
      ]).then(() => setDoDownload(false)),
    [items]
  );

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/sports"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Box display="flex" flex={1} justifyContent="space-between">
            <PageHeader alt>{option?.name}</PageHeader>
            <Button
              disabled={doDownload}
              variant="contained"
              color="secondary"
              onClick={download}
            >
              Download
            </Button>
          </Box>
          <EventOptionOddsGraph optionEventOdds={items ?? []} />
        </ContentCell>
      </PageContainer>
    </>
  );
};
