import React from "react";

import LayoutTabs from "../../Layout/Tabs";

const Tabs = ({ history, currentPath }) => {
  const options = [
    { label: "FAQ", value: "/pages/faq" },
    { label: "About", value: "/pages/about" },
    // {label: 'Careers', value: '/pages/careers'},
    // {label: 'Responsible Gaming', value: '/pages/responsible-gaming'}
  ];

  return (
    <LayoutTabs
      options={options}
      selectedValue={currentPath}
      onChange={path => history.push(path)}
    />
  );
};

export default Tabs;
