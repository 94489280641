import React from "react";

import { TextField, Grid } from "@material-ui/core";
import NumberFormatCustom from "Components/Layout/NumberFormatCustom";
import suggestedPriceInPennies from "shared/Util/suggestedPriceInPennies";

export default ({ odds, wagerInPennies, format }) => (
  <>
    {format === "text" &&
      suggestedPriceInPennies({
        wagerInPennies,
        currentCalculatedOdds:
          (odds.currentpriceup * 1.0) / (odds.currentpricedown * 1.0),
      }) / 100.0}
    {format !== "text" && (
      <>
        <Grid item xs={6}>
          <TextField
            className="formControl"
            fullWidth
            disabled
            label={"Suggested Price"}
            value={
              suggestedPriceInPennies({
                wagerInPennies,
                currentCalculatedOdds:
                  (odds.currentpriceup * 1.0) / (odds.currentpricedown * 1.0),
              }) / 100.0
            }
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputMode: "decimal",
              type: "text",
            }}
            inputMode="decimal"
            inputProps={{ inputMode: "decimal" }}
            type="text"
            variant="outlined"
            helperText="Price is based on current odds"
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </>
    )}
  </>
);
