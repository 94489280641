import React from "react";
import Button from "@material-ui/core/Button";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  FormHelperText,
  FormControl,
  FormLabel,
  FormGroup,
  TextField,
  MenuItem,
  Grid,
  Box,
} from "@material-ui/core";
import CameraPlusIcon from "mdi-material-ui/CameraPlus";
import { Link } from "react-router-dom";

import RadioButtons from "Components/Layout/RadioButtons";
import NumberFormatCustom from "Components/Layout/NumberFormatCustom";
import PhotoUpload from "Components/PhotoUpload";
import { betTypes, commissionWaiverRedemption } from "shared/Config";
import Rating from "Components/Rating";
import EventPicker from "Components/Event/Picker";
import SuggestedPrice from "Components/Ticket/SuggestedPrice";
import { DateTimePicker } from "@material-ui/pickers";
import ListingWaiverToggle from "Components/Listing/WaiverToggle";
import TicketFormSubjectField from "../Form/SubjectField";

export default ({
  classes,

  ticket,
  listing,
  currentUser,
  locales,
  sports,
  sportsbookLocales,
  event,
  option,
  selectedLocale,
  shouldCreateListing,
  setShouldCreateListing,
  submitting,
  agreedToCcTermsAt,
  setAgreedToCcTermsAt,
  agreedToTermsAt,
  setAgreedToTermsAt,
  payout,
  ticketIsValid,
  listingIsValid,
  showPhoto,
  setShowPhoto,
  parlayLegs,
  setParlayLegs,

  parlay,
  onParlayChange,

  onSubmit,
  onListingChange,
  onEventChange,
  onTicketChange,
  onOptionChange,
}) => (
  <form className={classes.form} noValidate autoComplete="off">
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography color="textPrimary" variant={"h4"} component="h2">
          Step 1
        </Typography>
        <FormControl className="formControl" fullWidth>
          <FormLabel>Ticket Type</FormLabel>
          <FormGroup>
            <RadioButtons
              selectedValue={ticket.isMobile}
              onChange={value => onTicketChange("isMobile", value)}
              options={[
                { label: "Paper", value: false },
                {
                  label: "Mobile",
                  value: true,
                  disabled:
                    currentUser.rating < 3 &&
                    !currentUser.groups.includes("Admins"),
                },
              ]}
            />
            <FormHelperText>
              Only three-star sellers can upload MOBILE tickets.
            </FormHelperText>
            <Grid container alignItems="center">
              <Rating rating={currentUser.rating || 1} />
            </Grid>
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography color="textPrimary" variant={"h4"} component="h2">
          Step 2
        </Typography>
        <FormControl
          error={!!showPhoto && !ticket.photoUrl}
          fullWidth
          className="formControl"
        >
          <FormLabel>Upload Ticket</FormLabel>
          <FormGroup>
            <div>
              {!!showPhoto ? (
                <PhotoUpload
                  doPrompt
                  skipCrop
                  onUpload={photoUrl => onTicketChange("photoUrl", photoUrl)}
                  photoUrl={ticket.photoUrl || ""}
                />
              ) : (
                <div
                  className={"photoUploaderHolder"}
                  onClick={() => setShowPhoto(true)}
                >
                  <CameraPlusIcon color="inherit" />
                </div>
              )}
            </div>
            {!!showPhoto && !ticket.photoUrl && (
              <FormHelperText>
                You must upload the photo to submit your ticket.
              </FormHelperText>
            )}
          </FormGroup>
        </FormControl>

        <TextField
          className="formControl"
          fullWidth
          select
          label={"Where is your ticket from?"}
          onChange={({ target: { value } }) =>
            onTicketChange("ticketLocaleId", value)
          }
          value={ticket.ticketLocaleId || ""}
          SelectProps={{
            displayEmpty: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        >
          <MenuItem value="">Select</MenuItem>
          {locales
            .slice()
            .sort((a, b) => (a.state > b.state ? 1 : -1))
            .filter(
              locale => !/undefined/.test(`${locale.state} / ${locale.name}`)
            )
            .map(locale => (
              <MenuItem key={locale.id} value={locale.id}>
                {locale.state === locale.name
                  ? locale.state
                  : `${locale.state} / ${locale.name}`}
              </MenuItem>
            ))}
        </TextField>

        {!!selectedLocale &&
          !!sportsbookLocales &&
          !!sportsbookLocales
            .slice()
            .map(sl => sl.sportsbook)
            .filter(sb => !!sb.active).length && (
            <TextField
              className="formControl"
              fullWidth
              select
              label={"Select Sportsbook"}
              onChange={({ target: { value } }) =>
                onTicketChange("ticketSportsbookId", value)
              }
              value={ticket.ticketSportsbookId ?? ""}
              SelectProps={{
                displayEmpty: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            >
              <MenuItem value="">Select</MenuItem>
              {sportsbookLocales
                .slice()
                .map(sl => sl.sportsbook)
                .filter(sb => !!sb.active)
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(sportsbook => (
                  <MenuItem key={sportsbook.id} value={sportsbook.id}>
                    {sportsbook.name}
                  </MenuItem>
                ))}
            </TextField>
          )}
      </Grid>

      <Grid item xs={12}>
        <Typography color="textPrimary" variant={"h4"} component="h2">
          Step 3
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={ticket.betType === "PARLAY" ? 6 : 12}>
            <TextField
              className="formControl"
              label={"Bet Type"}
              select
              fullWidth
              onChange={({ target: { value } }) =>
                onTicketChange("betType", value)
              }
              value={ticket.betType || ""}
              SelectProps={{
                displayEmpty: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            >
              <MenuItem value="">Select</MenuItem>
              {Object.keys(betTypes).map(betType => (
                <MenuItem key={betType} value={betType}>
                  {betType}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {ticket.betType === "PARLAY" && (
            <Grid item xs={12} md={6}>
              <TextField
                className="formControl"
                label={"How Many Legs?"}
                select
                fullWidth
                onChange={({ target: { value } }) => setParlayLegs(value)}
                value={parlayLegs}
                SelectProps={{
                  displayEmpty: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              >
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="6">6</MenuItem>
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="9">9</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="11">11</MenuItem>
                <MenuItem value="12">12</MenuItem>
              </TextField>
            </Grid>
          )}
        </Grid>
        {ticket.betType !== "PARLAY" && (
          <>
            <TextField
              className="formControl"
              label={"Sport"}
              select
              fullWidth
              onChange={({ target: { value } }) =>
                onTicketChange({ ticketSportId: value, subject: null })
              }
              value={ticket.ticketSportId ?? ""}
              SelectProps={{
                displayEmpty: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            >
              <MenuItem value="">Select</MenuItem>
              {sports
                .slice()
                .sort((a, b) => (a.position > b.position ? 1 : -1))
                .filter(sport => sport.abbrev.toLowerCase() !== "other")
                .map(sport => (
                  <MenuItem
                    key={sport.id}
                    value={sport.id}
                  >{`${sport.abbrev}`}</MenuItem>
                ))}
              {sports
                .slice()
                .sort((a, b) => (a.abbrev > b.abbrev ? 1 : -1))
                .filter(sport => sport.abbrev.toLowerCase() === "other")
                .map(sport => (
                  <MenuItem
                    key={sport.id}
                    value={sport.id}
                  >{`${sport.abbrev}`}</MenuItem>
                ))}
            </TextField>

            {!!ticket.ticketSportId && (
              <EventPicker
                onEventChange={onEventChange}
                ticket={ticket}
                sportId={ticket.ticketSportId}
                onTextChange={text => onTicketChange("eventDescription", text)}
              />
            )}

            {(!!ticket.eventDescription || !!ticket.ticketEventId) && (
              <TicketFormSubjectField
                eventId={ticket.ticketEventId}
                onChange={onTicketChange}
                value={ticket.subject}
                onSetOption={onOptionChange}
                freeForm={!ticket.ticketEventId}
              />
            )}
          </>
        )}

        {ticket.betType === "PARLAY" &&
          [...Array(parseInt(parlayLegs)).keys()].map(i => (
            <Grid key={i} container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  className="formControl"
                  label={`Sport of Leg #${i + 1}`}
                  select
                  fullWidth
                  onChange={({ target: { value } }) =>
                    onParlayChange(i.toString(), "sportId", value)
                  }
                  value={parlay?.[i.toString()]?.sportId ?? ""}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                >
                  <MenuItem value="">Select</MenuItem>
                  {sports
                    .slice()
                    .sort((a, b) => (a.position > b.position ? 1 : -1))
                    .filter(sport => sport.abbrev.toLowerCase() !== "other")
                    .map(sport => (
                      <MenuItem
                        key={sport.id}
                        value={sport.id}
                      >{`${sport.abbrev}`}</MenuItem>
                    ))}
                  {sports
                    .slice()
                    .sort((a, b) => (a.abbrev > b.abbrev ? 1 : -1))
                    .filter(sport => sport.abbrev.toLowerCase() === "other")
                    .map(sport => (
                      <MenuItem
                        key={sport.id}
                        value={sport.id}
                      >{`${sport.abbrev}`}</MenuItem>
                    ))}
                </TextField>
              </Grid>
              {!!parlay?.[i.toString()]?.sportId && (
                <Grid item xs={12}>
                  <EventPicker
                    parlayLegNumber={i + 1}
                    onEventChange={event => [
                      onParlayChange(i.toString(), "eventId", event?.id),
                      !!event?.id &&
                        onParlayChange(
                          i.toString(),
                          "eventDescription",
                          event?.parlayAlias ??
                            (event?.description ?? "").replace(/to win/i, "Win")
                        ),
                    ]}
                    ticket={parlay?.[i.toString()]}
                    sportId={parlay?.[i.toString()].sportId}
                    onTextChange={text =>
                      onParlayChange(i.toString(), "eventDescription", text)
                    }
                  />
                </Grid>
              )}

              {(!!parlay?.[i.toString()]?.eventId ||
                !!parlay?.[i.toString()]?.eventDescription) && (
                <Grid item xs={12}>
                  <TicketFormSubjectField
                    eventId={parlay?.[i.toString()]?.eventId}
                    onChange={(field, value) =>
                      onParlayChange(i.toString(), field, value)
                    }
                    value={parlay?.[i.toString()]?.subject}
                    // onSetOption={option => onParlayChange(i.toString(), 'optionId', option?.id)}
                  />
                </Grid>
              )}
            </Grid>
          ))}

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              className="formControl"
              fullWidth
              label={"Bet Amount"}
              onChange={({ target: { value } }) =>
                onTicketChange("betAmount", parseInt(value * 100.0))
              }
              value={
                !!ticket.betAmount ? parseFloat(ticket.betAmount) / 100.0 : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputMode: "decimal",
                type: "text",
              }}
              inputMode="decimal"
              inputProps={{ inputMode: "decimal" }}
              type="text"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              className="formControl"
              fullWidth
              label={"Win Amount"}
              onChange={({ target: { value } }) =>
                onTicketChange("winAmount", parseInt(value * 100.0))
              }
              value={
                !!ticket.winAmount ? parseFloat(ticket.winAmount) / 100.0 : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputMode: "decimal",
                type: "text",
              }}
              inputMode="decimal"
              inputProps={{ inputMode: "decimal" }}
              type="text"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TextField
              disabled
              className="formControl"
              fullWidth
              label={"Odds"}
              value={ticket.odds || ""}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              disabled
              className="formControl"
              fullWidth
              label={"Collect Amount"}
              value={
                !!ticket.collectAmount
                  ? parseFloat(ticket.collectAmount) / 100.0
                  : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputMode: "decimal",
                type: "text",
              }}
              inputMode="decimal"
              inputProps={{ inputMode: "decimal" }}
              type="text"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormHelperText className="dualHelperText">
              Please confirm that the Odds & Collect Amount shown above match
              the ticket you are listing.
            </FormHelperText>
          </Grid>
        </Grid>
        <TextField
          multiline
          rows={5}
          className="formControl"
          fullWidth
          label={"Notes"}
          onChange={({ target: { value } }) =>
            onTicketChange("additionalInfo", value)
          }
          value={ticket.additionalInfo || ""}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography color="textPrimary" variant={"h4"} component="h2">
          Step 4
        </Typography>
        <FormControl className="formControl" fullWidth>
          <FormLabel>List for Sale</FormLabel>
          <FormGroup>
            <RadioButtons
              selectedValue={shouldCreateListing}
              onChange={setShouldCreateListing}
              options={[
                { label: "Now", value: true },
                { label: "Later", value: false },
              ]}
            />
          </FormGroup>
        </FormControl>
      </Grid>

      {(!agreedToCcTermsAt || true) && (
        <Grid item xs={12}>
          <FormControl className="formControl">
            <FormLabel component="legend">Agree to Terms</FormLabel>
            <FormHelperText>
              Once your ticket sells, you will receive a confirmation email with
              detailed instructions as to how to get PropSwap your ticket.
              PropSwap MUST receive your ticket within seven (7) calendar days.
              <br />
              <br />
              If PropSwap does not receive your ticket within 7 days, you will
              be charged a $250 inconvenience fee and your account will be
              permanently disabled from buying or selling tickets in the future.
            </FormHelperText>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!agreedToCcTermsAt}
                    onChange={({ target: { checked, value } }) =>
                      setAgreedToCcTermsAt(checked ? value : null)
                    }
                    value={new Date().toISOString()}
                  />
                }
                label={<>I have read and agree to the Credit Card Terms</>}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      )}

      {!!shouldCreateListing && (
        <Grid item xs={12}>
          <Typography color="textPrimary" variant={"h4"} component="h2">
            Step 5
          </Typography>
          {/* <FormHelperText className="topHelperText">
            Please note: Tickes are less likely to sell if the odds are worse than the <Link to="/">market</Link>.
          </FormHelperText> */}
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                className="formControl"
                fullWidth
                label={"Sale Price"}
                onChange={({ target: { value } }) =>
                  onListingChange("askingPrice", parseInt(value * 100.0))
                }
                value={
                  !!listing.askingPrice
                    ? parseFloat(listing.askingPrice) / 100.0
                    : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputMode: "decimal",
                  type: "text",
                }}
                inputMode="decimal"
                inputProps={{ inputMode: "decimal" }}
                type="text"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                className="formControl"
                fullWidth
                label={"New Odds"}
                value={listing.newOdds || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            {!!event?.bovadoGroupId &&
              !!ticket?.collectAmount &&
              event?.bovadoEventId &&
              (option?.bovadoParticipantId ||
                option?.bovadoParticipantName) && (
                <SuggestedPrice
                  wagerInPennies={ticket?.collectAmount}
                  groupId={event?.bovadoGroupId}
                  eventId={event?.bovadoEventId}
                  participantId={option?.bovadoParticipantId}
                  participantName={option?.bovadoParticipantName}
                  event={event}
                />
              )}
            <Grid item xs={6}>
              <TextField
                className="formControl"
                fullWidth
                label={"Minimum Bid"}
                onChange={({ target: { value } }) =>
                  onListingChange("minimumBidPrice", parseInt(value * 100.0))
                }
                value={
                  !!listing.minimumBidPrice
                    ? parseFloat(listing.minimumBidPrice) / 100.0
                    : 0
                }
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputMode: "decimal",
                  type: "text",
                }}
                inputMode="decimal"
                inputProps={{ inputMode: "decimal" }}
                type="text"
                variant="outlined"
                helperText="If set, all bids must exceed this amount"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="formControl"
                fullWidth
                disabled
                label={"Payout"}
                value={payout}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputMode: "decimal",
                  type: "text",
                }}
                inputMode="decimal"
                inputProps={{ inputMode: "decimal" }}
                type="text"
                variant="outlined"
                helperText={`If Sold, you will receive ${
                  !!listing.commissionWaiverApplied ? "100%" : "90%"
                } of the Sale Price.`}
              />
            </Grid>
          </Grid>
          {!!currentUser.groups.includes("Admins") &&
            currentUser.availablePoints >= commissionWaiverRedemption && (
              <Grid item xs={12}>
                <Box mb={4} mt={-2}>
                  <ListingWaiverToggle
                    onChange={value =>
                      onListingChange("commissionWaiverApplied", !!value)
                    }
                  />
                </Box>
              </Grid>
            )}
          <Grid item xs={12}>
            {!!shouldCreateListing && (
              <DateTimePicker
                clearable
                disablePast
                className="formControl"
                fullWidth={false}
                label={"Recommended: Set Listing Expiration"}
                onChange={value => onListingChange("expiresAt", value)}
                value={listing.expiresAt || null}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="dialog"
                inputVariant="outlined"
                helperText={
                  "If set, this ticket will automatically come off the marketplace at this time"
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {currentUser?.groups?.includes?.("Admins") && (
              <TextField
                multiline
                rows={5}
                className="formControl"
                fullWidth
                label={"Notes"}
                onChange={({ target: { value } }) =>
                  onListingChange("notes", value)
                }
                value={listing.notes || ""}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            )}
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControl className="formControl">
          <FormLabel component="legend">Agree to Terms</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!agreedToTermsAt}
                  onChange={({ target: { checked, value } }) =>
                    setAgreedToTermsAt(checked ? value : null)
                  }
                  value={new Date().toISOString()}
                />
              }
              label={
                <>
                  I have read and agree to the{" "}
                  <Link target="_blank" to="/pages/seller-terms">
                    Seller Terms & Conditions
                  </Link>
                </>
              }
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Button
          disabled={
            !ticketIsValid ||
            (!listingIsValid && shouldCreateListing) ||
            !!submitting
          }
          variant={"contained"}
          color={"secondary"}
          onClick={() => onSubmit(shouldCreateListing)}
        >
          {shouldCreateListing ? "Create and List" : "Create"}
        </Button>
      </Grid>
    </Grid>
  </form>
);
