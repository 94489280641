import React from "react";

import Tabs from "Components/Admin/Components/Tabs";
import ContentCell from "Components/Layout/ContentCell";
import PageContainer from "Components/Layout/PageContainer";
import PageHeader from "Components/Layout/PageHeader";
import { gql, useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";
import EventOptionsList from "Components/EventOptionsList";

const getEventForOptionsListFragment = gql`
  fragment GetEventForOptionsListFragment on Event {
    description
  }
`;


const query = gql`
  query GetEventForOptionsList($id: ID!) {
    getEvent(id: $id) {
      ...GetEventForOptionsListFragment
    }
  }
  ${getEventForOptionsListFragment}
`


export default ({
  match: { params: { eventId } = {} },
  history,
  location: { search = "" } = {},
}) => {
  
  const { data: { getEvent } = {} } = useQuery(query, {
    skip: !eventId,
    variables: {
      id: eventId
    }
  })

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/sports"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Box display="flex" flex={1} justifyContent="space-between">
            <PageHeader alt>{getEvent?.description}</PageHeader>
          </Box>
          <EventOptionsList eventId={eventId} />
        </ContentCell>
      </PageContainer>
    </>
  );
};
