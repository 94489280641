import React from "react";
import FanduelListMarketCatalogueView from "./FanduelListMarketCatalogueView";
import FanduelListMarketCatalogueStyles from "./FanduelListMarketCatalogueStyles";
import useStyles from "Hooks/useStyles";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export default ({ eventTypeId, marketType }) => {
  const { theme, styles } = useStyles({
    Styles: FanduelListMarketCatalogueStyles,
  });
  const query = gql`
    query FanDuelListMarketCatalogue(
      $eventTypeIds: [Int]
      $marketTypes: [String]
      $eventIds: [String]
      $maxResults: Int
    ) {
      fanDuelListMarketCatalogue(
        eventTypeIds: $eventTypeIds
        marketTypes: $marketTypes
        eventIds: $eventIds
        maxResults: $maxResults
      ) {
        marketId
        marketName
        marketStartTime
      }
    }
  `;

  const result = useQuery(query, {
    variables: {
      eventTypeIds: [eventTypeId],
      marketTypes: [marketType],
      maxResults: 1000,
    },
  });

  return (
    <FanduelListMarketCatalogueView
      query={query}
      theme={theme}
      styles={styles}
      items={result?.data?.fanDuelListMarketCatalogue}
      eventTypeId={eventTypeId}
      marketType={marketType}
    />
  );
};
