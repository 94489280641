import React, { useState } from "react";
import GroupEdit from "Components/Group/Edit";
import PageContainer from "Components/Layout/PageContainer";
import ContentCell from "Components/Layout/ContentCell";
import Tabs from "Components/Admin/Components/Tabs";
import OptionNew from "Components/Option/New";
import SportOptionSelect from "Components/Sport/Option/Select";
import { Typography, Box } from "@material-ui/core";
import InactiveSwitch from "Components/InactiveSwitch";

export default ({ history, match: { params } }) => {
  const [showInactive, setShowInactive] = useState(false);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/sports"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Box mb={2}>
            <GroupEdit id={params.id} />
          </Box>

          <Box mb={2}>
            <Typography variant="h4">Select Options</Typography>
            <InactiveSwitch
              showInactive={showInactive}
              onChange={checked => setShowInactive(checked)}
            />
            <SportOptionSelect
              showInactive={showInactive}
              sportId={params.sportId}
              groupId={params.id}
            />
          </Box>

          <Box mb={2}>
            <Typography variant="h4">Add a new Option</Typography>
            <OptionNew sportId={params.sportId} />
          </Box>
        </ContentCell>
      </PageContainer>
    </>
  );
};
