import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import Bluesnap from "shared/api/Bluesnap";
import User from "shared/api/User";

const useStyles = makeStyles(theme => ({}));

const AchSelect = ({
  currentUser,
  selectedBankInfo,
  onChange,
  bluesnapVaultedShopper,
}) => {
  const classes = useStyles();
  const [doDelete, setDoDelete] = useState(false);

  const [deleteAch] = useMutation(Bluesnap.mutations.deleteAch, {
    variables: {
      input: {
        vaultedShopperId: currentUser.bluesnapVaultedShopperId,
        firstName: currentUser.attributes.given_name,
        lastName: currentUser.attributes.family_name,
        zip: currentUser.attributes["custom:zip"],
        ...(doDelete || {}),
      },
    },
    refetchQueries: [
      {
        query: User.queries.get,
        variables: {
          id: currentUser.id,
          withPrivate: true,
        },
      },
      {
        query: Bluesnap.queries.getVaultedShopper,
        variables: {
          vaultedShopperId: currentUser.bluesnapVaultedShopperId || 0,
        },
      },
    ],
  });

  useEffect(() => {
    !!doDelete &&
      deleteAch()
        .then(() => console.log("OK OK") || setDoDelete(false))
        .catch(e => [
          console.log(e),
          setDoDelete(false),
          window.alert(
            !!JSON.stringify(e).match(/pending approval/)
              ? "This payment method cannot be deleted because it is pending approval. Please try again later."
              : "Something went wrong. Please try again or contact support"
          ),
        ]);
  }, [doDelete]);

  return (
    <FormControl className={classes.formControl}>
      <RadioGroup
        className={classes.group}
        value={!selectedBankInfo ? "" : JSON.stringify(selectedBankInfo)}
        onChange={({ target: { value, checked } }) => onChange(value)}
      >
        {(
          ((bluesnapVaultedShopper || {}).paymentSources || {}).ecpDetails || []
        ).map(
          (
            { ecp: { accountType, publicAccountNumber, publicRoutingNumber } },
            i
          ) => (
            <React.Fragment key={i}>
              <FormControlLabel
                value={JSON.stringify({
                  accountType: accountType,
                  accountNumber: publicAccountNumber,
                  routingNumber: publicRoutingNumber,
                })}
                control={<Radio />}
                label={`${publicAccountNumber}`}
              />
              <FormHelperText
                onClick={() =>
                  window.confirm("Delete this payment method?") &&
                  setDoDelete({
                    accountType,
                    publicAccountNumber,
                    publicRoutingNumber,
                  })
                }
              >
                Delete
              </FormHelperText>
            </React.Fragment>
          )
        )}
        {/*         
          !!selectedBankInfo &&
          <FormControlLabel value={JSON.stringify(selectedBankInfo)} control={<Radio />} label={selectedBankInfo.accountNumber} />  
         */}
        {!bluesnapVaultedShopper?.paymentSources?.ecpDetails?.length && (
          <FormControlLabel
            value={"new"}
            control={<Radio />}
            label={`Enter Bank Info`}
          />
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default AchSelect;
