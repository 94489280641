import React from "react";

import { useQuery } from "@apollo/client";
import TicketLatestSuggestedPriceView from "./TicketLatestSuggestedPriceView";
import OptionEventOdds from "shared/api/OptionEventOdds";

export default ({ ticket }) => {
  const {
    data: { listOptionEventOddsByEventIdAndOptionId: { items } = {} } = {},
  } = useQuery(OptionEventOdds.queries.listByEventIdAndOptionId, {
    variables: {
      limit: 1,
      sortDirection: "DESC",
      eventId: ticket.ticketEventId,
      optionIdCreatedAt: {
        beginsWith: {
          optionId: ticket.ticketOptionId,
        },
      },
    },
  });

  const latestOdds = items?.[0];

  return (
    <>
      {!latestOdds ? (
        "N/A"
      ) : (
        <TicketLatestSuggestedPriceView
          wagerInPennies={ticket.collectAmount}
          optionEventOdds={latestOdds}
        />
      )}
    </>
  );
};
