import React from "react";
import PageContainer from "Components/Layout/PageContainer";
import ContentCell from "Components/Layout/ContentCell";
import Tabs from "Components/Admin/Components/Tabs";
import { Grid, Button } from "@material-ui/core";
import { Link as DomLink } from "react-router-dom";
import AdEdit from "Components/Ad/Edit";

export default ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/ads"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <Grid container spacing={4} justify={"flex-end"}>
          <Grid item xs={12} md={3}>
            <Button
              component={DomLink}
              to={`/admin/ads`}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <AdEdit adId={params.id} />
      </ContentCell>
    </PageContainer>
  </>
);
