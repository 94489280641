import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import ReactGA from "react-ga";
import NumberFormat from "react-number-format";

import { CurrentUserContext } from "gunner-react";

import { withoutKeys } from "gunner-react";

import { Form as GenericForm } from "gunner-react/web";
import { nonMutationFields, adminOnlyFields } from "./index";
import Listing from "shared/api/Listing";
import formatOdds from "shared/Util/formatOdds";
import Toast from "../Toast";

const Form = ({ listing = {}, ticket }) => {
  const currentUser = useContext(CurrentUserContext);
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [fields, setFields] = useState({
    askingPrice: {
      label: "Asking Price",
      regex: /\d+/,
      required: true,
      type: "money",
      errorMessage: (
        <>
          The Sale Price must be less than the amount the Ticket collects (
          <NumberFormat
            fixedDecimalScale
            decimalScale={2}
            value={parseFloat(ticket.collectAmount) / 100.0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
          )
        </>
      ),
      customValidator: askingPrice =>
        parseInt(askingPrice * 100.0) < ticket.collectAmount,
    },
    minimumBidPrice: {
      label: "Min Bid Price",
      regex: /\d+/,
      required: false,
      type: "money",
    },
    newOdds: {
      label: "New Odds",
      // eslint-disable-next-line
      regex: /[\d \-\.]+/,
      required: true,
      type: "text",
      disabled: true,
      errorMessage: "You are required to enter the odds",
      helperText: "This is calculated win you enter the sales price",
    },
    newOddsCalculated: {
      label: "New Odds Calculated",
      // eslint-disable-next-line
      regex: /.+/,
      required: true,
      type: "text",
      disabled: true,
      errorMessage: "You are required to enter the odds",
      helperText: "This is calculated win you enter the sales price",
    },
    expiresAt: {
      label: "Expires At",
      // eslint-disable-next-line
      regex: /.+/,
      required: false,
      type: "datetime",
      disabled: false,
      value: value => value || null,
    },
  });
  const [formData, setFormData] = useState({
    ...withoutKeys(
      listing,
      currentUser.groups.includes("Admins")
        ? ["cachedTicket", "cachedBid"]
        : [...adminOnlyFields, "cachedTicket"]
    ),
    askingPrice: !!listing.askingPrice
      ? parseFloat(listing.askingPrice) / 100.0
      : null,
    minimumBidPrice: !!listing.minimumBidPrice
      ? parseFloat(listing.minimumBidPrice) / 100.0
      : null,
  });
  const [isEditable, setIsEditable] = useState(false);

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      // [field]: field === 'featuredAt' ? (!!value ? (new Date()).toISOString() : "<>") :value,
      [field]:
        field === "featuredAt"
          ? !!value
            ? new Date().toISOString()
            : null
          : field === "recentlySold" || field === "commissionWaiverApplied"
          ? !!value
            ? true
            : false
          : value,
    });

  const [_createListing] = useMutation(Listing.mutations.create, {
    variables: {
      input: {
        ...withoutKeys(formData, nonMutationFields),
        newOddsCalculated:
          parseFloat(ticket.collectAmount) / parseFloat(formData.askingPrice),
        newOdds: formatOdds(ticket.collectAmount, formData.askingPrice),
        listingSellerId: formData.listingSellerId || currentUser.id,
        listingTicketId: ticket.id,
        askingPrice: parseInt(formData.askingPrice * 100.0),
        minimumBidPrice: !!formData.minimumBidPrice
          ? parseInt(formData.minimumBidPrice * 100.0)
          : null,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      createListing: {
        ...formData,
        newOddsCalculated:
          parseFloat(ticket.collectAmount) / parseFloat(formData.askingPrice),
        newOdds: formatOdds(ticket.collectAmount, formData.askingPrice),
        listingSellerId: formData.listingSellerId || currentUser.id,
        listingTicketId: ticket.id,
        askingPrice: parseInt(formData.askingPrice * 100.0),
        minimumBidPrice: !!formData.minimumBidPrice
          ? parseInt(formData.minimumBidPrice * 100.0)
          : null,
        __typename: "Listing",
      },
    },
  });

  const [_updateListing] = useMutation(Listing.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, nonMutationFields),
        newOddsCalculated:
          (parseFloat(ticket.collectAmount) -
            parseFloat(formData.askingPrice)) /
          parseFloat(formData.askingPrice),
        listingSellerId: formData.listingSellerId || currentUser.id,
        listingTicketId: ticket.id,
        askingPrice: parseInt(formData.askingPrice * 100.0),
        minimumBidPrice: formData.minimumBidPrice
          ? parseInt(formData.minimumBidPrice * 100.0)
          : null,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateListing: {
        ...formData,
        newOddsCalculated:
          parseFloat(ticket.collectAmount) / parseFloat(formData.askingPrice),
        listingSellerId: formData.listingSellerId || currentUser.id,
        listingTicketId: ticket.id,
        askingPrice: parseInt(formData.askingPrice * 100.0),
        minimumBidPrice: formData.minimumBidPrice
          ? parseInt(formData.minimumBidPrice * 100.0)
          : null,
        __typename: "Listing",
      },
    },
  });

  useEffect(() => {
    setFormData({
      ...formData,
      newOdds: formatOdds(
        parseFloat(ticket.collectAmount / 100.0),
        formData.askingPrice
      ),
    });
  }, [formData.askingPrice, ticket.collectAmount]);

  useEffect(() => {
    setFields({
      ...fields,
      ...(!currentUser.groups.includes("Admins")
        ? {}
        : {
            status: {
              label: "Status",
              regex: /\w*/,
              type: "select",
              options: [
                { value: "ACTIVE", label: "ACTIVE" },
                { value: "INACTIVE", label: "INACTIVE" },
                { value: "NEW", label: "NEW" },
                { value: "PENDING", label: "PENDING" },
                { value: "SOLD", label: "SOLD" },
                { value: "DROPPED", label: "DROPPED" },
                { value: "ARCHIVED", label: "ARCHIVED" },
              ],
            },
            boughtFrom: {
              label: "Bought From",
              regex: /\w*/,
              type: "select",
              options: [
                { value: "WEB", label: "WEB" },
                { value: "IOS", label: "IOS" },
                { value: "ANDROID", label: "ANDROID" },
              ],
            },
            postedFrom: {
              label: "Sold From",
              regex: /\w*/,
              type: "select",
              options: [
                { value: "WEB", label: "WEB" },
                { value: "IOS", label: "IOS" },
                { value: "ANDROID", label: "ANDROID" },
              ],
            },
            listingSellerId: {
              label: "Owner Id",
              regex: /\w*/,
              type: "text",
              helperText:
                "If you're creating this for someone else, enter their userId here.",
            },
            ratingOverride: {
              label: "Rating",
              regex: /.*/,
              options: [
                { value: 1, label: 1 },
                { value: 2, label: 2 },
                { value: 3, label: 3 },
              ],
            },
            bidCount: {
              label: "Bid Count",
              regex: /\d*/,
              type: "text",
              helperText: "Adjust the bid count for this listing.",
            },
            featuredAt: {
              label: "",
              regex: /.+/,
              type: "checkbox",
              checkboxLabel: "Featured?",
              // value: value => value === "<>" ? false : value
            },
            recentlySold: {
              label: "",
              regex: /.+/,
              type: "checkbox",
              checkboxLabel: "Recently Sold?",
              // value: value => value === "<>" ? false : value
            },
            commissionWaiverApplied: {
              label: "",
              regex: /.+/,
              type: "checkbox",
              checkboxLabel: "Waiver Applied?",
              // value: value => value === "<>" ? false : value
            },
            rebateStatus: {
              label: "Rebate Status",
              regex: /.+/,
              type: "text",
              options: [
                { label: "INELIGIBLE", value: "INELIGIBLE" },
                { label: "ELIGIBLE", value: "ELIGIBLE" },
                { label: "REDEEMED", value: "REDEEMED" },
              ],
              // checkboxLabel: "Recently Sold?",
              // value: value => value === "<>" ? false : value
            },
            valueRating: {
              label: "Value Rating",
              regex: /\d*/,
              type: "text",
              // value: value => value === "<>" ? false : value
            },
          }),
    });
  }, [currentUser.groups.includes("Admins")]);

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateListing : _createListing)() //if this is an existing listing, update it, otherwise, create it
        .then(
          ({
            data: {
              [!!formData.id ? "updateListing" : "createListing"]: listing,
            },
          }) => [setDoSubmit(false), setSubmitSuccess(true)]
        )
        .then(
          () =>
            !formData.id &&
            ReactGA.event({
              category: "Listing",
              action: "Create",
            })
        )
        .catch(e => [
          console.log(e),
          window.alert(e.message),
          setDoSubmit(false),
        ]);
  }, [doSubmit]);

  useEffect(() => {
    setIsEditable(
      currentUser.groups.includes("Admins") || !listing.id // if the user is an admin, or this is a new listing, allow it
        ? true
        : ["ACTIVE", "INACTIVE"].includes(listing.status)
    );
  }, [listing.status, currentUser.groups.includes("Admins")]);

  // useEffect(() => {
  //   currentUser.groups.includes("Admins") &&
  //   setFields({
  //     ...fields,

  // }, [currentUser.groups.includes("Admins")]);

  return !isEditable ? null : (
    <>
      {!!submitSuccess && (
        <Toast
          open={!!submitSuccess}
          onClose={() => setSubmitSuccess(false)}
          message="Listing Saved!"
        />
      )}
      <GenericForm
        submitting={doSubmit}
        fields={fields}
        onChange={handleChange}
        data={formData}
        onSubmit={() => setDoSubmit(true)}
        buttonLabel={!!listing.id ? "Update Listing" : "Post Listing"}
      />
    </>
  );
};

export default Form;
