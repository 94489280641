import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Moneybag = ({ size = "medium", ...rest }) => {
  return (
    <SvgIcon size={size} {...rest}>
      <path d="M14.4,14.1a2.5,2.5,0,0,1,.1.9,1.6,1.6,0,0,1-.4,1.2,2,2,0,0,1-1.4.6v.6H12v-.6a1.7,1.7,0,0,1-1.2-.5c-.4-.2-.6-.7-.6-1.4v-.3H12v1.1c0,.1.1.2.3.2h.2a.4.4,0,0,0,.1-.3c0-.3,0-.6-.1-.7s-.2-.3-.5-.5l-1.1-.6-.5-.6a2.4,2.4,0,0,1-.2-.9,1.5,1.5,0,0,1,.4-1,2.6,2.6,0,0,1,1.4-.5v-.5h.7v.5c.6.1,1,.2,1.2.5a1.2,1.2,0,0,1,.4,1v.3H12.6v-.2c0-.3,0-.5-.1-.6H12V12c0,.3,0,.4.1.5l1,.6.9.5Zm5.5,2.2a4.3,4.3,0,0,1-3.8,4.3,46.9,46.9,0,0,1-7.5,0,4.4,4.4,0,0,1-3.8-4.3A8,8,0,0,1,7.4,9.6C8.9,8.1,8.9,7.9,8.1,6A.4.4,0,0,1,8,5.7C7.4,4,7.7,3.6,9.4,3.6h6c1.7,0,1.9.4,1.4,2.1l-.2.3c-.8,1.9-.8,2.1.7,3.6A7.7,7.7,0,0,1,19.9,16.3ZM10.1,5.6l.5,1.7h3.5l.5-1.7H10.1Zm6,5.4C14.7,9.4,14,9,12.4,9s-2.3.4-3.8,2a6.3,6.3,0,0,0-1.9,5.2c.2,1.4.7,2.2,2.1,2.5a42.1,42.1,0,0,0,7.1,0c1.4-.3,1.9-1.1,2.1-2.5A6,6,0,0,0,16.1,11Z" />
    </SvgIcon>
  );
};

export default Moneybag;
