import React, { useState } from "react";
import PageContainer from "Components/Layout/PageContainer";
import ContentCell from "Components/Layout/ContentCell";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Tabs from "Components/Admin/Components/Tabs";

import { DataTable } from "gunner-react/web";

import { Button } from "@material-ui/core";
import Group from "shared/api/Group";
import InactiveSwitch from "Components/InactiveSwitch";

const fields = {
  Name: {
    value: item => item.name,
    sort: order => (a, b) =>
      a.name > b.name ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  Active: {
    value: item => (!!item.active ? "Yes" : "No"),
    sort: order => (a, b) =>
      !!a.active > !!b.active
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/sports/${item.sportId}/groups/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
};

export default ({ history, match: { params } }) => {
  const [showInactive, setShowInactive] = useState(false);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/sports"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Grid container spacing={4} justify={"flex-end"}>
            <Grid item xs={12} md={3}>
              <InactiveSwitch
                showInactive={showInactive}
                onChange={checked => setShowInactive(checked)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                component={Link}
                to={`/admin/sports/${params.sportId}/groups/new`}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Add Group
              </Button>
            </Grid>
          </Grid>

          <DataTable
            fields={fields}
            search={null}
            dataListParams={{
              query: Group.queries.listBySportId,
              dataKey: "listGroupsBySportId",
              variables: {
                sportId: params.sportId,
                ...(!!showInactive ? {} : { filter: { active: { eq: true } } }),
                limit: 1000,
              },
            }}
          />
        </ContentCell>
      </PageContainer>
    </>
  );
};
