import React from "react";
import { useQuery } from "@apollo/client";
import BidListView from "./BidListView";
import Bid from "shared/api/Bid";
import { CircularProgress } from "@material-ui/core";

const BidListContainer = ({ listingId }) => {
  const { loading, data: { bidsByListingId: { items: bids = [] } = {} } = {} } =
    useQuery(Bid.queries.listByListingId, {
      variables: { limit: 100, bidListingId: listingId },
    });
  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <BidListView bids={bids} />
  );
};

export default BidListContainer;
