import React from "react";
import GroupEdit from "Components/Group/Edit";
import PageContainer from "Components/Layout/PageContainer";
import ContentCell from "Components/Layout/ContentCell";
import Tabs from "Components/Admin/Components/Tabs";
import { Typography, Box } from "@material-ui/core";
import ConversionTemplateEdit from "Components/ConversionTemplate/Edit";

export default ({ history, match: { params } }) => {
  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/campaigns"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Box mb={2}>
            <ConversionTemplateEdit id={params.id} />
          </Box>

          {/* <Box mb={2}>
            <Typography variant="h4">Select Locales</Typography>
            <ConversionTemplateLocaleSelect conversionTemplateId={params.id} />
          </Box> */}
        </ContentCell>
      </PageContainer>
    </>
  );
};
