import { CurrentUserContext } from "gunner-react";
import React, { useContext } from "react";

import LayoutTabs from "../../Layout/Tabs";

const AltTabs = ({ history, currentPath, ticketCount = 0, bidCount = 0 }) => {
  const currentUser = useContext(CurrentUserContext);
  const options = [
    {
      label: `Tickets ${ticketCount > 0 ? ticketCount : ""}`,
      value: "/account/tickets",
    },
    { label: `Bids ${bidCount > 0 ? bidCount : ""}`, value: "/account/bids" },
    { label: `Referrals`, value: "/account/referrals" },
  ];

  return (
    <LayoutTabs
      options={[
        ...options,
        ...(currentUser?.groups?.includes?.("Admins")
          ? [
              { label: `Watchlist`, value: "/account/watchlist" },
              {
                label: !!currentUser?.hideNotificationsCount
                  ? `Activities`
                  : `Activities (${currentUser?.notificationsCount ?? 0})`,
                value: "/account/notification-center",
              },
            ]
          : []),
      ]}
      selectedValue={currentPath}
      onChange={path => history.push(path)}
    />
  );
};

export default AltTabs;
