import React from "react";

import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

import Form from "../Form";

import Sport from "shared/api/Sport";

const SportEditContainer = ({ id }) => {
  const { loading, data: { getSport: sport } = {} } = useQuery(
    Sport.queries.get,
    { variables: { id: id } }
  );

  return !!loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Form sport={sport} />
  );
};

export default SportEditContainer;
