import React, { useState, useContext } from "react";
import queryString from "query-string";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";

import TicketList from "../../Ticket/Components/List.js";
import AdCell from "../../Layout/AdCell";
import ContentCell from "../../Layout/ContentCell";
import PageContainer from "../../Layout/PageContainer";
import Tabs from "../Components/AltTabs";
import Search from "../../Listing/Components/Search";
// import TicketBidList from '../../Ticket/Components/BidList';
import InnerTabs from "../../Layout/InnerTabs";

import { CurrentUserContext } from "gunner-react";
import Bid from "shared/api/Bid";
import TypeFilter from "../../Listing/Components/TypeFilter";

const useStyles = makeStyles(theme => ({
  searchCell: {
    marginBottom: theme.spacing(1),
  },
}));

const BID_STATUSES = [
  "PENDING",
  "ACCEPTED",
  // 'COUNTERED',
  "REJECTED",
  "CANCELLED",
];

const Bids = ({ history, location: { search = "" } = {} }) => {
  const currentUser = useContext(CurrentUserContext);
  const classes = useStyles();
  const searchParams = useState(queryString.parse(search));
  const [status, setStatus] = useState(searchParams.status || "PENDING");
  const [token, setToken] = useState(searchParams.token);
  const [searchString, setSearchString] = useState("");
  const [typeFilter, setTypeFilter] = useState("All");

  const {
    error,
    loading,
    data: { bidsByBidderId: { nextToken, items = [] } = {} } = {},
  } = useQuery(Bid.queries.listByBidderIdWithListingAndTicket, {
    variables: {
      bidBidderId: currentUser.id,
      nextToken: token,
      statusUpdatedAt: {
        beginsWith: {
          status,
        },
      },
      sortDirection: "DESC",
      limit: 100,
      bidsSortDirection: "DESC",
      bidsFilter: {
        status: {
          eq: "PENDING",
        },
      },
    },
    pollInterval: 3000, //TODO: move to websocket
  });

  const tickets = items
    .reduce(
      (tickets, bid) =>
        tickets.some(
          ticket => false && ticket.bid.bidBidderId === bid.bidBidderId
        ) //TODO: Not sure how to handle this - multiple bids on a single ticket, so hacking "false"
          ? tickets
          : [
              ...tickets,
              ...[{ ...bid.listing.ticket, listing: bid.listing, bid }],
            ],
      []
    )
    .filter(
      item =>
        !searchString ||
        JSON.stringify(item).toLowerCase().includes(searchString.toLowerCase())
    )
    .filter(
      item =>
        typeFilter === "All" ||
        (typeFilter === "Countered" && !!item.bid.counter) ||
        (typeFilter === "Outbid" &&
          !!item.listing.bids.items[0] &&
          item.bid.bidBidderId !== item.listing.bids.items[0].bidBidderId)
    );

  !!error && console.log(error);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/account/bids"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell>
          <Grid container spacing={4}>
            <Grid item xs={12} container>
              <InnerTabs
                items={BID_STATUSES.map(status => ({
                  label: status,
                  value: status,
                }))}
                selected={status}
                onChange={status => setStatus(status)}
              />
            </Grid>
          </Grid>
          <Grid className={classes.searchCell} container spacing={2}>
            {status === "PENDING" ? (
              <TypeFilter onChange={setTypeFilter} typeFilter={typeFilter} />
            ) : (
              <Grid item xs={3} md={4} />
            )}
            <Search searchString={searchString} onChange={setSearchString} />
          </Grid>

          <TicketList
            view="bids"
            status={status}
            currentUser={currentUser}
            history={history}
            tickets={tickets}
          />

          {!!loading ? (
            <CircularProgress className={classes.progress} color="secondary" />
          ) : !!nextToken && !!items.length ? (
            <Button
              fullWidth
              variant={"contained"}
              onClick={() => setToken(nextToken)}
            >
              Show More
            </Button>
          ) : null}
        </ContentCell>
        <AdCell>
          <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};

export default Bids;
