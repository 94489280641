import {
  TableRow,
  TableCell,
  Button,
  Box,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";




const BetListItemView = ({ 
  bet,
  onWinClick,
  onLossClick,
  onPushClick,
  onDeleteClick,
  onSaveClick,
  newSubject,
  newEventDescription,
  setNewSubject,
  setNewEventDescription,
  hasChanges,
  loading
}) => 
  <TableRow>
    <TableCell>
      {bet.sport.name}
    </TableCell>
    <TableCell>
      {bet?.option?.name ? bet?.option?.name : <TextField
        onChange={({target: {value}}) => setNewSubject(value)}
        fullWidth
        label={false}
        value={newSubject ?? bet.subject}
        variant={"outlined"}
        size="small"
      />
      }
    </TableCell>
    <TableCell>
      {bet?.event?.description ?? <TextField
        onChange={({target: {value}}) => setNewEventDescription(value)}
        fullWidth
        label={false}
        value={newEventDescription ?? bet.eventDescription}
        variant={"outlined"}
        size="small"
      />}
    </TableCell>
    <TableCell>
      {bet.result}
    </TableCell>
    <TableCell>
      <Button
        color="secondary"
        component={Link}
        to={`/admin/users/${bet.userId}/edit`}
        variant="text"
        size="small"
      >
        View
      </Button>
    </TableCell>
    <TableCell>
      <Button
        color="secondary"
        component={Link}
        to={`/tickets/${bet.ticketId}`}
        variant="text"
        size="small"
      >
        View
      </Button>
    </TableCell>
    <TableCell 
      style={{
        whiteSpace: "nowrap"
      }}
    >
      <Box
        sx={{
          gap: 4,
          display: "flex"
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={onWinClick}
          disabled={!!loading || bet.result === "WIN"}
        >
          W
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={onLossClick}
          disabled={!!loading || bet.result === "LOSS"}
        >
          L
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={onPushClick}
          disabled={!!loading || bet.result === "PUSH"}
        >
          P
        </Button>
      </Box>
    </TableCell>
    <TableCell 
      style={{
        whiteSpace: "nowrap"
      }}
    >
      <Box
        sx={{
          gap: 4,
          display: "flex"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onDeleteClick}
          disabled={!!loading}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onSaveClick}
          disabled={!!loading || !hasChanges}
        >
          Save
        </Button>
      </Box>
    </TableCell>
  </TableRow>

export default BetListItemView;