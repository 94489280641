import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import ListingEditScreen from "../Screens/ListingEdit";

import SignInScreen from "../Screens/SignIn";
import SignOutScreen from "../Screens/SignOut";

import DesignScreen from "../Screens/Design";

import ListingListScreen from "../Components/Listing/Screens/List";
import SearchScreen from "./Search";

import TicketDetailScreen from "../Components/Ticket/Screens/Detail";

import AccountRecommendationsScreen from "../Components/Account/Screens/Recommendations";
import AccountSettingsScreen from "../Components/Account/Screens/Settings";
import AccountTransactionsScreen from "../Components/Account/Screens/Transactions";
// import AccountListingsScreen from "../Components/Account/Screens/Tickets"
import AccountBidsScreen from "../Components/Account/Screens/Bids";
import AccountDepositScreen from "../Components/Account/Screens/Deposit";
import AccountWithdrawScreen from "../Components/Account/Screens/Withdraw";

import AdminTicketEditScreen from "../Components/Admin/Screens/TicketEdit";
import AdminTicketsScreen from "../Components/Admin/Screens/Tickets";
import AdminUsersScreen from "../Components/Admin/Screens/Users";

import AboutScreen from "../Components/Pages/Screens/About";
import BuyerTermsScreen from "../Components/Pages/Screens/BuyerTerms";
import FAQScreen from "../Components/Pages/Screens/FAQ";
import SellScreen from "../Components/Pages/Screens/Sell";
import SellerTermsScreen from "../Components/Pages/Screens/SellerTerms";
import PrivacyPolicyScreen from "../Screens/PrivacyPolicy";

// import PrivateRoute from '../Components/PrivateRoute'
// import AdminRoute from '../Components/AdminRoute'
import { GroupRoute, PrivateRoute, useTracker } from "gunner-react/web";

import LocaleEdit from "./Admin/Locale/Edit";
import LocaleList from "./Admin/Locale/List";
import LocaleNew from "./Admin/Locale/New";

import SportEdit from "./Admin/Sport/Edit";
import SportList from "./Admin/Sport/List";
import SportNew from "./Admin/Sport/New";

import GroupEdit from "./Admin/Group/Edit";
import GroupList from "./Admin/Group/List";
import GroupNew from "./Admin/Group/New";

import AdminPromotionEdit from "./Admin/Promotion/Edit";
import AdminPromotionList from "./Admin/Promotion/List";
import AdminPromotionNew from "./Admin/Promotion/New";

import AdminCampaignEdit from "./Admin/Campaign/Edit";
import AdminCampaignList from "./Admin/Campaign/List";
import AdminCampaignNew from "./Admin/Campaign/New";

import AdminEventEdit from "./Admin/Event/Edit";
import AdminEventList from "./Admin/Event/List";
import AdminEventNew from "./Admin/Event/New";

import AdminRedemptionList from "./Admin/Redemption/List";

import SettingsEdit from "../Components/Account/Screens/SettingsEdit";
import AdminListingList from "./Admin/AdminListingList";
import CognitoEdit from "./Admin/Cognito/Edit";
import UserEdit from "./Admin/User/Edit";
import AdminUserListingList from "./Admin/User/Listing/List";
import AdminUserTicketList from "./Admin/User/Ticket/List";
import TicketList from "./TicketList";

import Auth from "Components/Auth";
import Layout from "Components/Layout";
import { LayoutProvider } from "gunner-react/web";
import AdminEventOddsList from "./AdminEventOddsList";
import AdminEventOptionOddsList from "./AdminEventOptionOddsList";
import AdminEventOptionsList from "./AdminEventOptionsList";
import AdminRebateList from "./AdminRebateList";
import AdminSportsbookEdit from "./AdminSportsbookEdit";
import AdminSportsbookList from "./AdminSportsbookList";
import AdminSportsbookNew from "./AdminSportsbookNew";
import OddsList from "./OddsList";
import TransactionLocaleReport from "./TransactionLocaleReport";
import Watchlist from "./Watchlist";

import Activities from "./Activities";
import AdminConversionTemplateEdit from "./AdminConversionTemplateEdit";
import AdminConversionTemplateList from "./AdminConversionTemplateList";
import AdminConversionTemplateNew from "./AdminConversionTemplateNew";
import AdminLinkEdit from "./AdminLinkEdit";
import AdminLinkList from "./AdminLinkList";
import AdminLinkNew from "./AdminLinkNew";
import AdminNotificationsNew from "./AdminNotificationsNew";
import AdminRedirectEdit from "./AdminRedirectEdit";
import AdminRedirectList from "./AdminRedirectList";
import AdminRedirectNew from "./AdminRedirectNew";
import AdminSearchList from "./AdminSearchList";
import Invitation from "./Invitation";
import NotificationCenter from "./NotificationCenter";
import NotificationPreferences from "./NotificationPreferences";
import Referrals from "./Referrals";
import TicketNew from "./TicketNew";

import AdminProviderEdit from "./AdminExchangeProviderEdit";
import AdminProviderList from "./AdminExchangeProviderList";
import AdminProviderNew from "./AdminExchangeProviderNew";

import AdminAdEdit from "./AdminAdEdit";
import AdminAdList from "./AdminAdList";
import AdminAdNew from "./AdminAdNew";

import Fanduel from "Screens/Fanduel";
import AdminBets from "./AdminBets";
import AdminTransactionList from "./AdminExchangeTransactionList";
import AdminTransactionNew from "./AdminExchangeTransactionNew";
import AdminSettled from "./AdminSettled";

export default () => (
  <BrowserRouter>
    <LayoutProvider LayoutComponent={Layout} showNav={true}>
      <Switch>
        <Route path="/" exact component={useTracker(ListingListScreen)} />
        <Route path="/activities" exact component={useTracker(Activities)} />
        <Route
          path="/reports/locale/transactions"
          exact
          component={useTracker(TransactionLocaleReport)}
        />

        <Route path="/pages/faq" exact component={useTracker(FAQScreen)} />
        <Route path="/pages/about" exact component={useTracker(AboutScreen)} />
        <Route path="/pages/sell" exact component={useTracker(SellScreen)} />
        <Route
          path="/pages/terms"
          exact
          component={useTracker(BuyerTermsScreen)}
        />
        <Route
          path="/pages/seller-terms"
          exact
          component={useTracker(SellerTermsScreen)}
        />
        <Route
          path="/pages/privacy-policy"
          exact
          component={useTracker(PrivacyPolicyScreen)}
        />
        <Route
          path="/pages/design"
          exact
          component={useTracker(DesignScreen)}
        />
        <Route
          path="/invite/:referralCode"
          exact
          component={useTracker(Invitation)}
        />
        <Route
          path="/invite/:referralCode/:bonusCode"
          exact
          component={useTracker(Invitation)}
        />

        <Route path="/sign-out" exact component={useTracker(SignOutScreen)} />

        <PrivateRoute
          authComponent={Auth}
          path="/sign-in"
          exact
          component={useTracker(SignInScreen)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/sign-up"
          exact
          component={useTracker(SignInScreen)}
        />

        <PrivateRoute
          authComponent={Auth}
          path="/account/settings"
          exact
          component={useTracker(AccountSettingsScreen)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/account/watchlist"
          exact
          component={useTracker(Watchlist)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/account/referrals"
          exact
          component={useTracker(Referrals)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/account/notification-center"
          exact
          component={useTracker(NotificationCenter)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/account/notification-preferences"
          exact
          component={useTracker(NotificationPreferences)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/account/deposit"
          exact
          component={useTracker(AccountDepositScreen)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/account/withdraw"
          exact
          component={useTracker(AccountWithdrawScreen)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/account/transactions"
          exact
          component={useTracker(AccountTransactionsScreen)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/account/tickets"
          exact
          component={useTracker(TicketList)}
        />

        <PrivateRoute
          authComponent={Auth}
          path="/account/bids"
          exact
          component={useTracker(AccountBidsScreen)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/account/edit"
          exact
          component={useTracker(CognitoEdit)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/account/settings/edit"
          exact
          component={useTracker(SettingsEdit)}
        />

        {/* <PrivateRoute authComponent={Auth} path='/tickets/new' exact component={useTracker(TicketNewScreen)} /> */}
        <PrivateRoute
          authComponent={Auth}
          path="/tickets/new"
          exact
          component={useTracker(TicketNew)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/tickets/:id"
          exact
          component={useTracker(TicketDetailScreen)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/tickets/:ticketId/listings/:id"
          exact
          component={useTracker(TicketDetailScreen)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/fanduel"
          exact
          component={useTracker(Fanduel)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/redirects"
          exact
          component={useTracker(AdminRedirectList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/redirects/new"
          exact
          component={useTracker(AdminRedirectNew)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/redirects/:id/edit"
          exact
          component={useTracker(AdminRedirectEdit)}
        />

        <Route
          path="/admin/links"
          exact
          component={useTracker(AdminLinkList)}
        />
        <Route
          path="/admin/searches"
          exact
          component={useTracker(AdminSearchList)}
        />
        {/* <GroupRoute group="Admins"  path='/admin/links' exact component={useTracker(AdminLinkList)} /> */}
        <GroupRoute
          group="Admins"
          path="/admin/links/new"
          exact
          component={useTracker(AdminLinkNew)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/links/:id/edit"
          exact
          component={useTracker(AdminLinkEdit)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/locales"
          exact
          component={useTracker(LocaleList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/locales/:id/edit"
          exact
          component={useTracker(LocaleEdit)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/locales/new"
          exact
          component={useTracker(LocaleNew)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/sports"
          exact
          component={useTracker(SportList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/sports/:id/edit"
          exact
          component={useTracker(SportEdit)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/sports/new"
          exact
          component={useTracker(SportNew)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/bets"
          exact
          component={useTracker(AdminBets)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/settled"
          exact
          component={useTracker(AdminSettled)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/sports/:sportId/groups"
          exact
          component={useTracker(GroupList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/sports/:sportId/groups/new"
          exact
          component={useTracker(GroupNew)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/sports/:sportId/groups/:id/edit"
          exact
          component={useTracker(GroupEdit)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/promotions/:promotionId/redemptions"
          exact
          component={useTracker(AdminRedemptionList)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/campaigns/:campaignId/promotions"
          exact
          component={useTracker(AdminPromotionList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/campaigns/:campaignId/promotions/:id/edit"
          exact
          component={useTracker(AdminPromotionEdit)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/campaigns/:campaignId/promotions/new"
          exact
          component={useTracker(AdminPromotionNew)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/campaigns"
          exact
          component={useTracker(AdminCampaignList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/campaigns/:id/edit"
          exact
          component={useTracker(AdminCampaignEdit)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/campaigns/new"
          exact
          component={useTracker(AdminCampaignNew)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/rebates"
          exact
          component={useTracker(AdminRebateList)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/sports/:sportId/events"
          exact
          component={useTracker(AdminEventList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/sports/:sportId/events/:id/edit"
          exact
          component={useTracker(AdminEventEdit)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/sports/:sportId/events/new"
          exact
          component={useTracker(AdminEventNew)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/notifications/new"
          exact
          component={useTracker(AdminNotificationsNew)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/conversionTemplates"
          exact
          component={useTracker(AdminConversionTemplateList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/conversionTemplates/new"
          exact
          component={useTracker(AdminConversionTemplateNew)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/conversionTemplates/:id/edit"
          exact
          component={useTracker(AdminConversionTemplateEdit)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/sportsbooks"
          exact
          component={useTracker(AdminSportsbookList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/sportsbooks/new"
          exact
          component={useTracker(AdminSportsbookNew)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/sportsbooks/:id/edit"
          exact
          component={useTracker(AdminSportsbookEdit)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/events/:eventId/odds"
          exact
          component={useTracker(AdminEventOddsList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/events/:eventId/options/:id/odds"
          exact
          component={useTracker(AdminEventOptionOddsList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/events/:eventId/options"
          exact
          component={useTracker(AdminEventOptionsList)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/account/:id/edit"
          exact
          component={useTracker(CognitoEdit)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/users/:id/edit"
          exact
          component={useTracker(UserEdit)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/users/:userId/listings"
          exact
          component={useTracker(AdminUserListingList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/users/:userId/tickets"
          exact
          component={useTracker(AdminUserTicketList)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/exchange/providers"
          exact
          component={useTracker(AdminProviderList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/exchange/providers/:id/edit"
          exact
          component={useTracker(AdminProviderEdit)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/exchange/providers/new"
          exact
          component={useTracker(AdminProviderNew)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/exchange/providers/:providerId/transactions"
          exact
          component={useTracker(AdminTransactionList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/exchange/providers/:providerId/transactions/new"
          exact
          component={useTracker(AdminTransactionNew)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/ads"
          exact
          component={useTracker(AdminAdList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/ads/:id/edit"
          exact
          component={useTracker(AdminAdEdit)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/ads/new"
          exact
          component={useTracker(AdminAdNew)}
        />

        <Route
          path="/admin/search"
          exact
          component={useTracker(SearchScreen)}
        />

        <GroupRoute
          group="Admins"
          path="/admin/tickets"
          exact
          component={useTracker(AdminTicketsScreen)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/listings"
          exact
          component={useTracker(AdminListingList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/users"
          exact
          component={useTracker(AdminUsersScreen)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/users/:userId/transactions"
          exact
          component={useTracker(AccountTransactionsScreen)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/users/:userId/recommendations"
          exact
          component={useTracker(AccountRecommendationsScreen)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/odds"
          exact
          component={useTracker(OddsList)}
        />
        <GroupRoute
          group="Admins"
          path="/admin/tickets/:id/edit"
          exact
          component={useTracker(AdminTicketEditScreen)}
        />
        <PrivateRoute
          authComponent={Auth}
          path="/tickets/:ticketId/listings/:id/edit"
          exact
          component={useTracker(ListingEditScreen)}
        />
      </Switch>
    </LayoutProvider>
  </BrowserRouter>
);
