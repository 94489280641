import React from "react";
import PageContainer from "../../Layout/PageContainer";
import ContentCell from "../../Layout/ContentCell";

const SellerTerms = () => {
  return (
    <PageContainer>
      <ContentCell fullWidth>
        <p>
          <strong>PropSwap – Terms/Conditions for Users (Sellers)</strong>
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong> Global User Agreement</strong>
          </li>
        </ol>
        <p>
          <span>
            PropSwap provides an online platform and related services (“
          </span>
          <strong>Services</strong>
          <span>
            ”) that connects sellers and buyers seeking to respectively sell and
            buy sports betting tickets (“
          </span>
          <strong>Tickets</strong>
          <span>
            ”) and we may make such Services available via our website, our
            mobile applications and/or associated services (collectively, the “
          </span>
          <strong>Site</strong>
          <span>
            ”). By accessing or using our Site, You agree to be bound by this
            PropSwap Marketplace User Agreement (“
          </span>
          <strong>User Agreement</strong>
          <span>
            ”). User Privacy Notice, Mobile Privacy Notice, Seller Policies,
            Cookies Policy, and all other policies applicable to your use of the
            Site (collectively, the “
          </span>
          <strong>Additional Policies</strong>
          <span>
            ”) are incorporated by reference into this User Agreement. We may
            periodically make changes to this User Agreement and shall notify
            you by posting a revised version on our Site and emailing you at
            your registered email address or otherwise notifying you via our
            Site. The revised User Agreement will become effective thirty (30)
            days following such notice and your continued use of our Site and
            Services will constitute acceptance of the revised User
            Agreement.&nbsp;
          </span>
          <span>
            Changes to the Additional Policies, except the User Privacy Notice
            and Payment Services Agreement, may be made from time to time
            without prior notice and your continued use of the Site or Service
            constitutes your acceptance of the modified terms of the Additional
            Policies.
          </span>
        </p>
        <ol start="2">
          <li>
            <strong> Overview</strong>
          </li>
        </ol>
        <p>
          <span>
            PropSwap is a ticket marketplace that allows registered users to buy
            (in this capacity, "
          </span>
          <strong>Buyer</strong>
          <span>") and sell (in this capacity, “</span>
          <strong>Seller</strong>
          <span>
            ”) Tickets. The Seller (and not PropSwap) sets the prices for the
            Tickets. As a marketplace, PropSwap does not own the Tickets sold on
            the Site.{" "}
          </span>
        </p>
        <ol>
          <li>
            <span>
              {" "}
              While PropSwap may provide pricing, shipping, listing and other
              guidance on the Site, such guidance is solely informational
              (without any warranty as to accuracy). Also, while we may help
              facilitate the resolution of disputes between Buyer and Seller,
              PropSwap has no control over and does not guarantee the existence,
              quality, safety or legality of the Tickets; the truth or accuracy
              of the user’s content or listings; the ability of Sellers to sell
              tickets or Buyers to pay for them; or that a Buyer or Seller will
              complete a transaction.
            </span>
          </li>
          <li>
            <strong> Your account</strong>
          </li>
        </ol>
        <p>
          <span>
            3.1 In order to list, sell or purchase Tickets you must register an
            account ("
          </span>
          <strong>Account</strong>
          <span>
            ") with PropSwap. You must be at least 21 years old and must be able
            to enter into legally binding contracts.{" "}
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            3.2 When opening an Account you must provide complete and accurate
            information and provide us with a valid credit card, debit card or
            PayPal account ("Payment Method"). If your registration or payment
            information changes at any time, you must promptly update your
            details in your Account. You acknowledge that PropSwap may charge
            any one of your payment methods on file for amounts you owe us,
            costs we incur or other losses arising out of your violation of this
            Agreement (including the Additional Policies).
          </span>
        </p>
        <p>
          <span>
            3.3 PropSwap reserves the right to temporarily or permanently
            suspend Accounts with incomplete or inaccurate information.
          </span>
        </p>
        <p>
          <span>
            3.4 You are solely responsible for maintaining the confidentiality
            of your Account information and password. You are solely responsible
            for any activity relating to your Account. Your Account is not
            transferable to another party.
          </span>
        </p>
        <p>
          <br />
          <br />
          <br />
        </p>
        <p>
          <strong>4.0 Dropped Sales</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>A dropped sale means any one of the following:</span>
        </p>
        <ul>
          <ul>
            <li>
              <span>
                You cannot deliver the exact ticket in time and as specified in
                your listing
              </span>
            </li>
            <li>
              <span>
                Or, for any other reason, you fail to complete your sale.{" "}
              </span>
            </li>
          </ul>
        </ul>
        <p>&nbsp;</p>
        <p>
          <span>
            Remember, reporting an issue to request a change to your sale
            doesn’t mean it has to be accepted. PropSwap may in its sole
            discretion cancel the order without further notice or payment to
            you.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>Here are a few things to keep in mind:</span>
        </p>
        <ol>
          <li>
            <span>
              It’s always your responsibility to report an issue immediately, no
              matter how much time has passed since you received your sale
              notification email.
            </span>
          </li>
          <li>
            <span>
              PropSwap monitors the frequency and rate at which you drop sales
              as compared to your total sales (your 'dropped order rate') and we
              reserve the right, upon notice to you, to suspend your account if
              we determine that your dropped order rate has exceeded an
              acceptable level. Similarly, if you abuse the PropSwap marketplace
              as determined by PropSwap in its sole discretion , you may be
              subject to an investigation and any applicable consequences.
            </span>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <span>
            <br />
            <br />
          </span>
        </p>
        <p>
          <span>
            If you dropped your sale, PropSwap will charge your payment method
            an amount equal to the greater of (i) 50% of Collect Amount of your
            ticket, or (ii) $250. In addition, you may be subject to other
            consequences as specified in the User Agreement at Clause 19,
            including account suspension.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <br />
          </strong>
          <strong>5.0 What happens when you break the rules</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            When you violate these Seller Policies, you may face consequences,
            which could include the following (or any combination):
          </span>
        </p>
        <ul>
          <li>
            <span>
              The fees detailed in Section 3 above (The rules for getting
              tickets to buyers), including the cost of replacement tickets,
              reprinting fees, additional delivery costs, administrative fees
              and refunds to the buyer
            </span>
          </li>
          <li>
            <span>Warnings</span>
          </li>
          <li>
            <span>The removal of your listings</span>
          </li>
          <li>
            <span>Order cancellation</span>
          </li>
          <li>
            <span>Limits to your buying and selling privileges</span>
          </li>
          <li>
            <span>Withholding of payments</span>
          </li>
          <li>
            <span>Temporary or permanent suspension of your account</span>
          </li>
          <li>
            <span>
              Or any other consequences specified in Clause 19 of the User
              Agreement
            </span>
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <span>
            Remember, it's your responsibility to consult PropSwap's User
            Agreement and all PropSwap policies (including these Seller
            Policies) for all of the detail.
          </span>
        </p>
        <p>
          <span>
            <br />
          </span>
          <span>
            <br />
            <br />
          </span>
        </p>
        <p>
          <strong>6.0 Counterfeit Tickets</strong>
        </p>
        <p>
          <span>
            <br />
            <br />
          </span>
        </p>
        <p>
          <span>
            If we receive a report that tickets you sold on PropSwap were not
            valid sportsbook tickets, we'll investigate to try to determine what
            caused the issue, and, if necessary, we'll reach out to you to
            request documentation.
          </span>
        </p>
        <p>
          <span>
            <br />
          </span>
          <span>
            In this situation, PropSwap will charge your payment method an
            amount equal to the greater of (i) 50% of Collect Amount of your
            ticket, or (ii) $250
          </span>
        </p>
        <p>
          <span>
            <br />
            <br />
          </span>
        </p>
        <p>
          <span>
            If you fail to respond to this email and or to take any other
            actions required by PropSwap to investigate the invalid ticket
            claim, you may be charged an amount equal to 55% of the cost of the
            original tickets, in addition to the Invalid Ticket Charge described
            in the paragraph above. A response constitutes an acknowledgment of
            our request and a bona fide effort to investigate the claim and
            obtain documentation. However, failing to respond, and not being
            able to provide documentation are not the same. We understand that
            every team and venue is different, and there may be cases where you
            cannot obtain documentation to assist in the investigation. In these
            cases, we'll work with you and use your history with us,
            specifically your invalid ticket claim rate, to determine the
            resolution. You can view your invalid ticket claim rate in the
            Seller Data Portal any time.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            7.0 Consequences for Dropped Sales and Invalid Tickets
          </strong>
        </p>
        <p>
          <span>
            <br />
            <br />
          </span>
        </p>
        <p>
          <span>
            We know things don't always go as planned. You need to report an
            issue right away if you:
          </span>
        </p>
        <ol>
          <li>
            <span>can't send the tickets based on your In Hand Date;</span>
          </li>
          <li>
            <span>can’t deliver them at all</span>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <span>You can report an issue by emailing info@propswap.com.</span>
        </p>
        <p>&nbsp;</p>
        <ol>
          <li></li>
          <li>
            <span>Payment Services Agreement for Sellers</span>
          </li>
        </ol>
        <p>
          <span>This Payment Services Agreement for Sellers&nbsp;</span>
          <strong>("Payment Services Agreement")</strong>
          <span>&nbsp;is between you ("</span>
          <strong>you</strong>
          <span>" or "</span>
          <strong>Seller</strong>
          <span>") and&nbsp;</span>
          <strong>PropSwap Services</strong>
          <span>
            &nbsp; ("PropSwap Services") and applies to your use of the payment
            processing services ("
          </span>
          <strong>Payment Services</strong>
          <span>
            ") provided by PropSwap Services to Sellers in connection with their
            use of the Site and Marketplace Services provided by PropSwap Europe
            S.à r.l. ("
          </span>
          <strong>PropSwap</strong>
          <span>
            ") according to the PropSwap.co.uk Marketplace Services User
            Agreement ("
          </span>
          <strong>User Agreement</strong>
          <span>").</span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            The terminology used in this Payment Services Agreement has the same
            meaning as in the User Agreement unless otherwise defined in this
            Payment Services Agreement. In the event of a conflict between one
            or more of the terms of the User Agreement and this Payment Services
            Agreement, the terms in this agreement will prevail unless
            explicitly stated otherwise.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            This Payment Services Agreement is effective on 22 September 2016
            for all current Sellers and upon acceptance for new Sellers.
            PropSwap Services may amend the terms of this Payment Services
            Agreement at any time in accordance with Clause 12.
          </span>
        </p>
        <p>
          <span>7.0</span>
          <strong> Scope of Payment Services</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            The Payment Services provided by PropSwap Services enable you to
            receive payments from Buyers for Ticket sales made through the Site
            and Marketplace Services (“Payments”) and to transfer these funds to
            a specified payment account, once they have been released in
            accordance with the terms of this Payment Services Agreement.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            7.1 PropSwap Services does not in any way become a contractual
            partner to, or other participant in, the purchase contracts
            concluded between Buyers and Sellers. The Payment Services provided
            by PropSwap Services are provided exclusively to Sellers. As a
            Seller you authorise PropSwap Services to receive, hold and transfer
            funds in accordance with your payment instructions and the terms of
            this Payment Services Agreement.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            7.2 The Payment Services provided by PropSwap Services are only
            available in certain countries and territories. You can only receive
            and transfer funds in certain currencies. PropSwap Services may
            modify the supported countries, territories and currencies at any
            time.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            7.3 &nbsp;The Payment Services provided by PropSwap Services is $10
            per withdrawal, for Buyers. This fee is waived, should you sell a
            ticket with PropSwap in the prior 30 calendar days. &nbsp;PropSwap
            Services reserves the right to introduce fees or other charges at
            its sole discretion without prior notice.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            7.4 &nbsp;PropSwap Services may use the services of one or more
            third parties, payment processors and financial institutions to
            provide the Payment Services according to this Payment Services
            Agreement.
          </span>
        </p>
        <p>
          <strong>8.0 Registration and verification</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            8.1 In order to use the Payment Services you must register an
            account ("
          </span>
          <strong>PropSwap account</strong>
          <span>
            ") with PropSwap Services. To register you must be 21 years or older
            and completely and accurately provide the required information
            during the registration process. Only legal persons, partnerships
            and natural persons with unrestricted legal capacity which have
            their place of residence or establishment in one of the countries
            where PropSwap Services provides the Payment Services are permitted
            to register. A legal person may only be registered by a natural
            person authorised to represent the legal person. You are not
            permitted to transfer or grant access to your PropSwap account to a
            third party.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            8.2 PropSwap Services may require you to provide additional
            information and/or documentary evidence during registration or at a
            later stage necessary to verify your or your business’ identity or
            that may be required by law as a condition of providing the Payment
            Services. In accordance with our Privacy Notice, PropSwap Services
            may make, directly or through a third party, any inquiries it
            considers necessary to validate information provided by you,
            including without limitation checking commercial databases or credit
            reports.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            8.3 In order to access your PropSwap account you must use the email
            address and password specified during registration. You may be
            provided with additional credentials to enable access to your
            PropSwap account and/or to authorise payment transactions. You are
            responsible for maintaining the confidentiality and security of your
            credentials and must not disclose your credentials to any third
            party.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            8.4 You are obliged to contact Customer Support if you have any
            indication or suspicion that any of your credentials have been lost,
            stolen, misappropriated, used without permission or otherwise
            compromised. Failure to notify us may put the security of your
            PropSwap account at risk and may result in you being liable for any
            losses that are incurred by an unauthorised access of your PropSwap
            account.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            8.5 You must update the information associated with your PropSwap
            account promptly after any change to ensure it is always kept up to
            date and accurate. PropSwap Services may ask you at any time to
            confirm the accuracy of your information or to provide documents or
            other evidence. PropSwap Services reserves the right to temporarily
            or permanently suspend your PropSwap account and/or delay or refuse
            payment transactions if your information is outdated or incorrect.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            8.6 You are required to specify a PayPal account to which funds can
            be transferred from your PropSwap account ("
          </span>
          <strong>Associated Payment Account</strong>
          <span>
            "). You may change your Associated Payment Account at any time. If
            you change your Associated Payment Account pending payment
            transactions may be delayed for security reasons. PropSwap Services
            reserves the right to modify the types of payment accounts a Seller
            may choose as an Associated Payment Account.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            8.7 If you do not use or access your PropSwap account for a period
            of three years it may be closed. After closure, and subject to any
            necessary money laundering, terrorism financing, fraud or other
            illegal activity checks, we will attempt to send any funds in your
            PropSwap account to your Associated Payment Account. If we are
            unable to send you the funds (e.g. because the information we have
            on file is no longer correct), we may deposit the funds (at your
            expense) with the Luxembourg Caisse de Consignation at Trésorerie de
            l'Etat, 3, rue du St-Esprit, L-1475, Luxembourg.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>9.0 Using the payment services</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            9.1 You agree to only use the Payment Services for lawful purposes
            and to adhere at all times to all laws, rules and regulations
            applicable to the use of the Payment Services, including the terms
            of this Payment Services Agreement.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            9.2 You may not use the Payment Services to receive or transfer any
            funds on behalf of any other natural person or legal entity. You
            cannot use the Payment Services to accept any payments that are not
            related to your Ticket sales made through the Site and Marketplace
            Services.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            9.3 For the purpose of enabling PropSwap Services to provide you
            with the Payment Services in connection with your use of the Site
            and Marketplace Services provided by PropSwap, you instruct,
            authorise and empower PropSwap Services to provide PropSwap with
            information about your payment transactions on an ongoing basis for
            the duration of this Payment Services Agreement as required.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            9.4 You acknowledge that PropSwap Services is required, under
            national anti-money laundering legislation or similar legislation to
            provide certain information about its customers to the competent
            financial intelligence unit (FIU) in charge of the fight against
            money laundering and terrorism financing or to law enforcement
            authorities. Furthermore, you instruct, authorise and empower
            PropSwap Services to provide information related to your PropSwap
            account and your payment transactions, as required by law or
            pursuant to its duty of vigilance (notably to prevent the fraudulent
            use of the Payment Services), to the relevant law enforcement
            authorities.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            9.5 You instruct, authorise and empower PropSwap Services to share
            information about you with third party service providers to render
            its fraud detection procedures more efficient so that PropSwap
            Services can provide a safe and reliable environment for your use of
            the Payment Services, as well as for identity verification,
            creditworthiness and solvency checking purposes. Please contact
            PropSwap to receive a current list of all third party service
            providers PropSwap Services employs for the purposes described
            above.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            9.6 In connection with the above, you understand, acknowledge and
            agree that PropSwap Services, by making available the required
            information to PropSwap, the competent FIU or the relevant
            authorities and third party service providers as described above, is
            not in violation of any professional secrecy, confidentiality or
            data protection laws of any jurisdiction it may be subject to.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>10.0 Receiving payments</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            10.1 PropSwap Services will credit the Payments received from
            Buyers, less any fees, charges, set-off and other amounts due to
            PropSwap Services or PropSwap pursuant to Clause 4.4, to your
            PropSwap account no later than the business day on which the
            Payments are credited to PropSwap Services’ bank account.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            10.2 Unless agreed otherwise and subject to Clause 4.5 below, all
            Payments credited to your PropSwap account will be released within 3
            (3) to five (5) business days after the end of the event for which
            the respective Ticket(s) were sold. If the sale happens within seven
            (7) calendar days of the Event Date (found on the ticket), the
            proceeds will be credited to your PropSwap account within (3)
            calendar days after the Event has ended. &nbsp;
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            10.3 You will not receive any interest or any other earnings on any
            funds held on your PropSwap account. Claims due to default of
            PropSwap Services remain unaffected.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            10.4 You hereby authorise and instruct PropSwap Services to pay any
            due Service Fees, additional fees and other charges pursuant to
            Clause 3.2 of the User Agreement or other amounts PropSwap is
            entitled to directly to PropSwap and to set them off against pending
            or future Payments received from Buyers before they are credited to
            your PropSwap account.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            10.5 PropSwap Services is entitled to delay the release of one or
            more Payments temporarily if:
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            (a)PropSwap does not receive the ticket by seven (7) calendar days
            after the Sale occurs ; or
          </span>
        </p>
        <p>
          <span>
            (b)There is a reversal or chargeback of the Payment by the Buyer or
            the Buyer's payment service provider.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            A Payment will be released immediately after the case has been
            resolved and the Seller is entitled to receive the Payment.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            10.6 Sellers may inform themselves at any time about all received
            Payments and their expected release date by contacting Customer
            Support.{" "}
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>11.0 Reversals and chargebacks</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            11.1 If a Payment is subject to a reversal or chargeback by the
            Buyer or the Buyer's payment service provider, you are obliged upon
            request to provide to PropSwap Services all information and
            documents required for the investigation of the case within a
            reasonable timeframe set by PropSwap Services.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            11.2 A reversal or chargeback by the Buyer or the Buyer's payment
            service provider may occur in particular for the following reasons:
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            (a) A chargeback of the funding source of a PayPal payment (e.g.
            direct debit reversals or credit card chargebacks);
          </span>
        </p>
        <p>
          <span>
            (b) A reversal or chargeback initiated by the holder of the e-money
            or bank account or of the credit card ("
          </span>
          <strong>Account Holder</strong>
          <span>
            ") from which the Payment originated because of an unauthorised use
            of the Account Holder's account or credit card information;
          </span>
        </p>
        <p>
          <span>
            (c) A reversal or chargeback based on a claim within the framework
            of a buyer protection or similar programme offered by the Buyer's
            payment service provider.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            11.3 If a reversal or chargeback is based on the fact that the Buyer
            has communicated to his payment service provider that he has not
            received the Tickets or the Account Holder has reported an
            unauthorised use of his payment account or credit card information
            to his payment service provider, and PropSwap Services cannot verify
            the delivery of the Tickets, you are obliged to prove that the
            Tickets were delivered within the required delivery timeframe to the
            address communicated to you by PropSwap. If you are unable to prove
            that the Tickets have been delivered, PropSwap Services shall not be
            liable for the losses incurred by the reversal or chargeback of the
            Payment. In the event that a Payment subject to a reversal or
            chargeback has already been released, PropSwap Services reserves the
            right to set off this amount against other pending or future
            Payments received from Buyers before they are credited to your
            PropSwap account.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <span>
            11.4 If PropSwap Services determines that you are incurring an
            excessive amount of reversals or chargebacks, PropSwap Services
            reserves the right to take appropriate measures to mitigate the risk
            including but not limited to (a) introducing payment transaction
            volume limits or withdrawal limits on your PropSwap account, (b)
            requiring you to maintain a reserve on your PropSwap account or (c)
            terminating or blocking your PropSwap account.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            12.0 Payment instructions, manual and recurring payment order
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            12.5 Payment transactions from your PropSwap account will be
            executed at the latest by the end of the next business day after the
            corresponding payment instruction has been received by PropSwap
            Services.{" "}
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            12.6 You are obliged to notify PropSwap Services without undue delay
            after becoming aware of any unauthorised or incorrectly executed
            payment transactions by contacting Customer Service.{" "}
          </span>
          <span>
            <br />
          </span>
          <span>
            <br />
            <br />
          </span>
        </p>
        <p>
          <span>
            12.7 Key information relating to your payment transactions will be
            provided to you via email. In addition, information relating to your
            payment transaction history and any applicable fees or other charges
            is available in your PropSwap account. You may access, download and
            print this information at any time by logging in to your PropSwap
            account. Except as required by law you are solely responsible for
            retaining permanent records of your account history.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            13.0 Unauthorised and incorrectly executed payment transactions
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            13.1 Subject to Clause 7.2 and 7.6 PropSwap Services will reimburse
            you in full for all unauthorised payment transactions sent from your
            PropSwap Account provided that you have informed PropSwap Services
            of the unauthorised payment transaction without undue delay after
            becoming aware of the transaction and in any event, no later than 13
            months after the transaction was executed, unless PropSwap Services
            failed to make available the information related to the transaction
            in accordance with Clause 6.7.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            13.2 You are liable for any losses incurred by an unauthorised
            payment transaction if you have acted fraudulently or failed either
            intentionally or through gross negligence, to use your PropSwap
            account in accordance with the terms of this Payment Services
            Agreement or to keep your credentials confidential and secure.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            13.3 You will not be liable for losses incurred by an unauthorised
            payment transaction which takes place after you have notified
            PropSwap Services of a compromise of your credentials according to
            Clause 2.4, unless you have acted fraudulently.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            13.4 Subject to Clause 7.5 and 7.6 PropSwap Services will refund you
            in full and is liable for losses directly and reasonably foreseeably
            incurred by an incorrectly or non-executed payment transaction
            provided that you have informed PropSwap Services of the incorrectly
            or non-executed payment transaction without undue delay after
            becoming aware of the defect and in any event, no later than 13
            months after the transaction was executed, unless PropSwap Services
            failed to make available the information related to the transaction
            in accordance with Clause 6.7.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            13.5 PropSwap Services is not liable for a refund or losses incurred
            by an incorrectly or non-executed payment transaction if the details
            of the Associated Payment Account provided by you were incorrect or
            PropSwap Services can prove that the full amount of the payment
            transaction was duly received by the payment service provider of
            your Associated Payment Account.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            13.6 PropSwap Services is not liable for any unauthorised or
            incorrectly executed payment transactions in case the transaction
            was affected by abnormal and unforeseeable circumstances beyond
            PropSwap Services’ control, despite PropSwap Services’ reasonable
            precautions or where PropSwap Services acted in accordance with a
            legal obligation.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            14.0 Blocking of accounts, delay or refusal of payment transactions
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            14.1 PropSwap Services reserves the right to temporarily or
            permanently suspend your PropSwap account, to delay or refuse
            payment transactions to and from your PropSwap account, or otherwise
            restrict its functionality if:
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            (a)Required by law or court order or if otherwise required by any
            regulatory or governmental authority, body or agency having
            jurisdiction over us;
          </span>
        </p>
        <p>
          <span>
            (b)Required to fulfil our legal obligations in relation to the fight
            against money laundering and financing of terrorism;
          </span>
        </p>
        <p>
          <span>
            (c)There is suspicion of unauthorised or fraudulent access to or use
            of your PropSwap account or that any of its security features have
            been compromised;
          </span>
        </p>
        <p>
          <span>
            (d)PropSwap Services has reasonable grounds to believe you are
            carrying out a prohibited or illegal activity;
          </span>
        </p>
        <p>
          <span>
            (e)PropSwap Services is unable to verify your or your business’
            identity or any other information pertaining to you, your PropSwap
            account or a payment transaction.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            14.2 PropSwap Services will notify you via email of the suspension
            or restriction and the reasons for it, where possible, before such
            measures take place and at the latest immediately thereafter, unless
            prevented from doing so for security reasons or by law.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            14.3 PropSwap Services will reinstate your PropSwap account or
            execute the payment transaction as soon as practicable after the
            reasons pursuant to Clause 8.1 no longer apply.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>15.0 Account limits and reserves</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            15.1 In order to comply with anti-money laundering obligations or to
            manage risk, PropSwap Services reserves the right at its reasonable
            discretion to place payment transaction volume limits or withdrawal
            limits ("
          </span>
          <strong>Limits</strong>
          <span>
            ") on your PropSwap account. Such Limits are determined based on
            your verification status and performance and risk factors. PropSwap
            Services will notify you of any Limits placed on your account and
            the steps required to remove them. This may involve providing
            additional information to PropSwap Services or going through
            additional verification processes.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            15.2 In order to manage risk or to secure your obligations under
            this Payment Services Agreement, PropSwap Services reserves the
            right at its reasonable discretion to require you to maintain a
            minimum balance ("
          </span>
          <strong>Reserve</strong>
          <span>
            ") on your PropSwap account. PropSwap Services will notify you of
            any Reserve placed on your account. A Reserve may be raised, lowered
            or removed at any time with prior notice to you depending on your
            performance and the risk associated with your PropSwap account.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>16.0 Term and termination</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            16.1 This Payment Services Agreement commences on the date that you
            register with PropSwap Services and will continue for an indefinite
            period unless terminated in accordance with this Clause 10.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            16.2 You may terminate this Payment Services Agreement at any time
            without charge by contacting&nbsp;
          </span>
          <a href="https://www.stubhub.com/contact-us/">
            <span>Customer Service</span>
          </a>
          <span>
            . You may not close your PropSwap account while PropSwap Services is
            carrying out verifications of your or your business’ identity or
            other information pertaining to you, your PropSwap account or
            payment transactions.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            16.3 Without limiting the rights of PropSwap Services according to
            Clause 7, PropSwap Services may terminate this Payment Services
            Agreement by giving two (2) months’ prior notice via email to your
            registered email address.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            16.4 If this Payment Services Agreement is terminated by either
            party, any use of the Site and Marketplace Services as a Seller will
            no longer be possible.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            16.5 After termination by either party, and subject to any necessary
            money laundering, terrorism financing, fraud or other illegal
            activity checks, any remaining funds on your PropSwap account will
            be automatically transferred to your Associated Payment Account.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            16.6 Termination of this Payment Services Agreement shall not affect
            the rights or liabilities of either party accrued prior to and
            including the date of termination or expiry and/or any terms
            intended expressly or by implication to survive termination or
            expiry. If there are still pending payment transactions at the time
            the termination takes effect, they will still be processed pursuant
            to terms of this Payment Services Agreement.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>17.0 Taxes</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            It is your responsibility to determine what, if any, taxes apply to
            the Payments you receive, and it is your responsibility to collect,
            report and remit the correct tax to the appropriate tax authority.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>18.0 Amendments to this payment services agreement</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            PropSwap Services may periodically make changes to this Payment
            Services Agreement and shall notify you by posting a revised version
            of the agreement on the Site and emailing you at your registered
            email address or otherwise notifying you via our Site or Payment
            Services. The revised terms will become effective two (2) months
            following such notice and your continued use of the Payment Services
            will constitute acceptance of the revised terms. If you do not
            accept the revised terms you can immediately terminate the agreement
            before the revised agreement takes effect.
          </span>
        </p>
        <p>&nbsp;</p>
        <ol start="19">
          <li>
            <strong> Liability</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <span>
            19.1 Subject to Clause 7 and to the fullest extent permitted by
            applicable law PropSwap Services (including its parent,
            subsidiaries, affiliates, directors, agents and employees) shall not
            be liable to you in contract, tort (including negligence) or
            otherwise for any business losses, such as loss of data, profits,
            revenue, business, opportunity, goodwill, reputation or business
            interruption or for any losses which are not reasonably foreseeable
            by us arising, directly or indirectly from:
          </span>
        </p>
        <ul>
          <li>
            <span>
              Your use of or your inability to use the Payment Services;
            </span>
          </li>
          <li>
            <span>Delays or disruptions in the Payment Services;</span>
          </li>
          <li>
            <span>
              Glitches, bugs, errors or inaccuracies of any kind in our Payment
              Services;
            </span>
          </li>
          <li>
            <span>
              Damage to your hardware device from the use of any of our Payment
              Services;
            </span>
          </li>
          <li>
            <span>The content, actions or inactions of third parties;</span>
          </li>
          <li>
            <span>
              A suspension or other action taken with respect to your PropSwap
              account;
            </span>
          </li>
          <li>
            <span>
              Your need to modify practices behaviour or your loss of or
              inability to do business, as a result of changes to this Payment
              Services Agreement or our policies.
            </span>
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <span>
            19.2 Some jurisdictions do not allow the disclaimer of warranties or
            exclusion of damages, so such disclaimers and exclusions may not
            apply to you and you may have rights in addition to those contained
            in this Payment Services Agreement. This is especially so if you are
            a consumer.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            19.3 You accept sole responsibility for the legality of your actions
            under laws applying to you.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            19.4 Nothing in this User Agreement shall limit or exclude our
            liability for fraud or fraudulent misrepresentation, for death or
            personal injury resulting from our negligence or the negligence of
            our agents or employees or for any other liability that cannot be
            limited or excluded by law.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>20.0 Release</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            If you have a dispute with one or more users, you release PropSwap
            Services (and its affiliates and subsidiaries, and its and their
            respective officers, directors, employees and agents) from claims,
            demands and damages (actual and consequential) of every kind and
            nature, known and unknown, arising out of or in any way connected
            with such disputes.
          </span>
        </p>
        <p>&nbsp;</p>
        <ol start="21">
          <li>
            <strong> Indemnification</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <span>
            You agree to indemnify and hold PropSwap Services and (if
            applicable) PropSwap Services' parent, subsidiaries, affiliates, and
            our and their respective officers, directors, attorneys, agents,
            employees, licensors and suppliers (the "PropSwap Services
            Indemnitees") harmless against any claim or demand and all
            liabilities, costs and expenses (including reasonable attorneys'
            fees) incurred by PropSwap Services and (if applicable) any PropSwap
            Services Indemnitee resulting from or arising out of your breach of
            this Payment Services Agreement, your improper use of the Payment
            Services, and/or your violation of any law or the rights of a third
            party.
          </span>
        </p>
        <p>&nbsp;</p>
        <ol start="22">
          <li>
            <strong> General provisions</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <span>
            22.1 This Payment Services Agreement (and all documents incorporated
            by reference) constitutes the entire agreement between the parties
            pertaining to the subject matter hereof and supersedes all prior
            agreements and understandings between the parties hereto with
            respect to the subject matter hereof, whether written or oral. No
            amendment, modification or supplement of any provision of this
            Payment Services Agreement will be valid or effective unless made in
            accordance with the express terms of this Payment Services
            Agreement.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            22.2 Except as otherwise stated, this Payment Services Agreement
            shall be governed by the laws of the Grand Duchy of Luxembourg. If
            you are registered as a business you agree to submit to the
            exclusive jurisdiction of the Luxembourg courts. If you are not
            registered as a business you agree to submit to the non-exclusive
            jurisdiction of the Luxembourg courts.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            22.3 If any provision of this Payment Services Agreement is held to
            be invalid or unenforceable under any circumstances, its application
            in any other circumstances and the remaining provisions of this
            Payment Services Agreement shall not be affected.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            22.4 PropSwap Services may assign or transfer its rights and
            obligations under this Payment Services Agreement at any time,
            provided that PropSwap Services assigns the Payment Services
            Agreement on the same terms or terms that are no less advantageous
            to you. You may not assign or transfer this Payment Services
            Agreement, or any of its rights or obligations, without the prior
            written consent of PropSwap Services, which PropSwap Services can
            refuse in its sole discretion.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            22.5 No agency, partnership, joint venture, employer-employee or
            franchisor-franchisee relationship is intended or created by this
            Payment Services Agreement or the provision of the Payment Services.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            22.6 Nothing in this Payment Services Agreement is intended to
            confer benefits, rights or remedies unto any person or entity other
            than the parties hereto and their permitted successors and assigns.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            22.7 The subject heading at the beginning of each paragraph of this
            Payment Services Agreement is for reference purposes only and in no
            way defines, limits, construes or describes the scope or extent of
            such paragraph. The following sections survive any termination of
            this Payment Services Agreement: Unauthorized and incorrectly
            executed payment transactions, Liability and Release.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            22.8 Our failure to act with respect to a breach by you or others
            does not waive our right to act with respect to subsequent or
            similar breaches. We do not guarantee we will take action against
            all breaches of this Payment Services Agreement.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            22.9 Any complaints about us or the services we provide should be
            addressed to us in the first instance by contactin Customer Support.{" "}
          </span>
          <span>
            <br />
          </span>
          <span>
            <br />
          </span>
          <span>
            22.10 PropSwap Services will communicate with you in the language(s)
            in which this Payment Services Agreement has been made available to
            you. You agree that PropSwap Services may provide notice or other
            information to you by posting it in your PropSwap Account, emailing
            it to your registered email address, mailing it to your registered
            physical address, calling you by phone or sending you mobile
            messages. Notices to you by email or mobile messages shall be deemed
            given 24 hours after the email is sent, unless the sending party is
            notified that the email address is invalid. Notices sent by
            registered mail shall be deemed to have been received three days
            after the date of mailing.{" "}
          </span>
        </p>
        <p>
          <span>
            22.11 You may request a copy of any legally required disclosures
            (including this Payment Services Agreement) from us and we will
            provide this to you in a form which allows you to store and
            reproduce the information (for example, by email).
          </span>
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <span>
            When your ticket sells, there will be a $100 “Hold” placed on your
            card. Your card will NOT be charged.
          </span>
        </p>
        <p>
          <span>
            Once your ticket sells, you will receive a confirmation email with
            detailed instructions as to how to get PropSwap your ticket.
            PropSwap MUST receive your ticket within seven (7) calendar days.
            Once PropSwap receives the ticket, the “Hold” will be released.
          </span>
        </p>
        <p>
          <span>
            If PropSwap does not receive your ticket within 7 days, you will be
            charged 50% of the ticket Collect Amount and you will not receive
            your Sale proceeds.
          </span>
        </p>
        <p>&nbsp;</p>
      </ContentCell>
    </PageContainer>
  );
};

export default SellerTerms;
