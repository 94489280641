import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { useState } from "react";

import BetListItemView from "./BetListItemView";

export const betListItemFragment = gql`
  fragment BetListItemFragment on Bet {
    id
    ticketId
    userId
    result
    eventDescription
    subject
    sport {
      name
    }
    option {
      name
    }
    event {
      description
    }
  }
`;

export const betListItemByTicketIdQuery = gql`
  query BetListItemByTicketIdQuery(
    $ticketId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelBetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBetsByTicketId(
      ticketId: $ticketId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ...BetListItemFragment
      }
      nextToken
    }
  }
  ${betListItemFragment}
`;

export const betListItemQuery = gql`
  query BetListItemQuery(
    $result: BetResult!
    $updatedAt: ModelStringKeyConditionInput
    $filter: ModelBetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBetsByResult(
      result: $result
      updatedAt: $updatedAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ...BetListItemFragment
      }
      nextToken
    }
  }
  ${betListItemFragment}
`;

const mutation = gql`
  mutation BetListItemUpdateBet($input: UpdateBetInput!) {
    updateBet(input: $input) {
      ...BetListItemFragment
    }
  }
  ${betListItemFragment}
`;

const destroy = gql`
  mutation BetListItemDeleteBet($input: DeleteBetInput!) {
    deleteBet(input: $input) {
      ...BetListItemFragment
    }
  }
  ${betListItemFragment}
`;


const BetListItemContainer = ({ bet }) => {
  const betId = bet.id;
  const [newEventDescription, setNewEventDescription] = useState(null);
  const [newSubject, setNewSubject] = useState(null);

  const [updateBet, { loading: updateLoading }] = useMutation(mutation, {
    variables: {
      input: {
        id: betId,
      }
    },
    refetchQueries: [
      {
        query: betListItemByTicketIdQuery,
        variables: {
          ticketId: bet.ticketId
        }
      },
      {
        query: betListItemQuery,
        variables: {
          result: "WIN",
          sortDirection: "DESC"
        }
      },
      {
        query: betListItemQuery,
        variables: {
          result: "LOSS",
          sortDirection: "DESC"
        }
      },
      {
        query: betListItemQuery,
        variables: {
          result: "PUSH",
          sortDirection: "DESC"
        }
      },
      {
        query: betListItemQuery,
        variables: {
          result: "UNDECIDED",
          sortDirection: "DESC"
        }
      }
    ]
  })

  const [deleteBet, { loading: deleteLoading }] = useMutation(destroy, {
    variables: {
      input: {
        id: betId,
      }
    },
    refetchQueries: [
      {
        query: betListItemByTicketIdQuery,
        variables: {
          ticketId: bet.ticketId
        }
      },
      {
        query: betListItemQuery,
        variables: {
          result: "WIN",
          sortDirection: "DESC"
        }
      },
      {
        query: betListItemQuery,
        variables: {
          result: "LOSS",
          sortDirection: "DESC"
        }
      },
      {
        query: betListItemQuery,
        variables: {
          result: "PUSH",
          sortDirection: "DESC"
        }
      },
      {
        query: betListItemQuery,
        variables: {
          result: "UNDECIDED",
          sortDirection: "DESC"
        }
      }
    ]
  });

  const loading = deleteLoading ?? updateLoading;
  const hasChanges = !!newSubject || !!newEventDescription;

  const handleSaveClick = useCallback(() => {
    updateBet({
      variables: {
        input: {
          id: betId,
          ...(!!newSubject ? {subject: newSubject} : {}),
          ...(!!newEventDescription ? {eventDescription: newEventDescription} : {}),
        }
      }
    })
      .catch(console.error);
  }, [betId, updateBet, newEventDescription, newSubject]);
  

  const handlePushClick = useCallback(() => {
    updateBet({
      variables: {
        input: {
          id: betId,
          result: "PUSH"
        }
      }
    })
      .catch(console.error);
  }, [betId, updateBet]);

  const handleWinClick = useCallback(() => {
    updateBet({
      variables: {
        input: {
          id: betId,
          result: "WIN"
        }
      }
    })
      .catch(console.error);
  }, [betId, updateBet]);

  const handleLossClick = useCallback(() => {
    updateBet({
      variables: {
        input: {
          id: betId,
          result: "LOSS"
        }
      }
    })
      .catch(console.error);
  }, [betId, updateBet]);

  const handleDeleteClick = useCallback(() => {
    window.confirm("Are you sure you want to delete this bet?") &&
    deleteBet({
      variables: {
        input: {
          id: betId,
        }
      }
    })
      .catch(console.error);
  }, [betId, deleteBet]);



  return <BetListItemView 
    onWinClick={handleWinClick}
    onLossClick={handleLossClick}
    onPushClick={handlePushClick}
    onDeleteClick={handleDeleteClick}
    onSaveClick={handleSaveClick}
    bet={bet}
    loading={loading}
    hasChanges={hasChanges}
    newEventDescription={newEventDescription}
    newSubject={newSubject}
    setNewSubject={setNewSubject}
    setNewEventDescription={setNewEventDescription}
  />
};

export default BetListItemContainer;