import React, { useState, useEffect, useContext, useCallback } from "react";
import Watching from "shared/api/Watching";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
import AdCell from "../Components/Layout/AdCell";
import TicketListItem from "../Components/Ticket/List/Item";
import Tabs from "../Components/Account/Components/AltTabs";
import { CurrentUserContext } from "gunner-react";
import { DataList } from "gunner-react/web";

const ConvertWatchingItemToTicketItem = ({
  object,
  id,
  filter = item => true,
}) => (
  <TicketListItem
    object={{ ...object.listing.ticket, listing: object.listing }}
    id={object.listing.listingTicketId}
    filter={filter}
  />
);

// const ConvertListingItemToTicketItem = args =>
//   console.log(args) ||
//   null

export default ({ history, location: { search = "" } = {} }) => {
  const currentUser = useContext(CurrentUserContext);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/account/watchlist"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell>
          <DataList
            clientFilter={item => item.listing.status === "ACTIVE"}
            RenderComponent={ConvertWatchingItemToTicketItem}
            query={Watching.queries.listByUserId}
            dataKey={"listWatchingsByUserId"}
            variables={{
              userId: currentUser.id,
              limit: 100,
              withListing: true,
              withTicket: true,
            }}

            // viewVariables={{
            //   status,
            // }}
          />
        </ContentCell>
        <AdCell>
          <img src={require("../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};
