const getGraphQLError = error => {
  let parsedError = null;
  try {
    parsedError = JSON.parse(error.graphQLErrors[0].message);
  } catch (_e) {
    parsedError = {
      message: error.graphQLErrors?.[0]?.message,
      level: 0,
    };
  }

  return parsedError;
};

export default getGraphQLError;
