import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import Form from "Components/Form";
import { withoutKeys } from "gunner-react";

const fields = {
  active: {
    label: "Active?",
    regex: /.+/,
    required: true,
    type: "checkbox",
    value: value => value === "YES",
    errorMessage: "Is the ad active?",
  },
  position: {
    label: "Position",
    regex: /.+/,
    required: true,
    type: "number",
    errorMessage: "You are required enter a name",
  },
  url: {
    label: "URL",
    regex: /\w+/,
    required: true,
    errorMessage: "Destination when clicked",
  },
  lightModeImageUrl: {
    label: "Light Mode Image",
    regex: /\w+/,
    required: true,
    type: "media",
    errorMessage: "You are required enter a name",
    helperText: "Image for Light Mode",
  },
  darkModeImageUrl: {
    label: "Dark Mode Image",
    regex: /\w+/,
    required: true,
    type: "media",
    errorMessage: "You are required enter a name",
    helperText: "Image for Dark Mode",
  },
};

export default ({ ad, query, mutation, dataKey }) => {
  const [formData, setFormData] = useState(ad ?? {});
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === "active" ? (!!value ? "YES" : "NO") : value,
    });

  const [mutate, { loading, error, data }] = useMutation(mutation, {
    variables: {
      input: {
        ...withoutKeys(formData, ["__typename"]),
      },
    },
    refetchQueries: [
      {
        query,
        variables: {
          active: "YES",
        },
      },
      {
        query,
        variables: {
          active: "NO",
        },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      [dataKey]: {
        ...formData,
        __typename: "Ad",
      },
    },
  });

  useEffect(() => {
    !!error && console.log(error);
    !!error && window.alert(error.message);
  }, [error]);

  return !!data ? (
    <Redirect push to={`/admin/ads`} />
  ) : (
    <Form
      submitting={loading}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={mutate}
      buttonLabel={"Submit"}
    />
  );
};
