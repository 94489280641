import gql from "graphql-tag";
import environment from "environment";
// import Ticket from "./Ticket"

const Event = {};

Event.fragments = {
  public: gql`
    fragment EventPublic on Event {
      id
      sportId
      groupId
      userId
      description
      active
      isActive
      featuredAt
      createdAt
      updatedAt
      bovadoGroupId
      bovadoEventId
      bovadoMarketId
      fanDuelMarketId
      fanDuelEventId
      rebateMessage
      rebateActive
      realtime
      aliases
      parlayAlias
      viewCount
      disableValues
      isLive
      endAt
      liveStartAt
      liveEndAt
      updateInterval
      lastOddsAt
      updateIntervalIsActive
      spread
      pointTotal
      winningOptionId
      eliminatedOptionIds
      sport {
        id
        name
        abbrev
      }
      group {
        id
        name
      }
    }
  `,
};

Event.mutations = {
  update: gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
      updateEvent(input: $input) {
        ...EventPublic
      }
    }
    ${Event.fragments.public}
  `,
  create: gql`
    mutation CreateEvent($input: CreateEventInput!) {
      createEvent(input: $input) {
        ...EventPublic
      }
    }
    ${Event.fragments.public}
  `,
};

Event.subscriptions = {
  onUpdate: gql`
    subscription OnUpdateEventPublic {
      onUpdateEventPublic {
        ...EventPublic
      }
    }
    ${Event.fragments.public}
  `,
  onCreate: gql`
    subscription OnCreateEvent {
      onCreateEvent {
        ...EventPublic
      }
    }
    ${Event.fragments.public}
  `,
};

Event.queries = {
  get: gql`
    query GetEvent($id: ID!) {
      getEvent(id: $id) {
        ...EventPublic
      }
    }
    ${Event.fragments.public}
  `,
  list: gql`
    query ListEvents(
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...EventPublic
        }
        nextToken
      }
    }
    ${Event.fragments.public}
  `,
  listBySportId: gql`
    query ListEventsBySportId(
      $sportId: ID!
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEventsBySportId(
        sportId: $sportId
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...EventPublic
        }
      }
    }
    ${Event.fragments.public}
  `,
  listByIsActive: gql`
    query ListEventsByIsActive(
      $isActive: String!
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEventsByIsActive(
        isActive: $isActive
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        nextToken
        items {
          ...EventPublic
        }
      }
    }
    ${Event.fragments.public}
  `,
  listBySportIdAndIsActive: gql`
    query ListEventsBySportIdAndIsActive(
      $sportId: ID!
      $isActive: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEventsBySportIdAndIsActive(
        sportId: $sportId
        isActive: $isActive
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...EventPublic
        }
      }
    }
    ${Event.fragments.public}
  `,
  listBySportIdAndIsActiveCreatedAt: gql`
    query ListEventsBySportIdAndIsActiveCreatedAt(
      $sportId: ID!
      $isActiveCreatedAt: ModelEventBySportIdAndIsActiveCreatedAtCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEventsBySportIdAndIsActiveCreatedAt(
        sportId: $sportId
        isActiveCreatedAt: $isActiveCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...EventPublic
        }
      }
    }
    ${Event.fragments.public}
  `,
};

export default Event;
