import { useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import TicketSettledListView from "./TicketSettledListView"
import { ticketSettledListItemQuery } from "./TicketSettledListQueries";

const possibleResults = [
  "WIN",
  "PUSH",
]

const TicketSettledListContainer = () => {
  const [ result, setResult ] = useState("WIN");
  const { error, refetch, fetchMore, data: { listTicketsByIsSettledAndResult: { nextToken, items: tickets = [] } = {} } = {} } = useQuery(ticketSettledListItemQuery, {
    variables: {
      limit: 20,
      isSettled: "false",
      result: {
        eq: result
      }
    }
  });

  // const entry = useQuery(ticketSettledListItemQuery, {
  //   variables: {
  //     limit: 20,
  //     isSettled: "false",
  //     result: {
  //       eq: result
  //     }
  //   }
  // });

  // console.log("entry", entry)

  const handleResultChange = useCallback(result =>
    setResult(result)
  , [])

  !!error && console.error(error);

  useEffect(() => {
    !!result && setTimeout(refetch, 100);
  }, [result, refetch])

  return <>
      <TicketSettledListView result={result} onResultChange={handleResultChange} possibleResults={possibleResults} tickets={tickets} />
      {
        !!nextToken && <Button 
          color="secondary" 
          variant="contained" 
          onClick={() => fetchMore({ variables: { nextToken }})} 
        >Load More</Button>
      }
    </>
};

export default TicketSettledListContainer;