import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { withRouter, Redirect } from "react-router-dom";
import { Cache } from "aws-amplify";

class SignOut extends Component {
  state = {
    signedOut: false,
  };

  componentDidMount() {
    Promise.all([
      Cache.removeItem("jwtToken"),
      Auth.signOut(),
      localStorage.clear(),
    ]).then(() => this.setState({ signedOut: true }));
  }

  render() {
    return this.state.signedOut ? <Redirect to="/" /> : null;
  }
}

export default withRouter(SignOut);
