import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    fontSize: theme.typography.h6.fontSize,
    textTransform: "uppercase",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(4, 0, 2, 0),
    "& .highlight": {
      color: theme.palette.text.highlight,
    },
    "& .secondary": {
      textTransform: "none",
    },
  },
  fullSectionHeader: {
    margin: 0,
  },
}));

const SectionHeader = ({ children, fullWidth = false }) => {
  const classes = useStyles();
  return (
    <Typography
      className={classNames(classes.sectionHeader, {
        [classes.fullSectionHeader]: fullWidth,
      })}
      variant={"h3"}
    >
      {children}
    </Typography>
  );
};

export default SectionHeader;
