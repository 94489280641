import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import Rating from "../../Rating";
import BuyButton from "./BuyButton";
import BidButton from "../../Bid/Components/CreateButton";
import { CardActions } from "@material-ui/core";
import moment from "moment";
import { useQuery } from "@apollo/client";
import Ticket from "shared/api/Ticket";

const useStyles = makeStyles(theme => ({
  listing: {
    borderRadius: 0,
    [theme.breakpoints.up("lg")]: {
      borderBottom: `1px solid ${theme.palette.background.default}`,
      marginBottom: theme.spacing(4),
    },
    "& .content": {
      display: "flex",
    },
    "& .header": {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0.5, 1),
    },
    "& .featured": {
      color: theme.palette.common.white,
      background: theme.palette.text.primary,
      padding: theme.spacing(0.5),
      margin: theme.spacing(-1),
      marginRight: theme.spacing(2),
    },
    "& .titleRow": {
      "& a": {
        textTransform: "uppercase",
      },
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-start",
        display: "flex",
      },
    },
    "& .actionRow": {
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(2),
      },
    },
  },
}));

const Item = ({
  adminView,
  history,
  ticket,
  listing,
  currentUser,
  bids,
  view,
  HeaderEndAdornment,
  ContentStartAdornment,
  ContentEndAdornment,
  Actions,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.listing} elevation={0}>
      <CardHeader
        className={"header"}
        disableTypography={true}
        title={
          <Grid container spacing={0} justify={"space-around"}>
            <Grid
              item
              xs={6}
              container
              justify="flex-start"
              alignItems="center"
              style={{ position: "relative" }}
            >
              {!!listing && !!listing.featuredAt && (
                <Typography className={"featured"} variant={"subtitle1"}>
                  Featured
                </Typography>
              )}
              {!!listing && !!listing.recentlySold && (
                <Typography className={"featured"} variant={"subtitle1"}>
                  Sold
                </Typography>
              )}
              <Typography variant={"subtitle1"}>
                {ticket.sport.abbrev}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end" alignItems="center">
              <Typography component={"p"} align={"right"} variant={"subtitle1"}>
                {ticket.betType}
              </Typography>
              {!!HeaderEndAdornment && HeaderEndAdornment}
            </Grid>
          </Grid>
        }
      />
      <CardContent className={"content"}>
        {!!ContentStartAdornment && ContentStartAdornment}
        <Grid container spacing={0}>
          <Grid className={"titleRow"} item xs={12}>
            <Typography variant={"h6"} component={"h3"}>
              {!listing ? (
                view === "results" ? (
                  <Link to={`/admin/tickets/${ticket.id}/edit`}>
                    {ticket.subject}
                  </Link>
                ) : (
                  <Link to={`/tickets/${ticket.id}`}>{ticket.subject}</Link>
                )
              ) : (
                <Link to={`/tickets/${ticket.id}/listings/${listing.id}`}>
                  {ticket.subject}
                </Link>
              )}
              &nbsp;&nbsp;
            </Typography>
            <Typography variant={"h6"} component={"h3"}>
              {ticket.eventDescription}
              {!!currentUser?.groups?.includes?.("Admins") && (
                <small>&nbsp;{ticket.views ?? 0} Views</small>
              )}
            </Typography>
          </Grid>
          <Grid container spacing={0} item xs={12}>
            <Grid item xs={!!listing && !!listing.recentlySold ? 4 : 3} lg={2}>
              <Typography variant={"subtitle2"}>Odds</Typography>
              <Typography variant={"body2"}>
                {!!listing ? listing.newOdds : ticket.odds}
              </Typography>
            </Grid>
            {!!listing && !!listing.recentlySold && (
              <Grid
                item
                xs={!!listing && !!listing.recentlySold ? 4 : 3}
                lg={2}
              >
                <Typography variant={"subtitle2"}>Sold For</Typography>
                <NumberFormat
                  renderText={text => (
                    <Typography
                      variant={"body2"}
                      style={text.length > 10 ? { fontSize: "0.775rem" } : null}
                    >
                      {text}
                    </Typography>
                  )}
                  fixedDecimalScale
                  decimalScale={2}
                  value={parseFloat(listing.salesPrice) / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Grid>
            )}
            <Grid item xs={!!listing && !!listing.recentlySold ? 4 : 3} lg={2}>
              <Typography variant={"subtitle2"}>Collect</Typography>
              <NumberFormat
                renderText={text => (
                  <Typography
                    variant={"body2"}
                    style={text.length > 10 ? { fontSize: "0.775rem" } : null}
                  >
                    {text}
                  </Typography>
                )}
                fixedDecimalScale
                decimalScale={2}
                value={parseFloat(ticket.collectAmount) / 100.0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </Grid>
            {!!listing && !listing?.recentlySold && (
              <Grid item xs={3} lg={2}>
                <Typography variant={"subtitle2"}>
                  {adminView ? "Asking" : "Price"}
                </Typography>
                <Typography variant={"body2"}>
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={parseFloat(listing.askingPrice) / 100.0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Typography>
              </Grid>
            )}
            {!listing?.recentlySold && (
              <Grid item xs={3} lg={2}>
                <Typography variant={"subtitle2"}>Min/High&nbsp;Bid</Typography>
                <Typography variant={"body2"}>
                  {!bids[0] ? (
                    !!listing?.minimumBidPrice ? (
                      <NumberFormat
                        fixedDecimalScale
                        decimalScale={2}
                        value={parseFloat(listing?.minimumBidPrice) / 100.0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    ) : (
                      <>N/A</>
                    )
                  ) : (
                    <NumberFormat
                      fixedDecimalScale
                      decimalScale={2}
                      value={
                        parseFloat(
                          bids
                            .slice()
                            .sort((a, b) => (a.offer > b.offer ? -1 : 1))[0]
                            .offer
                        ) / 100.0
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  )}
                </Typography>
              </Grid>
            )}
            {view === "public" && !!listing && !listing?.recentlySold && (
              <Grid
                className={"actionRow"}
                container
                justify="space-between"
                alignItems="center"
                item
                xs={12}
                lg={4}
              >
                {!!adminView && (
                  <Grid item xs={8}>
                    <Typography variant={"subtitle2"}>Sale</Typography>
                    <Typography variant={"body2"}>
                      {!!listing.salesPrice ? (
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={parseFloat(listing.salesPrice) / 100.0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      ) : (
                        "N/A"
                      )}
                    </Typography>
                  </Grid>
                )}
                <div>
                  <Rating rating={listing.ratingOverride || 1} />
                </div>
                {!adminView && (
                  <>
                    <BuyButton
                      listing={listing}
                      currentUser={currentUser}
                      onSuccess={listing => history.push(`/account/tickets`)}
                      onClick={() =>
                        history.push(
                          `/tickets/${listing.listingTicketId}/listings/${listing.id}`
                        )
                      }
                    />
                    <BidButton
                      listing={listing}
                      currentUser={currentUser}
                      highAmount={
                        !!bids[0]
                          ? bids
                              .slice()
                              .sort((a, b) => (a.offer > b.offer ? -1 : 1))[0]
                              .offer
                          : 0
                      }
                      onSuccess={bid =>
                        history.push(
                          `/tickets/${listing.listingTicketId}/listings/${bid.bidListingId}`
                        )
                      }
                      onClick={() =>
                        history.push(
                          `/tickets/${listing.listingTicketId}/listings/${listing.id}`
                        )
                      }
                    />
                  </>
                )}
              </Grid>
            )}
          </Grid>
          {!!listing.expiresAt && (
            <Grid container spacing={0} item xs={12}>
              <Grid item xs={12}>
                <Typography variant={"body2"}>
                  {`${
                    listing.expiresAt >= new Date().toISOString()
                      ? "Expires"
                      : "Expired"
                  } ${moment(listing.expiresAt).fromNow()}`}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!!ContentEndAdornment && ContentEndAdornment}
      </CardContent>
      {!!Actions && <CardActions>{Actions}</CardActions>}
    </Card>
  );
};

export default Item;
