import React, { useState } from "react";
import EventList from "../../../../Components/Event/List";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Tabs from "../../../../Components/Admin/Components/Tabs";
import InactiveSwitch from "Components/InactiveSwitch";

export default ({ history, match: { params } }) => {
  const [showInactive, setShowInactive] = useState(false);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/sports"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Grid container justify={"flex-end"}>
            <Grid item xs={12} md={3}>
              <InactiveSwitch
                showInactive={showInactive}
                onChange={checked => setShowInactive(checked)}
              />
            </Grid>
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={3}>
              <Button
                component={Link}
                to={`/admin/sports/${params.sportId}/events/new`}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Add Event
              </Button>
            </Grid>
          </Grid>
          <EventList showInactive={showInactive} sportId={params.sportId} />
        </ContentCell>
      </PageContainer>
    </>
  );
};
