import React, { useEffect } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { App } from "gunner-react/web";

import Router from "./Routes";

import { I18n } from "aws-amplify";

import awsmobile from "./aws-exports";

import useFindUser from "react-shared/Hooks/useFindUser";
import { EventsProvider } from "Contexts/Events";
import { OptionsProvider } from "Contexts/Options";
// import useDisplayNamePrompt from 'Hooks/useDisplayNamePrompt';
// import useCreateUser from "react-shared/Hooks/useCreateUser"

// const DisplayNameModal = () => {
//   const DisplayNamePrompt = useDisplayNamePrompt();

//   return <DisplayNamePrompt />
// }

const authScreenLabels = {
  en: {
    Username: "Email",
    "Enter your username": "Enter your email",
    "Sign Up": "Create new account",
    "Sign Up Account": "Create a new account",
    "Confirm Sign Up":
      "Confirm Sign Up by entering the code that was sent to your email address",
  },
};

I18n.setLanguage("en");
I18n.putVocabularies(authScreenLabels);

const backgroundDefaultColor = "#f2f2f2";
const spacing = 8;
const white = "#fff";
const primary = "#1b94e9";
const secondary = "#50b44d";
const textPrimary = "#666666";
const fontWeightBold = 700;
const fontWeightMedium = 500;
const buttonFontSize = "1.125rem";
const buttonLargeFontSize = "1.5rem";

export default () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = createMuiTheme({
    overrides: {
      MuiTypography: {
        h5: {
          fontSize: "1.2rem",
        },
      },
      MuiInputLabel: {
        shrink: {
          marginLeft: spacing * -2,
        },
        outlined: {},
      },
      MuiOutlinedInput: {
        root: {
          marginTop: spacing * 2,
        },
        notchedOutline: {
          top: "0 !important",
        },
        multiline: {
          background: backgroundDefaultColor,
        },
        input: {
          background: "#f0f0f0", //backgroundDefaultColor
        },
      },

      MuiFilledInput: {
        root: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
      // MuiInputBase: {
      //   input: {
      //     border: '1px solid black'
      //   }
      // },
      MuiTableCell: {
        // sizeSmall: {
        //   padding: "4px 16px 4px 8px"
        // },
        // head: {
        //   backgroundColor: backgroundDefaultColor,
        //   fontWeight: 700,
        //   textTransform: 'uppercase'
        // }
      },
      MuiFormHelperText: {
        root: {
          // fontSize: "1rem"
          fontSize: "0.875rem",
        },
        contained: {
          marginLeft: 0,
        },
      },
      MuiFormLabel: {
        root: {
          textTransform: "uppercase",
          paddingBottom: spacing,
          fontWeight: fontWeightBold,
          fontSize: buttonFontSize,
        },
      },
      MuiButton: {
        text: {
          padding: 0,
        },
        root: {
          paddingLeft: spacing * 3,
          paddingRight: spacing * 3,
          paddingTop: 0,
          paddingBottom: 0,
          //   fontSize: 18,
          //   height: "2em"
        },
        contained: {
          boxShadow: "none",
        },
        containedSecondary: {
          color: white,
        },
        // sizeSmall: {
        //   height: "auto"
        // },
        sizeLarge: {
          fontSize: buttonLargeFontSize,
          padding: "0 20px",
        },
        // outlined: {
        //   padding: "0 20px"
        // }
      },
    },
    shape: {
      // borderRadius: 8
    },
    typography: {
      useNextVariants: true,
      button: {
        fontSize: buttonFontSize,
      },
      subtitle1: {
        // color: '#1b94e9',
        textTransform: "uppercase",
        fontWeight: fontWeightMedium,
      },
      subtitle2: {
        textTransform: "uppercase",
        fontWeight: fontWeightBold,
        fontSize: "0.70rem",
      },
      // h2: {
      //   textTransform: "uppercase",
      //   fontSize: "1.25rem",
      //   fontWeight: 700
      // },
      // h3: {
      //   fontSize: "1.65rem",
      //   color: "#00a651",
      //   fontWeight: 700
      // }
      h4: {
        fontSize: "1.175rem",
      },
      h6: {
        fontSize: "1.1111111111rem",
      },
    },
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      background: {
        default: backgroundDefaultColor,
      },
      text: {
        primary: textPrimary,
      },
    },
  });

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <App
      theme={theme}
      useFindUser={useFindUser}
      // useCreateUser={useCreateUser}
      sentryConfig={
        !(process.env.REACT_APP_sentry_url ?? "").replace("<sentry-url>", "")
          ? null
          : {
              dsn: (process.env.REACT_APP_sentry_url ?? "").replace(
                "<sentry-url>",
                ""
              ),
              release: `${process.env.REACT_APP_STAGE}@${process.env.REACT_APP_VERSION}`,
            }
      }
      amplifyConfig={awsmobile}
      ga={process.env.REACT_APP_GA}
    >
      <EventsProvider>
        <OptionsProvider>
          <>
            <Router />
            {/* <DisplayNameModal /> */}
          </>
        </OptionsProvider>
      </EventsProvider>
    </App>
  );
};
