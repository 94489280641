import React, { useState, useEffect, useContext } from "react";
import CognitoIdentityServiceProvider from "aws-sdk/clients/cognitoidentityserviceprovider";
import { Auth } from "aws-amplify";
// import { Redirect } from 'react-router-dom';
import { CurrentUserContext } from "gunner-react";
import Form from "../../Form";
import { withoutKeys } from "gunner-react";
import awsmobile from "../../../aws-exports";
import Toast from "../../Toast";
import User from "shared/api/User";
import { useApolloClient, useQuery } from "@apollo/client";
import { STATES } from "shared/Config";
import CheckIcon from "mdi-material-ui/Check";
import CloseIcon from "mdi-material-ui/Close";
import { Box, Typography } from "@material-ui/core";

const canadianProvinces = [
  ["BC", "British Columbia"],
  ["ON", "Ontario"],
  ["NL", "Newfoundland and Labrador"],
  ["NS", "Nova Scotia"],
  ["PE", "Prince Edward Island"],
  ["NB", "New Brunswick"],
  ["QC", "Quebec"],
  ["MB", "Manitoba"],
  ["SK", "Saskatchewan"],
  ["AB", "Alberta"],
  ["NT", "Northwest Territories"],
  ["NU", "Nunavut"],
  ["YT", "Yukon Territory"],
];

const cognitoClient = () =>
  Auth.currentCredentials().then(credentials =>
    Promise.resolve(
      new CognitoIdentityServiceProvider({
        apiVersion: "2016-04-18",
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      })
    )
  );

const changePassword = ({ currentUser, user, password, currentPassword }) =>
  currentUser.groups.includes("Admins")
    ? cognitoClient().then(client =>
        client
          .adminSetUserPassword({
            Password: password,
            UserPoolId: awsmobile.aws_user_pools_id,
            Username: user.sub,
            Permanent: true,
          })
          .promise()
      )
    : Auth.currentAuthenticatedUser({ bypassCache: true }).then(cognitoUser =>
        Auth.changePassword(cognitoUser, currentPassword, password)
      );

const updateSelf = attributes =>
  Auth.currentAuthenticatedUser({ bypassCache: true }).then(cognitoUser =>
    Auth.updateUserAttributes(
      cognitoUser,
      withoutKeys(attributes || {}, [
        "email",
        "admin",
        "disabled",
        "password",
        "sub",
        "current_password",
        "formatted_birthday",
      ])
    )
  );

const updateOther = (userId, attributes) =>
  cognitoClient().then(client =>
    client
      .adminUpdateUserAttributes({
        UserPoolId: awsmobile.aws_user_pools_id,
        Username: userId,
        UserAttributes: Object.entries(
          withoutKeys(attributes || {}, [
            "current_password",
            "email",
            "password",
            "admin",
            "disabled",
            "sub",
            "formatted_birthday",
          ])
        ).map(([key, value]) => ({ Name: key, Value: value })),
      })
      .promise()
  );

const removeAdmin = userId =>
  cognitoClient().then(client =>
    client
      .adminRemoveUserFromGroup({
        GroupName: "Admins",
        UserPoolId: awsmobile.aws_user_pools_id,
        Username: userId,
      })
      .promise()
  );

const addAdmin = userId =>
  cognitoClient().then(client =>
    client
      .adminAddUserToGroup({
        GroupName: "Admins",
        UserPoolId: awsmobile.aws_user_pools_id,
        Username: userId,
      })
      .promise()
  );

const adminFields = {
  sub: {
    label: "ID",
    regex: /\w+/,
    required: true,
    type: "text",
    errorMessage: "A user must have a unique id",
    disabled: true,
  },
  formatted_birthday: {
    label: "Birth Date",
    // eslint-disable-next-line
    regex: /\d\d\/\d\d\/\d\d\d\d/,
    type: "text",
    required: true,
    helperText: "MM/DDD/YYYY",
  },
  admin: {
    label: "Admin",
    regex: /\w/,
    type: "checkbox",
  },
  disabled: {
    label: "Disable",
    regex: /\w/,
    type: "checkbox",
  },
};

const CognitoFormContainer = ({ dynamoUserId, user }) => {
  const client = useApolloClient();
  const currentUser = useContext(CurrentUserContext);
  const birthdatePieces = user?.birthdate?.split?.("-");

  const { data: { getUser } = {} } = useQuery(User.queries.get, {
    skip: !dynamoUserId,
    variables: { id: dynamoUserId },
    pollInterval: 4000,
  });

  const formatPhoneNumber = phone =>
    `+${phone[0]} (${phone[1]}${phone[2]}${phone[3]}) ${phone[4]}${phone[5]}${
      phone[6]
    }-${phone.substring(7)}`;

  const [formData, setFormData] = useState({
    ...(user || {}),
    "custom:mobile": formatPhoneNumber(user?.["custom:mobile"]),
    formatted_birthday: !birthdatePieces[1]
      ? user?.birthdate
      : `${birthdatePieces[1]}/${birthdatePieces[2]}/${birthdatePieces[0]}`,
    "custom:address2":
      (user?.["custom:address2"] ?? "").toString().trim() ?? "",
  });

  console.log("formData", formData);

  const formattedBirthdayPeices = formData?.formatted_birthday?.split?.("/");
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: value,
    });

  const {
    loading: userLoading,
    error: userError,
    data: { listUsersByDisplayName: { items: users } = {} } = {},
  } = useQuery(User.queries.listByDisplayName, {
    skip: formData.displayName?.length < 4 || formData.displayName?.length > 16,
    variables: {
      displayName: formData.displayName,
      limit: 1,
    },
  });

  const usernameTaken = !!users?.length && users[0].id !== currentUser?.id;

  const fields = {
    email: {
      label: "Email",
      regex: /\w+/,
      required: true,
      type: "email",
      disabled: true,
    },
    displayName: {
      label: "Display Name",
      regex: /^\w{4,16}$/,
      required: false,
      value: item => item || getUser?.displayName || currentUser.displayName,
      extra:
        !!userLoading ||
        formData.displayName?.length < 4 ||
        formData.displayName?.length > 16 ? null : !!usernameTaken ? (
          <Box display="flex">
            <CloseIcon style={{ color: "red" }} />{" "}
            <Typography style={{ color: "red" }}>
              That name is taken.
            </Typography>
          </Box>
        ) : !!formData?.displayName ? (
          <Box display="flex">
            <CheckIcon style={{ color: "green" }} />{" "}
            <Typography style={{ color: "green" }}>
              That name is available.
            </Typography>
          </Box>
        ) : null,
    },
    profilePicUri: {
      label: "Photo",
      regex: /\w+/,
      required: false,
      value: item =>
        item ||
        getUser?.profilePicUri ||
        (dynamoUserId === currentUser?.id ? currentUser.profilePicUri : ""),
      type: "media",
      errorMessage: "You are required to upload a photo",
    },
    "custom:mobile": {
      label: "Mobile Number",
      regex: /^\+\d\d?\d? \(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      type: "phone",
      required: true,
    },
    given_name: {
      label: "First Name",
      regex: /\w+/,
      type: "text",
      required: true,
    },
    family_name: {
      label: "Last Name",
      regex: /\w+/,
      type: "text",
      required: true,
    },
    address: {
      label: "Address",
      regex: /\w+/,
      type: "text",
      required: true,
    },
    "custom:address2": {
      label: "Address 2",
      regex: /\w+/,
      type: "text",
    },
    "custom:city": {
      label: "City",
      regex: /\w+/,
      type: "text",
      required: true,
    },
    "custom:state": {
      label: formData["custom:country"] === "CA" ? "Province" : "State",
      regex: /^\w\w$/,
      type: "select",
      required: true,
      value: value => (value ?? "").toUpperCase(),
      options: formData["custom:country"] === "CA" ? canadianProvinces.map(state => ({ value: state[0], label: state[1] })) : STATES.map(state => ({ value: state[1], label: state[0] })),
    },
    "custom:country": {
      label: "Country",
      regex: /^\w\w$/,
      type: "select",
      required: true,
      value: value => (value ?? "").toUpperCase(),
      options: [["United States", "US"], ["Canada", "CA"]].map(state => ({ value: state[1], label: state[0] })),
    },
    "custom:zip": {
      label: formData["custom:country"] === "CA" ? "Postal Code" : "Zip",
      regex: /\d+/,
      type: "text",
      regex: formData["custom:country"] === "CA" ? /^.{6}$/ : /^.{5}$/,
      required: true,
    },
    password: {
      label: "New Password",
      regex: /^.{8,25}$/,
      type: "password",
      required: false,
      helperText:
        "Leave blank unless you want to change. Must be 8-25 characters with one capital letter, one lower case letter, one digit and one special character.",
    },
    current_password: {
      label: "Current Password",
      regex: /^.{8,25}$/,
      type: "password",
      required: false,
      helperText: "Leave blank unless you want to change.",
    },
  };

  useEffect(() => {
    !!doSubmit &&
      (currentUser.email === user.email
        ? updateSelf(withoutKeys(formData, ["displayName", "profilePicUri"]))
        : updateOther(user.sub, {
            ...withoutKeys(formData, ["displayName", "profilePicUri"]),
            formatted_birthday: null,
            birthdate: `${formattedBirthdayPeices[2]}-${formattedBirthdayPeices[1]}-${formattedBirthdayPeices[0]}`,
          })
      )
        .then(() =>
          client.mutate({
            mutation: User.mutations.update,
            variables: {
              input: {
                id: dynamoUserId,
                updatedAt: new Date().toISOString(),
                displayName: formData.displayName,
                profilePicUri: formData.profilePicUri,
              },
            },
          })
        )
        .then(() =>
          currentUser.groups.includes("Admins")
            ? !!formData.admin
              ? addAdmin(user.sub)
              : removeAdmin(user.sub)
            : Promise.resolve(null)
        )
        .then(() =>
          currentUser.groups.includes("Admins")
            ? client
                .mutate({
                  mutation: User.mutations.enable,
                  variables: {
                    input: {
                      id: dynamoUserId,
                      enabled: !formData.disabled,
                    },
                  },
                })
                .catch(console.log)
            : null
        )
        .then(() =>
          !!formData.password
            ? changePassword({
                currentUser,
                user,
                password: formData.password,
                currentPassword: formData.current_password,
              })
            : Promise.resolve(null)
        )
        .then(() => [setDoSubmit(false), setSubmitSuccess(true)])
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [doSubmit, user.sub, currentUser.id, user.email]);

  return (
    <>
      {!!submitSuccess && (
        <Toast
          open={!!submitSuccess}
          onClose={() => setSubmitSuccess(false)}
          message="Changes Saved!"
        />
      )}
      <Form
        submitting={false}
        fields={
          currentUser.groups.includes("Admins")
            ? withoutKeys({ ...fields, ...adminFields }, ["current_password"])
            : fields
        }
        onChange={handleChange}
        data={formData}
        onSubmit={() => setDoSubmit(true)}
        buttonLabel={"Save"}
        invalid={usernameTaken}
      />
    </>
  );
};

export default CognitoFormContainer;
