import React from "react";
import PageContainer from "Components/Layout/PageContainer";
import ContentCell from "Components/Layout/ContentCell";
import Tabs from "Components/Admin/Components/Tabs";
import { Box } from "@material-ui/core";
import ProviderEdit from "Components/Provider/Edit";

export default ({ history, match: { params } }) => {
  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/exchange/providers"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Box mb={2}>
            <ProviderEdit id={params.id} />
          </Box>
        </ContentCell>
      </PageContainer>
    </>
  );
};
