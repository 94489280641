import { useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import Event from "shared/api/Event";

const EventsContext = React.createContext();

const EventsProvider = ({ children }) => {
  // const [events, setEvents] = useState([]);
  // const [token, setToken] = useState(null);

  // const { error, data: { listEvents: { nextToken, items } = {} } = {} } =
  //   useQuery(Event.queries.list, {
  //     variables: {
  //       limit: 100,
  //       nextToken: token,
  //       filter: {
  //         active: {
  //           eq: true,
  //         },
  //       },
  //     },
  //   });

  // useEffect(() => {
  //   !!nextToken && setToken(nextToken);
  // }, [nextToken]);

  // useEffect(() => {
  //   setEvents(events => [...events, ...(items ?? [])]);
  // }, [items]);

  return (
    <EventsContext.Provider value={[]}>{children}</EventsContext.Provider>
  );
};

export { EventsContext, EventsProvider };
