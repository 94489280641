import React from "react";

import NumberFormat from "react-number-format";

import { Link } from "react-router-dom";

import Rating from "../../Rating";

import DataTable from "../../DataTable";
import ListingEditButton from "../EditButton";
import UserLink from "../../User/Link";
import { Box } from "@material-ui/core";

const dataFields = {
  Rating: {
    value: item => <Rating rating={item.ratingOverride || 1} />,
    nowrap: true,
    sort: order => (a, b) =>
      a.ratingOverride > b.ratingOverride
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Sport: {
    value: item => item?.ticket?.sport?.abbrev,
    sort: order => (a, b) =>
      a.ticket?.sport?.abbrev > b.ticket?.sport?.abbrev
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  "Bet Type": {
    value: item => item.ticket?.betType,
    sort: order => (a, b) =>
      a.ticket.betType > b.ticket?.betType
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Event: {
    value: item => (
      <Box width="100px">
        <Link to={`/tickets/${item.ticket.id}`}>
          {item.ticket?.eventDescription}
        </Link>
      </Box>
    ),
    sort: null,
    searchValue: item => item.ticket?.eventDescription,
  },
  Subject: {
    value: item => (
      <Box width="100px">
        <Link to={`/tickets/${item.ticket.id}`}>{item.ticket.subject}</Link>
      </Box>
    ),
    sort: null,
    searchValue: item => item.ticket.subject,
  },
  Status: {
    value: item => item.status,
    sort: order => (a, b) =>
      a.status > b.status
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    searchValue: item => item.status,
  },
  Odds: {
    value: item => item.newOdds,
    sort: order => (a, b) =>
      a.newOddsCalculated > b.newOddsCalculated
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Price: {
    value: item => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        value={parseFloat(item.askingPrice) / 100.0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.askingPrice > b.askingPrice
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  Collects: {
    value: item => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        value={parseFloat(item.ticket.collectAmount) / 100.0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.ticket.collectAmount > b.ticket.collectAmount
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  "Original Odds": {
    value: item => item.ticket.odds,
    sort: order => (a, b) =>
      a.ticket.calculatedOdds > b.ticket.calculatedOdds
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Buyer: {
    sort: null,
    value: item =>
      !item.listingBuyerId ? null : <UserLink id={item.listingSellerId} />,
  },
  Seller: {
    sort: null,
    value: item => <UserLink id={item.listingSellerId} />,
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => <ListingEditButton listingId={item.id} />,
  },
};

const ListingListView = ({ listings }) => (
  <DataTable fields={dataFields} items={listings} />
);

export default ListingListView;
