import React, { useContext, useState, useEffect, useCallback } from "react";
import UserVerificationModal from "Components/User/Verification/Modal";
import AdCell from "Components/Layout/AdCell";
import ContentCell from "Components/Layout/ContentCell";
import PageHeader from "Components/Layout/PageHeader";
import PageContainer from "Components/Layout/PageContainer";
import BackLink from "Components/Layout/BackLink";
import NewCustomerForm from "Components/Deposit/Components/NewCustomerForm";
import Modal from "Components/Layout/Modal";
import TicketNew from "Components/Ticket/New";
import { useQuery, useMutation } from "@apollo/client";
import User from "shared/api/User";
import Bluesnap from "shared/api/Bluesnap";
import { CurrentUserContext } from "gunner-react";
import { Typography, Grid } from "@material-ui/core";
import getGraphQLError from "shared/Util/getGraphQLError";

export default ({ history, location: { search = "" } = {} }) => {
  const currentUser = useContext(CurrentUserContext);

  const { data: { getUser } = {} } = useQuery(User.queries.get, {
    variables: {
      id: currentUser.id,
      withPrivate: true,
    },
  });

  const initialTicketData = {
    odds: "",
    oddsCalculated: 0.0,
    photoUrl: null,
    betType: null,
    eventDescription: "",
    subject: "",
    winAmount: 0,
    betAmount: 0,
    collectAmount: 0,
    additionalInfo: "",
    ticketId: "",
    bookName: "",
    isMobile: false,
    creatorId: currentUser.id,
    ticketUserId: currentUser.id,
    ticketOriginalOwnerId: currentUser.id,
    ticketSportId: null,
    ticketLocaleId: null,
    ticketSportsbookId: null,
    result: "UNDECIDED", //TODO make mutation to prevent hack
  };

  const initialListingData = {
    askingPrice: 0,
    newOdds: "",
    newOddsCalculated: 0.0,
    listingTicketId: null,
    listingSellerId: currentUser.id,
    minimumBidPrice: 0,
    postedFrom: "WEB",
  };

  const [ticketCreated, setTicketCreated] = useState(false);
  const [ticketData, setTicketData] = useState(initialTicketData);
  const [listingData, setListingData] = useState(initialListingData);
  const [cardHolderName, setCardHolderName] = useState(
    `${currentUser.attributes.given_name} ${currentUser.attributes.family_name}`
  );
  const [zip, setZip] = useState(`${currentUser.attributes["custom:zip"]}`);
  const [bluesnapToken, setBluesnapToken] = useState(null);
  const [submittingCreditCard, setSubmittingCreditCard] = useState(null);

  const [_createBluesnapPaymentToken] = useMutation(
    Bluesnap.mutations.createPaymentToken
  );
  const [_addCreditCard] = useMutation(Bluesnap.mutations.createTransaction, {
    variables: {
      input: {
        amount: 1,
        storeCard: true,
        currency: "USD",
        cardTransactionType: "AUTH_ONLY",

        cardHolderInfo: {
          firstName: !!cardHolderName
            ? cardHolderName.split(" ")[0]
            : undefined,
          lastName: !!cardHolderName
            ? cardHolderName.split(" ").slice(1).join(" ")
            : undefined,
          zip: zip || undefined,
        },
        userId: currentUser.id,
        pfToken: bluesnapToken,
        vaultedShopperId: getUser?.bluesnapVaultedShopperId ?? undefined,
        creditCard: undefined,
      },
    },
    refetchQueries: [
      {
        query: User.queries.get,
        variables: {
          id: currentUser.id,
          withPrivate: true,
        },
      },
      {
        query: User.queries.listByEmail,
        variables: {
          email: currentUser.email,
          withPrivate: true,
        },
      },
    ],
  });

  const handleCreditCardSubmit = () =>
    Promise.resolve(setSubmittingCreditCard(true)).then(() =>
      _addCreditCard()
        .then(() => [setSubmittingCreditCard(false)])
        .catch(error => [
          console.log("error1!!!!", JSON.stringify(getGraphQLError(error))),
          getGraphQLError(error).level === 0
            ? window.alert(
                "An error occured during processing. Please contact info@propswap.com for help with this problem."
              )
            : window.alert(
                "That card could not be validated. Please try again."
              ),
          setSubmittingCreditCard(false),
        ])
    );

  const handleTicketChange = useCallback(
    (key, value) =>
      typeof key === "string"
        ? setTicketData(ticketData => ({
            ...ticketData,
            [key]: value,
          }))
        : setTicketData(ticketData => ({
            ...ticketData,
            ...key,
          })),
    []
  );

  const handleListingChange = useCallback(
    (key, value) =>
      typeof key === "string"
        ? setListingData(listingData => ({
            ...listingData,
            [key]: value,
          }))
        : setListingData(listingData => ({
            ...listingData,
            ...key,
          })),
    []
  );

  const handleEventChange = useCallback(
    event => handleTicketChange("ticketEventId", event?.id),
    [handleTicketChange]
  );

  const handleOptionChange = useCallback(
    option =>
      console.log(option) || handleTicketChange("ticketOptionId", option?.id),
    [handleTicketChange]
  );

  const handleTicketCreated = useCallback(() => setTicketCreated(true), []);

  const resetForm = partial =>
    !!partial
      ? [
          setListingData({
            ...initialListingData,
          }),
          setTicketData({
            ...initialTicketData,
            isMobile: ticketData.isMobile,
            ticketLocaleId: ticketData.ticketLocaleId,
            ticketSportsbookId: ticketData.ticketSportsbookId,
            ticketSportId:
              ticketData.betType === "PARLAY" ? null : ticketData.ticketSportId,
            betType:
              ticketData.betType === "PARLAY" ? null : ticketData.betType,
            eventDescription:
              ticketData.betType === "PARLAY"
                ? null
                : ticketData.eventDescription,
            ticketEventId:
              ticketData.betType === "PARLAY" ? null : ticketData.ticketEventId,
          }),
          setTicketCreated(false),
        ]
      : [
          setListingData(initialListingData),
          setTicketData(initialTicketData),
          setTicketCreated(false),
        ];

  useEffect(() => {
    !bluesnapToken &&
      _createBluesnapPaymentToken()
        .then(({ data: { createBluesnapPaymentToken } }) =>
          setBluesnapToken(createBluesnapPaymentToken)
        )
        .catch(err => console.log(err));
  }, [bluesnapToken, _createBluesnapPaymentToken]);

  return (
    <>
      <UserVerificationModal
        show={
          currentUser.groups.includes("Admins") &&
          !!getUser &&
          !getUser?.verifiedAt
        }
        closeBehavior="back"
        //TODO: LIFT GATING
      />

      <Modal
        title="Ticket Created"
        body={`Congratulations! Your ticket has been successfully created.`}
        onClose={resetForm}
        opened={!!ticketCreated}
        secondaryButton={{
          text: "Create New",
          onClick: resetForm,
          ButtonProps: {
            fullWidth: true,
            size: "small",
            variant: "contained",
            color: "secondary",
          },
        }}
        saveButton={{
          text: "My Tickets",
          onClick: () => history.push("/account/tickets"),
          ButtonProps: {
            fullWidth: true,
            variant: "contained",
            color: "secondary",
            size: "small",
          },
        }}
        tertiaryButton={{
          text: "Create Similar",
          onClick: () => resetForm(true),
          ButtonProps: {
            fullWidth: true,
            variant: "contained",
            color: "secondary",
            size: "small",
          },
        }}
      />

      <Modal
        title="Add a Card"
        body={
          <>
            <Typography paragraph>
              In order to sell tickets on PropSwap, you must have a valid credit
              card on file. This is for insurance purposes only. You do not need
              to deposit any funds into your account in order to sell.
            </Typography>
            <NewCustomerForm
              zip={zip}
              submitting={submittingCreditCard}
              cardHolderName={cardHolderName}
              bluesnapToken={bluesnapToken}
              onCancel={null}
              onSubmit={handleCreditCardSubmit}
              setZip={setZip}
              setCardHolderName={setCardHolderName}
            />
          </>
        }
        onClose={() => null}
        opened={!!getUser && !getUser?.bluesnapVaultedShopperId}
        saveButton={null}
      />

      <PageContainer>
        <ContentCell>
          <BackLink url="/" />
          <PageHeader gutterBottom gutterTop>
            Sell Ticket
          </PageHeader>
          <Grid container spacing={0} justify="center">
            <Grid item xs={12} md={9}>
              <TicketNew
                ticketData={ticketData}
                listingData={listingData}
                currentUser={currentUser}
                onTicketChange={handleTicketChange}
                onEventChange={handleEventChange}
                onListingChange={handleListingChange}
                onOptionChange={handleOptionChange}
                onTicketCreated={handleTicketCreated}
              />
            </Grid>
          </Grid>
        </ContentCell>
        <AdCell>
          <img src={require("assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};
