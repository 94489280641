import React, { useState } from "react";

import { useQuery } from "@apollo/client";

import SimpleModal from "../SimpleModal";
import UserTransactionForm from "./TransactionForm";
import User from "shared/api/User";

const UserTransactionFormModal = ({ userId, modalOpen, onClose }) => {
  const { data: { getUser: user } = {} } = useQuery(User.queries.get, {
    variables: { id: userId },
  });
  const [saveModal, setSaveModal] = useState(false);
  const [isValid, setIsValid] = useState(false);

  return !user ? null : (
    <>
      <SimpleModal
        opened={modalOpen}
        title={`Add a Transaction for ${user.email}`}
        body={
          <UserTransactionForm
            doSave={saveModal}
            onError={message => [window.alert(message), setSaveModal(false)]}
            onSuccess={() => [onClose(), setSaveModal(false)]}
            user={user}
            onValidChange={valid => setIsValid(valid)}
          />
        }
        option2={{
          disabled: !isValid,
          text: "Submit",
          onClick: () => setSaveModal(true),
        }}
        option1={{ text: "Cancel", onClick: onClose }}
        saving={false}
        onClose={onClose}
      />
    </>
  );
};

export default UserTransactionFormModal;
