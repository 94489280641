import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import {
  Typography,
  CircularProgress,
  Grid,
  Button,
  Hidden,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import moment from "moment";

import Transaction from "shared/api/Transaction";
import Listing from "shared/api/Listing";
import formatOdds from "shared/Util/formatOdds";
import { commissionAdjust } from "shared/Config";

const useStyles = makeStyles(theme => ({
  transactionDetail: {
    minWidth: 350,
    minHeight: 350,
    margin: theme.spacing(-1, -3),
    "& header": {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 3),
      marginTop: theme.spacing(-2.5),
      "& h3": {
        color: "#666666",
        textTransform: "uppercase",
      },
      "& h4": {
        color: "#666666",
        textTransform: "uppercase",
        fontWeight: theme.typography.fontWeightLight,
        fontSize: "1rem",
      },
    },
    "& section": {
      padding: theme.spacing(2, 3),
      "& h5": {
        color: theme.palette.text.highlight,
        textTransform: "uppercase",
      },
      "& .description": {
        marginBottom: theme.spacing(2),
      },
    },
    "& footer": {
      padding: theme.spacing(2, 3),
      borderTop: `2px solid #666666`,
      "& button": {
        marginTop: theme.spacing(3),
      },
    },
  },
}));

const Detail = ({ match: { params }, onClose }) => {
  const classes = useStyles();
  const { data: { getTransaction: transaction } = {} } = useQuery(
    Transaction.queries.get,
    { variables: { id: params.id } }
  );
  const { data: { getListing: listing } = {} } = useQuery(Listing.queries.get, {
    variables: { id: !!transaction ? transaction.transactionId || "<>" : "<>" },
  });
  const highBid = (((listing || {}).bids || {}).items || []).filter(
    bid => bid.status !== "CANCELLED"
  )[0];

  return !transaction || !listing ? (
    <CircularProgress color={"secondary"} />
  ) : (
    <div className={classes.transactionDetail}>
      <Grid component={"header"} container spacing={0}>
        <Grid item xs={6} container alignItems={"center"}>
          <Typography variant={"h3"}>
            {transaction.type === "BUY" ? "Purchased" : "Sold"}
          </Typography>
        </Grid>
        <Grid item xs={6} container alignItems={"center"} justify={"center"}>
          <Typography variant={"h4"} align={"right"}>
            {moment(transaction.createdAt).format("MM/DD/YYYY")}
          </Typography>
        </Grid>
      </Grid>
      <Grid component={"section"} container spacing={0}>
        <Grid item xs={12}>
          <Typography variant={"h5"}>{listing.cachedTicket.subject}</Typography>
          <Typography className="description" variant={"h6"}>
            {listing.cachedTicket.eventDescription}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={0}>
          <Grid item xs={3}>
            <Typography variant={"subtitle2"}>Odds</Typography>
            <Typography className={classes.heading} component={"h6"}>
              {formatOdds(
                listing.cachedTicket.collectAmount,
                listing.salesPrice || listing.askingPrice
              )}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant={"subtitle2"}>Collect</Typography>
            <Typography className={classes.heading} component={"h6"}>
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={parseFloat(listing.cachedTicket.collectAmount) / 100.0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant={"subtitle2"}>Price</Typography>
            <Typography className={classes.heading} component={"h6"}>
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={
                  parseFloat(listing.salesPrice || listing.askingPrice) / 100.0
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {!!highBid && (
              <>
                <Typography variant={"subtitle2"}>Highest Bid</Typography>
                <Typography className={classes.heading} component={"h6"}>
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={parseFloat(highBid.offer) / 100.0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid component={"footer"} container spacing={0}>
        <Grid item xs={4}>
          <Typography variant={"subtitle2"}>Amount</Typography>
          <Typography className={classes.heading} component={"h6"}>
            <NumberFormat
              fixedDecimalScale
              decimalScale={2}
              value={parseFloat(transaction.amount) / 100.0}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle2"}>Collected</Typography>
          <Typography className={classes.heading} component={"h6"}>
            {listing.cachedTicket.result === "WIN" ? (
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={
                  parseFloat(
                    listing.cachedTicket.collectAmount *
                      (!!listing.commissionWaiverApplied ? 1 : commissionAdjust)
                  ) / 100.0
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            ) : (
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            )}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"subtitle2"}>Cash Balance</Typography>
          <Typography className={classes.heading} component={"h6"}>
            <NumberFormat
              fixedDecimalScale
              decimalScale={2}
              value={parseFloat(transaction.balanceSnapshot) / 100.0}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={0}>
          <Grid item container xs={12} justify="flex-end">
            <Hidden mdUp>
              <Button variant="contained" onClick={onClose}>
                Close
              </Button>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Detail;
