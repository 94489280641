import React from "react";
import FanduelListMarketPricesView from "./FanduelListMarketPricesView";
import FanduelListMarketPricesStyles from "./FanduelListMarketPricesStyles";
import useStyles from "Hooks/useStyles";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export default ({ marketIds }) => {
  const { theme, styles } = useStyles({
    Styles: FanduelListMarketPricesStyles,
  });

  const query = gql`
    query FanDuelListMarketPrices($marketIds: [String!]!) {
      fanDuelListMarketPrices(marketIds: $marketIds) {
        marketDetails {
          marketId
          eventId
          marketName
          marketType
          marketStartTime
          inplay
          livePriceAvailable
          guaranteedPriceAvailable
          runnerDetails {
            selectionId
            selectionName
            runnerOrder
            handicap
            runnerStatus
            winRunnerOdds {
              decimal
              numerator
              denominator
            }
            runnerResult {
              runnerResultType
            }
          }
        }
      }
    }
  `;

  const result = useQuery(query, {
    variables: {
      marketIds,
    },
  });

  console.log("LLLLL", result);

  return (
    <FanduelListMarketPricesView
      query={query}
      theme={theme}
      styles={styles}
      items={result?.data?.fanDuelListMarketPrices?.marketDetails}
    />
  );
};
