import { Cache } from "aws-amplify";
import { CurrentUserContext } from "gunner-react";
import moment from "moment";
import React, { useContext, useState, useEffect } from "react";

export default () => {
  const [shouldRecordRegistration, setShouldRecordRegistration] =
    useState(false);
  const currentUser = useContext(CurrentUserContext);
  const justRegistered =
    moment(currentUser?.createdAt).toDate() > moment().subtract(20, "minutes");

  // console.log("justRegistered", justRegistered)

  useEffect(() => {
    !!justRegistered &&
      !!currentUser &&
      Promise.resolve(Cache.getItem("hasRegistered1"))
        .then(
          hasRegistered =>
            console.log("hasRegistered1", hasRegistered) ||
            setShouldRecordRegistration(!hasRegistered)
        )
        .then(() => Cache.setItem("hasRegistered1", "yes"));
  }, [justRegistered, currentUser]);

  // console.log(currentUser)

  return (
    <>
      {!!shouldRecordRegistration && (
        <img
          src={`https://trkn.us/pixel/conv/ppt=8460;g=registration;gid=28245;ord=${currentUser?.id}`}
          height="0"
          width="0"
          border="0"
        />
      )}
      <img
        src={`https://trkn.us/pixel/conv/ppt=8460;g=web_visit;gid=28246;ord=${currentUser?.id}`}
        height="0"
        width="0"
        border="0"
      />
    </>
  );
};
