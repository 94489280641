import React from "react";
import GroupNew from "../../../../Components/Group/New";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const GroupNewRoute = ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/sports"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <GroupNew sportId={params.sportId} />
      </ContentCell>
    </PageContainer>
  </>
);

export default GroupNewRoute;
