import React from "react";
import ListView from "./AdListView";
import ListStyles from "./AdListStyles";
import useStyles from "Hooks/useStyles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import AdDeleteButton from "Components/Ad/DeleteButton";
import { Typography } from "@material-ui/core";
import environment from "environment";

const fields = {
  Position: {
    value: item => item.position,
  },
  "Light Mode Image": {
    value: item => (
      <img
        src={item.lightModeImageUrl}
        style={{ maxWidth: "100%", height: "auto" }}
      />
    ),
  },
  "Dark Mode Image": {
    value: item => (
      <img
        src={item.darkModeImageUrl}
        style={{ maxWidth: "100%", height: "auto" }}
      />
    ),
  },
  URL: {
    value: item => item.url,
  },
  Edit: {
    hideLabel: true,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/ads/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
  Delete: {
    hideLabel: true,
    value: item => <AdDeleteButton id={item.id} />,
  },
};

const query = gql`
  query ListAdsByPosition(
    $active: StringBoolean!
    $position: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdsByPosition(
      active: $active
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        lightModeImageUrl
        darkModeImageUrl
        url
        position
        active
      }
      nextToken
    }
  }
`;

export default () => {
  const { theme, styles } = useStyles({ Styles: ListStyles });

  return (
    <>
      <Typography variant="h4">Active</Typography>
      <ListView
        fields={fields}
        query={query}
        dataKey={"listAdsByPosition"}
        variables={{
          active: "YES",
        }}
        theme={theme}
        styles={styles}
      />
      <Typography variant="h4">Inactive</Typography>
      <ListView
        fields={fields}
        query={query}
        dataKey={"listAdsByPosition"}
        variables={{
          active: "NO",
        }}
        theme={theme}
        styles={styles}
      />
    </>
  );
};
