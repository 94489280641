import React from "react";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import CognitoEdit from "../../../../Components/Cognito/Edit";
import Tabs from "../../../../Components/Admin/Components/Tabs";
import { Grid } from "@material-ui/core";
import UserSubTabsView from "../../../../Components/User/SubTabs";

const CognitoEditRoute = ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/users"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <Grid container spacing={4}>
          <Grid item xs={12} container>
            <UserSubTabsView
              selected="account"
              history={history}
              userId={params.id}
            />
          </Grid>
        </Grid>
        <CognitoEdit userId={params.id} />
      </ContentCell>
    </PageContainer>
  </>
);

export default CognitoEditRoute;
