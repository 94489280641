import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import Ticket from "shared/api/Ticket";
import Sport from "shared/api/Sport";
import SportsbookLocale from "shared/api/SportsbookLocale";
import Option from "shared/api/Option";
import Listing from "shared/api/Listing";
import Event from "shared/api/Event";
import Locale from "shared/api/Locale";
import { withoutBlanks } from "gunner-react";
import TicketNewView from "./TicketNewView";
import { commissionAdjust } from "shared/Config";
import ReactGA from "react-ga";

import { makeStyles } from "@material-ui/core/styles";
import formatOdds from "shared/Util/formatOdds";

const invalidStates = ["Illinois"];

const useStyles = makeStyles(theme => ({
  form: {
    "& h2": {
      textTransform: "uppercase",
      borderBottom: `2px solid ${theme.palette.background.default}`,
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
    "& .dualHelperText": {
      marginTop: theme.spacing(-6),
      marginBottom: theme.spacing(6),
    },
    "& .topHelperText": {
      marginBottom: theme.spacing(6),
    },
    "& .formControl": {
      marginBottom: theme.spacing(3),
    },
    "& .photoUploaderHolder": {
      borderWidth: 4,
      borderStyle: "solid",
      borderColor: theme.palette.background.default,
      display: "flex",
      flex: 1,
      cursor: "pointer",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(4, 0),
      "&:hover": {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },

      "& svg": {
        fontSize: 100,
      },
    },
  },
}));

const parlayOptions = parlay =>
  !parlay
    ? ""
    : `${Object.values(parlay)
        .map(p => p.subject)
        .join(", ")}`;

const parlayDescription = parlay =>
  !parlay
    ? ""
    : `${Object.keys(parlay).length} Leg Parlay (${Object.values(parlay)
        .map(p => p.eventDescription)
        .join(", ")})`;

export default ({
  ticketData,
  listingData,
  currentUser,

  onEventChange,
  onTicketChange,
  onListingChange,
  onOptionChange,
  onTicketCreated,
}) => {
  const classes = useStyles();
  const [shouldCreateListing, setShouldCreateListing] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);
  const [agreedToCcTermsAt, setAgreedToCcTermsAt] = useState(false); //currentUser.agreedToCcTermsAt -> TODO: move this back to the listing object. Each listing should be agreed to terms. not once per user
  const [agreedToTermsAt, setAgreedToTermsAt] = useState(false);
  const [parlayLegs, setParlayLegs] = useState("2");
  const [parlay, setParlay] = useState(null);

  const { data: { listLocales: { items: locales = [] } = {} } = {} } = useQuery(
    Locale.queries.list,
    { variables: { limit: 100 } }
  );
  const { data: { listSports: { items: sports = [] } = {} } = {} } = useQuery(
    Sport.queries.list,
    { variables: { limit: 100 } }
  );
  const { data: { getEvent } = {} } = useQuery(Event.queries.get, {
    variables: {
      id: ticketData.ticketEventId ?? "<>",
    },
  });
  const { data: { getOption } = {} } = useQuery(Option.queries.get, {
    variables: {
      id: ticketData.ticketOptionId ?? "<>",
    },
  });
  const selectedLocale = useMemo(
    () => locales.find(locale => locale.id === ticketData.ticketLocaleId),
    [locales, ticketData.ticketLocaleId]
  );
  const {
    data: {
      listSportsbookLocalesByLocaleId: { items: sportsbookLocales = [] } = {},
    } = {},
  } = useQuery(SportsbookLocale.queries.listByLocaleId, {
    skip: !selectedLocale?.id,
    variables: {
      limit: 2000,
      localeId: selectedLocale?.id,
    },
  });

  const payout = !listingData.askingPrice
    ? ""
    : (!listingData.commissionWaiverApplied
        ? parseFloat(listingData.askingPrice) * commissionAdjust
        : parseFloat(listingData.askingPrice) > 100000
        ? 100000 +
          (parseFloat(listingData.askingPrice) - 100000) * commissionAdjust
        : parseFloat(listingData.askingPrice)) / 100.0;

  const ticketIsValid =
    !!ticketData.photoUrl &&
    !!ticketData.ticketLocaleId &&
    !!ticketData.ticketSportId &&
    !!ticketData.betType &&
    !!ticketData.winAmount &&
    !!ticketData.betAmount &&
    !invalidStates.includes(selectedLocale?.state) &&
    !!currentUser?.bluesnapVaultedShopperId &&
    ticketData.eventDescription !== "Other" &&
    !!ticketData.ticketSportsbookId;

  const listingIsValid =
    !!listingData.askingPrice &&
    !!agreedToCcTermsAt &&
    !!agreedToTermsAt &&
    listingData.askingPrice <
      (ticketData.winAmount || 0) + (ticketData.betAmount || 0) &&
    parseInt(listingData.askingPrice || 0) >=
      parseInt(listingData.minimumBidPrice || 0);

  const [createTicket] = useMutation(Ticket.mutations.create, {
    variables: {
      input: {
        ...withoutBlanks(ticketData),
      },
    },
    refetchQueries: [
      {
        query: Ticket.queries.listByUserIdWithListingsAndBids,
        variables: {
          ticketUserId: currentUser.id,
          sortDirection: "DESC",
          limit: 500,

          listingsLimit: 1,
          listingsSortDirection: "DESC",

          bidsLimit: 1000,
          bidsSortDirection: "DESC",
        },
      },
    ],
  });

  const [createListing] = useMutation(Listing.mutations.create, {
    variables: {
      input: {
        ...withoutBlanks(listingData),
      },
    },
    refetchQueries: [
      {
        query: Ticket.queries.listByUserIdWithListingsAndBids,
        variables: {
          ticketUserId: currentUser.id,
          sortDirection: "DESC",
          limit: 500,

          listingsLimit: 1,
          listingsSortDirection: "DESC",

          bidsLimit: 1000,
          bidsSortDirection: "DESC",
        },
      },
      {
        query: Listing.queries.listBySellerIdWithBids,
        variables: {
          listingSellerId: currentUser.id,
          sortDirection: "DESC",
          limit: 500,
          status: {
            eq: "ACTIVE",
          },

          bidsLimit: 1000,
          bidsSortDirection: "DESC",
        },
      },
    ],
  });

  const handleSubmit = useCallback(
    () =>
      Promise.resolve(setSubmitting(true))
        .then(createTicket)
        .then(
          data =>
            onListingChange("listingTicketId", data.data.createTicket.id) ||
            new Promise(resolve => setTimeout(resolve, 1000)) // give react a second to set the ticket id on the listing object
        )
        .then(() => (!!shouldCreateListing ? createListing() : null))
        .then(onTicketCreated)
        .then(() =>
          Promise.all([
            setShowPhoto(false),
            !!shouldCreateListing &&
              ReactGA.event({
                category: "Listing",
                action: "Create",
              }),
            ReactGA.event({
              category: "Ticket",
              action: "Create",
            }),
          ])
        )
        .catch(e => [console.log(e), window.alert(JSON.stringify(e))])
        .finally(() => setSubmitting(false)),
    [
      shouldCreateListing,
      createListing,
      createTicket,
      onTicketCreated,
      onListingChange,
    ]
  );

  const handleParlayChange = useCallback(
    (leg, field, value) =>
      setParlay(parlay => ({
        ...(parlay ?? {}),
        [leg]: {
          ...(parlay?.[leg] ?? {}),
          [field]: value,
        },
      })),
    []
  );

  useEffect(() => {
    !!parlay &&
      ticketData.betType === "PARLAY" &&
      parlayDescription(parlay) !== ticketData.eventDescription &&
      onTicketChange("eventDescription", parlayDescription(parlay));
  }, [parlay, ticketData.eventDescription, ticketData.betType]);

  useEffect(() => {
    !!parlay &&
      ticketData.betType === "PARLAY" &&
      parlayOptions(parlay) !== ticketData.subject &&
      onTicketChange("subject", parlayOptions(parlay));
  }, [parlay, ticketData.subject, ticketData.betType]);

  useEffect(() => {
    !!parlay?.[0]?.sportId &&
      ticketData.betType === "PARLAY" &&
      ticketData.ticketSportId !== parlay[0].sportId &&
      onTicketChange("ticketSportId", parlay[0].sportId);
  }, [parlay, ticketData.ticketSportId, ticketData.betType]);

  useEffect(() => {
    (!ticketData.betAmount || !ticketData.winAmount) &&
      onTicketChange(
        "collectAmount",
        ticketData.betAmount + ticketData.winAmount
      );

    (!ticketData.betAmount || !ticketData.winAmount) &&
      onTicketChange(
        "odds",
        formatOdds(ticketData.collectAmount, ticketData.betAmount)
      );

    (!ticketData.betAmount || !ticketData.winAmount) &&
      onTicketChange(
        "oddsCalculated",
        parseFloat(ticketData.winAmount) / parseFloat(ticketData.betAmount)
      );
  }, [ticketData.betAmount, ticketData.winAmount, onTicketChange]);

  useEffect(() => {
    !!ticketData.betAmount && !!ticketData.winAmount
      ? onTicketChange({
          collectAmount: ticketData.betAmount + ticketData.winAmount,
          odds: formatOdds(
            ticketData.betAmount + ticketData.winAmount,
            ticketData.betAmount
          ),
          oddsCalculated:
            parseFloat(ticketData.betAmount + ticketData.winAmount) /
            parseFloat(ticketData.betAmount),
        })
      : onTicketChange({
          collectAmount: "",
          odds: "",
          oddsCalculated: 0.0,
        });
  }, [ticketData.betAmount, ticketData.winAmount, onTicketChange]);

  useEffect(() => {
    !!ticketData.collectAmount && !!listingData.askingPrice
      ? onListingChange({
          newOdds: formatOdds(
            parseFloat(ticketData.collectAmount),
            listingData.askingPrice
          ),
          newOddsCalculated:
            parseFloat(ticketData.collectAmount) /
            parseFloat(listingData.askingPrice),
        })
      : onListingChange({
          newOdds: "",
          newOddsCalculated: 0.0,
        });
  }, [ticketData.collectAmount, listingData.askingPrice, onListingChange]);

  // console.log(parlay)
  // console.log(ticketData)

  return (
    <TicketNewView
      classes={classes}
      ticket={ticketData}
      listing={listingData}
      currentUser={currentUser}
      locales={locales}
      sports={sports}
      sportsbookLocales={sportsbookLocales}
      event={getEvent}
      option={getOption}
      selectedLocale={selectedLocale}
      shouldCreateListing={shouldCreateListing}
      setShouldCreateListing={setShouldCreateListing}
      submitting={submitting}
      agreedToCcTermsAt={agreedToCcTermsAt}
      setAgreedToCcTermsAt={setAgreedToCcTermsAt}
      setAgreedToTermsAt={setAgreedToTermsAt}
      agreedToTermsAt={agreedToTermsAt}
      payout={payout}
      ticketIsValid={ticketIsValid}
      listingIsValid={listingIsValid}
      setShowPhoto={setShowPhoto}
      showPhoto={showPhoto}
      parlayLegs={parlayLegs}
      setParlayLegs={setParlayLegs}
      parlay={parlay}
      onParlayChange={handleParlayChange}
      onSubmit={handleSubmit}
      onListingChange={onListingChange}
      onTicketChange={onTicketChange}
      onEventChange={onEventChange}
      onOptionChange={onOptionChange}
    />
  );
};
