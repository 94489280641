import React, { useState, useContext, useCallback } from "react";
import ContentCell from "Components/Layout/ContentCell";
import PageContainer from "Components/Layout/PageContainer";
import SearchBar from "material-ui-search-bar";
import { Redirect } from "react-router-dom";
import { List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import Search from "shared/api/Search";
import { CurrentUserContext } from "gunner-react";
import { Button } from "@material-ui/core";
import { EventsContext } from "Contexts/Events";
import { OptionsContext } from "Contexts/Options";

export default ({ history, location: { pathname, search = "" } = {} }) => {
  const currentUser = useContext(CurrentUserContext);
  const events = useContext(EventsContext);
  const options = useContext(OptionsContext);
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [showTopSearches, setShowTopSearches] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [doSearch, setDoSearch] = useState(0);
  const { data: { listSearchesByUserId: { items: searches } = {} } = {} } =
    useQuery(Search.queries.listByUserId, {
      skip: !currentUser?.id,
      variables: {
        userId: currentUser?.id,
        sortDirection: "DESC",
      },
    });

  const [saveSearch] = useMutation(Search.mutations.create, {
    variables: {
      input: {
        text: searchText,
      },
    },
  });

  const handleSearchRequest = useCallback(
    type => saveSearch().finally(() => setDoSearch(type === 1 ? 1 : 2)),
    [saveSearch]
  );

  const handleSearchFocus = useCallback(() => {
    !!currentUser && setShowSearchHistory(true);

    !currentUser && setShowTopSearches(true);
  }, [currentUser]);

  const handleSearchBlur = useCallback(() => {
    setShowSearchHistory(false);
    setShowTopSearches(false);
  }, []);

  const handleOptionClick = useCallback(
    text => () =>
      Promise.resolve(setSearchText(text)).then(() => handleSearchRequest(1)),
    [handleSearchRequest]
  );

  return (
    <PageContainer>
      {!!doSearch && (
        <Redirect
          to={`/?searchString=${searchText}&searchExact=${
            doSearch === 1 ? "true" : "false"
          }`}
          push
        />
      )}
      <ContentCell>
        <SearchBar
          value={searchText}
          onChange={setSearchText}
          onRequestSearch={handleSearchRequest}
          onFocus={handleSearchFocus}
          type="search"
          // onBlur={handleSearchBlur}
        />
        {!!showSearchHistory && !searchText && (
          <List dense>
            <ListSubheader>History</ListSubheader>
            {searches
              .map(search => search.text)
              .filter((v, i, a) => a.indexOf(v) === i)
              .map(search => (
                <ListItem
                  onClick={handleOptionClick(search)}
                  component={Button}
                  key={search}
                >
                  <ListItemText primary={search} />
                </ListItem>
              ))}
          </List>
        )}
        {!!searchText && (
          <List dense>
            <ListSubheader>Matches</ListSubheader>
            {[...(events ?? []), ...(options ?? [])]
              .map(item => [
                item.name ?? item.description ?? "",
                ...(item.aliases ?? "").split(","),
              ])
              .filter(searches =>
                searches.some(search =>
                  new RegExp(`${searchText.trim()}`, "i").test(search.trim())
                )
              )
              .map(searches => searches[0])
              .filter((v, i, a) => a.indexOf(v) === i)
              .map(search => (
                <ListItem
                  onClick={handleOptionClick(search)}
                  component={Button}
                  key={search}
                >
                  <ListItemText primary={search} />
                </ListItem>
              ))}
          </List>
        )}
        {!!showTopSearches && !searchText && (
          <List dense>
            <ListSubheader>Top Events</ListSubheader>
            {[...(events ?? [])]
              .sort((a, b) => (a.viewCount > b.viewCount ? -1 : 1))
              .map(item => [
                item.name ?? item.description ?? "",
                ...(item.aliases ?? "").split(","),
              ])
              .map(searches => searches[0])
              .filter((v, i, a) => i < 3)
              .map(search => (
                <ListItem
                  onClick={handleOptionClick(search)}
                  component={Button}
                  key={search}
                >
                  <ListItemText primary={search} />
                </ListItem>
              ))}
          </List>
        )}
        {!!showTopSearches && !searchText && (
          <List dense>
            <ListSubheader>Top Teams</ListSubheader>
            {[...(options ?? [])]
              .sort((a, b) => (a.viewCount > b.viewCount ? -1 : 1))
              .map(item => [
                item.name ?? item.description ?? "",
                ...(item.aliases ?? "").split(","),
              ])
              .map(searches => searches[0])
              .filter((v, i, a) => i < 3)
              .map(search => (
                <ListItem
                  onClick={handleOptionClick(search)}
                  component={Button}
                  key={search}
                >
                  <ListItemText primary={search} />
                </ListItem>
              ))}
          </List>
        )}
      </ContentCell>
    </PageContainer>
  );
};
