import React from "react";
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";

export default ({ items, eventTypeId }) => (
  <>
    <Button
      component={Link}
      to={`/admin/fanduel?marketIds=${(items ?? [])
        .map(item => item.marketId)
        .join(",")}`}
      fullWidth
      size={"small"}
      variant={"contained"}
      color={"secondary"}
    >
      All Prices
    </Button>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Market Id</TableCell>
          <TableCell>Market Name</TableCell>
          <TableCell>Market Start Time</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(items ?? []).map(item => (
          <TableRow key={item.marketId}>
            <TableCell>{item.marketId}</TableCell>
            <TableCell>{item.marketName}</TableCell>
            <TableCell>{item.marketStartTime}</TableCell>
            <TableCell>
              <Button
                component={Link}
                to={`/admin/fanduel?marketIds=${item.marketId}`}
                fullWidth
                size={"small"}
                variant={"contained"}
                color={"secondary"}
              >
                Prices
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </>
);
