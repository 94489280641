import React from "react";

import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

import Form from "../Form";

import Option from "shared/api/Option";

const OptionEditContainer = ({ id, onSuccess }) => {
  const { loading, data: { getOption: option } = {} } = useQuery(
    Option.queries.get,
    { variables: { id: id } }
  );

  return !!loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Form option={option} onSuccess={onSuccess} />
  );
};

export default OptionEditContainer;
