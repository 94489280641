import React from "react";
import PromotionEdit from "../../../../Components/Promotion/Edit";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const PromotionEditRoute = ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/campaigns"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <PromotionEdit id={params.id} campaignId={params.campaignId} />
      </ContentCell>
    </PageContainer>
  </>
);

export default PromotionEditRoute;
