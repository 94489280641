import React from "react";
import LinkNew from "Components/Link/New";
import PageContainer from "Components/Layout/PageContainer";
import ContentCell from "Components/Layout/ContentCell";
import Tabs from "Components/Admin/Components/Tabs";

export default ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/links"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <LinkNew />
      </ContentCell>
    </PageContainer>
  </>
);
