import React from "react";

import Tabs from "Components/Admin/Components/Tabs";
import ContentCell from "Components/Layout/ContentCell";
import PageContainer from "Components/Layout/PageContainer";
import OptionsRow from "Components/Layout/OptionsRow";
import EventOddsList from "Components/Event/Odds/List";
import PageHeader from "Components/Layout/PageHeader";
import { useQuery } from "@apollo/client";
import Event from "shared/api/Event";
import { useState } from "react";
import moment from "moment";
import { TextField } from "@material-ui/core";

export default ({
  match: { params = {} },
  history,
  location: { search = "" } = {},
}) => {
  const [date, setDate] = useState(new Date());
  const { data: { getEvent: event } = {} } = useQuery(Event.queries.get, {
    variables: {
      id: params.eventId,
    },
  });

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/sports"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <PageHeader alt>
            {event?.description} - {moment(date).format("MMMM Do YYYY")}
          </PageHeader>
          <OptionsRow>
            <TextField
              label="Date"
              type="date"
              value={moment(date).format("YYYY-MM-DD")}
              onChange={({ target: { value } }) =>
                setDate(moment(value).toDate())
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </OptionsRow>

          <EventOddsList
            eventId={params.eventId}
            date={moment(date).format("YYYY-MM-DD")}
          />
        </ContentCell>
      </PageContainer>
    </>
  );
};
