import React, { useMemo } from "react";
import Activity from "shared/api/Activity";
import { DataTableNew as DataTable } from "gunner-react/web";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
import AdCell from "../Components/Layout/AdCell";
// import TicketListItem from '../Components/Ticket/List/Item'
import { useQuery } from "@apollo/client";
import moment from "moment";

const fields = {
  Title: {
    value: item => item.title,
    nowrap: true,
    sort: order => (a, b) =>
      a.title > b.title
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Type: {
    value: item => item.type,
    nowrap: true,
    sort: order => (a, b) =>
      a.type > b.type ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
    rowProps: {},
  },
  Body: {
    value: item => item.body,
    nowrap: true,
    sort: order => (a, b) =>
      a.body > b.body ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
    rowProps: {},
  },
  Time: {
    value: item => moment(item.createdAt).fromNow(),
    nowrap: true,
    sort: order => (a, b) =>
      a.createdAt > b.createdAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
};

export default ({ history, location: { search = "" } = {} }) => {
  const query = useMemo(
    () => ({
      query: Activity.queries.listByGlobalEligible,
      dataKey: "listActivitiesByGlobalEligible",
      variables: {
        sortDirection: "DESC",
        globalEligible: "YES",
      },
    }),
    []
  );

  const entry = useQuery(query.query, {
    variables: query.variables,
  });

  return (
    <>
      <PageContainer>
        <ContentCell>
          <DataTable
            fields={fields}
            dataListParams={{
              query,
              refetchCount: 1,
            }}
          />
        </ContentCell>
        <AdCell>
          <img src={require("../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};
