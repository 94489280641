import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import { ticketSettledListItemQuery } from "../TicketSettledListQueries";

import TicketSettledListItemView, { ticketSettledListItemFragment } from "./TicketSettledItemView";





const mutation = gql`
  mutation TicketSettledListItemUpdateTicket($input: UpdateTicketInput!) {
    updateTicket(input: $input) {
      ...TicketSettledListItemFragment
    }
  }
  ${ticketSettledListItemFragment}
`;


const TicketSettledListItemContainer = ({ ticket }) => {
  const ticketId = ticket.id;
  const isSettled = ticket.isSettled;

  const [updateTicket, { loading }] = useMutation(mutation, {
    variables: {
      input: {
        id: ticketId,
      }
    },
    refetchQueries: [
      {
        query: ticketSettledListItemQuery,
        variables: {
          isSettled: "false",
          result: {
            eq: "PUSH"
          }
        }
      },
      {
        query: ticketSettledListItemQuery,
        variables: {
          isSettled: "false",
          result: {
            eq: "WIN"
          }
        }
      },
    ]
  })

  const handleIsSettledClick = useCallback(() => {
    updateTicket({
      variables: {
        input: {
          id: ticketId,
          isSettled: isSettled === "true" ? "false" : "true"
        }
      }
    })
      .catch(console.error);
  }, [ticketId, updateTicket, isSettled]);

  return <TicketSettledListItemView 
    onIsSettledClick={handleIsSettledClick}
    ticket={ticket}
    loading={loading}
  />
};

export default TicketSettledListItemContainer;