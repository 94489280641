import React, { useContext } from "react";
import {
  Grid,
  CircularProgress,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import AdCell from "../../Layout/AdCell";
import ContentCell from "../../Layout/ContentCell";
import PageHeader from "../../Layout/PageHeader";
import PageContainer from "../../Layout/PageContainer";
import BackLink from "../../Layout/BackLink";
import DetailComponent from "../../Listing/Components/Detail";
import { Link } from "react-router-dom";

import { CurrentUserContext } from "gunner-react";

import Listing from "shared/api/Listing";
import OptionEventOdds from "shared/api/OptionEventOdds";
import Option from "shared/api/Option";
import Event from "shared/api/Event";
import Ticket from "shared/api/Ticket";
import useRecordTicketView from "react-shared/Hooks/useRecordTicketView";

import NumberFormat from "react-number-format";
import ActivityList from "../../Activity/List";
import BidList from "../../Bid/List/BidListContainer";
import EventOptionOddsGraph from "Components/Event/Option/Odds/Graph";
import formatOdds from "shared/Util/formatOdds";
import Bet from "shared/api/Bet";

const useStyles = makeStyles(theme => ({}));

export default ({ match: { params } }) => {
  const classes = useStyles();
  const currentUser = useContext(CurrentUserContext);

  const {
    loading,
    error,
    data: { getTicket: ticket } = {},
  } = useQuery(Ticket.queries.get, {
    variables: {
      id: params.ticketId || params.id,
    },
  });

  const { data: { getListing: listing } = {} } = useQuery(Listing.queries.get, {
    pollInterval: 3000,
    variables: {
      id: params.ticketId ? params.id : "<>",
      bidsLimit: 1000,
      bidsSortDirection: "DESC",
    },
  });

  const {
    error: listingsError,
    data: { listingsByTicketId: { items: listings = [] } = {} } = {},
  } = useQuery(Listing.queries.listByTicketIdWithSellerAndBuyer, {
    variables: {
      listingTicketId: params.ticketId || params.id,
      limit: 2000,
      withBuyer: true,
      withSeller: true,
    },
  });

  console.log("listingsError", listingsError);

  const { data: { listEventsBySportId: { items: events } = {} } = {} } =
    useQuery(Event.queries.listBySportId, {
      skip: !ticket?.ticketSportId,
      variables: {
        sportId: ticket?.ticketSportId,
        limit: 2000,
        filter: {
          active: {
            eq: true,
          },
        },
      },
    });

  const { data: { listOptionsBySportId: { items: options } = {} } = {} } =
    useQuery(Option.queries.listBySportId, {
      skip: !ticket?.ticketSportId,
      variables: {
        sportId: ticket?.ticketSportId,
        limit: 1000,
        filter: {
          active: {
            eq: true,
          },
        },
      },
    });

  const event = (events ?? []).find(
    event => event.description === ticket?.eventDescription
  );
  const option = (options ?? []).find(
    option => option.name === ticket?.subject
  );

  const {
    data: {
      listOptionEventOddsByEventIdAndOptionId: { items: optionEventOdds } = {},
    } = {},
  } = useQuery(OptionEventOdds.queries.listByEventIdAndOptionId, {
    skip: !event?.id || !option?.id,
    variables: {
      limit: 365,
      eventId: event?.id,
      optionIdCreatedAt: {
        beginsWith: {
          optionId: option?.id,
        },
      },
    },
  });

  const {
    error: betError,
    data: { listBetsByTicketId: { items: bets = [] } = {} } = {},
  } = useQuery(Bet.queries.listByTicketId, {
    skip: !ticket?.id,
    variables: {
      limit: 365,
      ticketId: ticket?.id,
    },
  });

  useRecordTicketView({ ticketId: params.ticketId ?? params.id });

  !!error && console.error(error);
  !!betError && console.error(betError);

  return (
    <PageContainer>
      <ContentCell>
        <BackLink url="/" />
        <PageHeader gutterBottom gutterTop>
          Tickets Details
        </PageHeader>
        <Grid container spacing={0} justify="center">
          <Grid item xs={12} md={9}>
            {!!error || (!loading && !ticket) ? (
              "Something went wrong"
            ) : !!loading ? (
              <CircularProgress
                className={classes.progress}
                color="secondary"
              />
            ) : (
              <DetailComponent
                currentUser={currentUser}
                listing={listing}
                ticket={ticket}
                bids={
                  !!listing
                    ? (listing.bids?.items??[]).filter(
                        bid => !["REJECTED", "CANCELLED"].includes(bid.status)
                      )
                    : []
                }
              />
            )}
          </Grid>
        </Grid>
        {
          //TODO: LIFT ADMIN GATING
          !!optionEventOdds?.length && !!currentUser.groups.includes("Admins") && (
            <Grid container spacing={0} justify="center">
              <Grid item xs={12} md={12}>
                <Typography variant={"h5"}>Market Value History</Typography>
                <EventOptionOddsGraph
                  ticketCollectsAmount={ticket?.collectAmount}
                  optionEventOdds={optionEventOdds}
                />
              </Grid>
            </Grid>
          )
        }
        {!!currentUser && !!currentUser.groups.includes("Admins") && !!listing && (
          <Box mt={4} mb={4}>
            <Typography variant={"h5"}>Listing Activity</Typography>
            <ActivityList model="Listing" modelId={listing.id} />
          </Box>
        )}
        {!!currentUser && !!currentUser.groups.includes("Admins") && !!listing && (
          <Box mt={4} mb={4}>
            <Typography variant={"h5"}>Bids</Typography>
            <BidList listingId={listing.id} />
          </Box>
        )}
        {/* {
          !!currentUser &&
          !!currentUser.groups.includes("Admins") &&
          !!ticket &&
          <Box mt={4} mb={4}>
            <Typography variant={'h5'}>Ticket Activity</Typography>
            <ActivityList model="Ticket" modelId={ticket.id} />
          </Box>
        } */}
        {!!currentUser && !!currentUser.groups.includes("Admins") && !!ticket && (
          <Grid container spacing={0} justify="center">
            <Grid item xs={12}>
              <Typography variant={"h5"}>Listings for this Ticket</Typography>
              <Box w={1} overflow="auto">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Updated
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Seller
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Buyer
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Sold
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Odds
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Asking Price
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Sale Price
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listings.map(listing => (
                      <TableRow key={listing.id}>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {!!ticket && (
                            <Link
                              to={`/tickets/${ticket.id}/listings/${listing.id}/edit`}
                            >
                              {listing.status}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {moment(listing.updatedAt).format("MM/DD/YYYY H:mm")}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <Link
                            to={`/admin/users/${listing.listingSellerId}/edit`}
                          >
                            {listing.seller?.displayName}
                          </Link>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {!!listing.buyer && (
                            <Link
                              to={`/admin/users/${listing.listingBuyerId}/edit`}
                            >
                              {listing.buyer.displayName}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {!!listing.soldAt &&
                            moment(listing.soldAt).format("MM/DD/YYYY H:mm")}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {formatOdds(
                            ticket.collectAmount,
                            listing.salesPrice ?? listing.askingPrice
                          )}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <NumberFormat
                            fixedDecimalScale
                            decimalScale={2}
                            value={parseFloat(listing.askingPrice || 0) / 100.0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {listing.salesPrice && (
                            <NumberFormat
                              fixedDecimalScale
                              decimalScale={2}
                              value={
                                parseFloat(listing.salesPrice || 0) / 100.0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
        )}
        {!!currentUser && !!currentUser.groups.includes("Admins") && !!ticket && (
          <Grid container spacing={0} justify="center">
            <Grid item xs={12}>
              <Typography variant={"h5"}>Bets for this Ticket</Typography>
              <Box w={1} overflow="auto">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Event
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Team
                      </TableCell>
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Result
                      </TableCell>
                      {/* <TableCell  style={{whiteSpace: 'nowrap'}}  component="th">
                        Odds
                      </TableCell> */}
                      <TableCell
                        style={{ whiteSpace: "nowrap" }}
                        component="th"
                      >
                        Sport
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bets.map(bet => (
                      <TableRow key={bet.id}>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {bet.eventDescription}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {bet.subject}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {bet.result}
                        </TableCell>
                        {/* <TableCell  style={{whiteSpace: 'nowrap'}} >
                            {bet.odds}
                          </TableCell> */}
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {bet.sport?.name}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
        )}
      </ContentCell>
      <AdCell>
        <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
      </AdCell>
    </PageContainer>
  );
};
