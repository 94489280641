import React from "react";
import AdNewView from "./AdNewView";
import AdNewStyles from "./AdNewStyles";
import useStyles from "Hooks/useStyles";
import gql from "graphql-tag";
import environment from "environment";
const query = gql`
  query ListAdsByPosition(
    $active: StringBoolean!
    $position: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdsByPosition(
      active: $active
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        lightModeImageUrl
        darkModeImageUrl
        url
        position
        active
      }
      nextToken
    }
  }
`;

const mutation = gql`
  mutation CreateAd($input: CreateAdInput!) {
    createAd(input: $input) {
      id
      createdAt
      updatedAt
      lightModeImageUrl
      darkModeImageUrl
      url
      active
      position
    }
  }
`;

const dataKey = "createAd";

export default () => {
  const { theme, styles } = useStyles({ Styles: AdNewStyles });

  return (
    <AdNewView
      theme={theme}
      styles={styles}
      ad={{ active: "YES" }}
      query={query}
      mutation={mutation}
      dataKey={dataKey}
    />
  );
};
