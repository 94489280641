import React from "react";

import Tabs from "Components/Admin/Components/Tabs";
import ContentCell from "Components/Layout/ContentCell";
import PageContainer from "Components/Layout/PageContainer";
import RebateList from "Components/Rebate/List";

export default ({
  match: { params = {} },
  history,
  location: { search = "" } = {},
}) => {
  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/campaigns"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <RebateList />
        </ContentCell>
      </PageContainer>
    </>
  );
};
