import React from "react";
import SportEdit from "../../../../Components/Sport/Edit";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const SportEditRoute = ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/sports"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <SportEdit id={params.id} />
      </ContentCell>
    </PageContainer>
  </>
);

export default SportEditRoute;
