import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import FormLabel from '@material-ui/core/FormLabel';
import { TextField, Grid } from "@material-ui/core";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(theme => ({
  amount: {
    "& .balance": {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0, 1),
    },
  },
}));

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    fixedDecimalScale
    decimalScale={2}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    thousandSeparator
    prefix="$"
  />
);

const Amount = ({ amount = "2500", onChange }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.amount}>
      <Grid item xs={8} container alignItems="center">
        <TextField
          onChange={({ target: { value } }) =>
            onChange(parseInt((value || 0) * 100.0))
          }
          fullWidth
          label={"Enter Amount"}
          value={!!amount ? parseFloat(amount) / 100.0 : ""}
          helperText="$5.00 Minimum"
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputMode: "decimal",
            type: "text",
          }}
          inputMode="decimal"
          inputProps={{ inputMode: "decimal" }}
          type="text"
          variant={"outlined"}
        />
      </Grid>
      <Grid item xs={4} container alignContent="center" justify="center">
        {/* <div className="balance">
          <Typography noWrap variant={"subtitle2"} align="center">Starting Balance</Typography>
          <Typography variant={"h6"} align="center">
            <NumberFormat fixedDecimalScale decimalScale={2} value={200} displayType={'text'} thousandSeparator={true} prefix={'$'} /> 
          </Typography>
        </div> */}
      </Grid>
    </Grid>
  );
};

export default Amount;
