import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

// import useLocaleListStyles from "./LocaleListStyles";
import DataTable from "../../DataTable";
import DeleteButton from "../DeleteButton";

const fields = {
  State: {
    value: item => item.state,
    nowrap: true,
    sort: order => (a, b) =>
      a.state > b.state
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Name: {
    value: item => item.name,
    sort: order => (a, b) =>
      a.name > b.name ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  "Mailing Address": {
    value: item => item.mailingAddress,
    sort: order => (a, b) =>
      a.mailingAddress > b.mailingAddress
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/locales/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
  Delete: {
    hideLabel: true,
    sort: null,
    value: item => <DeleteButton id={item.id} />,
  },
};

const LocaleListView = ({ locales }) => (
  <DataTable fields={fields} items={locales} />
);

export default LocaleListView;
