import React, { useContext, useEffect, useMemo } from "react";
import Activity from "shared/api/Activity";
import User from "shared/api/User";
import { DataTableNew as DataTable } from "gunner-react/web";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
import AdCell from "../Components/Layout/AdCell";
// import TicketListItem from '../Components/Ticket/List/Item'
import Tabs from "../Components/Account/Components/AltTabs";
import { CurrentUserContext } from "gunner-react";
import { useMutation, useQuery } from "@apollo/client";
import {
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import moment from "moment";

const NotificationsCountToggle = () => {
  const currentUser = useContext(CurrentUserContext);
  const [updatePreference] = useMutation(User.mutations.update, {
    variables: {
      input: {
        id: currentUser?.id,
        hideNotificationsCount: !currentUser?.hideNotificationsCount,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateUser: {
        ...currentUser,
        __typename: "User",
      },
    },
    refetchQueries: [
      {
        query: User.queries.listByEmail,
        variables: {
          email: currentUser?.email,
          withPrivate: true,
          limit: 1,
        },
      },
    ],
  });

  // const handleChange = event =>
  //   setState({ ...state, [event.target.name]: event.target.checked })

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={!currentUser?.hideNotificationsCount}
            onChange={updatePreference}
            name="checkedB"
            color="primary"
          />
        }
        label="Show Notification Count"
      />
    </FormGroup>
  );
};

const fields = {
  Title: {
    value: item => item.title,
    nowrap: true,
    sort: order => (a, b) =>
      a.title > b.title
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Type: {
    value: item => item.type,
    nowrap: true,
    sort: order => (a, b) =>
      a.type > b.type ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
    rowProps: {},
  },
  Body: {
    value: item => item.body,
    nowrap: true,
    sort: order => (a, b) =>
      a.body > b.body ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
    rowProps: {},
  },
  Time: {
    value: item => moment(item.createdAt).fromNow(),
    nowrap: true,
    sort: order => (a, b) =>
      a.createdAt > b.createdAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
};

export default ({ history, location: { search = "" } = {} }) => {
  const currentUser = useContext(CurrentUserContext);
  const [resetNotificationsCount] = useMutation(User.mutations.update, {
    variables: {
      input: {
        id: currentUser?.id,
        notificationsCount: 0,
      },
    },
  });

  // const query = useMemo(() =>  ({
  //   query: Activity.queries.listForNotificationCenter,
  //   dataKey: "listActivitiesForNotificationCenter",
  //   variables: {
  //     userId: currentUser.id,
  //     notificationCenterEligibleTypeCreatedAt: {
  //       beginsWith: {
  //         notificationCenterEligible: "YES"
  //       }
  //     }
  //   },
  // }), [currentUser.id])

  const query = useMemo(
    () => ({
      query: Activity.queries.listByUserIdAndCreatedAt,
      dataKey: "listActivitiesByUserIdAndCreatedAt",
      variables: {
        userId: currentUser.id,
        sortDirection: "DESC",
        notificationCenterEligibleTypeCreatedAt: {
          beginsWith: {
            notificationCenterEligible: "YES",
          },
        },
      },
    }),
    [currentUser.id]
  );

  const entry = useQuery(query.query, {
    variables: query.variables,
  });

  useEffect(() => {
    !!resetNotificationsCount && resetNotificationsCount();
  }, [resetNotificationsCount]);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/account/notification-center"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell>
          <NotificationsCountToggle />
          <DataTable
            fields={fields}
            dataListParams={{
              query,
              refetchCount: 1,
            }}
          />
        </ContentCell>
        <AdCell>
          <img src={require("../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};
