import { formatPennies } from "shared/Util/money";

const ALLOWED_STATES = [
  "Arizona",
  "Arkansas",
  "California",
  // "Colorado",
  "Georgia",
  "Connecticut",
  "Florida",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kentucky",
  "Louisiana",
  "Maryland",
  "Massachusetts",
  "Nebraska",
  "Montana",
  "Maine",
  "New Mexico",
  "Oregon",
  "Vermont",
  "Wisconsin",
  "Wyoming",
  // "Michigan",
  "Mississippi",
  "New Hampshire",
  "New Jersey",
  "New York",
  "Ohio",
  "Pennsylvania",
  "Tennessee",
  "Texas",
  "Virginia",
  "West Virginia",
].slice(0).sort();

const STATES = [
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arizona", "AZ"],
  ["Arkansas", "AR"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["District Of Columbia", "DC"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
];

const getExtendedTheme = (paperTheme, theme) => ({
  fonts: {
    caption: {
      fontSize: 11,
    },
    p: {
      fontSize: 12,
    },
    h6: {
      fontSize: 13,
    },
    h5: {
      fontSize: 14,
    },
    h4: {
      fontSize: 15,
    },
    h3: {
      fontSize: 16,
    },
    h2: {
      fontSize: 17,
      fontWeight: "bold",
      lineHeight: 24,
    },
    h1: {
      fontSize: 18,
    },
    subtitle: {
      fontSize: 22,
    },
    title: {
      fontSize: 36,
    },
  },
  spacing: {
    xs: 4,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 20,
  },
  colors: {
    ...paperTheme.colors,
    ...theme.colors,
    card: !theme.dark ? "rgb(242, 242, 242)" : "rgb(18, 18, 18)", //added
    // surface: theme.colors.card,
    surface: !theme.dark ? "rgb(255,255,255)" : "rgb(1, 1, 1)", //added
    background: !theme.dark ? "rgb(242, 242, 242)" : "rgb(18, 18, 18)", //added
    accent: theme.dark ? "rgb(255, 55, 95)" : "rgb(255, 45, 85)",
    backgroundBlack: "#0a1218",
    navHeaderBackground: "#33516e",
    textReverse: "#FFFFFF",
    primary: "#28af1d",
    secondary: "#0c70a3",
    error: "#bb1f23",
    labelBackground: "#33516e",
    worst: "#bb1f23",
    bad: "",
    neutral: "#f6ba21",
    good: "",
    best: "#28af1d",
    lowest: "#28ae1d",
    low: "#2592bc",
    middle: "#bc4464",
    high: "#a1adb2",
    highest: "rgb(246,186,33)",
  },
});

const inactiveSports = [];
const LISTING_STATUSES = [
  "ACTIVE",
  "INACTIVE",
  "PENDING",
  "SOLD",
  "DROPPED",
  "ARCHIVED",
];

const disableMoney = false;

const betTypes = {
  STRAIGHT: "Straight",
  PARLAY: "Parlay",
  FUTURE: "Future",
  PROP: "Prop",
};

const currentTicketStatusTutorialNumber = 1;

const withdrawMethods = [
  {
    value: "ACH_STANDARD",
    label: "Bank Account",
  },
  {
    value: "CHECK",
    label: "Check",
  },
];

const ACH_DEPOSIT_FEE = 0.015;
const CC_DEPOSIT_FEE = 0.03;
const ACH_WITHDRAW_FEE = 0;
const PAPER_WITHDRAW_FEE = 0;
const w9Threshold = 60000;
const ungateW9 = false;
const minimumDepositInPennies = 500;

const activityConfig = {
  BUYING: [
    {
      BID_ACCEPTED: {
        description: "Your Bid Has Been Accepted",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      BID_REJECTED: {
        description: "Your Bid Has Been Rejected",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      BID_COUNTERED: {
        description: "Your Bid Has Been Countered",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      BID_OUTBID: {
        description: "You Have Been Outbid",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      BID_EXPIRED: {
        description: "Your Bid Has Expired",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      BID_CANCELLED: {
        description: "Your Bid Has Been Cancelled",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      WATCHLIST_PRICE_DROP: {
        description: "A Watchlist ticket Dropped in Price",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      WATCHLIST_EXPIRING_SOON: {
        description: "A Watchlist Ticket is Expiring Soon",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      LISTING_PURCHASED: {
        description: "When you purchase a listing",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
  ],
  SELLING: [
    {
      LISTING_SOLD: {
        description: "Your Ticket Sold",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: true,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      BID_RECEIVED: {
        description: "You Have Received a Bid",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: true,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      LISTING_EXPIRING_SOON: {
        description: "Your Listing is Expiring Soon",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      LISTING_EXPIRED: {
        description: "Your Listing Has Expired",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
  ],
  NEW_TICKETS: [
    {
      LISTING_NEW: {
        description: "All New Tickets Listed For Sale",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      LISTING_NEW_SELLER: {
        description: "asaddddd",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    // {
    //   LISTING_TEAM_NEW: {
    //     description: "Favorite Team Listed for Sale",
    //     ACTIVITY_FEED: {
    //       mutable: false,
    //       defaultOn: false,
    //       title: null,
    //       body: null
    //     },
    //     NOTIFICATION_CENTER: {
    //       mutable: false,
    //       defaultOn: true,
    //       title: args => null,
    //       body: args => null
    //     },
    //     EMAIL: {
    //       mutable: false,
    //       defaultOn: false,
    //       title: args => null,
    //       body: args => null
    //     },
    //     PUSH: {
    //       mutable: true,
    //       defaultOn: false,
    //       title: args => null,
    //       body: args => null
    //     }
    //   }
    // },
    // {
    //   LISTING_SPORT_NEW: {
    //     description: "Favorite Sport Listed for Sale",
    //     ACTIVITY_FEED: {
    //       mutable: false,
    //       defaultOn: false,
    //       title: null,
    //       body: null
    //     },
    //     NOTIFICATION_CENTER: {
    //       mutable: false,
    //       defaultOn: true,
    //       title: args => null,
    //       body: args => null
    //     },
    //     EMAIL: {
    //       mutable: false,
    //       defaultOn: false,
    //       title: args => null,
    //       body: args => null
    //     },
    //     PUSH: {
    //       mutable: true,
    //       defaultOn: false,
    //       title: args => null,
    //       body: args => null
    //     }
    //   }
    // },
    {
      LISTING_VALUE_NEW: {
        description: "Tickets with the BEST Value Listed for Sale",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
  ],
  USER_ACTIVITY: [
    {
      USER_ACTIVE: {
        description: "",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
      USER_SIGN_IN: {
        description: "",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      USER_SIGN_UP: {
        description: "",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      USER_PROMOTION: {
        description: "",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      TICKET_VIEW: {
        description: "",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      WATCHLIST_NEW: {
        description: "",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      TRANSACTION_DEPOSIT: {
        description: "Deposit Confirmations",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      TRANSACTION_WITHDRAW: {
        description: "Withdraw Confirmations",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: true,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
  ],
  TICKET_ACTIVITY: [
    {
      LISTING_PRICE_INCREASE: {
        description: "",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: true,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      LISTING_MIN_BID_CHANGE: {
        description: "",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      LISTING_EXPIRATION_CHANGED: {
        description: "",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
    {
      LISTING_PRICE_DROP: {
        description: "Price Drop",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: false,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
  ],
  SPECIAL_EVENTS: [
    {
      ADMIN_CUSTOM: {
        description: "Get Alerted For Special Deals",
        ACTIVITY_FEED: {
          mutable: false,
          defaultOn: false,
          title: null,
          body: null,
        },
        NOTIFICATION_CENTER: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        EMAIL: {
          mutable: false,
          defaultOn: true,
          title: args => null,
          body: args => null,
        },
        PUSH: {
          mutable: true,
          defaultOn: false,
          title: args => null,
          body: args => null,
        },
      },
    },
  ],
};

const activityTitles = {
  BUYING: "Buying",
  SELLING: "Selling",
  NEW_TICKETS: "New Tickets",
  USER_ACTIVITY: "User Activity",
  TICKET_ACTIVITY: "Ticket Activity",
  SPECIAL_EVENTS: "Special Events",
};

const defaultEmailPreferences = Object.values(activityConfig)
  .map(items => items)
  .flat(1000)
  .filter(
    item =>
      !!Object.values(item).find(
        config => !!config.EMAIL && !!config.EMAIL.defaultOn
      )
  )
  .map(item => Object.keys(item)[0]);

const defaultPushPreferences = Object.values(activityConfig)
  .map(items => items)
  .flat(1000)
  .filter(
    item =>
      !!Object.values(item).find(
        config => !!config.PUSH && !!config.PUSH.defaultOn
      )
  )
  .map(item => Object.keys(item)[0]);

const commissionWaiverRedemption = 5000;
const commissionAdjust = 0.9;

const userStatusLabels = [
  {
    label: "Rookie",
    color: "lowest",
    range: [0, 499],
  },
  {
    label: "Pro",
    color: "low",
    range: [500, 9999],
  },
  {
    label: "Veteran",
    color: "middle",
    range: [10000, 49999],
  },
  {
    label: "Hall of Fame",
    color: "high",
    range: [50000, 99999],
  },
  {
    label: "G.O.A.T.",
    color: "highest",
    range: [100000],
  },
];

const valueMeter = [
  {
    label: "Poor",
    color: "worst",
    width: "20%",
  },
  {
    label: "OK",
    color: "neutral",
    width: "40%",
  },
  {
    label: "Good",
    color: "best",
    width: "60%",
  },
  {
    label: "Great",
    color: "best",
    width: "80%",
  },
  {
    label: "Best",
    color: "best",
    width: "100%",
  },
];

const activityLabelMap = {
  // LISTING_VALUE_NEW: "Great Value!",
  LISTING_NEW: "New Listing",
  LISTING_SOLD: "Sold - Full Asking Price",
  BID_ACCEPTED: "Sold - Bid Accepted",
  BID_RECEIVED: "Bid Placed",
  LISTING_PRICE_INCREASE: "Price Change - Up",
  LISTING_PRICE_DROP: "Price Change - Down",
  USER_SIGN_UP: "New User",
  USER_PROMOTION: "Level Up",
};

const getTicketFromActivity = activity => {
  const data = activityData(activity);

  if (data?.subject) {
    return data;
  }

  if (data?.ticket) {
    return data.ticket;
  }

  if (data?.listing?.ticket) {
    return data.listing.ticket;
  }

  return data?.cachedTicket ?? data?.listing?.cachedTicket;
};

const getListingFromActivity = activity => {
  const data = activityData(activity);

  if (!!data.listingSellerId) {
    return data;
  } else {
    return data?.listing;
  }
};

const activityData = activity => {
  let data = JSON.parse(activity.data);

  data = typeof data === "string" ? JSON.parse(data) : data;

  return data;
};

const activityBodies = {
  ///NOTIFICATION CENTER
  BID_RECEIVED: activity =>
    `Bid ${formatPennies(
      activityData(activity).offer,
      "$"
    )} on your ${formatPennies(
      getTicketFromActivity(activity)?.collectAmount,
      "$"
    )} ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket`.trim(),
  BID_OUTBID: activity =>
    `Outbid you on the ${formatPennies(
      getTicketFromActivity(activity)?.collectAmount,
      "$"
    )} ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket. The new high bid is ${formatPennies(
      activityData(activity).offer,
      "$"
    )}`.trim(),
  BID_COUNTERED: activity =>
    `Countered your bid of ${formatPennies(
      activityData(activity)?.offer,
      "$"
    )} on the ${formatPennies(
      getTicketFromActivity(activity)?.collectAmount,
      "$"
    )} ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket. They suggest bidding ${formatPennies(
      activityData(activity).counter,
      "$"
    )}`.trim(),
  BID_ACCEPTED: activity =>
    `Accepted your bid of ${formatPennies(
      activityData(activity)?.offer,
      "$"
    )} on the ${formatPennies(
      getTicketFromActivity(activity)?.collectAmount,
      "$"
    )} ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket`.trim(),
  BID_EXPIRED: activity =>
    `Your Bid of ${formatPennies(
      activityData(activity)?.offer,
      "$"
    )} on the ${formatPennies(
      getTicketFromActivity(activity)?.collectAmount,
      "$"
    )} ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket has Expired`.trim(),
  BID_REJECTED: activity =>
    `Rejected your bid of ${formatPennies(
      activityData(activity)?.offer,
      "$"
    )} on the ${formatPennies(
      getTicketFromActivity(activity)?.collectAmount,
      "$"
    )} ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket`.trim(),
  BID_CANCELLED: activity =>
    `Your Bid of ${formatPennies(
      activityData(activity)?.offer,
      "$"
    )} on the ${formatPennies(
      getTicketFromActivity(activity)?.collectAmount,
      "$"
    )} ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket has been cancelled`.trim(),
  LISTING_SOLD: activity =>
    `Purchased your ${formatPennies(
      getTicketFromActivity(activity)?.collectAmount,
      "$"
    )} ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket for ${formatPennies(
      getListingFromActivity(activity)?.salesPrice,
      "$"
    )}`.trim(),
  LISTING_PURCHASED: activity =>
    `Your purchase of the ${formatPennies(
      getTicketFromActivity(activity)?.collectAmount,
      "$"
    )} ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket is complete`.trim(),
  WATCHLIST_EXPIRING_SOON: activity =>
    `Has a ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket expiring soon`.trim(),
  LISTING_EXPIRED: activity =>
    `Your ${formatPennies(
      getTicketFromActivity(activity)?.collectAmount,
      "$"
    )} ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket is no longer listed for sale`.trim(),
};

const activityHeaderTitles = {
  ///GLOBAL
  LISTING_NEW: activity =>
    `${(activity?.subject ?? activity?.user)?.displayName} just listed a ${
      getTicketFromActivity(activity)?.subject
    } ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket for ${formatPennies(
      getListingFromActivity(activity)?.askingPrice,
      "$"
    )}`.trim(),
  BID_RECEIVED: activity =>
    `${activity?.subject?.displayName} placed a bid of ${formatPennies(
      activityData(activity)?.offer,
      "$"
    )} on a ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket`.trim(),
  USER_SIGN_UP: activity =>
    `${
      (activity?.subject ?? activity?.user)?.displayName
    } just joined PropSwap!`.trim(),
  USER_PROMOTION: activity =>
    `${(activity?.subject ?? activity?.user)?.displayName} just moved up from ${
      userStatusLabels[(activity?.user?.status ?? 2) - 2].label
    } to ${
      userStatusLabels[(activity?.user?.status ?? 1) - 1].label
    } status!`.trim(),
  LISTING_PRICE_INCREASE: activity =>
    `${
      (activity?.subject ?? activity?.user)?.displayName
    } raised the price on their ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket to ${formatPennies(
      getListingFromActivity(activity)?.askingPrice,
      "$"
    )}`.trim(),
  LISTING_PRICE_DROP: activity =>
    `${
      (activity?.subject ?? activity?.user)?.displayName
    } dropped the price on their ${getTicketFromActivity(activity)?.subject} ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket to ${formatPennies(
      getListingFromActivity(activity)?.askingPrice,
      "$"
    )}`.trim(),
  BID_ACCEPTED: activity =>
    `${activity?.user?.displayName} purchased a ${
      getTicketFromActivity(activity)?.subject
    } ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket for ${formatPennies(
      getListingFromActivity(activity)?.salesPrice,
      "$"
    )}`.trim(),
  LISTING_SOLD: activity =>
    `${activity?.subject?.displayName} purchased a ${
      getTicketFromActivity(activity)?.subject
    } ${
      getTicketFromActivity(activity)?.eventDescription
    } ticket for ${formatPennies(
      getListingFromActivity(activity)?.salesPrice,
      "$"
    )}`.trim(),
};

const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE";
const THEME_PERSISTENCE_KEY = "THEME_TYPE";

export {
  THEME_PERSISTENCE_KEY,
  NAVIGATION_PERSISTENCE_KEY,
  activityBodies,
  activityLabelMap,
  activityConfig,
  activityTitles,
  activityHeaderTitles,
  defaultEmailPreferences,
  defaultPushPreferences,
  ALLOWED_STATES,
  STATES,
  inactiveSports,
  LISTING_STATUSES,
  betTypes,
  currentTicketStatusTutorialNumber,
  withdrawMethods,
  ACH_DEPOSIT_FEE,
  ACH_WITHDRAW_FEE,
  w9Threshold,
  ungateW9,
  disableMoney,
  commissionWaiverRedemption,
  commissionAdjust,
  userStatusLabels,
  valueMeter,
  CC_DEPOSIT_FEE,
  PAPER_WITHDRAW_FEE,
  minimumDepositInPennies,
  getExtendedTheme,
};
