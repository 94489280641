import React from "react";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
// import AdCell from '../Components/Layout/AdCell'
import Tabs from "Components/Admin/Components/Tabs";
import FanduelListEvents from "Components/Fanduel/FanduelListEvents";
import FanduelListMarketTypes from "Components/Fanduel/FanduelListMarketTypes";
import FanduelListMarketCatalogue from "Components/Fanduel/FanduelListMarketCatalogue";
import FanduelListMarketPrices from "Components/Fanduel/FanduelListMarketPrices";
import queryString from "query-string";

export default ({
  history,
  match: { params = {} } = {},
  location: { search = "", pathname } = {},
}) => {
  const queryParams = queryString.parse(search);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/fanduel"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          {!queryParams.eventTypeId && !queryParams.marketIds && (
            <FanduelListEvents />
          )}
          {!!queryParams.eventTypeId &&
            !queryParams.marketType &&
            !queryParams.marketIds && (
              <FanduelListMarketTypes eventTypeId={queryParams.eventTypeId} />
            )}
          {!!queryParams.eventTypeId &&
            !!queryParams.marketType &&
            !queryParams.marketIds && (
              <FanduelListMarketCatalogue
                eventTypeId={queryParams.eventTypeId}
                marketType={queryParams.marketType}
              />
            )}
          {!!queryParams.marketIds && (
            <FanduelListMarketPrices
              marketIds={queryParams.marketIds.split(",")}
            />
          )}
        </ContentCell>
      </PageContainer>
    </>
  );
};
