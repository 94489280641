import React from "react";
import AdEditView from "./AdEditView";
import AdEditStyles from "./AdEditStyles";
import useStyles from "Hooks/useStyles";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import environment from "environment";

const query = gql`
  query ListAdsByPosition(
    $active: StringBoolean!
    $position: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdsByPosition(
      active: $active
      position: $position
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        lightModeImageUrl
        darkModeImageUrl
        url
        position
        active
      }
      nextToken
    }
  }
`;

const mutation = gql`
  mutation UpdateAd($input: UpdateAdInput!) {
    updateAd(input: $input) {
      id
      createdAt
      updatedAt
      lightModeImageUrl
      darkModeImageUrl
      url
      active
      position
    }
  }
`;

const getQuery = gql`
  query GetAd($id: ID!) {
    getAd(id: $id) {
      id
      createdAt
      updatedAt
      lightModeImageUrl
      darkModeImageUrl
      url
      active
      position
    }
  }
`;

const dataKey = "updateAd";

export default ({ adId }) => {
  const { theme, styles } = useStyles({ Styles: AdEditStyles });
  const { data: { getAd } = {} } = useQuery(getQuery, {
    variables: { id: adId },
  });

  return !getAd ? null : (
    <AdEditView
      theme={theme}
      styles={styles}
      ad={getAd}
      query={query}
      mutation={mutation}
      dataKey={dataKey}
    />
  );
};
