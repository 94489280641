import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  popOver: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: "rgba(0,0,0,0.8)",
    width: theme.spacing(30),
    "& h6": {
      textTransform: "uppercase",
      fontSize: theme.typography.caption.fontSize,
    },
  },
  popOverBalances: {
    backgroundColor: "white",
    display: "flex",
  },
  popOverNav: {
    "list-style-type": "none",
    padding: 0,
    margin: 0,
    "text-transform": "uppercase",
    backgroundColor: "white",
    "& li": {
      borderBottom: `1px solid ${theme.palette.background.default}`,
      padding: theme.spacing(1, 2),
      fontSize: theme.typography.caption.fontSize,
    },
  },
  popOverHeader: {
    textTransform: "uppercase",
    padding: theme.spacing(1),
    "& *": {
      fontSize: theme.typography.caption.fontSize,
      color: "white",
    },
  },
  popOverFooter: {
    textTransform: "uppercase",
    padding: theme.spacing(1),
    "& *": {
      fontSize: theme.typography.caption.fontSize,
      color: "white",
    },
  },
  popOverActions: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    "justify-content": "space-between",
    padding: theme.spacing(0, 0.5),
  },
  popOverSecondaryBalances: {
    border: `1px solid ${theme.palette.background.default}`,
    flex: 1,
    display: "flex",
    "flex-direction": "column",
  },
  popOverSecondaryBalance: {
    borderBottom: `1px solid ${theme.palette.background.default}`,
    padding: theme.spacing(1),
  },
  withdrawButton: {
    color: "#1b94e9",
    textTransform: "none",
  },
  popOverPrimaryBalance: {
    flex: 2,
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "& > div": {
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.background.default,
      textAlign: "center",
      "& h6": {
        fontSize: theme.typography.subtitle1.fontSize,
      },
    },
  },
}));

const MyAccountButton = ({ className, currentUser, history }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => [
    event.preventDefault(),
    setAnchorEl(event.currentTarget),
  ];

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "account-popover" : undefined;

  useEffect(() => {
    handleClose();
  }, [history.location.key]);

  return (
    <>
      <Link
        className={className}
        to={"/account/settings"}
        onClick={handleClick}
      >
        My Account
      </Link>
      <Popover
        PaperProps={{
          className: classes.popOver,
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.popOverWrapper}>
          <div className={classes.popOverHeader}>
            <Typography align={"right"} variant={"h6"}>
              {currentUser.attributes.given_name}{" "}
              {currentUser.attributes.family_name}
            </Typography>
          </div>
          <div className={classes.popOverBalances}>
            <div className={classes.popOverSecondaryBalances}>
              <div className={classes.popOverSecondaryBalance}>
                <Typography variant={"h6"}>Pending</Typography>
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={parseFloat(currentUser.pendingBalance || 0) / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
              <div className={classes.popOverSecondaryBalance}>
                <Typography variant={"h6"}>Bonus</Typography>
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={
                    parseFloat(currentUser.promotionalBalance || 0) / 100.0
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
            </div>
            <div className={classes.popOverPrimaryBalance}>
              <div>
                <Typography variant={"h6"}>Balance</Typography>
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={parseFloat(currentUser.balance || 0) / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
            </div>
          </div>
          <div className={classes.popOverActions}>
            <Button
              component={Link}
              to={"/account/withdraw"}
              size={"small"}
              className={classes.withdrawButton}
            >
              Withdraw
            </Button>
            <Button
              component={Link}
              to={"/account/deposit"}
              size={"small"}
              variant={"contained"}
              color={"secondary"}
            >
              Deposit
            </Button>
          </div>
          <ul className={classes.popOverNav}>
            <li className={classes.popOverNavItem}>
              <Link className={classes.popOverNavLink} to={"/account/tickets"}>
                My Tickets
              </Link>
            </li>
            {currentUser?.groups?.includes?.("Admins") && (
              <li className={classes.popOverNavItem}>
                <Link
                  className={classes.popOverNavLink}
                  to={"/account/watchlist"}
                >
                  Watchlist
                </Link>
              </li>
            )}
            <li className={classes.popOverNavItem}>
              <Link
                className={classes.popOverNavLink}
                to={"/account/transactions"}
              >
                Transaction History
              </Link>
            </li>
            <li className={classes.popOverNavItem}>
              <Link className={classes.popOverNavLink} to={"/account/settings"}>
                Account Settings
              </Link>
            </li>
            <li className={classes.popOverNavItem}>
              <Link
                className={classes.popOverNavLink}
                to={"/account/referrals"}
              >
                Referrals
              </Link>
            </li>
            {!!currentUser?.groups?.includes?.("Admins") && (
              <li className={classes.popOverNavItem}>
                <Link
                  className={classes.popOverNavLink}
                  to={"/account/notification-center"}
                >
                  {!!currentUser?.hideNotificationsCount
                    ? `Notifications`
                    : `Notifications (${currentUser?.notificationsCount ?? 0})`}
                </Link>
              </li>
            )}
            {!!currentUser?.groups?.includes?.("Admins") && (
              <li className={classes.popOverNavItem}>
                <Link
                  className={classes.popOverNavLink}
                  to={"/account/notification-preferences"}
                >
                  Notification Preferences
                </Link>
              </li>
            )}
            {!!currentUser?.groups?.includes?.("Admins") && (
              <li className={classes.popOverNavItem}>
                <Link className={classes.popOverNavLink} to={"/activities"}>
                  Activity Feed
                </Link>
              </li>
            )}
          </ul>
          <div className={classes.popOverFooter}>
            <Typography align={"right"} variant={"h6"}>
              <Link to={`/sign-out`}>Sign Out</Link>
            </Typography>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default withRouter(MyAccountButton);
