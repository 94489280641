import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import CreditCardSelect from "./CreditCardSelect";
import AchSelect from "./AchSelect";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: 0,
    width: "100%",
  },
  group: {
    // margin: theme.spacing(1, 0),
    flexDirection: "row",
  },
  column: {
    flex: 1,
    display: "flex",
    "flex-direction": "column",
  },
  method: {
    marginLeft: theme.spacing(-5),
    marginTop: theme.spacing(0),
    transform: "scale(0.7)",
  },
}));

const Method = ({
  method = "card",
  onChange,
  currentUser,
  selectedCardInfo,
  onCardChange,
  bluesnapVaultedShopper,
  bluesnapToken,
  onAchChange,
  selectedBankInfo,
}) => {
  const classes = useStyles();
  const handleChange = event => onChange(event.target.value);

  return (
    <>
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="method"
            name="method"
            className={classes.group}
            value={method}
            onChange={method => [handleChange(method), onCardChange(null)]}
          >
            <div className={classes.column}>
              <FormControlLabel
                value={"card"}
                control={<Radio />}
                label="Credit/Debit Card"
              />
              {method === "card" && (
                <div className={classes.method}>
                  <CreditCardSelect
                    bluesnapVaultedShopper={bluesnapVaultedShopper}
                    currentUser={currentUser}
                    selectedCardInfo={selectedCardInfo}
                    onChange={onCardChange}
                  />
                </div>
              )}
              <FormControlLabel
                value={"ach"}
                control={<Radio />}
                label="ACH Payment"
              />
              {method === "ach" && (
                <div className={classes.method}>
                  <AchSelect
                    bluesnapVaultedShopper={bluesnapVaultedShopper}
                    currentUser={currentUser}
                    selectedBankInfo={selectedBankInfo}
                    onChange={onAchChange}
                  />
                </div>
              )}
            </div>
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

export default Method;
