import React from "react";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import UserEdit from "../../../../Components/User/Edit";
import Tabs from "../../../../Components/Admin/Components/Tabs";
import { Grid, Typography } from "@material-ui/core";
import UserSubTabsView from "../../../../Components/User/SubTabs";
import { useQuery } from "@apollo/client";
import Listing from "shared/api/Listing";

export default ({ history, match: { params } }) => {
  const {
    error,
    data: { listRecommendedListingsByUser: { items } = {} } = {},
  } = useQuery(Listing.queries.listRecommendedByUser, {
    variables: { userId: params.id },
  });

  // console.log(entry)
  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/users"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Grid container spacing={4}>
            <Grid item xs={12} container>
              <UserSubTabsView
                selected="users"
                history={history}
                userId={params.id}
              />
            </Grid>
          </Grid>
          <UserEdit userId={params.id} />
          <Typography variant="h4">Recommendations</Typography>
          <ul>
            {(items ?? []).map(listing => (
              <li key={listing.id}>
                {listing.ticket.subject} {listing.ticket.eventDescription} -{" "}
                {listing.score}
              </li>
            ))}
          </ul>
        </ContentCell>
      </PageContainer>
    </>
  );
};
