import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import Form from "../../Form";
import Sport from "shared/api/Sport";
import { withoutKeys } from "gunner-react";

const fields = {
  name: {
    label: "Name",
    regex: /\w+/,
    required: true,
    type: "text",
    errorMessage: "You are required enter a name",
  },
  abbrev: {
    label: "Abbreviation",
    regex: /\w+/,
    required: true,
    type: "text",
    errorMessage: "You are required to enter an abbreviation",
  },
  position: {
    label: "Position",
    regex: /\d+/,
    required: true,
    type: "number",
    errorMessage: "You are required to enter a position",
  },
  fanDuelCompetitionId: {
    label: "FanDuel Competition Id",
    regex: /\w+/,
    required: false,
    type: "text",
  }
};

const SportFormContainer = ({ sport }) => {
  const [formData, setFormData] = useState(sport || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: value,
    });

  const [_createSport] = useMutation(Sport.mutations.create, {
    variables: {
      input: {
        ...formData,
      },
    },
    refetchQueries: [
      {
        query: Sport.queries.list,
        variables: { limit: 100 },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      createSport: {
        ...formData,
        __typename: "Sport",
      },
    },
  });

  const [_updateSport] = useMutation(Sport.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, ["__typename"]),
      },
    },
    refetchQueries: [
      {
        query: Sport.queries.list,
        variables: { limit: 100 },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updateSport: {
        ...formData,
        __typename: "Sport",
      },
    },
  });

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateSport : _createSport)() //if this is an existing sport, update it, otherwise, create it
        .then(
          ({
            data: { [!!formData.id ? "updateSport" : "createSport"]: sport },
          }) => setSubmitSuccess(true)
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [_createSport, _updateSport, doSubmit, formData.id]);

  return !!submitSuccess ? (
    <Redirect push to={"/admin/sports"} />
  ) : (
    <Form
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  );
};

export default SportFormContainer;
