import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  dialog: {
    // paddingTop: theme.spacing(7),
    // [theme.breakpoints.up('md')]: {
    //   paddingTop: 0,
    // }
  },
}));

const SimpleModal = ({
  opened,
  body,
  title,
  fullScreen,
  option1,
  option2,
  saving,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      fullScreen={fullScreen}
      open={opened}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!!title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description"> */}
        {body}
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        {saving ? (
          <div
            style={{
              paddingTop: 8,
              paddingBottom: 8,
              display: "flex",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <CircularProgress className={classes.progress} color="secondary" />
          </div>
        ) : (
          <>
            {!!option1 && (
              <Button
                size="large"
                onClick={evt => [
                  evt.stopPropagation(),
                  evt.preventDefault(),
                  option1.onClick(),
                ]}
                color="primary"
              >
                {option1.text}
              </Button>
            )}
            {!!option2 && (
              <Button
                size="large"
                disabled={option2.disabled}
                variant={"contained"}
                color="secondary"
                onClick={evt => [
                  evt.stopPropagation(),
                  evt.preventDefault(),
                  option2.onClick(),
                ]}
              >
                {option2.text}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withMobileDialog()(SimpleModal);
