import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";
// import useRedemptionListStyles from "./RedemptionListStyles";
import DataTable from "../../DataTable";
import UserLink from "../../User/Link";
import Transaction from "shared/api/Transaction";
import Promotion from "shared/api/Promotion";
import NumberFormat from "react-number-format";
import { useQuery } from "@apollo/client";

const TransactionAmount = ({ userId, promotionId }) => {
  const {
    error,
    loading,
    data: {
      listTransactionsByPromotionIdUserId: { items: transactions = [] } = {},
    } = {},
  } = useQuery(Transaction.queries.listByPromotionIdUserId, {
    variables: {
      limit: 100,
      transactionPromotionId: promotionId,
      transactionUserId: {
        eq: userId,
      },
    },
  });

  !!error && console.log(error);

  return !!loading || !transactions[0] ? null : (
    <NumberFormat
      fixedDecimalScale
      decimalScale={2}
      value={parseFloat(transactions[0].amount || 0) / 100.0}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
};

const BonusAmount = ({ userId, promotionId }) => {
  const {
    error,
    loading,
    data: {
      listTransactionsByPromotionIdUserId: { items: transactions = [] } = {},
    } = {},
  } = useQuery(Transaction.queries.listByPromotionIdUserId, {
    variables: {
      limit: 100,
      transactionPromotionId: promotionId,
      transactionUserId: {
        eq: userId,
      },
    },
  });

  const { data: { getPromotion } = {} } = useQuery(Promotion.queries.get, {
    variables: { id: promotionId },
  });
  const bonusAmount =
    getPromotion?.type === "DEPOSIT_MATCH_500"
      ? 50000
      : getPromotion?.type === "DEPOSIT_MATCH_100"
      ? 10000
      : 0;

  !!error && console.log(error);

  return !!loading || !transactions[0] ? null : (
    <NumberFormat
      fixedDecimalScale
      decimalScale={2}
      value={
        parseFloat(Math.min(transactions[0].amount, bonusAmount) || 0) / 100.0
      }
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
};

const fields = {
  User: {
    value: item => <UserLink id={item.redemptionUserId} />,
    sort: null,
  },
  Amount: {
    value: item => (
      <TransactionAmount
        userId={item.redemptionUserId}
        promotionId={item.redemptionPromotionId}
      />
    ),
    sort: null,
  },
  Bonus: {
    value: item => (
      <BonusAmount
        userId={item.redemptionUserId}
        promotionId={item.redemptionPromotionId}
      />
    ),
    sort: null,
  },
  Created: {
    value: item => moment(item.createdAt).format("MM/DD/YYYY H:mm"),
    sort: order => (a, b) =>
      a.createdAt > b.createdAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
};

const RedemptionListView = ({ redemptions }) => (
  <DataTable fields={fields} items={redemptions} />
);

export default RedemptionListView;
