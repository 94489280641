import React, { useState, useEffect } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import queryString from "query-string";
import moment from "moment";
import { Link } from "react-router-dom";

import { Auth } from "aws-amplify";

import CognitoIdentityServiceProvider from "aws-sdk/clients/cognitoidentityserviceprovider";

import ContentCell from "../../Layout/ContentCell";
import PageContainer from "../../Layout/PageContainer";
import Tabs from "../Components/Tabs";

import awsmobile from "../../../aws-exports";
import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import Cognito from "shared/api/Cognito";
import User from "shared/api/User";
import Rating from "../../Rating";
import { downloadCsv } from "gunner-react";
import FileUploader from "Components/FileUploader";

const useStyles = makeStyles(theme => ({
  searchCell: {
    marginBottom: theme.spacing(1),
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
}));

const cognitoClient = () =>
  Auth.currentCredentials().then(credentials =>
    Promise.resolve(
      new CognitoIdentityServiceProvider({
        apiVersion: "2016-04-18",
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      })
    )
  );

const UserRow = ({ cognitoUser, onClick }) => {
  const { error, data: { usersByEmail: { items = [] } = {} } = {} } = useQuery(
    User.queries.listByEmail,
    {
      variables: {
        email: cognitoUser.Attributes.find(a => a.Name === "email").Value,
      },
    }
  );

  !!error && console.error(error)

  return (
    <TableRow>
      <TableCell style={{ whiteSpace: "nowrap" }}>
        {cognitoUser.Attributes.find(a => a.Name === "email").Value}
      </TableCell>
      <TableCell style={{ whiteSpace: "nowrap" }}>
        {cognitoUser.Attributes.find(a => a.Name === "family_name")?.Value}
        ,&nbsp;
        {cognitoUser.Attributes.find(a => a.Name === "given_name")?.Value}
      </TableCell>
      <TableCell style={{ whiteSpace: "nowrap" }}>
        {cognitoUser.Username}
      </TableCell>
      <TableCell style={{ whiteSpace: "nowrap" }}>
        {!!items.length && moment(items[0].createdAt).format("MM/DD/YYYY")}
      </TableCell>
      <TableCell style={{ whiteSpace: "nowrap" }}>
        <Rating rating={!!items[0] ? items[0].rating || 1 : 0} />
      </TableCell>
      <TableCell style={{ whiteSpace: "nowrap" }}>
        {!!items.length && (
          <Button
            component={Link}
            to={`/admin/users/${items[0].id}/edit`}
            size="small"
            variant="contained"
            color="secondary"
          >
            View
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

const Users = ({ history, location: { search = "" } = {} }) => {
  const classes = useStyles();
  const searchParams = queryString.parse(search);
  const [email, setEmail] = useState(searchParams.email);
  const [lastName, setLastName] = useState(searchParams.lastName);
  const [displayName, setDisplayName] = useState();
  const [cognitoUsers, setCognitoUsers] = useState([]);
  const [paginationToken, setPaginationToken] = useState(null);
  const [loadMore, setLoadMore] = useState(false);
  const [doDownload, setDoDownload] = useState(false);
  const client = useApolloClient();

  const [
    lookUpUserByDisplayName,
    {
      loading,
      error,
      data: { listUsersByDisplayName: { items: users } = {} } = {},
    },
  ] = useLazyQuery(User.queries.listByDisplayName, {
    variables: {
      displayName,
    },
  });

  const handleFileUpload = csvData =>
    console.log(csvData.split("\r").length) ||
    Promise.all(
      csvData
        .split("\n")
        .slice(1)
        .map(
          line =>
            console.log(line) ||
            client
              .query({
                fetchPolicy: "network-only",
                query: User.queries.listByEmail,
                variables: {
                  limit: 1,
                  email: line.split(",")[0].trim(),
                },
              })
              .then(
                ({
                  data: {
                    usersByEmail: { items },
                  },
                }) =>
                  !!items[0] &&
                  client.mutate({
                    mutation: User.mutations.update,
                    variables: {
                      input: {
                        id: items[0].id,
                        buyOrSellTransactionCount: parseInt(
                          line.split(",")[1].trim()
                        ),
                      },
                    },
                  })
              )
        )
    );

  useEffect(() => {
    !!users?.length && history.push(`/admin/users/${users[0].id}/edit`);
  }, [users, history]);

  useEffect(() => {
    setCognitoUsers([]);
    setPaginationToken(null);
    history.push(
      "?" +
        queryString.stringify({
          ...searchParams,
          email,
        })
    );
  }, [email]);

  useEffect(() => {
    setCognitoUsers([]);
    setPaginationToken(null);
    history.push(
      "?" +
        queryString.stringify({
          ...searchParams,
          lastName,
        })
    );
  }, [lastName]);

  useEffect(() => {
    (!!email || !!lastName) &&
      cognitoClient()
        .then(client =>
          client
            .listUsers({
              UserPoolId: awsmobile.aws_user_pools_id,
              AttributesToGet: null,
              Limit: 60,
              Filter: !!email
                ? `email ^= "${!!email && email.length > 0 ? email : "<>"}"`
                : `family_name ^= "${lastName}"`,
            })
            .promise()
        )
        .then(({ Users, PaginationToken }) => [
          setPaginationToken(PaginationToken),
          setCognitoUsers(Users),
        ]);
  }, [email, lastName]);

  useEffect(() => {
    !!loadMore &&
      (!!email || !!lastName) &&
      cognitoClient()
        .then(client =>
          client
            .listUsers({
              UserPoolId: awsmobile.aws_user_pools_id,
              AttributesToGet: null,
              Limit: 60,
              PaginationToken: paginationToken,
              Filter: !!email
                ? `email ^= "${!!email && email.length > 0 ? email : "<>"}"`
                : `family_name ^= "${lastName}"`,
            })
            .promise()
        )
        .then(({ Users, PaginationToken }) => [
          setLoadMore(false),
          setPaginationToken(PaginationToken),
          setCognitoUsers([...cognitoUsers, ...Users]),
        ]);
  }, [loadMore, email, lastName]);

  const entry = useQuery(Cognito.queries.dump, {
    skip: !doDownload,
  });

  // console.log(entry?.data?.dump)

  useEffect(() => {
    !!entry?.data?.dump &&
      Promise.resolve(downloadCsv(entry?.data?.dump)).then(() =>
        setDoDownload(false)
      );
  }, [entry?.data?.dump]);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/users"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Grid
            className={classes.searchCell}
            container
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label={"Email"}
                onChange={({ target: { value } }) => setEmail(value)}
                value={email}
                type="email"
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label={"Last Name"}
                onChange={({ target: { value } }) => setLastName(value)}
                value={lastName}
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                onClick={() => setDoDownload(true)}
                variant="contained"
                color="secondary"
                fullWidth
                disabled={!!doDownload}
              >
                {!!doDownload ? "Preparing..." : "Download"}
              </Button>

              <FileUploader
                variant="contained"
                color="secondary"
                fullWidth
                onFile={handleFileUpload}
              >
                Upload Count
              </FileUploader>
            </Grid>
          </Grid>
          <Grid
            className={classes.searchCell}
            container
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={9}>
              <TextField
                fullWidth
                label={"Display Name"}
                onChange={({ target: { value } }) => setDisplayName(value)}
                value={displayName}
                variant={"outlined"}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                onClick={lookUpUserByDisplayName}
                variant="contained"
                color="secondary"
                fullWidth
              >
                Look Up
              </Button>
            </Grid>
          </Grid>
          <div className={classes.root}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell component="th">Email</TableCell>
                  <TableCell component="th">Name</TableCell>
                  <TableCell component="th">Id</TableCell>
                  <TableCell component="th">Created At</TableCell>
                  <TableCell component="th">Rating</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {cognitoUsers.map(cu => (
                  <UserRow
                    key={cu.Username}
                    cognitoUser={cu}
                    onClick={cu =>
                      history.push(`/admin/users/${cu.Username}/edit`)
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </div>
          {!!paginationToken && (
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="secondary"
              disabled={!!loadMore}
              onClick={() => setLoadMore(true)}
            >
              {!!loadMore ? "Loading..." : "Load More"}
            </Button>
          )}

          {/* <UserManagement email={email} /> */}
        </ContentCell>
      </PageContainer>
    </>
  );
};

export default Users;
