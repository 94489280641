import { useMutation } from "@apollo/client";
import { CurrentUserContext } from "gunner-react";
import React, { useCallback, useContext, useState } from "react";
import User from "shared/api/User";
import UserConvertPointsToCashView from "./UserConvertPointsToCashView";

export default ({}) => {
  const [amount, setAmount] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const currentUser = useContext(CurrentUserContext);
  const [convertPointsToCash] = useMutation(
    User.mutations.convertPointsToCash,
    {
      variables: {
        input: {
          amount,
        },
      },
      refetchQueries: [
        {
          query: User.queries.listByEmail,
          variables: {
            email: currentUser?.email,
            withPrivate: true,
            limit: 1,
          },
        },
      ],
    }
  );

  const handleSubmit = useCallback(
    () =>
      Promise.all([
        setSubmitting(true),
        convertPointsToCash()
          .catch(e => [console.log(e), window.alert(JSON.stringify(e))])
          .finally(() => setSubmitting(false)),
      ]),
    [convertPointsToCash]
  );

  return (
    <UserConvertPointsToCashView
      submitting={submitting}
      amount={amount}
      onChange={setAmount}
      user={currentUser}
      onSubmit={handleSubmit}
    />
  );
};
