import React from "react";

import Form from "../Form";
import { useQuery } from "@apollo/client";
import Link from "shared/api/Link";

export default ({ id }) => {
  const { data: { getLink } = {} } = useQuery(Link.queries.get, {
    variables: { id },
  });

  return !getLink ? null : <Form link={getLink} />;
};
