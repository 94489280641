import { useEffect, useContext } from "react";
import TicketView from "shared/api/TicketView";
import { useMutation } from "@apollo/client";
import { CurrentUserContext } from "gunner-react";

export default ({ ticketId, skip }) => {
  const currentUser = useContext(CurrentUserContext);
  const [createTicketView, { data: { createTicketView: newTicketView } = {} }] =
    useMutation(TicketView.mutations.create, {
      variables: {
        input: {
          ticketId,
          ticketViewUserId: currentUser?.id ?? undefined,
        },
      },
    });

  useEffect(() => {
    !!ticketId && !skip && createTicketView();
  }, [ticketId, createTicketView, skip]);

  return newTicketView;
};
