import React, { useState, useEffect } from "react";
import Item from "../../Listing/Components/Item";
import SellerBidCard from "../../Bid/Components/SellerCard";
import BuyerBidCard from "../../Bid/Components/BuyerCard";
import Edit from "../../Listing/Screens/Edit";
import New from "../../Listing/Screens/New";
import {
  Button,
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import Ticket from "shared/api/Ticket";
import Listing from "shared/api/Listing";
import UserLink from "../../User/Link";

const TicketOptionsButton = ({ ticket }) => {
  //TODO: move to export
  const [action, setAction] = useState(null);

  const handleSelect = action => () => [setAction(action)];

  return ticket.result !== "UNDECIDED" ||
    (!!ticket.listing &&
      !["ACTIVE", "INACTIVE"].includes(ticket.listing.status)) ? null : (
    <>
      <Button
        color="primary"
        size="small"
        variant="text"
        onClick={handleSelect(!!ticket.listing ? "edit" : "list")}
      >
        {
          //!!ticket.listing ? `Edit ${ticket?.listing?.status} - ${ticket.result}` : `List ${ticket?.listing?.status} - ${ticket.result}`
          !!ticket.listing ? `Edit` : `List`
        }
      </Button>
      {action === "edit" && (
        <Edit
          match={{ params: { id: ticket.listing.id } }}
          onClose={() => setAction(null)}
        />
      )}
      {action === "list" && (
        <New
          match={{ params: { ticketId: ticket.id } }}
          onClose={() => setAction(null)}
        />
      )}
    </>
  );
};

const ActionIcon = ({ isForSale }) => (
  <Box ml={-2} mr={2}>
    <img
      src={
        !!isForSale
          ? require("../../../assets/css/for-sale-icon.png")
          : require("../../../assets/css/not-for-sale-icon.png")
      }
      alt="Sale"
    />
  </Box>
);

const ResultForm = ({ ticket }) => {
  const [result, setResult] = useState(ticket.result);
  const [inPropswapsPossession, setInPropswapsPossession] = useState(
    ticket.inPropswapsPossession
  );
  const [reviewed, setReviewed] = useState(ticket.reviewed);
  const [isMobile, setIsMobile] = useState(ticket.isMobile);

  const [_updateTicket] = useMutation(Ticket.mutations.update, {
    variables: {
      input: {
        id: ticket.id,
        result,
        inPropswapsPossession,
        reviewed,
        isMobile,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateTicket: {
        __typename: "Ticket",
        ...ticket,
        result,
        inPropswapsPossession,
        reviewed,
        isMobile,
      },
    },
    refetchQueries: [
      {
        query: Ticket.queries.listByResultUpdatedAt,
        variables: {
          result: ticket.result,
          nextToken: null,
          sortDirection: "DESC",
          limit: 100,
        },
      },
      {
        query: Ticket.queries.listByResultUpdatedAt,
        variables: {
          result: result,
          nextToken: null,
          sortDirection: "DESC",
          limit: 100,
        },
      },
    ],
  });

  useEffect(() => {
    !!result &&
      result !== ticket.result &&
      _updateTicket().then(console.log).catch(console.log);
  }, [result, ticket.result]);

  useEffect(() => {
    reviewed !== ticket.reviewed &&
      _updateTicket().then(console.log).catch(console.log);
  }, [reviewed, ticket.reviewed]);

  useEffect(() => {
    isMobile !== ticket.isMobile &&
      _updateTicket().then(console.log).catch(console.log);
  }, [isMobile, ticket.isMobile]);

  useEffect(() => {
    inPropswapsPossession !== ticket.inPropswapsPossession &&
      _updateTicket().then(console.log).catch(console.log);
  }, [inPropswapsPossession, ticket.inPropswapsPossession]);

  return (
    <Box mt={-3} component="form" width={1}>
      <Grid container alignContent={"center"} alignItems={"center"}>
        <Grid item xs={12} md={3}>
          <FormControl>
            <FormGroup
              onChange={({ target: { checked } }) => setIsMobile(!!checked)}
              row
            >
              <FormControlLabel
                control={<Checkbox checked={!!isMobile} color="primary" />}
                label="Mobile?"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl>
            <FormGroup
              onChange={({ target: { checked } }) =>
                setInPropswapsPossession(!!checked)
              }
              row
            >
              <FormControlLabel
                control={
                  <Checkbox checked={!!inPropswapsPossession} color="primary" />
                }
                label="Possession?"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl>
            <FormGroup
              onChange={({ target: { checked } }) => setReviewed(!!checked)}
              row
            >
              <FormControlLabel
                control={<Checkbox checked={!!reviewed} color="primary" />}
                label="Reviewed?"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            select
            fullWidth
            variant="outlined"
            margin="dense"
            label="Result"
            value={result || "UNDECIDED"}
            onChange={({ target: { value } }) => setResult(value)}
          >
            {["WIN", "LOSS", "PUSH", "UNDECIDED"].map(result => (
              <MenuItem key={result} value={result}>
                {result}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box mb={{ xs: 3, sm: 3 }}>
            <Typography variant="body1">
              <strong>Current Owner Id:&nbsp;</strong>
              <UserLink skipLoading id={ticket.ticketUserId} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const StatusForm = ({ listing }) => {
  const [status, setStatus] = useState(listing.status);
  const [featuredAt, setFeaturedAt] = useState(listing.featuredAt);

  const [_updateListing] = useMutation(Listing.mutations.update, {
    variables: {
      input: {
        id: listing.id,
        status,
        featuredAt,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateListing: {
        __typename: "Listing",
        ...listing,
        status,
        featuredAt,
      },
    },
    refetchQueries: [
      {
        query: Listing.queries.listByStatusWithBids,
        variables: {
          status: listing.status,
          nextToken: null,
          sortDirection: "DESC",
          limit: 50,
        },
      },
      {
        query: Listing.queries.listByStatusWithBids,
        variables: {
          status: status,
          nextToken: null,
          sortDirection: "DESC",
          limit: 50,
        },
      },
    ],
  });

  useEffect(() => {
    status !== listing.status &&
      _updateListing().then(console.log).catch(console.log);
  }, [status, listing.status]);

  useEffect(() => {
    !!featuredAt !== !!listing.featuredAt &&
      _updateListing().then(console.log).catch(console.log);
  }, [featuredAt, listing.featuredAt]);

  return (
    <Box mt={-2} component="form" width={1}>
      <Grid container alignContent={"center"} alignItems={"center"}>
        <Grid item xs={12} md={3}>
          <FormControl>
            <FormGroup
              onChange={({ target: { checked } }) =>
                setFeaturedAt(!!checked ? new Date().toISOString() : null)
              }
              row
            >
              <FormControlLabel
                control={<Checkbox checked={!!featuredAt} color="primary" />}
                label="Featured?"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            select
            fullWidth
            variant="outlined"
            margin="dense"
            label="Status"
            value={status || "ACTIVE"}
            onChange={({ target: { value } }) => setStatus(value)}
          >
            {[
              "ACTIVE",
              "INACTIVE",
              "DROPPED",
              "PENDING",
              "SOLD",
              "ARCHIVED",
            ].map(status => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );
};

const List = ({
  tickets,
  history,
  currentUser,
  status,
  view = "public",
  filters = {},
}) => {
  return tickets
    .filter(ticket =>
      Object.entries(filters).every(([key, value]) => !!ticket[key] === !!value)
    )
    .map(ticket => (
      <Grid
        key={ticket.listing ? ticket.listing.id : ticket.id}
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
          md={
            ["ACTIVE", "PENDING"].includes(status) || view === "bids" ? 8 : 12
          }
          lg={
            ["ACTIVE", "PENDING"].includes(status) || view === "bids" ? 9 : 12
          }
          style={{ marginBottom: -32 }}
        >
          <Item
            view={view}
            status={status}
            currentUser={currentUser}
            listing={ticket.listing}
            history={history}
            ticket={ticket}
            bids={
              !!ticket.listing
                ? ticket.listing.bids.items.filter(
                    bid => !["CANCELLED", "REJECTED"].includes(bid.status)
                  )
                : []
            }
            HeaderEndAdornment={
              view !== "results" &&
              currentUser.id === ticket.ticketUserId && (
                <TicketOptionsButton ticket={ticket} />
              )
            }
            ContentStartAdornment={
              status === "ACTIVE" && (
                <ActionIcon
                  isForSale={
                    !!ticket.listing && ticket.listing.status === "ACTIVE"
                  }
                />
              )
            }
            Actions={
              view === "results" ? (
                <ResultForm ticket={ticket} />
              ) : view === "status" ? (
                <StatusForm listing={ticket.listing} ticket={ticket} />
              ) : null
            }
          />
        </Grid>
        {status === "ACTIVE" && (
          <Grid
            item
            xs={12}
            md={["ACTIVE", "PENDING"].includes(status) ? 4 : 12}
            lg={["ACTIVE", "PENDING"].includes(status) ? 3 : 12}
          >
            <SellerBidCard
              currentUser={currentUser}
              listing={ticket.listing}
              ticket={ticket}
              bidCount={!!ticket.listing ? ticket.listing.bidCount : 0}
              activeBid={
                !!ticket.listing
                  ? ticket.listing.bids.items.filter(bid =>
                      ["PENDING"].includes(bid.status)
                    )[0]
                  : null
              }
            />
          </Grid>
        )}
        {view === "bids" && (
          <Grid item xs={12} md={4} lg={3}>
            <BuyerBidCard
              currentUser={currentUser}
              listing={ticket.listing}
              ticket={ticket}
              bidCount={!!ticket.listing ? ticket.listing.bidCount : 0}
              activeBid={
                !!ticket.listing
                  ? ticket.listing.bids.items.filter(bid =>
                      ["PENDING"].includes(bid.status)
                    )[0]
                  : null
              }
              bid={ticket.bid}
              history={history}
              status={status}
            />
          </Grid>
        )}
      </Grid>
    ));
};

export default List;
