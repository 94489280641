import React from "react";
import { Button, TableCell, TableRow } from "@material-ui/core";

const EventOptionsListItemView = ({optionGroup, onEliminateClick, onWinnerClick, loading, outcome}) =>
  <TableRow>
    <TableCell>{optionGroup.option.name}</TableCell>
    <TableCell>{outcome}</TableCell>
    <TableCell>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={onWinnerClick}
        disabled={!!loading || !!outcome}
      >
        Winner  
      </Button>
    </TableCell>
    <TableCell>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={onEliminateClick}
        disabled={!!loading || !!outcome}
      >
        Eliminate  
      </Button>
    </TableCell>
  </TableRow>

export default EventOptionsListItemView