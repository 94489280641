import React from "react";
import ListingRotatorView from "./ListingRotatorView";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  featuredList: {
    padding: theme.spacing(1),
    backgroundImage: `url(${require("../../../assets/css/featured-background.jpg")})`,
    backgroundColor: "#383837",
    backgroundAttachment: "scroll",
    // backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "repeat",
    marginBottom: theme.spacing(4),
    // '& .wrapper': {
    //   margin: theme.spacing(2)
    // }
  },
}));

export default () => {
  const classes = useStyles();

  return <ListingRotatorView classes={classes} />;
};
