import React, { useEffect } from "react";
import { Redirect as DomRedirect, useLocation } from "react-router-dom";

import useStyles from "./LayoutStyles";
import LayoutView from "./LayoutView";
import { useContext } from "react";
import { CurrentUserContext } from "gunner-react";
import { useQuery } from "@apollo/client";
import Redirect from "shared/api/Redirect";
import ReactGA from "react-ga";

const FullRedirect = ({ destination }) => {
  useEffect(() => {
    if (!!destination) {
      console.log("GOT A REDIRECT");
      window.location.assign(destination);
    }
  }, [destination]);

  return null;
};

const LayoutContainer = ({ children, showNav }) => {
  const classes = useStyles();
  const location = useLocation();
  const currentUser = useContext(CurrentUserContext);

  const { error, data: { listRedirectsBySource: { items } = {} } = {} } =
    useQuery(Redirect.queries.listBySource, {
      variables: {
        source: window.location.pathname?.toLowerCase?.(),
      },
    });

  useEffect(() => {
    !!currentUser?.id && //ReactGA.set({ dimension1: user.id });
      ReactGA.set({ dimension1: currentUser.id });
  }, [currentUser]);

  !!error && console.log(error);

  return !!items?.length ? (
    <FullRedirect destination={items[0].destination} />
  ) : (
    <LayoutView
      showNav={showNav}
      classes={classes}
      location={location}
      currentUser={currentUser}
    >
      {children}
    </LayoutView>
  );
};

export default LayoutContainer;
