import React from "react";
import { DataTable } from "gunner-react/web";
import Sportsbook from "shared/api/Sportsbook";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const fields = {
  Name: {
    value: item => item.name,
    nowrap: true,
    sort: order => (a, b) =>
      a.name > b.name ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
    rowProps: {},
  },
  "Active?": {
    value: item => (!!item.active ? "Yes" : "No"),
    nowrap: true,
    sort: order => (a, b) =>
      a.active > b.active
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/sportsbooks/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
};

export default ({ showInactive }) => (
  <DataTable
    fields={fields}
    search={null}
    dataListParams={{
      query: Sportsbook.queries.list,
      dataKey: "listSportsbooks",
      variables: {
        ...(!!showInactive ? {} : { filter: { active: { eq: true } } }),
      },
    }}
  />
);
