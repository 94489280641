import React from "react";
import { useApolloClient } from "@apollo/client";
import { Box, Grid, MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import { useCallback, useEffect, useState } from "react";

import ContentCell from "../../Layout/ContentCell";
import PageContainer from "../../Layout/PageContainer";
import Search from "../../Listing/Components/Search";
import Tabs from "../Components/Tabs";

import { Cache } from "aws-amplify";
import TicketList from "Components/Ticket/List";
import Ticket from "shared/api/Ticket";
import InnerTabs from "../../Layout/InnerTabs";

const useStyles = makeStyles(theme => ({
  searchCell: {
    marginBottom: theme.spacing(1),
  },
}));

const Tickets = ({ history, location: { search = "" } = {} }) => {
  const [changeToResult, setChangeToResult] = useState(null);
  const classes = useStyles();
  const searchParams = queryString.parse(search);
  const [searchString, setSearchString] = useState(
    searchParams.searchString || ""
  );
  const [tickets, setTickets] = useState([]);
  const [result, setResult] = useState(searchParams.result || "UNDECIDED");
  const [isMobile, setIsMobile] = useState(
    !!searchParams.isMobile ? searchParams.isMobile === "true" : null
  );
  const [inPropswapsPossession, setInPropswapsPossession] = useState(
    !!searchParams.inPropswapsPossession
      ? searchParams.inPropswapsPossession === "true"
      : null
  );
  const [reviewed, setReviewed] = useState(
    !!searchParams.reviewed ? searchParams.reviewed === "true" : null
  );
  const client = useApolloClient();

  // const handleTicketsChange = useCallback((items) =>
  //    setTickets(items)
  // )

  // const clientFilter = useCallback(item =>
  //   (item.result === result || !result) &&
  //   Object.entries(withoutBlanks({
  //     reviewed,
  //     inPropswapsPossession,
  //     isMobile,
  //   })).every(([key, value]) => !!item[key] === !!value)
  // ,[reviewed, inPropswapsPossession, isMobile, result])

  const clientFilter = useCallback(
    item =>
      (item.result === result || !result) &&
      // !!searchString &&
      // searchString.length > 2 &&
      (!searchString ||
        searchString.length < 3 ||
        new RegExp(searchString, "i").test(item.eventDescription) ||
        new RegExp(searchString, "i").test(item.subject)),
    [result, searchString]
  );

  useEffect(() => {
    !!changeToResult &&
      Promise.all(
        tickets
          .filter(ticket => ticket.result === result || !result)
          .filter(
            ticket =>
              (JSON.parse(Cache.getItem("CheckedTickets")) || []).indexOf(
                ticket.id
              ) > -1
          )
          // .filter(clientFilter)
          .map(
            ticket =>
              console.log("CHANGING", ticket.id) ||
              client
                .mutate({
                  mutation: Ticket.mutations.update,
                  variables: {
                    input: {
                      id: ticket.id,
                      result: changeToResult,
                    },
                  },
                })
                .then(console.log)
                .catch(console.log)
          )
      )
        .then(() => [
          setChangeToResult(null),
          setResult(changeToResult),
          Cache.removeItem("CheckedTickets"),
        ])
        .catch(console.log);
  }, [
    changeToResult,
    reviewed,
    inPropswapsPossession,
    isMobile,
    tickets.length,
    result,
  ]);

  useEffect(() => {
    history.push(
      "?" +
        queryString.stringify({
          ...searchParams,
          searchString,
        })
    );
  }, [searchString]);

  useEffect(() => {
    history.push(
      "?" +
        queryString.stringify({
          ...searchParams,
          result,
        })
    );
  }, [result]);

  useEffect(() => {
    history.push(
      "?" +
        queryString.stringify({
          ...searchParams,
          isMobile,
        })
    );
  }, [isMobile]);

  useEffect(() => {
    history.push(
      "?" +
        queryString.stringify({
          ...searchParams,
          inPropswapsPossession,
        })
    );
  }, [inPropswapsPossession]);

  useEffect(() => {
    history.push(
      "?" +
        queryString.stringify({
          ...searchParams,
          reviewed,
        })
    );
  }, [reviewed]);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/tickets"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={5} container>
              <Box>
                <InnerTabs
                  items={["WIN", "LOSS", "PUSH", "UNDECIDED"].map(result => ({
                    label: result,
                    value: result,
                  }))}
                  selected={result}
                  onChange={result => setResult(result)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={7}>
              <TextField
                select
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                margin="dense"
                label="Set Selected To"
                value={changeToResult ?? ""}
                onChange={({ target: { value } }) => setChangeToResult(value)}
              >
                <MenuItem value={""} />
                {["WIN", "LOSS", "PUSH", "UNDECIDED"].map(result => (
                  <MenuItem key={result} value={result}>
                    {result}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            className={classes.searchCell}
            container
            spacing={2}
            alignItems="center"
          >
            <Search searchString={searchString} onChange={setSearchString} />
          </Grid>
          {/* <Grid className={classes.searchCell} container spacing={2} alignItems="center">
            <Grid item xs={6} md={4}>
              <TextField
                fullWidth
                label={"Mobile?"}
                select
                onChange={({target: {value}}) => setIsMobile(value)}
                value={isMobile ?? ""}
                variant={"outlined"}
                SelectProps={{displayEmpty: true}}
                InputLabelProps={{shrink: true}}
              >
                <MenuItem value={""} />
                <MenuItem value={true}>
                  Yes
                </MenuItem>
                <MenuItem value={false}>
                  No
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                fullWidth
                label={"Possession?"}
                select
                onChange={({target: {value}}) => setInPropswapsPossession(value)}
                value={inPropswapsPossession ?? ""}
                variant={"outlined"}
                SelectProps={{displayEmpty: true}}
                InputLabelProps={{shrink: true}}
              >
                <MenuItem value={""} />
                <MenuItem value={true}>
                  Yes
                </MenuItem>
                <MenuItem value={false}>
                  No
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                fullWidth
                label={"Reviewed?"}
                select
                onChange={({target: {value}}) => setReviewed(value)}
                value={reviewed ?? ""}
                variant={"outlined"}
                SelectProps={{displayEmpty: true}}
                InputLabelProps={{shrink: true}}
              >
                <MenuItem value={""} />
                <MenuItem value={true}>
                  Yes
                </MenuItem>
                <MenuItem value={false}>
                  No
                </MenuItem>
              </TextField>
            </Grid>
          </Grid> */}

          <Box mb={4}>
            <a
              href={`${process.env.REACT_APP_API_BASE}/tickets/stream`}
              target="_blank" rel="noreferrer"
            >
              Export Ticket Data
            </a>
          </Box>

          <TicketList
            clientFilter={clientFilter}
            searchString={(searchString ?? "").length < 3 ? "" : searchString}
            result={result}
            reviewed={reviewed}
            inPropswapsPossession={inPropswapsPossession}
            isMobile={isMobile}
            onItemsChange={setTickets}
          />
        </ContentCell>
      </PageContainer>
    </>
  );
};

export default Tickets;
