import React from "react";
import { useQuery } from "@apollo/client";
import EventListView from "./EventListView";
import Event from "shared/api/Event";
import { CircularProgress } from "@material-ui/core";

const EventListContainer = ({ sportId, showInactive }) => {
  const {
    loading,
    error, 
    data: { [!showInactive ? "listEventsBySportIdAndIsActive" : "listEventsBySportId"]: { items: events = [] } = {} } = {},
  } = useQuery(Event.queries[!showInactive ? "listBySportIdAndIsActive" : "listBySportId"], {
    variables: {
      limit: 2000,
      sportId,
      // filter: {
      ...(!showInactive ? {isActive: {eq: "x"}} : {}),
      // }
    },
  });

  !!error && console.error(error);

  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <EventListView showInactive={showInactive} events={events} />
  );
};

export default EventListContainer;
