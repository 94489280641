import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";

import { CurrentUserContext } from "gunner-react";

import ContentCell from "Components/Layout/ContentCell";
import AdCell from "Components/Layout/AdCell";
import PageContainer from "Components/Layout/PageContainer";
import Device from "shared/api/Device";
import User from "shared/api/User";
import { activityConfig, activityTitles } from "shared/Config";
// import PageContainer from '../Layout/PageContainer';

export default ({
  history,
  match: { params = {} } = {},
  location: { search = "", pathname } = {},
}) => {
  const currentUser = useContext(CurrentUserContext);

  const { data: { listDevicesByUserId: { items: devices } = {} } = {} } =
    useQuery(Device.queries.listByUserId, {
      skip: !currentUser?.id,
      variables: {
        userId: currentUser?.id,
      },
    });

  const [updatePreferences] = useMutation(User.mutations.update, {
    variables: {
      input: {
        id: currentUser.id,
      },
    },
  });

  const methods = {
    emailNotifications: JSON.parse(currentUser.emailNotifications),
    pushNotifications: JSON.parse(currentUser.pushNotifications),
  };

  const handleChange = (method, type, checked) =>
    updatePreferences({
      variables: {
        input: {
          id: currentUser.id,
          [method]: JSON.stringify({
            ...methods[method],
            [type]: !!checked ? "YES" : "NO",
          }),
        },
      },
    });

  return (
    <PageContainer>
      <ContentCell>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {devices?.length === 0 && (
              <Typography align="center">
                Get the PropSwap mobile app for{" "}
                <a href="https://play.google.com/store/apps/details?id=com.gunnertech.propswapapp">
                  Android
                </a>{" "}
                or{" "}
                <a href="https://apps.apple.com/us/app/propswap/id1492962264">
                  iOS
                </a>
                .
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Table size="small" style={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Push</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(activityConfig)
                  .filter(
                    ([category, items]) =>
                      !!items.filter(
                        item =>
                          !!Object.values(item).find(
                            config =>
                              !!config.EMAIL?.mutable || !!config.PUSH?.mutable
                          )
                      ).length
                  )
                  .map(([category, items]) => (
                    <>
                      <TableRow key={category}>
                        <TableCell colSpan={3}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            {activityTitles[category]}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {items
                        .filter(
                          item =>
                            !!Object.values(item).find(
                              config =>
                                !!config.EMAIL?.mutable ||
                                !!config.PUSH?.mutable
                            )
                        )
                        .map(item => Object.entries(item))
                        .flat(1)
                        .map(([slug, config]) => (
                          <TableRow key={slug}>
                            <TableCell>
                              <Typography
                                style={{ textTransform: "capitalize" }}
                              >
                                {config.description}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {!!config.EMAIL?.mutable && (
                                <Checkbox
                                  onChange={({ target: { checked } }) =>
                                    handleChange(
                                      "emailNotifications",
                                      slug,
                                      !!checked
                                    )
                                  }
                                  checked={
                                    methods.emailNotifications[slug] === "YES"
                                  }
                                  color="primary"
                                />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {!!config.PUSH?.mutable && (
                                <Checkbox
                                  onChange={({ target: { checked } }) =>
                                    handleChange(
                                      "pushNotifications",
                                      slug,
                                      !!checked
                                    )
                                  }
                                  checked={
                                    methods.pushNotifications[slug] === "YES"
                                  }
                                  color="primary"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </ContentCell>
      <AdCell>
        <img src={require("../assets/css/ad.gif")} alt="Ad" />
      </AdCell>
    </PageContainer>
  );
};

/***
 * {
                  Object.keys({...methods.emailNotifications, ...methods.pushNotifications}).map(name =>
                    <TableRow key={name}>
                      <TableCell>
                        <Typography style={{textTransform: "capitalize"}}>{name.split("_").join(" ").toLowerCase()}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox onChange={({target: {checked}}) => handleChange('emailNotifications', name, !!checked)} checked={methods.emailNotifications[name] === "YES"} color="primary" />
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox onChange={({target: {checked}}) => handleChange('pushNotifications', name, !!checked)} checked={methods.pushNotifications[name] === "YES"} color="primary" />
                      </TableCell>
                    </TableRow>
                  )
                }
 */
