import React from "react";
import { TextField, MenuItem, Box, Typography } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import Event from "shared/api/Event";
import { useEffect, useState } from "react";

export default ({
  sportId,
  onTextChange,
  ticket,
  onEventChange,
  parlayLegNumber,
}) => {
  const [text, setText] = useState(null);
  const [freeForm, setFreeForm] = useState(false);
  const {
    error: eventsError,
    data: { listEventsBySportId: { items: allEvents = [] } = {} } = {},
  } = useQuery(Event.queries.listBySportId, {
    skip: !sportId,
    variables: {
      sportId,
      limit: 2000,
      filter: {
        active: {
          eq: true,
        },
      },
    },
  });

  // console.log(eventsError)

  const {
    data: { listEventsBySportId: { items: featuredEvents = [] } = {} } = {},
  } = useQuery(Event.queries.listBySportId, {
    skip: !sportId,
    variables: {
      sportId,
      limit: 2000,
      filter: {
        and: [
          {
            featuredAt: {
              beginsWith: "2",
            },
          },
          {
            active: {
              eq: true,
            },
          },
        ],
      },
    },
  });

  const selectedEvent = allEvents?.find?.(event => event.description === text);

  // useEffect(() => {
  //   setFreeForm(!featuredEvents.length)
  // }, [featuredEvents.length])

  useEffect(() => {
    !!ticket?.id && setFreeForm(true);

    !!ticket?.id && setText(ticket.eventDescription);
  }, [ticket?.id]);

  useEffect(() => {
    onTextChange(text);

    onEventChange(allEvents.find(event => event.description === text));
  }, [text, allEvents]);

  useEffect(() => {
    !text && !!featuredEvents.length && setText(featuredEvents[0].description);
  }, [text, featuredEvents]);

  useEffect(() => {
    !!freeForm && onEventChange(null);
  }, [freeForm]);

  useEffect(() => {
    !freeForm && text === "Other" && setFreeForm(true);
  }, [freeForm, text]);

  // console.log(allEvents)

  //"Example: To Win 2020 NFL Championship"
  return (
    <>
      <TextField
        className="formControl"
        fullWidth
        label={
          !!parlayLegNumber
            ? `Event of Leg #${parlayLegNumber}`
            : "Select Event"
        }
        onChange={({ target: { value } }) => [
          setFreeForm(false),
          setText(value),
        ]}
        value={!!freeForm ? "Other" : text ?? ""}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        select={true}
      >
        {featuredEvents.map(event => (
          <MenuItem key={event.id} value={event.description}>
            {event.description}
          </MenuItem>
        ))}
        {allEvents
          .filter(event => !featuredEvents.find(fe => fe.id === event.id))
          .map(event => (
            <MenuItem key={event.id} value={event.description}>
              {event.description}
            </MenuItem>
          ))}
        <MenuItem value={"Other"}>Other</MenuItem>
      </TextField>
      {!!freeForm && (
        <TextField
          className="formControl"
          fullWidth
          label={"Enter Event"}
          onChange={({ target: { value } }) => setText(value)}
          value={text === "Other" ? "" : text ?? ""}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        />
      )}

      {!!selectedEvent?.rebateMessage &&
        !!selectedEvent?.rebateActive &&
        !parlayLegNumber && (
          <Box mb={4}>
            <Typography color="secondary">
              {selectedEvent?.rebateMessage}
            </Typography>
          </Box>
        )}
    </>
  );
};
