
import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { withoutBlanks, withoutKeys } from "gunner-react";
import moment from "moment";
import Event from "shared/api/Event";
import Group from "shared/api/Group";
import Form from "../../Form";

const EventFormContainer = ({ event }) => {
  const [formData, setFormData] = useState(event || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: ["featuredAt"].includes(field)
        ? !!value
          ? new Date().toISOString()
          : null
        : ["rebateActive", "realtime"].includes(field)
        ? !!value
        : ["liveStartAt", "liveEndAt", "endAt"].includes(field)
        ? moment(value).toDate()
        : field === "active" || field === "isActive"
        ? !!value
        : field === "disableValues"
        ? !!value
        : value,
    });

  const { data: { listGroupsBySportId: { items: groups = [] } = {} } = {} } =
    useQuery(Group.queries.listBySportId, {
      variables: { limit: 1000, sportId: formData.sportId },
    });

  const [_createEvent] = useMutation(Event.mutations.create, {
    variables: {
      input: withoutBlanks({
        ...formData,
        groupId: formData.groupId ?? "<>",
        isActive: !!formData.isActive ? "x" : undefined,
        active: !!formData.isActive
      }),
    },
    refetchQueries: [
      {
        query: Event.queries.listBySportId,
        variables: { limit: 2000, sportId: formData.sportId },
      },
      {
        query: Event.queries.listBySportIdAndIsActive,
        variables: { limit: 2000, sportId: formData.sportId, isActive: {eq: "x"} },
      },
      {
        query: Event.queries.get,
        variables: { id: event.id }
      }
    ],
    optimisticResponse: {
      __typename: "Mutation",
      createEvent: {
        ...formData,
        isLive: !!formData?.isLive,
        updateIntervalIsActive: !!formData?.updateIntervalIsActive,
        __typename: "Event",
      },
    },
  });

  const [_updateEvent] = useMutation(Event.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, ["__typename", "sport", "group"]),
        isLive: !!formData?.isLive,
        updateIntervalIsActive: !!formData?.updateIntervalIsActive,
        groupId: formData.groupId ?? "<>",
        isActive: !!formData.isActive ? "x" : null,
        active: !!formData.isActive
      },
    },
    refetchQueries: [
      {
        query: Event.queries.listBySportId,
        variables: { limit: 2000, sportId: formData.sportId },
      },
      {
        query: Event.queries.listBySportIdAndIsActive,
        variables: { limit: 2000, sportId: formData.sportId, isActive: {eq: "x"} },
      },
      {
        query: Event.queries.get,
        variables: { id: event.id }
      }
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updateEvent: {
        ...formData,
        __typename: "Event",
      },
    },
  });

  //   sportId: ID!

  //   activeAt: AWSDateTime!
  //   featuredAt: AWSDateTime!

  const fields = {
    groupId: {
      label: "Group",
      regex: /\w+/,
      required: false,
      errorMessage: "You are required enter a name",
      type: "select",
      options: groups
        .filter(group => !!group.active)
        .map(group => ({ value: group.id, label: group.name })),
    },
    description: {
      label: "Description",
      regex: /\w+/,
      required: true,
      type: "text",
      errorMessage: "You are required to enter a description",
    },
    bovadoGroupId: {
      label: "Bovado Group Id",
      regex: /\w+/,
      required: false,
      type: "text",
    },
    bovadoEventId: {
      label: "Bovado Event Id",
      regex: /\w+/,
      required: false,
      type: "text",
    },
    bovadoMarketId: {
      label: "Bovado Market Id",
      regex: /\w+/,
      required: false,
      type: "text",
    },
    fanDuelEventId: {
      label: "FanDuel Event Id",
      regex: /\w+/,
      required: false,
      type: "text",
    },
    fanDuelMarketId: {
      label: "FanDuel Market Id",
      regex: /\w+/,
      required: false,
      type: "text",
    },
    aliases: {
      label: "Aliases",
      regex: /\w+/,
      required: false,
      type: "text",
    },
    parlayAlias: {
      label: "Parlay Alias",
      regex: /\w+/,
      required: false,
      type: "text",
    },
    viewCount: {
      label: "Views",
      regex: /\w+/,
      required: false,
      type: "text",
    },
    // active: {
    //   label: "Active?",
    //   regex: /\w+/,
    //   required: false,
    //   type: "checkbox",
    // },
    isActive: {
      label: "Active?",
      regex: /\w+/,
      required: false,
      type: "checkbox",
    },
    featuredAt: {
      label: "Featured?",
      regex: /\w+/,
      required: false,
      type: "checkbox",
    },
    rebateMessage: {
      label: "Rebate Message",
      regex: /\w+/,
      required: false,
      type: "text",
    },
    rebateActive: {
      label: "Rebate Active?",
      regex: /\w+/,
      required: false,
      type: "checkbox",
    },
    isLive: {
      label: "Is the event live?",
      regex: /\w+/,
      required: false,
      type: "checkbox",
    },
    liveStartAt: {
      label: "When does the event go live?",
      regex: /\w+/,
      required: false,
      type: "datetime-local",
      value: value => moment(value).format("YYYY-MM-DDTHH:mm"),
    },
    liveEndAt: {
      label: "When does the event end?",
      regex: /\w+/,
      required: false,
      type: "datetime-local",
      value: value => moment(value).format("YYYY-MM-DDTHH:mm"),
    },
    updateInterval: {
      label: "Update Frequency (in minutes)",
      regex: /\w+/,
      required: false,
      type: "number",
    },
    updateIntervalIsActive: {
      label: "Is the update interval active?",
      regex: /\w+/,
      required: false,
      type: "checkbox",
    },
    endAt: {
      label: "When should the app stop getting odds?",
      regex: /\w+/,
      required: false,
      type: "datetime-local",
      value: value => moment(value).format("YYYY-MM-DDTHH:mm"),
    },
    disableValues: {
      label: "Disable Values?",
      regex: /\w+/,
      required: false,
      type: "checkbox",
    },
  };

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateEvent : _createEvent)() //if this is an existing event, update it, otherwise, create it
        .then(
          ({
            data: { [!!formData.id ? "updateEvent" : "createEvent"]: event },
          }) => setSubmitSuccess(true)
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [_createEvent, _updateEvent, doSubmit, formData.id]);

  return !!submitSuccess ? (
    <Redirect push to={`/admin/sports/${formData.sportId}/events`} />
  ) : (
    <Form
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  );
};

export default EventFormContainer;
