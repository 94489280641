import React from "react";

import suggestedPriceInPennies from "shared/Util/suggestedPriceInPennies";

export default ({ wagerInPennies, optionEventOdds }) => (
  <>
    $
    {(
      suggestedPriceInPennies({
        wagerInPennies,
        currentCalculatedOdds: optionEventOdds.oddsCalculated,
      }) / 100.0
    ).toFixed(2)}
  </>
);
