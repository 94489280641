import React from "react";

import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

import Form from "../Form";

import Promotion from "shared/api/Promotion";

const PromotionEditContainer = ({ id, campaignId }) => {
  const { loading, data: { getPromotion: promotion } = {} } = useQuery(
    Promotion.queries.get,
    { variables: { id: id } }
  );

  return !!loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Form promotion={promotion} campaignId={campaignId} />
  );
};

export default PromotionEditContainer;
