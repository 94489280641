import React from "react";

import StarIcon from "mdi-material-ui/Star";
import StarOutlineIcon from "mdi-material-ui/StarOutline";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "mdi-material-ui/CheckCircle";

const useStyles = makeStyles(theme => ({
  starOn: {
    color: theme.palette.secondary.main,
  },
  starOff0: {
    color: "#a6a6a6",
  },
  starOff1: {
    color: "#a6a6a6",
  },
  starOff2: {
    color: "#a6a6a6",
  },
}));

const Rating = ({ rating }) => {
  const classes = useStyles();
  return rating > 3 ? (
    <CheckCircleIcon color="secondary" />
  ) : (
    [...Array(3).keys()].map((_, i) =>
      rating >= i + 1 ? (
        <StarIcon className={classes.starOn} key={i} />
      ) : (
        <StarOutlineIcon key={i} className={classes[`starOff${i}`]} />
      )
    )
  );
};

export default Rating;
