import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import Form from "../../Form";
import User from "shared/api/User";
import { withoutKeys } from "gunner-react";
import { useContext } from "react";
import { CurrentUserContext } from "gunner-react";

const UserFormContainer = ({ user }) => {
  const currentUser = useContext(CurrentUserContext);
  const [formData, setFormData] = useState({
    ...user,
    displayName:
      user.displayName ||
      `${user.email.split("@")[0]}${Math.floor(
        1000 + Math.random() * 9000
      )}`.replace(/\./g, ""),
  });
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData(formData => ({
      ...formData,
      [field]:
        field === "verifiedAt"
          ? !!value
            ? new Date().toISOString()
            : null
          : [
              "lockRating",
              "lockStatus",
              "interchecksRecipientW9Verified",
            ].includes(field)
          ? !!value
          : value,
    }));

  const [_createUser] = useMutation(User.mutations.create, {
    variables: {
      input: {
        ...formData,
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      createUser: {
        ...formData,
        __typename: "User",
      },
    },
  });

  const [_updateUser] = useMutation(User.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, [
          "__typename",
          "sport",
          "team",
          "promotionalBalance",
          "pendingBalance",
          "balance",
        ]),
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateUser: {
        ...user,
        ...formData,
        __typename: "User",
      },
    },
  });

  const fields = {
    id: {
      label: "ID",
      regex: /\w+/,
      required: true,
      type: "text",
      errorMessage: "A user must have a unique id",
      disabled: true,
    },
    email: {
      label: "Email",
      regex: /\w+/,
      required: true,
      type: "email",
      disabled: true,
    },
    legacyId: {
      label: "Legacy ID",
      regex: /\w+/,
      type: "text",
      disabled: true,
      helperText: "The user's id from the old system",
    },
    referralCode: {
      label: "Referral Code",
      regex: /\w+/,
      type: "text",
      disabled: true,
    },
    bluesnapVaultedShopperId: {
      label: "Bluesnap ID",
      regex: /\w+/,
      type: "text",
      disabled: true,
      helperText: "The user's id in Bluesnap",
    },
    displayName: {
      label: "Display Name",
      regex: /\w+/,
      type: "text",
      required: true,
      errorMessage: "You are required to enter a unique Display Name",
      disabled: !currentUser.groups.includes("Admins"),
      helperText:
        "Self selected unique username. Right now these are autogenerated",
    },
    balance: {
      label: "Balance",
      value: data => parseFloat(data || 0) / 100.0,
      regex: /\w+/,
      type: "money",
      disabled: true,
      helperText:
        "To adjust this, enter an adjusting entry on the user's transaction page",
    },
    pendingBalance: {
      label: "Pending Balance",
      value: data => parseFloat(data || 0) / 100.0,
      regex: /\w+/,
      type: "money",
      disabled: true,
      helperText:
        "To adjust this, enter an adjusting entry on the user's transaction page",
    },
    promotionalBalance: {
      label: "Promotional Balance",
      value: data => parseFloat(data || 0) / 100.0,
      regex: /\w+/,
      type: "money",
      disabled: true,
      helperText:
        "To adjust this, enter an adjusting entry on the user's transaction page",
    },
    points: {
      label: "Points (Lifetime)",
      regex: /\w+/,
      type: "text",
    },
    availablePoints: {
      label: "Available Points",
      regex: /\w+/,
      type: "text",
    },
    buyOrSellTransactionCount: {
      label: "Transaction Count",
      regex: /\w+/,
      disabled: true,
    },
    createdAt: {
      label: "Created",
      regex: /\w+/,
      type: "text",
      required: true,
      disabled: true,
    },
    updatedAt: {
      label: "Updated",
      regex: /\w+/,
      type: "text",
      required: true,
      disabled: true,
    },
    agreedToTermsAt: {
      label: "Agreed to Terms",
      regex: /\w+/,
      type: "text",
      disabled: true,
    },
    agreedToCcTermsAt: {
      label: "Agreed to CC Terms",
      regex: /\w+/,
      type: "text",
      disabled: true,
    },
    // allowListingWithoutAuth: {
    //   label: "Allow Listing without Auth?",
    //   regex: /\w+/,
    //   type: 'checkbox',
    //   helperText: "If checked, this user will be allowed to create users without accepting terms"
    // },
    verifiedAt: {
      label: "KYC Verified?",
      regex: /\w+/,
      type: "checkbox",
    },
    interchecksRecipientW9Verified: {
      label: "W-9 Confirmed?",
      regex: /\w+/,
      type: "checkbox",
    },
    rating: {
      label: "Seller Rating",
      required: true,
      regex: /^(1|2|3)$/,
      type: "number",
      inputProps: {
        min: 1,
        max: 3,
      },
    },
    lockRating: {
      label: "Lock Rating",
      required: false,
      regex: /\w/,
      type: "checkbox",
      helperText:
        "Locking a user's rating will prevent it from getting auto updated from selling",
    },
    status: {
      label: "Status",
      regex: /.+/,
      type: "text",
      options: [
        { label: "Rookie", value: 1 },
        { label: "Pro", value: 2 },
        { label: "Veteran", value: 3 },
        { label: "Hall of Fame", value: 4 },
        { label: "G.O.A.T.", value: 5 },
      ],
      // checkboxLabel: "Recently Sold?",
      // value: value => value === "<>" ? false : value
    },
    lockStatus: {
      label: "Lock Status",
      required: false,
      regex: /\w/,
      type: "checkbox",
      helperText:
        "Locking a user's status will prevent it from getting auto updated",
    },
    buyOrSellTransactionCount: {
      label: "Transaction Count",
      required: false,
      regex: /^\d+$/,
      type: "number",
    },
  };

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateUser : _createUser)() //if this is an existing user, update it, otherwise, create it
        .then(
          ({
            error,
            data: { [!!formData.id ? "updateUser" : "createUser"]: user },
          }) => [
            window.alert("Success!"),
            setDoSubmit(false),
            setSubmitSuccess(true),
          ]
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [doSubmit]);

  return !!submitSuccess ? (
    <Redirect push to={"/admin/users"} />
  ) : (
    <Form
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  );
};

export default UserFormContainer;
