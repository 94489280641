import React, { useState, useEffect, useMemo } from "react";
import queryString from "query-string";
import { Box, TextField, MenuItem, Grid, Button } from "@material-ui/core";
import { useQuery } from "@apollo/client";

import Listing from "shared/api/Listing";
import Sport from "shared/api/Sport";

import { DataTable } from "gunner-react/web";

import Rating from "Components/Rating";
import UserLink from "Components/User/Link";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Tabs from "Components/Admin/Components/Tabs";
import ContentCell from "Components/Layout/ContentCell";
import PageContainer from "Components/Layout/PageContainer";
import OptionsRow from "Components/Layout/OptionsRow";
import PriceRangeFilter from "Components/Listing/Components/PriceRangeFilter";
import SortOptions from "Components/Listing/Components/SortOptions";
import Search from "Components/Listing/Components/Search";
import environment from "environment";

const tabSports = ["NFL", "CFB", "NBA", "CBB", "MLB", "NHL", "GOLF"];

const fields = {
  Rating: {
    value: item => <Rating rating={item.ratingOverride || 1} />,
    nowrap: true,
    sort: order => (a, b) =>
      a.ratingOverride > b.ratingOverride
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Sport: {
    value: item => item.cachedTicket.sport.abbrev,
    sort: order => (a, b) =>
      a.cachedTicket.sport.abbrev > b.cachedTicket.sport.abbrev
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  "Bet Type": {
    value: item => item.cachedTicket.betType,
    sort: order => (a, b) =>
      a.cachedTicket.betType > b.cachedTicket.betType
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Event: {
    value: item => (
      <Box width="100px">
        <Link to={`/tickets/${item.cachedTicket.id}`}>
          {item.cachedTicket.eventDescription}
        </Link>
      </Box>
    ),
    sort: null,
    searchValue: item => item.cachedTicket.eventDescription,
  },
  Subject: {
    value: item => (
      <Box width="100px">
        <Link to={`/tickets/${item.cachedTicket.id}`}>{item.cachedTicket.subject}</Link>
      </Box>
    ),
    sort: null,
    searchValue: item => item.cachedTicket.subject,
  },
  Status: {
    value: item => item.status,
    sort: order => (a, b) =>
      a.status > b.status
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    searchValue: item => item.status,
  },
  Odds: {
    value: item => item.newOdds,
    sort: order => (a, b) =>
      a.newOddsCalculated > b.newOddsCalculated
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Price: {
    value: item => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        value={parseFloat(item.askingPrice) / 100.0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.askingPrice > b.askingPrice
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  Collects: {
    value: item => (
      <NumberFormat
        fixedDecimalScale
        decimalScale={2}
        value={parseFloat(item.cachedTicket.collectAmount) / 100.0}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    ),
    sort: order => (a, b) =>
      a.cachedTicket.collectAmount > b.cachedTicket.collectAmount
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    numeric: true,
  },
  "Original Odds": {
    value: item => item.cachedTicket.odds,
    sort: order => (a, b) =>
      a.cachedTicket.calculatedOdds > b.cachedTicket.calculatedOdds
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Buyer: {
    sort: null,
    value: item =>
      !item.listingBuyerId ? null : <UserLink id={item.listingBuyerId} />,
  },
  Seller: {
    sort: null,
    value: item => <UserLink id={item.listingSellerId} />,
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        component={Link}
        to={`/tickets/${item.listingTicketId}/listings/${item.id}/edit`}
        variant={"contained"}
        size="small"
        color="secondary"
      >
        Edit
      </Button>
    ),
  },
};

export default ({ history, location: { search = "" } = {} }) => {
  const searchParams = queryString.parse(search);
  const [sortDirection, setSortDirection] = useState(
    searchParams.sortDirection || "DESC"
  );
  const [status, setStatus] = useState(searchParams.status || "ACTIVE");
  const [sortField, setSortField] = useState(
    searchParams.sortField || "createdAt"
  );
  const [sportAbbrev] = useState(searchParams.sportAbbrev);
  const [searchString, setSearchString] = useState(
    searchParams.searchString || ""
  );
  const [featuredOnly, setFeaturedOnly] = useState(
    searchParams.featuredOnly || "0"
  );
  const [priceHigh, setPriceHigh] = useState(
    searchParams.priceHigh === undefined
      ? 10000000 * 100
      : parseInt(searchParams.priceHigh)
  );
  const [priceLow, setPriceLow] = useState(
    searchParams.priceLow === undefined ? 0 : parseInt(searchParams.priceLow)
  );

  const setPriceRange = ([low, high]) => [
    setPriceHigh(high),
    setTimeout(() => setPriceLow(low), 10),
  ];

  const { data: { listSports: { items: sports = [] } = {} } = {} } = useQuery(
    Sport.queries.list,
    {
      variables: {
        limit: 50,
      },
    }
  );

  const otherSportAbbrev = (
    sports.find(sport => sport.abbrev === "OTHER") || {}
  ).abbrev;

  const sportsString = JSON.stringify(sports);
  const tabSports = sports.map(sport => sport.abbrev).slice(0, 7);

  const otherSportAbbrevs = useMemo(() => [
    otherSportAbbrev,
    ...JSON.parse(sportsString)
      .filter(sport => !tabSports.includes(sport.abbrev.toUpperCase()))
      .map(sport => sport.abbrev),
  ], [otherSportAbbrev, sportsString, tabSports]);

  const filter = useMemo(() => ({
    and: [
      {
        status: {
          [status !== "ALL" ? "match" : "exists"]:
            status !== "ALL" ? `${status}` : true,
        },
      },
      status === "NEW"
        ? null
        : {
            askingPrice: {
              gt: priceLow,
            },
          },
      status === "NEW"
        ? null
        : {
            askingPrice: {
              lt: priceHigh,
            },
          },
      {
        searchString: {
          [!!searchString &&
          / /.test(decodeURIComponent(searchString))
            ? "match"
            : !!searchString
            ? "regexp"
            : "exists"]: !!searchString
            ? `${decodeURIComponent(searchString) || ""}${
                / /.test(decodeURIComponent(searchString))
                  ? ""
                  : ".*"
              }`.toLowerCase()
            : true,
        },
      },
      {
        searchString: {
          [!!sportAbbrev && sportAbbrev === "Other"
            ? "regexp"
            : !!sportAbbrev
            ? "match"
            : "exists"]:
            !!sportAbbrev && sportAbbrev === "Other"
              ? otherSportAbbrevs
                  .map(abbrev => `(${abbrev})`)
                  .join("|")
                  .toLowerCase()
              : !!sportAbbrev
              ? ` ${sportAbbrev} `
              : true,
        },
      },
      featuredOnly === "1"
        ? {
            featuredAt: {
              exists: true,
            },
          }
        : null,
    ].filter(item => !!item),
  }), [featuredOnly, otherSportAbbrevs, priceHigh, priceLow, searchString, sportAbbrev, status]);

  // useEffect(() => {
  //   history.push(
  //     "?" +
  //       queryString.stringify({
  //         ...searchParams,
  //         sortDirection,
  //       })
  //   );
  // }, [history, searchParams, sortDirection]);

  // useEffect(() => {
  //   history.push(
  //     "?" +
  //       queryString.stringify({
  //         ...searchParams,
  //         sortField,
  //       })
  //   );
  // }, [history, searchParams, sortField]);

  // useEffect(() => {
  //   history.push(
  //     "?" +
  //       queryString.stringify({
  //         ...searchParams,
  //         status,
  //       })
  //   );
  // }, [history, searchParams, status]);

  // useEffect(() => {
  //   history.push(
  //     "?" +
  //       queryString.stringify({
  //         ...searchParams,
  //         sportAbbrev,
  //       })
  //   );
  // }, [history, searchParams, sportAbbrev]);

  // useEffect(() => {
  //   history.push(
  //     "?" +
  //       queryString.stringify({
  //         ...searchParams,
  //         sortField,
  //       })
  //   );
  // }, [history, searchParams, sortField]);

  // useEffect(() => {
  //   history.push(
  //     "?" +
  //       queryString.stringify({
  //         ...searchParams,
  //         priceHigh,
  //       })
  //   );
  // }, [history, priceHigh, searchParams]);

  // useEffect(() => {
  //   history.push(
  //     "?" +
  //       queryString.stringify({
  //         ...searchParams,
  //         priceLow,
  //       })
  //   );
  // }, [history, priceLow, searchParams]);

  // useEffect(() => {
  //   history.push(
  //     "?" +
  //       queryString.stringify({
  //         ...searchParams,
  //         searchString,
  //       })
  //   );
  // }, [history, searchParams, searchString]);

  useEffect(() => {
    sortField === "createdAt" && setSortDirection("DESC");
  }, [sortField]);
  
  const sort = environment.use_v2 ? [{
                  direction: sortDirection.toLowerCase(),
                  field: sortField,
                }] : {
                  direction: sortDirection.toLowerCase(),
                  field: sortField,
                };

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/listings"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <OptionsRow>
            <PriceRangeFilter
              onChange={setPriceRange}
              priceRange={[priceLow, priceHigh]}
            />
            <SortOptions
              onChangeField={setSortField}
              onChangeDirection={setSortDirection}
              sortDirection={sortDirection}
              sortField={sortField}
              adminView={true}
            />
            <Search
              searchString={searchString}
              onChange={setSearchString}
              onChangeField={setSortField}
              onChangeDirection={setSortDirection}
              sortDirection={sortDirection}
              sortField={sortField}
            />
          </OptionsRow>

          <Box mb={4}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={"Status"}
                  select
                  onChange={({ target: { value } }) => setStatus(value)}
                  value={status}
                  variant={"outlined"}
                  SelectProps={{ displayEmpty: true }}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="ALL">ALL</MenuItem>
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="NEW">NEW</MenuItem>
                  <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                  <MenuItem value="PENDING">PENDING</MenuItem>
                  <MenuItem value="SOLD">SOLD</MenuItem>
                  <MenuItem value="DROPPED">DROPPED</MenuItem>
                  <MenuItem value="ARCHIVED">ARCHIVED</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={"Featured Only?"}
                  select
                  onChange={({ target: { value } }) => setFeaturedOnly(value)}
                  value={featuredOnly}
                  variant={"outlined"}
                  SelectProps={{ displayEmpty: true }}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="1">Yes</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Box>

          <Box mb={4}>
            <a
              href={`${process.env.REACT_APP_API_BASE}/tickets/stream`}
              target="_blank" rel="noreferrer"
            >
              Export Listing Data
            </a>
          </Box>

          <DataTable
            fields={fields}
            search={null}
            dataListParams={{
              // noPoll: true,
              useButton: true,
              query: Listing.queries.searchWithTicket,
              dataKey: "searchListings",
              variables: {
                sort,
                limit: 20,
                filter
              },
            }}
          />
        </ContentCell>
      </PageContainer>
    </>
  );
};
