import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import AlertCircleIcon from "mdi-material-ui/AlertCircle";
import { Redirect } from "react-router-dom";
import useReferral from "react-shared/Hooks/useReferral";
import ReactGA from "react-ga";

import Tabs from "../Components/Tabs";
import Modal from "../../Layout/Modal";

import SimpleModal from "../../../Components/SimpleModal";

import AdCell from "../../Layout/AdCell";
import ContentCell from "../../Layout/ContentCell";
import PageHeader from "../../Layout/PageHeader";
import PageContainer from "../../Layout/PageContainer";
import { Grid, Typography, Box } from "@material-ui/core";
import Amount from "../../Deposit/Components/Amount";
import Summary from "../../Deposit/Components/Summary";
import Method from "../../Deposit/Components/Method";
import NewCustomerForm from "../../Deposit/Components/NewCustomerForm";
import AchForm from "../../Deposit/Components/AchForm";

import { CurrentUserContext } from "gunner-react";
import Bluesnap from "shared/api/Bluesnap";
import User from "shared/api/User";

import getGraphQLError from "shared/Util/getGraphQLError";
import PromoCode from "../../Deposit/PromoCode";
import UserVerificationModal from "../../User/Verification/Modal";
import { formatPennies } from "shared/Util/money";

const useStyles = makeStyles(theme => ({
  content: {
    backgroundImage: `url(${require("../../../assets/css/deposit-bg.png")})`,
    backgroundColor: "transparent",
    backgroundAttachment: "scroll",
    backgroundPosition: "top left",
    backgroundRepeat: "repeat",
    marginTop: theme.spacing(2),
    "& h4": {
      fontSize: "1.125rem",
      textTransform: "uppercase",
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(2),
    },
  },
  contentCell: {
    marginBottom: theme.spacing(4),
    "& > div": {
      backgroundColor: "rgba(242,242,242, 0.8)",
      padding: theme.spacing(2, 2),
    },
  },
  summaryHeader: {
    "&:after": {
      background: "gray",
      height: 1,
      content: '""',
      display: "block",
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginTop: theme.spacing(2),
    },
  },
}));

const Deposit = ({ history, location: { search = "" } }) => {
  const classes = useStyles();
  const currentUser = useContext(CurrentUserContext);

  const [amount, setAmount] = useState("2500");
  const [method, setMethod] = useState("card");
  const [selectedCardInfo, setSelectedCardInfo] = useState("");
  const [selectedBankInfo, setSelectedBankInfo] = useState("");
  const [modalType, setModalType] = useState(null);
  const [bluesnapToken, setBluesnapToken] = useState(null);
  const [cardHolderName, setCardHolderName] = useState(
    `${currentUser.attributes.given_name} ${currentUser.attributes.family_name}`
  );
  const [zip, setZip] = useState(`${currentUser.attributes["custom:zip"]}`);
  const [submitting, setSubmitting] = useState(false);
  const [newBankInfo, setNewBankInfo] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [promotion, setPromotion] = useState(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const isComplete = /success/.test(search);
  const isAllowedToDeposit = true; //useIsAllowedToDeposit()
  const isValid =
    !!amount &&
    amount >= 500 &&
    ((!!selectedCardInfo && selectedCardInfo !== "new") ||
      (!!selectedBankInfo && selectedBankInfo !== "new"));

  const referral = useReferral();

  const referralMapping = (referral?.conversionTemplate?.mapping ?? [])
    .slice(0)
    .sort((a, b) => (a.threshold > b.threshold ? -1 : 1))
    .find?.(mapping => parseInt(amount) >= mapping.threshold);

  const { data: { getUser } = {} } = useQuery(User.queries.get, {
    variables: {
      id: currentUser.id,
      withPrivate: true,
    },
  });

  const {
    error: bluesnapError,
    data: { getBluesnapVaultedShopper: bluesnapVaultedShopper } = {},
  } = useQuery(Bluesnap.queries.getVaultedShopper, {
    skip: !getUser?.bluesnapVaultedShopperId,
    variables: {
      vaultedShopperId: getUser?.bluesnapVaultedShopperId,
    },
  });

  const [_createBluesnapPaymentToken] = useMutation(
    Bluesnap.mutations.createPaymentToken
  );
  const [_addCreditCard] = useMutation(Bluesnap.mutations.createTransaction, {
    variables: {
      input: {
        amount: 1,
        storeCard: true,
        currency: "USD",
        cardTransactionType: "AUTH_ONLY",

        cardHolderInfo: {
          firstName: !!cardHolderName
            ? cardHolderName.split(" ")[0]
            : undefined,
          lastName: !!cardHolderName
            ? cardHolderName.split(" ").slice(1).join(" ")
            : undefined,
          zip: zip || undefined,
        },
        userId: currentUser.id,
        pfToken: bluesnapToken,
        vaultedShopperId: getUser?.bluesnapVaultedShopperId ?? undefined,
        creditCard: undefined,
      },
    },
    refetchQueries: [
      {
        query: User.queries.get,
        variables: {
          id: currentUser.id,
          withPrivate: true,
        },
      },
      {
        query: Bluesnap.queries.getVaultedShopper,
        variables: {
          vaultedShopperId: getUser?.bluesnapVaultedShopperId ?? 0,
        },
      },
    ],
  });

  const [_createBluesnapTransaction] = useMutation(
    Bluesnap.mutations.createTransaction,
    {
      variables: {
        input: {
          amount: amount,
          storeCard: true,
          currency: "USD",
          cardTransactionType: "AUTH_CAPTURE",
          transactionPromotionId: promotion?.id || undefined,
          cardHolderInfo: {
            firstName: !!cardHolderName
              ? cardHolderName.split(" ")[0]
              : undefined,
            lastName: !!cardHolderName
              ? cardHolderName.split(" ").slice(1).join(" ")
              : undefined,
            zip: zip || undefined,
          },
          userId: currentUser.id,
          vaultedShopperId: getUser?.bluesnapVaultedShopperId ?? undefined,
          creditCard: !!Object.values(selectedCardInfo || {}).join("")
            ? selectedCardInfo
            : undefined,
        },
      },
      refetchQueries: [
        {
          query: User.queries.get,
          variables: {
            id: currentUser.id,
            withPrivate: true,
          },
        },
        {
          query: Bluesnap.queries.getVaultedShopper,
          variables: {
            vaultedShopperId: getUser?.bluesnapVaultedShopperId ?? undefined,
          },
        },
      ],
    }
  );

  const [_createBluesnapAchTransaction] = useMutation(
    Bluesnap.mutations.createTransaction,
    {
      variables: {
        input: {
          amount: amount,
          currency: "USD",
          userId: currentUser.id,
          authorizedByShopper: true,
          transactionPromotionId: promotion?.id || undefined,
          ecpTransaction: {
            ...selectedBankInfo,
          },
          ...(!getUser?.bluesnapVaultedShopperId
            ? {
                payerInfo: {
                  firstName: !!cardHolderName
                    ? cardHolderName.split(" ")[0]
                    : undefined,
                  lastName: !!cardHolderName
                    ? cardHolderName.split(" ").slice(1).join(" ")
                    : undefined,
                  zip: zip || undefined,
                },
              }
            : {
                vaultedShopperId: getUser?.bluesnapVaultedShopperId,
              }),
        },
      },
      refetchQueries: [
        {
          query: User.queries.get,
          variables: {
            id: currentUser.id,
            withPrivate: true,
          },
        },
        {
          query: Bluesnap.queries.getVaultedShopper,
          variables: {
            vaultedShopperId: getUser?.bluesnapVaultedShopperId ?? 0,
          },
        },
      ],
    }
  );

  const [_addBankInfo] = useMutation(Bluesnap.mutations.createTransaction, {
    variables: {
      input: {
        amount: "1",
        currency: "USD",
        userId: currentUser.id,
        authorizedByShopper: true,
        ecpTransaction: {
          ...newBankInfo,
        },
        vaultedShopperId: getUser?.bluesnapVaultedShopperId ?? undefined,
        payerInfo: {
          firstName: !!cardHolderName
            ? cardHolderName.split(" ")[0]
            : undefined,
          lastName: !!cardHolderName
            ? cardHolderName.split(" ").slice(1).join(" ")
            : undefined,
          zip: zip || undefined,
        },
      },
    },
    refetchQueries: [
      {
        query: User.queries.get,
        variables: {
          id: currentUser.id,
          withPrivate: true,
        },
      },
      {
        query: Bluesnap.queries.getVaultedShopper,
        variables: {
          vaultedShopperId: getUser?.bluesnapVaultedShopperId ?? 0,
        },
      },
    ],
  });

  // const hasStoredData = !!(((bluesnapVaultedShopper||{}).paymentSources||{}).creditCardInfo||[]).length

  const handleCardChange = cardInfo => [
    setSelectedCardInfo(cardInfo === "new" ? cardInfo : JSON.parse(cardInfo)),
    setSelectedBankInfo(""),
  ];

  const handleAchChange = bankInfo => [
    setSelectedBankInfo(bankInfo === "new" ? bankInfo : JSON.parse(bankInfo)),
    setSelectedCardInfo(""),
  ];

  const handleAchSubmit = bankInfo => [
    setNewBankInfo(bankInfo),
    setSubmitting(true),
  ];

  const handleCreditCardSubmit = () =>
    Promise.resolve(setSubmitting(true)).then(() =>
      _addCreditCard()
        .then(() => [setSubmitting(false), setModalType(null)])
        .catch(error => [
          console.log("error1!!!!", JSON.stringify(getGraphQLError(error))),
          getGraphQLError(error).level === 0
            ? window.alert(
                "An error occured during processing. Please contact info@propswap.com for help with this problem."
              )
            : window.alert(
                "That card could not be validated. Please try again."
              ),
          setSubmitting(false),
        ])
    );

  useEffect(() => {
    /success/.test(search) && setShowModal(true);
  }, [search]);

  useEffect(() => {
    selectedBankInfo === "new"
      ? setModalType("ach")
      : selectedCardInfo === "new"
      ? setModalType("card")
      : console.log("none");
  }, [selectedCardInfo, selectedBankInfo]);

  useEffect(() => {
    !!isComplete &&
      ReactGA.event({
        category: "Transaction",
        action: "Deposit",
        label: "deposit",
        value: parseInt(amount),
      });
  }, [isComplete, amount]);

  useEffect(() => {
    !!newBankInfo &&
      !!submitting &&
      _addBankInfo()
        .then(() => [
          setSubmitting(false),
          setSelectedBankInfo(newBankInfo),
          setNewBankInfo(null),
          setModalType(null),
        ])
        .catch(error => [
          console.log("error!!!!", error),
          window.alert("That account could not be added. Please try again."),
          setSubmitting(false),
          setModalType("ach"),
        ]);
  }, [submitting, newBankInfo]);

  useEffect(() => {
    !!selectedBankInfo &&
      selectedBankInfo !== "new" &&
      !!submitting &&
      _createBluesnapAchTransaction()
        .then(() => [
          history.push("?success=true&method=ach"),
          setShowModal(true),
          setSubmitting(false),
        ])
        .catch(error => [
          console.log("error!!!!", error),
          window.alert("We could not charge that account. Please try again."),
          setSubmitting(false),
          setModalType("ach"),
        ]);
  }, [submitting, selectedBankInfo]);

  // useEffect(() => {
  //   !currentUser.groups.includes("Admins") &&
  //   fetch("http://api.ipify.org/").then(resp => resp.text().then(ip =>
  //     fetch(`http://www.geoplugin.net/json.gp?ip=${ip}`).then(resp => resp.json().then(json => !ALLOWED_STATES.includes(json.geoplugin_region) && (window.alert(`${json.geoplugin_region} is not an approved state.`) || setRedirectTo("/")) ))
  //   )).catch(console.log)
  // }, [currentUser])

  useEffect(() => {
    !isAllowedToDeposit &&
      (window.alert(`You are not in an approved state.`) || setRedirectTo("/"));
  }, [isAllowedToDeposit]);

  useEffect(() => {
    !!selectedCardInfo &&
      selectedCardInfo !== "new" &&
      !!submitting &&
      _createBluesnapTransaction()
        .then(() => [
          history.push("?success=true"),
          setShowModal(true),
          setSubmitting(false),
        ])
        .catch(error => [
          console.log(
            "error1!!!!",
            error,
            JSON.stringify(getGraphQLError(error))
          ),
          getGraphQLError(error).level === 0
            ? window.alert(
                "An error occured during processing. Please contact info@propswap.com to ensure you were credited for your transaction"
              )
            : window.alert("That card was not accepted. Please try again."),
          setSubmitting(false),
        ]);
  }, [submitting, selectedCardInfo, currentUser.id, selectedCardInfo]);

  useEffect(() => {
    !bluesnapToken &&
      _createBluesnapPaymentToken()
        .then(({ data: { createBluesnapPaymentToken } }) =>
          setBluesnapToken(createBluesnapPaymentToken)
        )
        .catch(err => console.log(err));
  }, []);

  // useEffect(() => {
  //   method !== 'card' &&
  //   !!promotion && (
  //     window.alert("Promo Codes are only valid for credit card deposits at this time.") ||
  //     setPromotion(null)
  //   )
  // }, [method, !!promotion])

  // console.log(method, promotion)

  useEffect(() => {
    !!referral && setAmount("500");
  }, [referral]);

  return !!redirectTo ? (
    <Redirect push to={redirectTo} />
  ) : (
    <>
      <UserVerificationModal
        show={showVerificationModal}
        closeBehavior="close"
      />
      <Modal
        title={/ach/.test(search) ? "Deposit Pending" : "Success!"}
        body={
          <div style={{ overflow: "hidden" }}>
            <Grid container alignItems="center" justify="center" spacing={4}>
              <Grid container item xs={12} justify="center">
                <AlertCircleIcon style={{ fontSize: 80 }} />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" variant="body1" paragraph>
                  {/ach/.test(search)
                    ? "Please allow up to 5 business days for funds to be available in your PropSwap account."
                    : "Funds have successfully been added to your wallet."}
                </Typography>
              </Grid>
            </Grid>
          </div>
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={{
          text: "OK",
          onClick: () => [
            setShowModal(false),
            setRedirectTo(`/account/transactions`),
          ],
          ButtonProps: {
            fullWidth: true,
          },
        }}
      />
      {!!modalType && (
        <SimpleModal
          opened={!!modalType}
          title={modalType === "card" ? `Add Card` : `Add Bank Account`}
          body={
            modalType === "card" ? (
              <NewCustomerForm
                zip={zip}
                submitting={submitting}
                cardHolderName={cardHolderName}
                bluesnapToken={bluesnapToken}
                onCancel={() => [setSelectedCardInfo(""), setModalType(null)]}
                onSubmit={handleCreditCardSubmit}
                setZip={setZip}
                setCardHolderName={setCardHolderName}
              />
            ) : (
              <AchForm
                zip={zip}
                submitting={submitting}
                cardHolderName={cardHolderName}
                onCancel={() => [setSelectedBankInfo(""), setModalType(null)]}
                onSubmit={handleAchSubmit}
                setZip={setZip}
                setCardHolderName={setCardHolderName}
              />
            )
          }
          saving={false}
          onClose={() => [setSelectedCardInfo(""), setModalType(null)]}
        />
      )}
      <Tabs
        history={history}
        currentPath={"/account/deposit"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell>
          <PageHeader>Deposit</PageHeader>
          <Grid container className={classes.content} spacing={4}>
            <Grid item xs={12} md={7}>
              <Grid item xs={12} className={classes.contentCell}>
                <Box display="flex" flexDirection="column">
                  {!!referral && !!referralMapping && (
                    <>
                      <Typography variant="h4">
                        Complete your Referral Bonus!
                      </Typography>
                      <Typography variant="body2">
                        Deposit ${formatPennies(amount)} and get an extra $
                        {formatPennies(referralMapping.referreeBonus)}!
                      </Typography>
                    </>
                  )}
                  {!referral && (
                    <>
                      <Typography variant="h4">Have a Promo Code?</Typography>
                      <PromoCode
                        method={method}
                        promotion={promotion}
                        onSuccess={promotion => setPromotion(promotion)}
                      />
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.contentCell}>
                <div>
                  <Typography variant="h4">1. Choose Deposit Amount</Typography>
                  <Amount onChange={setAmount} amount={amount} />
                </div>
              </Grid>
              <Grid item xs={12} className={classes.contentCell}>
                <div>
                  <Typography variant="h4">2. Choose Payment Method</Typography>
                  <Method
                    currentUser={currentUser}
                    method={method}
                    bluesnapToken={bluesnapToken}
                    onChange={setMethod}
                    onCardChange={handleCardChange}
                    onAchChange={handleAchChange}
                    selectedCardInfo={selectedCardInfo}
                    selectedBankInfo={selectedBankInfo}
                    bluesnapVaultedShopper={bluesnapVaultedShopper}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid item xs={12} className={classes.contentCell}>
                <div>
                  <Typography className={classes.summaryHeader} variant="h4">
                    Order Summary
                  </Typography>
                  <Summary
                    bonusAmount={referralMapping?.referreeBonus}
                    paymentType={method}
                    promotion={promotion}
                    onPromotionSet={setPromotion}
                    submitting={submitting}
                    //TODO: LIFT GATING
                    onSubmit={() =>
                      !getUser?.verifiedAt &&
                      currentUser.groups.includes("Admins")
                        ? setShowVerificationModal(true)
                        : setSubmitting(true)
                    }
                    amount={amount}
                    currentUser={currentUser}
                    valid={isValid}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </ContentCell>
        <AdCell>
          <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};

export default Deposit;
