import React from "react";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

const RadioButtons = ({ options, selectedValue, onChange, ...rest }) => (
  <ButtonGroup color="primary" {...rest}>
    {options.map(({ label, value, disabled }, i) => (
      <Button
        onClick={() => onChange(value)}
        key={i}
        color={value === selectedValue ? "secondary" : "primary"}
        variant={value === selectedValue ? "contained" : "outlined"}
        disabled={disabled}
      >
        {label}
      </Button>
    ))}
  </ButtonGroup>
);

export default RadioButtons;
