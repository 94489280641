import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { Hidden, Typography, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuDownIcon from "mdi-material-ui/MenuDown";

const useStyles = makeStyles(theme => ({
  icon: {
    marginBottom: theme.spacing(-1),
    lineHeight: theme.typography.subtitle2.lineHeight,
  },
  container: {
    marginBottom: theme.spacing(2),
  },
}));

const TypeFilter = ({ onChange, typeFilter }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = (event, filter) => setAnchorEl(null);

  const handleSelect = filter => [handleClose(), onChange(filter)];

  return (
    <Grid className={classes.container} item xs={3} md={4}>
      <div onClick={handleClick} className={classes.menu}>
        <Typography variant={"subtitle2"}>
          Show
          <Hidden smDown>: {typeFilter}</Hidden>
          <MenuDownIcon className={classes.icon} />
        </Typography>
      </div>
      <Menu
        id="sale-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={evt => handleSelect("All")}>All</MenuItem>
        <MenuItem onClick={evt => handleSelect("Countered")}>
          Countered
        </MenuItem>
        <MenuItem onClick={evt => handleSelect("Outbid")}>Outbid</MenuItem>
      </Menu>
    </Grid>
  );
};

export default TypeFilter;
