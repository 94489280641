import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Tabs from "../Components/Tabs";

import AdCell from "../../Layout/AdCell";
import ContentCell from "../../Layout/ContentCell";
import PageContainer from "../../Layout/PageContainer";
import { Typography, Grid } from "@material-ui/core";
import Search from "../../Listing/Components/Search";
import SectionHeader from "../../Layout/SectionHeader";
import { ALLOWED_STATES } from "shared/Config";

const sections = [
  {
    header: "General FAQ",
    items: [
      {
        header: "Is this legal?",
        content: `Yes, it’s legal. Once your bet is made at a licensed sportsbook, it’s your property, and you may do as you please with it, including sell it! But of course, we take gaming regulation very seriously here at PropSwap. All inquiries made by gaming regulators will be addressed with full attention.`,
      },
      {
        header: "Where do all of these tickets come from?",
        content: `These tickets all originated at licensed sportsbooks throughout the country. To ensure the Seller follows through with delivering the ticket, we place a Hold on his/her credit card. Should they not deliver the ticket on-time, we have the full authority to charge the Seller a penalty fee.`,
      },
      {
        header: "Why would I just hedge my bet, instead of selling it?",
        content: `1st reason – Everyone knows, hedging involves spending more money on a new bet! We want you to keep your money in your pocket. Let’s do a simple example: You bet on Texas Tech before March Madness starts, at 200:1. You wager $100 collect $20,100. The Championship game comes and Tech is set to square off vs. Virginia! UVA is a -150 Favorite. 

        With hedging, you’d need to bet $14,000 on UVA Moneyline, to guarantee yourself at least $9,000 in profit (what your Tech ticket would currently sell for.) Do you have $14,000 in your bank account to make that bet? Not many people do! 
        
        2nd reason – Deciding when to hedge, is very hard. If you own the Cowboys at 50/1 to win the NFL Championship, and you want to hedge before the Wild Card Round, you’d need to bet their opponent in the Wild Card, and then the Divisional Round, and then the Conference round…… still with me? By the time they make the Championship, you’ve got no profit left! 
        
        Moral of the story: Sell your bet on PropSwap, do less math, and spend less money!`,
      },
      {
        header:
          "How are tickets stored? I'm worried about my tickets getting lost once handed over.",
        content: `As soon as you bring in a ticket to be listed, a PropSwap employee takes a photo of each: 1) the actual sports betting ticket, and 2) the completed Seller Form. He (or she) then uploads those 2 digital documents into our Cloud-managed database, safely secured. If for any reason, the physical hard copy is misplaced from PropSwap, we will be providing the Owner with each a copy of the Seller Form, and photo of the ticket, so they may work with the issuing Sportsbook, to come to a resolution. We of course will IMMEDIATELY contact the issuing Sportsbook, so that they freeze the betting ticket, and the misplaced ticket will become unredeemable.`,
      },
      {
        header:
          "Does PropSwap disclose which State/Casino each ticket is from?",
        content: `We do not. For security purposes, this information is kept private. Every ticket listed on PropSwap is able to be cashed via Mail Pay, if you are unable to cash it in person at the sportsbook.`,
      },
      // {
      //   header: "Will you ever be locking tickets that are available for sale?",
      //   content: `No, it is your responsibility to adjust the Sale Price of your ticket using the website or app.`
      // }
    ],
  },
  {
    header: "Selling",
    items: [
      // {
      //   header: "Can I sell a ticket if I'm not in Nevada?",
      //   content: `Yes of course. Get the process started TODAY by clicking on “SELL” on the top of our homepage.`
      // },
      {
        header: "Once my ticket sells, how do I get paid?",
        content: `You will be notified by PropSwap that your ticket has been sold. Those proceeds (net our 10% commission) will be deposited into your PropSwap Balance.

        Once we receive your ticket, you can request a withdrawal via check.`,
      },
      {
        header: "Once my ticket sells, where do I mail my ticket to?",
        content: `When your ticket sells, you will receive an email confirmation letting you know it has been sold. Depending on where your ticket was originated, your email confirmation will have a corresponding address for where to send it. If you did not receive an address on where to send in your ticket, please contact PropSwap support immediately (info@propswap.com)`,
      },
      // {
      //   header: "When can I drop my ticket off?",
      //   content: `When your ticket sells, you will receive an email with instructions on where to mail your ticket.

      //   In some cases, we do allow in-person drop-offs. If you would like to inquire about scheduling a drop-off, please email us at info@propswap.com.`
      // },
      {
        header: "Are there any bets you won't accept?",
        content: `Yes. We do not accept ANY bets made on an offshore account, or made with your local bookie. Only bets made at a licensed American sportsbook, will be accepted. 
        PropSwap reserves the right to decline any ticket that is presented to us for Sale. We’ll do our best to accommodate you as the Seller, but try to keep your tickets presentable, and legible!
        Other examples of bets we WON’T accept are:
        1. Any “field” bet. Say you make a bet at South Point Casino, taking the “Field” to win the College Football Championship Game. The “Field” may differ from South Point vs. the Westgate, and we are not (yet) in the business of figuring out which casino offers which Field!
        2. A crumbled up ticket that is illegible.
        3. Bets made on an offshore account. 
        4. Tickets from Illinois sportsbooks are not eligible to be sold.`,
      },
      {
        header:
          "Why would I click on SELL if I'm not ready to sell my ticket yet?",
        content: `The SELL section of our website allows you to upload your ticket’s information into our system. You can put a high price on it now, then lower it later! Or, whenever you’re ready (at a later hour or day), at the click of a button, you can move your ticket to Active and instantly list it for Sale. This can be done in the “My Tickets” section.`,
      },
    ],
  },
  {
    header: "Managing My Tickets",
    items: [
      {
        header: "How do I change the price of a ticket that I've submitted?",
        content: `Click on My Tickets, in the top right-hand corner. There, you can Manage all of the Tickets that you own.`,
      },
      {
        header:
          "Will I be notified if the market price of my listed ticket should be drastically higher?",
        content: `As of now, no. It’s up to you to change the Selling Price of your ticket. However, that may be a feature we add very soon, so stay tuned!`,
      },
      {
        header:
          "What if I have my ticket's price live on the website, and I forget to change it?",
        content: `If you have the ticket’s status set to “Active” and have entered in a Sale Price, that ticket is Live and will be purchased by a willing buyer. If you are worried about selling your ticket, please enter your “minimum accept” price, or simply remove it from the marketplace.`,
      },
    ],
  },
  {
    header: "Buying",
    items: [
      {
        header: "Which states does PropSwap allow Buyers to be located in?",
        content: `Customers must be located in one of these ${
          ALLOWED_STATES.length
        } states in order to purchase tickets: ${ALLOWED_STATES.map(
          state => state
        ).join(", ")}.

        Please note you will be delivered the hard ticket to the address you’ve provided. It is your responsibility to redeem the ticket, either in-person at the issuing sportsbook, or by mailing the ticket in. Mail-in directions can be found on the back of the ticket.`,
      },
      {
        header:
          "I'm worried about buying a counterfeit ticket. Is PropSwap taking measures to combat counterfeiting?",
        content: `Yes. Any time a Ticket is listed for Sale, we place a Hold on the Seller’s credit card, until the ticket has been delivered, and verified. Once the ticket has been verified for authenticity, the Hold will be removed.`,
      },
      {
        header: "When do I receive the ticket I’ve purchased?",
        content: `Once a ticket wins, it will be sent to the final Buyer. When you purchase a ticket on PropSwap and the Event is still undecided, PropSwap maintains possession of the ticket. Once the Event has been decided and the ticket is a winner, then it will be mailed to you.`,
      },
      // {
      //   header: "When I Buy a ticket will I receive it in the mail no matter what?",
      //   content: `No, Buyers only receive tickets when they Win. When you purchase a ticket on PropSwap and the Event is still undecided, PropSwap will maintain possession of the ticket in case you decide you want to Sell it later on. Once the Event has been decided and the ticket is a winner, then it will be mailed to you.`
      // },
      {
        header: "What is PropSwap's Refund Policy?",
        content: `All sales are final. The only time a refund is issued to a buyer, is if the seller fails to send the ticket to PropSwap within the mandatory 7 day timeframe. In these cases, the buyer is given a full 100% refund of the purchase price, regardless of the result of the event.`,
      },
    ],
  },
  {
    header: "Fees & Withdrawals",
    items: [
      {
        header: "Is there a fee to withdraw my funds from PropSwap?",
        content: `Nope! You can withdraw as many times as you’d like!`,
      },
      {
        header: "Why can't PropSwap cash my winning ticket for me?",
        content: `Some casinos prefer that the final Buyer redeem the ticket. So for now, we send all tickets to the winning Buyer for redemption.`,
      },
      {
        header: "How much is PropSwap's fee?",
        content: `PropSwap’s commission fee is 10% of the Sale Price. Say a Seller would like to net $90 for his ticket. He should list the ticket for $100. 
        
        The deposit fee is 3% on new money into PropSwap. We encourage Sellers to leave their sale proceeds with us, to avoid paying the 3% fee on new money deposited into PropSwap.`,
      },
    ],
  },
];

const useStyles = makeStyles(theme => ({
  sectionItemPreview: {
    cursor: "pointer",
  },
  open: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const SectionItem = ({ preview, content, open, onClick }) => {
  //TODO: move to export
  const classes = useStyles();

  return (
    <>
      <Typography
        onClick={onClick}
        paragraph
        className={classNames(classes.sectionItemPreview, {
          [classes.open]: !!open,
        })}
      >
        {!!open ? "-" : "+"}&nbsp;
        {preview}
      </Typography>
      {!open
        ? null
        : content.split("\n").map((line, i) => (
            <Typography paragraph key={i}>
              {line}
            </Typography>
          ))}
    </>
  );
};

const SearchBox = ({ searchText, onChange }) => {
  return (
    <>
      <Grid container justify={"center"}>
        <Grid item xs={12} md={6}>
          <Typography>What can we help you find?</Typography>
        </Grid>
      </Grid>
      <Grid container justify={"center"}>
        <Search
          searchString={searchText}
          onChange={onChange}
          gridProps={{ xs: 12, md: 6 }}
        />
      </Grid>
    </>
  );
};

const FAQ = ({ history }) => {
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState("");

  const handleItemClick = item => () =>
    selected.includes(item.header)
      ? setSelected(selected.filter(header => item.header !== header))
      : setSelected([...selected, ...[item.header]]);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/pages/faq"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell>
          <SearchBox onChange={setSearchText} searchText={searchText} />
          {sections.map((section, i) => (
            <React.Fragment key={i}>
              <SectionHeader>{section.header}</SectionHeader>
              {section.items.map((item, j) => (
                <SectionItem
                  preview={item.header}
                  content={item.content}
                  key={`section-item-${j}`}
                  open={
                    (!!searchText &&
                      (new RegExp(searchText, "ig").test(item.content) ||
                        new RegExp(searchText, "ig").test(item.header))) ||
                    selected.includes(item.header)
                  }
                  onClick={handleItemClick(item)}
                />
              ))}
            </React.Fragment>
          ))}
        </ContentCell>
        <AdCell>
          <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};

export default FAQ;
