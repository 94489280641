import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  pageContainer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(-2),
  },
}));

const PageContainer = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.pageContainer} container spacing={8} {...rest}>
      {children}
    </Grid>
  );
};

export default PageContainer;
