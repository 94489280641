import React from "react";

import Form from "../Form";

export default ({ providerId }) => (
  <Form
    exchangeTransaction={{
      type: "PAYMENT",
      providerId,
    }}
  />
);
