import React from "react";

import { DataTableNew as DataTable } from "gunner-react/web";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
import Tabs from "Components/Admin/Components/Tabs";
import gql from "graphql-tag";

const fields = {
  Text: {
    value: item => item.text,
    // nowrap: true,
    sort: null,
    rowProps: {},
  },
};

const queryString = gql`
  query ListSearchesByCreatedAt(
    $unused: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearchesByCreatedAt(
      unused: $unused
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
      }
      nextToken
    }
  }
`;

const query = {
  dataKey: "listSearchesByCreatedAt",
  query: queryString,
  variables: {
    unused: "y",
    sortDirection: "DESC",
  },
};

export default ({ history, location: { search = "" } = {} }) => {
  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/searches"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <DataTable
            fields={fields}
            dataListParams={{
              query,
            }}
          />
        </ContentCell>
      </PageContainer>
    </>
  );
};
