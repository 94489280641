import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import EventOptionsListItemView from "./EventOptionLIstItemView";

export const getEventForOptionsListContainerFragment = gql`
  fragment GetEventForOptionsListContainerFragment on Event {
    id
    winningOptionId
    eliminatedOptionIds
    groupId
  }
`;


export const getEventQuery = gql`
  query GetEventForOptionsListContainer($id: ID!) {
    getEvent(id: $id) {
      ...GetEventForOptionsListContainerFragment
    }
  }
  ${getEventForOptionsListContainerFragment}
`


const mutation = gql`
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      ...GetEventForOptionsListContainerFragment
    }
  }
  ${getEventForOptionsListContainerFragment}
`;

const EventOptionListItemContainer = ({event, optionGroup}) => {

  const optionId = optionGroup.option.id;
  const eventId = event.id;

  const [updateEvent, { loading }] = useMutation(mutation, {
    refetchQueries: [
      {
        query: getEventQuery,
        variables: {
          id: event?.id
        }
      }
    ]
  });

  const handleWinnerClick = useCallback(() => {
    updateEvent({
      variables: {
        input: {
          id: eventId,
          winningOptionId: optionId
        }
      }
    })
      .catch(console.error);
  }, [updateEvent, optionId, eventId])

  const handleEliminateClick = useCallback(() => {
    updateEvent({
      variables: {
        input: {
          id: eventId,
          eliminatedOptionIds: [
            ...(event.eliminatedOptionIds ?? []),
            optionId
          ]
        }
      }
    })
      .catch(console.error);
  }, [event, updateEvent, optionId, eventId]);

  const outcome = event?.eliminatedOptionIds?.includes?.(optionGroup.option.id) || (!!event?.winningOptionId && event?.winningOptionId !== optionGroup.option.id) ? "Eliminated" : event?.winningOptionId === optionGroup.option.id ? "Winner" : false;

  return <EventOptionsListItemView 
    optionGroup={optionGroup} 
    onWinnerClick={handleWinnerClick}
    onEliminateClick={handleEliminateClick}
    loading={loading}
    outcome={outcome}
  />
}


export const optionGroupForEventOptionsFragment = gql`
  fragment OptionGroupForEventOptionsFragment on OptionGroup {
    id
    option {
      id
      name
    }
  }
`;

export default EventOptionListItemContainer;