import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
// import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import { IconButton, Button, TextField, Box } from "@material-ui/core";
import { useMutation } from "@apollo/client";

import SwapHorizontalCircleIcon from "mdi-material-ui/SwapHorizontalCircle";
import CloseCircleIcon from "mdi-material-ui/CloseCircle";
import Bid from "shared/api/Bid";
import Listing from "shared/api/Listing";
import Ticket from "shared/api/Ticket";
import Modal from "../../Layout/Modal";
import NumberFormatCustom from "../../Layout/NumberFormatCustom";
import formatOdds from "shared/Util/formatOdds";

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    "& .content": {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5, 1),
    "& button": {
      margin: 0,
      padding: 0,
    },
  },
  cardSubHeader: {
    display: "flex",
    justifyContent: "flex-start",
    position: "relative",
  },
  bidContent: {
    paddingBottom: 0,
    "& p": {
      fontSize: "0.55rem",
    },
    "& button": {
      padding: 0,
    },
    "& .iconBar": {
      marginTop: theme.spacing(2),
    },
    "& .buttonCell": {
      "flex-direction": "column",
      "align-items": "center",
      "justify-content": "flex-start",
    },
  },
}));

const BidCard = ({ listing, ticket, currentUser, bidCount, activeBid }) => {
  const classes = useStyles();
  const [modalType, setModalType] = useState(null);
  const [updatingBid, setUpdatingBid] = useState(false);
  const [counter, setCounter] = useState(null);

  const [_updateBidStatus] = useMutation(Bid.mutations.updateBidStatus, {
    variables: {
      input: {
        id: !!listing && !!activeBid ? activeBid.id : "<>",
        status: modalType === "reject" ? "REJECTED" : "ACCEPTED",
      },
    },
    refetchQueries: [
      {
        query: Listing.queries.listBySellerIdWithBids,
        variables: {
          listingSellerId: currentUser.id,
          sortDirection: "DESC",
          limit: 500,
          status: {
            eq: "ACTIVE",
          },
        },
      },
      {
        query: Ticket.queries.listByUserIdWithListingsAndBids,
        variables: {
          ticketUserId: currentUser.id,
          sortDirection: "DESC",
          limit: 500,

          listingsLimit: 1,
          listingsSortDirection: "DESC",

          bidsLimit: 1000,
          bidsSortDirection: "DESC",
        },
      },
    ],
  });

  const [_counterBid] = useMutation(Bid.mutations.counter, {
    variables: {
      input: {
        id: !!listing && !!activeBid ? activeBid.id : "<>",
        counter,
      },
    },
    refetchQueries: [
      {
        query: Listing.queries.listBySellerIdWithBids,
        variables: {
          listingSellerId: currentUser.id,
          sortDirection: "DESC",
          limit: 500,
          status: {
            eq: "ACTIVE",
          },
        },
      },
    ],
  });

  useEffect(() => {
    !!updatingBid &&
      !!modalType &&
      (modalType === "counter" ? _counterBid() : _updateBidStatus())
        .then(() => [setUpdatingBid(false), setModalType(null)])
        .catch(err => [
          setUpdatingBid(false),
          window.alert(err.message),
          console.log(err),
        ]);
  }, [updatingBid, modalType]);

  return (
    <Card className={classes.card} elevation={0}>
      {/* <CardHeader
        className={classes.cardHeader}
        disableTypography={true}
        title={
          <Grid container spacing={0} justify={'space-around'}>
            <Grid item xs={12}>
              <div className={classes.cardSubHeader}>
                <div>
                  <Typography component={"p"} variant={'subtitle1'}><strong>{bidCount}</strong> Bid{ bidCount === 1 ? "" : "s"}</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        }
      /> */}
      <CardContent className={classes.bidContent}>
        {!!activeBid && (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography align="center" variant={"subtitle2"}>
                Highest Bid
              </Typography>
              <Typography
                align="center"
                className={classes.heading}
                component={"h6"}
              >
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={parseFloat(activeBid.offer) / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Typography>
            </Grid>
            <Grid
              container
              spacing={0}
              item
              xs={12}
              justify="space-between"
              className="iconBar"
            >
              <Grid
                style={{ cursor: "pointer" }}
                onClick={() => setModalType("counter")}
                item
                container
                className="buttonCell"
                xs={3}
              >
                <IconButton>
                  <SwapHorizontalCircleIcon />
                </IconButton>
                <Typography align="center" variant="caption" component="p">
                  Counter
                </Typography>
              </Grid>

              <Grid item container className="buttonCell" xs={6}>
                <Button
                  onClick={() => setModalType("accept")}
                  fullWidth
                  size="small"
                  variant="contained"
                  color="secondary"
                >
                  Accept
                </Button>
              </Grid>

              <Grid
                style={{ cursor: "pointer" }}
                onClick={() => setModalType("reject")}
                item
                container
                className="buttonCell"
                xs={3}
              >
                <IconButton>
                  <CloseCircleIcon />
                </IconButton>
                <Typography align="center" variant="caption" component="p">
                  Reject
                </Typography>
              </Grid>
              {!!modalType && (
                <Modal
                  body={
                    modalType === "counter" ? (
                      <>
                        <Box
                          justifyContent="space-between"
                          display="flex"
                          component="form"
                          w={1}
                          noValidate
                          autoComplete="off"
                        >
                          <Box mt={2}>
                            <TextField
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                inputMode: "decimal",
                                type: "text",
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={({ target: { value } }) =>
                                setCounter(parseFloat(value) * 100.0)
                              }
                              value={
                                !!counter ? parseFloat(counter / 100.0) : ""
                              }
                              label="Counter Offer"
                              variant={"outlined"}
                              fullWidth
                              type="text"
                              inputMode="decimal"
                              inputProps={{ inputMode: "decimal" }}
                            />
                          </Box>
                          <Box mt={2}>
                            <TextField
                              disabled
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={
                                !!counter
                                  ? formatOdds(ticket.collectAmount, counter)
                                  : ""
                              }
                              label="New Odds"
                              variant={"outlined"}
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box mb={2} mt={2}>
                          <Typography variant="body1" paragraph>
                            By submitting a Counter Offer, you are Rejecting
                            this Bid and it will no longer be available. This
                            Counter Offer is a suggested amount that the Buyer
                            will need to submit in the form of a new Bid.
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <>Are you sure you want to {modalType} this offer?</>
                    )
                  }
                  preTitle={
                    <Box mt={1} mb={1}>
                      <Typography variant={"h4"}>
                        <Box color="secondary.main" component="span">
                          {ticket.subject} <br />
                        </Box>
                        {ticket.eventDescription}
                      </Typography>
                    </Box>
                  }
                  title={
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mr={2}
                      ml={2}
                    >
                      <div>
                        <strong>Bid:</strong>&nbsp;
                        <Box color="secondary.main" component="span">
                          <NumberFormat
                            fixedDecimalScale
                            decimalScale={2}
                            value={parseFloat(activeBid.offer || 0) / 100.0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </Box>
                      </div>
                      <div>
                        <strong>Odds:</strong>{" "}
                        {formatOdds(ticket.collectAmount, activeBid.offer)}
                      </div>
                    </Box>
                  }
                  subTitle={
                    modalType !== "counter" && (
                      <>
                        Bid:{" "}
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={parseFloat(activeBid.offer) / 100.0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                        <br />
                        <br />
                        You will receive 90% of the Bid -&nbsp;
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={(parseFloat(activeBid.offer) / 100.0) * 0.9}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </>
                    )
                  }
                  onClose={() => setModalType(null)}
                  submitting={updatingBid}
                  opened={!!modalType}
                  saveButton={{
                    disabled:
                      !!updatingBid ||
                      (modalType === "counter" &&
                        parseInt(counter || 0) <= activeBid.offer),
                    onClick: () => setUpdatingBid(true),
                    text:
                      modalType === "accept"
                        ? `Accept`
                        : modalType === "reject"
                        ? "Reject"
                        : `Counter`,
                    ButtonProps: {
                      fullWidth: true,
                    },
                  }}
                />
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default BidCard;
