import React, { useMemo, useContext, useCallback } from "react";
import TicketListItemView from "./TicketListItemView";
import { CurrentUserContext, useDataObject } from "gunner-react";

import Ticket from "shared/api/Ticket";
import Listing from "shared/api/Listing";

import useStyles from "./TicketListItemStyles";
import useCanCreateListing from "react-shared/Hooks/useCanCreateListing";
import useCanEditListing from "react-shared/Hooks/useCanEditListing";
import { useMutation } from "@apollo/client";

export default ({
  object: shallowTicket,
  id: passedTicketId,
  listingId,
  listing: shallowListing,
  status,
  bid,
}) => {
  const classes = useStyles();

  const ticketId = shallowTicket?.id ?? passedTicketId;
  const currentUser = useContext(CurrentUserContext);

  const ticket = useDataObject({
    objectId: ticketId,
    object: shallowTicket,
    query: Ticket.queries.get,
  });
  const listing = useDataObject({
    objectId: listingId,
    object: shallowListing ?? ticket.listing,
    query: Listing.queries.get,
  });
  const canEditListing = useCanEditListing({ listingId, listing });
  const canCreateListing = useCanCreateListing({ ticketId, ticket });
  const [updateTicket] = useMutation(Ticket.mutations.update, {
    variables: {
      input: {
        id: ticketId,
        hasShipped: false,
      },
    },
  });

  const highBid = useMemo(
    () =>
      bid ??
      listing?.bids?.items?.filter(item => item.status === "PENDING")?.[0],
    [JSON.stringify(bid), JSON.stringify(listing?.bids?.items)]
  );

  const handleEditPress = useCallback(console.log, [listingId]);
  const handleCreatePress = useCallback(console.log, [ticketId]);
  const handleBidPress = useCallback(console.log, []);
  const handleShippedChaged = useCallback(
    ({ target: { checked, value } }) =>
      window.confirm(
        `Click “OK” if you have mailed this ticket to PropSwap. Once you confirm, this action cannot be undone.`
      ) &&
      updateTicket({
        variables: {
          input: {
            id: ticketId,
            hasShipped: !!checked,
          },
        },
      }),
    [ticketId]
  );

  return useMemo(
    () =>
      !ticket ? null : (
        <TicketListItemView
          onShippedChanged={handleShippedChaged}
          ticket={ticket}
          listing={listing}
          highBid={highBid}
          canEditListing={canEditListing}
          canCreateListing={canCreateListing}
          onEditPress={handleEditPress}
          onCreatePress={handleCreatePress}
          onBidPress={handleBidPress}
          status={status}
          classes={classes}
          currentUser={currentUser}
        />
      ),
    [
      highBid,
      handleEditPress,
      handleCreatePress,
      canCreateListing,
      canEditListing,
      JSON.stringify(ticket),
      JSON.stringify(listing),
      status,
    ]
  );
};
