import React from "react";
import { TextField, Button } from "@material-ui/core";

//bovadoGroupId: String!, bovadoEventId: String!, bovadoParticipantId: String, bovadoParticipantName: String

export default ({
  groupId,
  eventId,
  participantId,
  participantName,
  onChange,
  onSubmit,
}) => (
  <>
    <TextField
      label={"Group Id"}
      onChange={({ target: { value } }) => onChange("groupId", value)}
      value={groupId}
      variant={"outlined"}
      InputLabelProps={{ shrink: true }}
    />
    <TextField
      label={"Event Id"}
      onChange={({ target: { value } }) => onChange("eventId", value)}
      value={eventId}
      variant={"outlined"}
      InputLabelProps={{ shrink: true }}
    />
    <TextField
      label={"Participant Id"}
      onChange={({ target: { value } }) => onChange("participantId", value)}
      value={participantId}
      variant={"outlined"}
      InputLabelProps={{ shrink: true }}
    />
    <TextField
      label={"Participant Name"}
      onChange={({ target: { value } }) => onChange("participantName", value)}
      value={participantName}
      variant={"outlined"}
      InputLabelProps={{ shrink: true }}
    />

    <Button variant={"contained"} color="secondary" onClick={onSubmit}>
      Look Up
    </Button>
  </>
);
