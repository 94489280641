import React from "react";
import RecentlySoldListView from "./RecentlySoldListView";
import { useQuery } from "@apollo/client";
import Listing from "shared/api/Listing";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({}));

export default () => {
  const classes = useStyles();

  const {
    error,
    data: { searchListings: { items: listings = [] } = {} } = {},
  } = useQuery(Listing.queries.search, {
    pollInterval: 3000,
    variables: {
      limit: 3,
      sort: {
        direction: "desc",
        field: "updatedAt",
      },
      filter: {
        and: [
          // {
          //   status: {
          //     eq: "ACTIVE"
          //   }
          // },
          {
            recentlySold: {
              eq: true,
            },
          },
        ],
      },
    },
  });

  !!error && console.log(error);

  return <RecentlySoldListView classes={classes} listings={listings} />;
};
