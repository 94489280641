import React, { useState } from "react";
import { Hidden, Typography, Menu, MenuItem } from "@material-ui/core";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "mdi-material-ui/Menu";

const useStyles = makeStyles(theme => ({
  tabs: {
    display: "flex",
    marginBottom: theme.spacing(2),
    "& .tab": {
      marginRight: theme.spacing(0.5),
      padding: theme.spacing(0.5, 2),
      backgroundColor: theme.palette.background.default,
      borderTopLeftRadius: theme.shape.borderRadius * 2,
      borderTopRightRadius: theme.shape.borderRadius * 2,
      cursor: "pointer",
      "& .label": {
        fontWeight: "inherit",
        textTransform: "uppercase",
        fontSize: "1.875rem",
      },
      "& .hamburger": {
        marginBottom: theme.spacing(-0.5),
        marginLeft: theme.spacing(1),
      },
    },
    "& .selectedTab": {
      backgroundColor: theme.palette.background.paper,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

const SmallTab = ({ options, selectedValue, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = value => [onChange(value), setAnchorEl(null)];

  const option = options.find(o => o.value === selectedValue) || options[0];

  return (
    <>
      <div onClick={handleClick} className={classNames("tab", "selectedTab")}>
        <Typography className={"label"} variant={"h4"} component="span">
          {option.label}&nbsp;
          <MenuIcon className={classNames("hamburger", "label")} />
        </Typography>
      </div>
      <Menu
        id="option-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options
          .filter(o => o.value !== option.value)
          .map((option, i) => (
            <MenuItem key={i} onClick={() => handleClose(option.value)}>
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

const LargeTabs = ({ options, selectedValue, onChange }) => {
  return options.map((option, i) => (
    <Tab
      option={option}
      key={i}
      selected={
        !!option.selected ||
        option.value === selectedValue ||
        (option.value === null && typeof selectedValue === "undefined")
      }
      onChange={onChange}
    />
  ));
};

const Tab = ({ option, selected, onChange }) => {
  return (
    <div
      onClick={() => onChange(option.value)}
      className={classNames("tab", { selectedTab: !!selected })}
    >
      <Typography className={"label"} variant={"h4"} component="span">
        {option.label}
      </Typography>
    </div>
  );
};

const SmallTabs = ({ options, selectedValue, onChange }) => {
  return (
    <SmallTab
      options={options}
      selectedValue={selectedValue}
      onChange={onChange}
    />
  );
};

const Tabs = ({ options, selectedValue, onChange }) => {
  const classes = useStyles();
  return (
    <nav className={classes.tabs}>
      <Hidden smDown>
        <LargeTabs
          options={options}
          selectedValue={selectedValue}
          onChange={onChange}
        />
      </Hidden>
      <Hidden mdUp>
        <SmallTabs
          options={options}
          selectedValue={selectedValue}
          onChange={onChange}
        />
      </Hidden>
    </nav>
  );
};

export default Tabs;
