import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Box,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import queryString from "query-string";
import { useQuery } from "@apollo/client";

import PageContainer from "../../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../../Components/Layout/ContentCell";
import Tabs from "../../../../../Components/Admin/Components/Tabs";
import UserSubTabsView from "../../../../../Components/User/SubTabs";
import ListingList from "../../../../../Components/Listing/List";
import Listing from "shared/api/Listing";

const AdminUserListingListRoute = ({
  history,
  location: { search = "" },
  match: { params },
}) => {
  const searchParams = queryString.parse(search);
  const [token, setToken] = useState(searchParams.nextToken);
  const [listings, setListings] = useState([]);
  const [listingType, setListingType] = useState("Seller");

  const {
    loading,
    error,
    data: {
      [`listingsBy${listingType}Id`]: { nextToken, items = [] } = {},
    } = {},
  } = useQuery(Listing.queries[`listBy${listingType}IdWithTicket`], {
    variables: {
      limit: 50,
      nextToken: token,
      [`listing${listingType}Id`]: params.userId,
    },
  });

  useEffect(() => {
    setListings([]);
    setToken(null);
  }, [listingType]);

  // useEffect(() => {
  //   setListings([...listings, ...items]);
  // }, [items, listings]);

  const searchItemsString = JSON.stringify(items);

  useEffect(() => {
    const searchItems = JSON.parse(searchItemsString);
    
    setListings(listings => [...listings, ...searchItems]);
  }, [searchItemsString, token]);

  !!error && console.log(error);

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/users"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Grid container spacing={4}>
            <Grid item xs={12} container>
              <UserSubTabsView
                selected="listings"
                history={history}
                userId={params.userId}
              />
            </Grid>
          </Grid>
          <Box mt={4} mb={4} component="form">
            <TextField
              type={"text"}
              onChange={({ target: { value } }) => setListingType(value)}
              label={"As?"}
              InputLabelProps={{ shrink: true }}
              value={listingType}
              margin="normal"
              fullWidth
              variant="outlined"
              select
            >
              <MenuItem value={"Seller"}>Seller</MenuItem>
              <MenuItem value={"Buyer"}>Buyer</MenuItem>
            </TextField>
          </Box>
          <ListingList listings={listings} />
          {!!loading && <CircularProgress color="secondary" />}
          {!!nextToken && !loading && (
            <Box mt={4}>
              <Button fullWidth onClick={() => setToken(nextToken)}>
                Load More
              </Button>
            </Box>
          )}
        </ContentCell>
      </PageContainer>
    </>
  );
};

export default AdminUserListingListRoute;
