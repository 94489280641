import React, { useContext, useState, useEffect } from "react";
import CognitoIdentityServiceProvider from "aws-sdk/clients/cognitoidentityserviceprovider";
import { Auth } from "aws-amplify";
import Form from "../Form";
import { CircularProgress } from "@material-ui/core";
import { CurrentUserContext } from "gunner-react";
import awsmobile from "../../../aws-exports";
import { useQuery } from "@apollo/client";
import User from "shared/api/User";

const cognitoClient = () =>
  Auth.currentCredentials().then(credentials =>
    Promise.resolve(
      new CognitoIdentityServiceProvider({
        apiVersion: "2016-04-18",
        credentials: Auth.essentialCredentials(credentials),
        region: "us-east-1",
      })
    )
  );

const fixMobileNumber = number =>
  (number ?? "").trim().replace(/[^\d]/g, "").length === 10
    ? `1${number.trim().replace(/[^\d]/g, "")}`
    : (number ?? "").trim().replace(/[^\d]/g, "");

const CognitoEditContainer = ({ userId: passedUserId }) => {
  const currentUser = useContext(CurrentUserContext);
  const userId = passedUserId ?? currentUser?.id;
  const { data: { getUser: user = {} } = {} } = useQuery(User.queries.get, {
    variables: { withPrivate: true, id: userId || currentUser.id },
  });
  const [cognitoAttributes, setCognitoAttributes] = useState(null);

  useEffect(() => {
    currentUser.id === user.id
      ? Auth.currentAuthenticatedUser({ bypassCache: true }).then(cognitoUser =>
          setCognitoAttributes({
            ...cognitoUser.attributes,
            admin: currentUser.groups.includes("Admins"),
          })
        )
      : !user.email
      ? console.log(null)
      : cognitoClient().then(client =>
          client
            .listUsers({
              UserPoolId: awsmobile.aws_user_pools_id,
              Limit: 1,
              Filter: `email = "${user.email}"`,
            })
            .promise()
            .then(({ Users }) => Users[0])
            .then(cognitoUser =>
              Promise.all([
                cognitoUser.Attributes,
                client
                  .adminListGroupsForUser({
                    UserPoolId: awsmobile.aws_user_pools_id,
                    Username: cognitoUser.Username,
                  })
                  .promise(),
                cognitoUser.Enabled,
              ])
            )
            .then(([UserAttributes, { Groups }, enabled]) =>
              UserAttributes.reduce(
                (obj, currentValue) => ({
                  ...obj,
                  [currentValue.Name]: currentValue.Value,
                }),
                {
                  disabled: !enabled,
                  admin: !!Groups.find(group => group.GroupName === "Admins"),
                }
              )
            )
            .then(attributes => setCognitoAttributes(attributes))
        );
  }, [currentUser.id, user.id]);

  return !cognitoAttributes ? (
    <CircularProgress color="secondary" />
  ) : (
    <Form
      dynamoUserId={userId}
      user={{
        ...cognitoAttributes,
        "custom:mobile": fixMobileNumber(cognitoAttributes["custom:mobile"]),
        password: "",
      }}
    />
  );
};

export default CognitoEditContainer;
