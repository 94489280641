import React, { useState, useEffect, useCallback, useMemo } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import Event from "shared/api/Event";
import OptionGroup from "shared/api/OptionGroup";
import { useQuery } from "@apollo/client";

export default ({
  eventId,
  onChange,
  onSetOption,
  value: passedValue,
  freeForm: passedFreeForm,
}) => {
  const [freeForm, setFreeForm] = useState(false);
  const [value, setValue] = useState(null);
  const { data: { getEvent: event } = {} } = useQuery(Event.queries.get, {
    variables: { id: eventId },
  });
  const {
    data: { listOptionGroupsByGroupId: { items: optionGroups = [] } = {} } = {},
  } = useQuery(OptionGroup.queries.listByGroupId, {
    skip: !event?.groupId,
    variables: {
      limit: 2000,
      groupId: event?.groupId,
    },
  });

  const options = useMemo(
    () => (optionGroups ?? []).map(og => og.option),
    [optionGroups]
  );
  const selectedOption = useMemo(
    () => options.find(option => option.id === value || option.name === value),
    [options, value]
  );

  const handleChange = useCallback(
    ({ target: { value } }) =>
      value === "Other" && !freeForm
        ? setFreeForm(true)
        : !!freeForm || !options.length
        ? setValue(value)
        : setValue(
            options.find(option => option.id === value || option.name === value)
              ?.name
          ),
    [options, freeForm]
  );

  useEffect(() => {
    setFreeForm(!!passedFreeForm);
  }, [passedFreeForm]);

  useEffect(() => {
    !!onSetOption && onSetOption(selectedOption);
  }, [selectedOption, onSetOption]);

  useEffect(() => {
    onChange("subject", value);
  }, [value]);

  useEffect(() => {
    !passedValue && setValue(null);
  }, [passedValue]);

  return (
    <TextField
      select={!freeForm && !!optionGroups.length}
      className="formControl"
      fullWidth
      helperText={`Example: NE Patriots`}
      label={"Team / Player"}
      onChange={handleChange}
      value={passedValue ?? value ?? ""}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
    >
      {!!freeForm
        ? null
        : options.map(option => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
      <MenuItem value={"Other"}>Other</MenuItem>
    </TextField>
  );
};
