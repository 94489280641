import React, { useState } from "react";
import BovadoOddsLookUpView from "./BovadoOddsLookUpView";
import Bovado from "shared/api/Bovado";
import { useLazyQuery } from "@apollo/client";

export default ({}) => {
  const [params, setParams] = useState({});
  const [
    getOddsDataForEventAndOption,
    { data: { getOddsDataForEventAndOption: odds = null } = {}, error },
  ] = useLazyQuery(Bovado.queries.getOddsDataForEventAndOption, {
    variables: {
      bovadoGroupId: params.groupId,
      bovadoEventId: params.eventId,
      bovadoParticipantId: params.participantId,
      bovadoParticipantName: params.participantName,
    },
  });

  const handleChange = (field, value) =>
    setParams({
      ...params,
      [field]: value,
    });

  const handleSubmit = () => getOddsDataForEventAndOption();

  console.log("HERE", odds, error);

  return (
    <BovadoOddsLookUpView
      {...params}
      onSubmit={handleSubmit}
      onChange={handleChange}
    />
  );
};
