import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import {
  Button,
  Grid,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import AccountTabs from "../Components/Tabs";

import { CurrentUserContext } from "gunner-react";
import TransactionTable from "../../Transaction/Components/Table";

import User from "shared/api/User";
import Transaction from "shared/api/Transaction";
import ContentCell from "../../Layout/ContentCell";
import PageHeader from "../../Layout/PageHeader";
import AdCell from "../../Layout/AdCell";
import PageContainer from "../../Layout/PageContainer";
import UserTransactionFormModal from "../../User/TransactionFormModal";
import AdminTabs from "../../Admin/Components/Tabs";
// import InnerTabs from '../../Layout/InnerTabs';
import UserSubTabsView from "../../User/SubTabs";
import useCanRequestRefund from "react-shared/Hooks/useCanRequestRefund";

const useStyles = makeStyles(theme => ({
  mainCell: {
    backgroundColor: theme.palette.background.paper,
  },
  widthdraw: {
    color: theme.palette.error.dark,
  },
  headerContainer: {
    marginTop: theme.spacing(3),
    paddingBottom: "0 !important",
  },
  adCell: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchCell: {
    marginBottom: theme.spacing(5),
  },
  content: {
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
    },
  },
  rail: {
    "& button": {
      margin: theme.spacing(1),
    },
    "& a": {
      margin: theme.spacing(1),
    },
  },
  adImage: {
    maxWidth: "100%",
    height: "auto",
    marginTop: theme.spacing(3),
  },
}));

const Transactions = ({
  history,
  match: { params = {} } = {},
  location: { search = "", pathname } = {},
}) => {
  const currentUser = useContext(CurrentUserContext);
  const classes = useStyles();
  const searchParams = useState(queryString.parse(search));
  const [token, setToken] = useState(searchParams.token);
  const [transactions, setTransactions] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const {
    error,
    loading,
    data: { transactionsByUserId: { nextToken, items = [] } = {} } = {},
  } = useQuery(Transaction.queries.listByUserId, {
    variables: {
      nextToken: token,
      transactionUserId:
        !!params.userId && currentUser.groups.includes("Admins")
          ? params.userId
          : currentUser.id,
      limit: 50,
      sortDirection: "DESC",
    },
    pollInterval: 3000,
  });

  const { error: userError, data: { getUser: user } = {} } = useQuery(
    User.queries.get,
    {
      variables: {
        withPrivate: true,
        id:
          !!params.userId && currentUser.groups.includes("Admins")
            ? params.userId
            : currentUser.id,
      },
      pollInterval: 3000,
    }
  );

  const itemsString = JSON.stringify(items);

  !!userError && console.log(userError);

  !!error && console.log(error);

  useEffect(() => {
    Promise.resolve(JSON.parse(itemsString))
      .then(items =>
        setTransactions(transactions => [
          ...transactions.filter(
            transaction => !items.find(item => item.id === transaction.id)
          ),
          ...items,
        ])
      );
  }, [itemsString, nextToken, token]);

  return (
    <>
      {!!openModal && (
        <UserTransactionFormModal
          onClose={() => setOpenModal(false)}
          userId={params.userId}
          modalOpen={!!openModal}
        />
      )}
      {/admin/.test(pathname) ? (
        <AdminTabs
          history={history}
          currentPath={"/admin/users"}
          onChange={path => history.push(path)}
        />
      ) : (
        <AccountTabs
          history={history}
          currentPath={"/account/transactions"}
          onChange={path => history.push(path)}
        />
      )}
      <PageContainer>
        <ContentCell>
          {/admin/.test(pathname) ? (
            <Grid container spacing={4}>
              <Grid item xs={12} container>
                <UserSubTabsView
                  selected="transactions"
                  history={history}
                  userId={params.userId}
                />
              </Grid>
            </Grid>
          ) : (
            <PageHeader>Transaction History</PageHeader>
          )}
          <Grid container className={classes.content} spacing={4}>
            <Grid className={classes.rail} item xs={12} md={3}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Current Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Typography align="center" variant={"h3"}>
                        {!!user && user.id && (
                          <NumberFormat
                            fixedDecimalScale
                            decimalScale={2}
                            value={parseFloat(user.balance || 0) / 100.0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Pending Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Typography align="center" variant={"h3"}>
                        {!!user && user.id && (
                          <NumberFormat
                            fixedDecimalScale
                            decimalScale={2}
                            value={parseFloat(user.pendingBalance || 0) / 100.0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Promo Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      <Typography align="center" variant={"h3"}>
                        {!!user && user.id && (
                          <NumberFormat
                            fixedDecimalScale
                            decimalScale={2}
                            value={
                              parseFloat(user.promotionalBalance || 0) / 100.0
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {currentUser.groups.includes("Admins") &&
              !!params.userId &&
              currentUser.id !== params.userId ? (
                <>
                  <Box mt={2}>
                    <Button
                      fullWidth
                      size={"small"}
                      variant={"contained"}
                      color={"secondary"}
                      onClick={() => setOpenModal(true)}
                    >
                      Add Transaction
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Box mt={2}>
                    <Button
                      component={Link}
                      to={"/account/deposit"}
                      fullWidth
                      size={"small"}
                      variant={"contained"}
                      color={"secondary"}
                    >
                      Deposit
                    </Button>
                  </Box>
                  <Box mt={2}>
                    <Button
                      component={Link}
                      to={"/account/withdraw"}
                      fullWidth
                      size={"small"}
                      variant={"contained"}
                      className={classes.withdrawButton}
                    >
                      Withdraw
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={9}>
              <TransactionTable
                items={transactions}
                currentUser={currentUser}
                history={history}
              />
              {!!loading ? (
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              ) : !!nextToken && !!transactions.length ? (
                <Button
                  fullWidth
                  variant={"contained"}
                  onClick={() => setToken(nextToken)}
                >
                  Show More
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </ContentCell>
        <AdCell>
          <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};

export default Transactions;
