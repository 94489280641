import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import HelpCircleOutlineIcon from "mdi-material-ui/HelpCircleOutline";
import { Redirect, Link } from "react-router-dom";

import SportIcon from "../../Sport/Components/Icon";
import Rating from "../../Rating";
import BuyButton from "./BuyButton";
import BidButton from "../../Bid/Components/CreateButton";
import { Input, Hidden, Box } from "@material-ui/core";
import formatOdds from "shared/Util/formatOdds";
import Modal from "../../Layout/Modal";
import { inactiveSports } from "shared/Config";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  detail: {
    borderRadius: 0,
    "& .bidContainer": {
      "& input": {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(0.5),
      },
      "& button": {
        backgroundColor: theme.palette.background.default,
      },
      "& .bidInputContainer": {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(0.5, 0, 0.5, 0.5),
        "& > *": {
          width: "100%",
        },
      },
      "& .bidButtonContainer": {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(0.25, 0),
        display: "inline-block",
        // '& > *': {
        //   width: '100%'
        // }
      },
    },
    "& .steps": {
      borderTop: `1px solid ${theme.palette.background.default}`,
      borderBottom: `1px solid ${theme.palette.background.default}`,
      paddingBottom: theme.spacing(2),
    },
    "& .help": {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 5),
    },
    "& .header": {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0.5, 1),
      "& h2": {
        // lineHeight: 1.2,
        // color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: "uppercase",
      },
      "& h4": {
        [theme.breakpoints.up("md")]: {
          marginRight: theme.spacing(3),
        },
      },
    },
  },
  comments: {
    border: `2px solid ${theme.palette.background.default}`,
    padding: theme.spacing(0.5, 1),
  },

  buyContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // '& h3': {
    //   fontSize: theme.typography.body1.fontSize
    // },
  },
  step: {
    marginTop: theme.spacing(2),
    position: "relative",
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    "& .line": {
      height: 2,
      backgroundColor: theme.palette.background.default,
      position: "absolute",
      top: 12,
      left: `calc(50% + 12px)`,
      width: "100%",
    },
    "& .indicator": {
      marginBottom: theme.spacing(1),
      border: "1px solid black",
      borderRadius: 12,
      backgroundColor: theme.palette.background.default,
      width: 24,
      height: 24,
      "&.completed": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));

const Step = ({ title, completed = false, last = false }) => {
  const classes = useStyles();
  return (
    <div className={classes.step}>
      {!last && <div className="line" />}
      <div className={classNames("indicator", { completed })} />
      <Typography align={"center"} variant={"subtitle2"}>
        {title.split(" ").map((piece, i) => (
          <React.Fragment key={i}>
            {piece} <br />
          </React.Fragment>
        ))}
      </Typography>
    </div>
  );
};

const Detail = ({ listing, ticket, bids = [], currentUser }) => {
  const theme = useTheme();
  const classes = useStyles();
  const matchesSmallDown = useMediaQuery(theme.breakpoints.down("sm"));
  const highAmount = bids[0]
    ? parseInt(
        parseFloat(
          bids.slice().sort((a, b) => (a.offer > b.offer ? -1 : 1))[0].offer
        ) / 100.0
      )
    : 0;
  const [bidAmount, setBidAmount] = useState(highAmount ? highAmount + 1 : 0);
  const [bidOdds, setBidOdds] = useState(!!listing ? listing.newOdds : "");
  const [redirectTo, setRedirectTo] = useState(null);
  const [modalRating, setModalRating] = useState(null);

  useEffect(() => {
    setBidOdds(
      !bidAmount
        ? ""
        : formatOdds(
            ticket.collectAmount,
            !!parseInt(bidAmount)
              ? parseInt(bidAmount) * 100
              : listing.askingPrice
          )
    );
  }, [bidAmount, listing ? listing.askingPrice : 0]);

  // useEffect(() => {
  //   console.log(!!listing && listing.minimumBidPrice)
  //   !!listing &&
  //   (listing.minimumBidPrice || 0) > bidAmount &&
  //   setBidAmount(listing.minimumBidPrice)
  // }, [!!listing, bidAmount])

  // console.log(bidAmount)

  return !!redirectTo ? (
    <Redirect push to={redirectTo} />
  ) : (
    <>
      <Modal
        title={
          <>
            <Rating rating={modalRating} />
            <Box component={"span"} ml={2}>
              {modalRating > 3
                ? "Authenticity verified"
                : modalRating === 3
                ? "Seller has completed 10+ sales"
                : modalRating === 2
                ? "Seller has completed 1-9 sales"
                : "First-timer Seller"}
            </Box>
          </>
        }
        body={
          modalRating > 50
            ? "PropSwap possess the ticket."
            : modalRating >= 10
            ? "Seller possesses the ticket."
            : modalRating > 1
            ? "Seller possesses the ticket."
            : "Seller possesses the ticket."
        }
        // body={
        //   ""
        // }
        onClose={() => setModalRating(null)}
        submitting={false}
        opened={!!modalRating}
        saveButton={{
          text: "OK",
          onClick: () => setModalRating(null),
          ButtonProps: {
            fullWidth: true,
          },
        }}
      />
      <Card className={classes.detail} elevation={0}>
        <CardHeader
          className={"header"}
          disableTypography={true}
          title={
            <Grid container spacing={0} justify={"space-around"}>
              <Grid item xs={10} md={8}>
                <Typography variant="h5" component="h2">
                  {ticket.subject}
                </Typography>
                {!!currentUser && !!currentUser.groups.includes("Admins") ? (
                  <Typography variant="h5" component="h3">
                    <Link to={`/admin/tickets/${ticket.id}/edit`}>
                      {ticket.eventDescription}
                    </Link>
                  </Typography>
                ) : (
                  <Typography variant="h5" component="h3">
                    {ticket.eventDescription}
                  </Typography>
                )}
              </Grid>
              <Grid item container xs={2} md={4} justify={"flex-end"}>
                <Hidden mdUp>
                  <SportIcon
                    size="large"
                    sport={ticket.sport.abbrev.toLowerCase()}
                  />
                </Hidden>
                <Typography
                  color="textSecondary"
                  variant="subtitle1"
                  component="h4"
                >
                  {ticket.betType}
                </Typography>
                <Hidden smDown>
                  <SportIcon
                    size="large"
                    sport={ticket.sport.abbrev.toLowerCase()}
                  />
                </Hidden>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {inactiveSports.includes(ticket.sport.abbrev.toLowerCase()) && (
            <Typography paragraph>
              Due to the uncertainty surrounding the 2020-21 season, all
              purchasing of{" "}
              {inactiveSports.map(sport => sport.toUpperCase()).join(" and ")}{" "}
              tickets has been temporarily paused. If you have any questions,
              please feel free to contact us at info@propswap.com.
            </Typography>
          )}
          <Grid container spacing={2}>
            <Grid
              container
              spacing={matchesSmallDown ? 0 : 2}
              justify={matchesSmallDown ? "space-between" : "flex-start"}
              item
              xs={12}
            >
              {!!listing && listing.status === "ACTIVE" && (
                <Grid item container xs={3} alignItems="center">
                  <Rating rating={listing.ratingOverride || 1} />
                  <IconButton
                    size={"small"}
                    aria-label="close"
                    color="inherit"
                    onClick={() => setModalRating(listing.ratingOverride || 1)}
                  >
                    <HelpCircleOutlineIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={3} md={2}>
                <Typography variant={"subtitle2"}>Odds</Typography>
                <Typography variant={"body2"}>
                  {!!listing ? listing.newOdds : ticket.odds}
                </Typography>
              </Grid>
              <Grid item xs={3} md={2}>
                <Typography variant={"subtitle2"}>Collect</Typography>
                <NumberFormat
                  renderText={text => (
                    <Typography
                      variant={"body2"}
                      style={text.length > 10 ? { fontSize: "0.775rem" } : null}
                    >
                      {text}
                    </Typography>
                  )}
                  fixedDecimalScale
                  decimalScale={2}
                  value={parseFloat(ticket.collectAmount) / 100.0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Grid>
              {!!listing && listing.status !== "ACTIVE" && (
                <Grid item xs={3} md={2}>
                  <Typography variant={"subtitle2"}>Price</Typography>
                  <Typography variant={"body2"}>
                    <NumberFormat
                      fixedDecimalScale
                      decimalScale={2}
                      value={parseFloat(listing.askingPrice) / 100.0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Typography>
                </Grid>
              )}
              {!!listing && ["PENDING", "SOLD"].includes(listing.status) && (
                <Grid item xs={3} md={2}>
                  <Typography variant={"subtitle2"}>Final Sale</Typography>
                  <Typography variant={"body2"}>
                    <NumberFormat
                      fixedDecimalScale
                      decimalScale={2}
                      value={parseFloat(listing.salesPrice) / 100.0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Typography>
                </Grid>
              )}
            </Grid>
            {!!listing && listing.status === "ACTIVE" && (
              <Grid container spacing={2} item xs={12} justify="space-between">
                <Grid
                  item
                  xs={12}
                  md={3}
                  lg={4}
                  container
                  justify="space-around"
                  alignItems="center"
                >
                  <Typography
                    align="center"
                    variant={"button"}
                    component="h5"
                    color="secondary"
                  >
                    <NumberFormat
                      fixedDecimalScale
                      decimalScale={2}
                      value={parseFloat(listing.askingPrice) / 100.0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Typography>
                  <BuyButton
                    disabled={inactiveSports.includes(
                      ticket.sport.abbrev.toLowerCase()
                    )}
                    listing={listing}
                    currentUser={currentUser}
                    onSuccess={listing => setRedirectTo(`/account/tickets`)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  lg={8}
                  spacing={2}
                  container
                  justify="space-around"
                  alignItems="center"
                >
                  <Grid item xs={12} md={3}>
                    <Hidden smDown>
                      <Typography noWrap variant={"body2"}>
                        Min/High&nbsp;Bid:
                      </Typography>
                      <Typography variant={"subtitle2"}>
                        {!bids[0] ? (
                          !!listing?.minimumBidPrice ? (
                            <NumberFormat
                              fixedDecimalScale
                              decimalScale={2}
                              value={
                                parseFloat(listing?.minimumBidPrice) / 100.0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          ) : (
                            <>N/A</>
                          )
                        ) : (
                          <NumberFormat
                            fixedDecimalScale
                            decimalScale={2}
                            value={parseFloat(bids[0].offer) / 100.0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        )}
                      </Typography>
                    </Hidden>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={7}
                    container
                    justify="space-between"
                    alignItems="center"
                    className="bidContainer"
                  >
                    <Grid item xs={10} md={5}>
                      <div className="bidInputContainer">
                        <Input
                          // eslint-disable-next-line
                          onChange={({ target: { value } }) =>
                            setBidAmount(value.replace(/[^\d\.]/g, ""))
                          }
                          placeholder={!!bidAmount ? `$${bidAmount}` : ""}
                          disableUnderline
                          value={!!bidAmount ? `$${bidAmount}` : ""}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2} md={7}>
                      <div className="bidButtonContainer">
                        <BidButton
                          disabled={inactiveSports.includes(
                            ticket.sport.abbrev.toLowerCase()
                          )}
                          variant="text"
                          highAmount={highAmount * 100}
                          amount={parseInt(bidAmount * 100.0)}
                          listing={listing}
                          currentUser={currentUser}
                          onSuccess={bid =>
                            setBidAmount(parseFloat(bid.offer) / 100.0 + 1)
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    container
                    justify={matchesSmallDown ? "space-between" : "flex-start"}
                  >
                    <Hidden mdUp>
                      <Grid item xs={6}>
                        <Typography noWrap variant={"body2"}>
                          Min/High&nbsp;Bid:
                        </Typography>
                        <Typography variant={"subtitle2"}>
                          {!bids[0] ? (
                            !!listing?.minimumBidPrice ? (
                              <NumberFormat
                                fixedDecimalScale
                                decimalScale={2}
                                value={
                                  parseFloat(listing?.minimumBidPrice) / 100.0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            ) : (
                              <>N/A</>
                            )
                          ) : (
                            <NumberFormat
                              fixedDecimalScale
                              decimalScale={2}
                              value={parseFloat(bids[0].offer) / 100.0}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant={"subtitle2"}>Odds</Typography>
                        <Typography variant={"subtitle1"}>
                          {bidOdds || "N/A"}
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Hidden smDown>
                      <Grid item xs={12}>
                        <Typography variant={"subtitle2"}>Odds</Typography>
                        <Typography variant={"subtitle1"}>
                          {bidOdds || "N/A"}
                        </Typography>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!!listing?.expiresAt && (
              <Grid container spacing={0} item xs={12}>
                <Grid item xs={12}>
                  <Typography variant={"body2"}>
                    {`${
                      listing.expiresAt >= new Date().toISOString()
                        ? "Expires"
                        : "Expired"
                    } ${moment(listing.expiresAt).fromNow()}`}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={classes.comments}>
                <Typography variant={"subtitle2"}>Seller Says:</Typography>
                <Typography variant={"body2"}>
                  {ticket.additionalInfo || "N/A"}
                </Typography>
              </div>
            </Grid>
            {!!listing && ["PENDING", "SOLD"].includes(listing.status) && (
              <>
                <Grid className={"steps"} container spacing={0} item xs={12}>
                  <Grid item xs={4} container justify="center">
                    <Step title={"Accepted Offer"} completed />
                  </Grid>
                  <Grid item xs={4} container justify="center">
                    <Step
                      title={"Ticket Received"}
                      completed={ticket.inPropswapsPossession}
                    />
                  </Grid>
                  <Grid item xs={4} container justify="center">
                    <Step
                      title={"Payment Processed"}
                      completed={ticket.inPropswapsPossession}
                      last={true}
                    />
                  </Grid>
                </Grid>
                {listing.status === "PENDING" && !ticket.inPropswapsPossession && (
                  <Grid container spacing={4} item xs={12}>
                    <Grid item xs={12} md={4}>
                      <Typography variant={"subtitle2"}>Mail to:</Typography>
                      {ticket.locale.mailingAddress
                        .split("\n")
                        .map((line, i) => (
                          <Typography variant={"caption"} component="p" key={i}>
                            {line}
                          </Typography>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant={"subtitle2"}>
                        Mailing Information
                      </Typography>
                      <Typography variant={"caption"} paragraph component="p">
                        When you mail your ticket in, on a separate sheet of
                        paper enclosed in the envelope, please include your
                        first/last name and the email address you used to
                        regsiter with PropSwap. No other information is
                        necessary as we have a photo of the ticket already
                        on-file.
                      </Typography>
                      <Typography variant={"subtitle2"}>
                        In Person Meetings
                      </Typography>
                      <Typography variant={"caption"} paragraph component="p">
                        We do arrange for in-person meetings as some ticket
                        prices can exceed your available electronic funds.
                        Please email info@propswap.com if you see a ticket you'd
                        like to purchase in-person.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={0} item xs={12}>
                  <Grid item xs={12} className="help">
                    <Typography
                      align="center"
                      variant={"caption"}
                      component="p"
                      paragraph
                    >
                      If you have any questions regarding ticket processing,
                      please reach out to us at:
                    </Typography>
                    <Typography align="center" variant={"subtitle2"} paragraph>
                      <a href="mailto:info@propswap.com">info@propswap.com</a>
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default Detail;
