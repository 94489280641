import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Box } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { ACH_WITHDRAW_FEE } from "shared/Config";

const useStyles = makeStyles(theme => ({
  summary: {
    display: "flex",
    flexDirection: "column",
    "& > div": {
      display: "flex",
      flex: 1,
    },
    "& .label": {
      flex: 2,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& .value": {
      flex: 1,
    },
    "& .total": {
      borderTop: "1px solid gray",
      // marginTop: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
}));

const Summary = ({
  submitting,
  method,
  amount = "2500",
  valid = false,
  onSubmit,
  currentUser,
} = {}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.summary}>
        <div>
          <Typography paragraph className={"label"}>
            Available Balance
          </Typography>
          <Typography paragraph className={"value"}>
            <NumberFormat
              fixedDecimalScale
              decimalScale={2}
              value={parseFloat(currentUser.balance) / 100.0}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </Typography>
        </div>
        <div>
          <Typography paragraph className={"label"}>
            Payout
          </Typography>
          <Typography paragraph className={"value"}>
            <NumberFormat
              fixedDecimalScale
              decimalScale={2}
              value={parseFloat(Math.abs(amount)) / 100.0}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </Typography>
        </div>
        <div>
          <Typography paragraph className={"label"}>
            Withdraw Fee
          </Typography>
          <Typography paragraph className={"value"}>
            {method === "ACH_STANDARD" && ACH_WITHDRAW_FEE > 0 && (
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={ACH_WITHDRAW_FEE / 100.0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            )}
            {(method !== "ACH_STANDARD" || ACH_WITHDRAW_FEE === 0) && (
              <Box
                component="span"
                color="secondary.main"
                style={{ textTransform: "uppercase", fontWeight: "bold" }}
              >
                Free
              </Box>
            )}
          </Typography>
        </div>
        <div className={"total"}>
          <Typography paragraph className={"label"}>
            Remaining Balance
          </Typography>
          <Typography paragraph className={"value"}>
            {method === "ACH_STANDARD" ? (
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={
                  parseFloat(currentUser.balance - amount - ACH_WITHDRAW_FEE) /
                  100.0
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            ) : (
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={parseFloat(currentUser.balance - amount) / 100.0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            )}
          </Typography>
        </div>
        <Button
          onClick={onSubmit}
          disabled={!valid || !amount || !!submitting}
          fullWidth
          variant="contained"
          color="secondary"
        >
          {!!submitting ? "Please Wait" : "Withdraw"}
        </Button>
      </div>
    </>
  );
};

export default Summary;
