import React from "react";
import { useQuery } from "@apollo/client";
import SportListView from "./SportListView";
import Sport from "shared/api/Sport";
import { CircularProgress } from "@material-ui/core";

const SportListContainer = () => {
  const { loading, data: { listSports: { items: sports = [] } = {} } = {} } =
    useQuery(Sport.queries.list, { variables: { limit: 100 } });
  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <SportListView sports={sports} />
  );
};

export default SportListContainer;
