import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";
// import useCampaignListStyles from "./CampaignListStyles";
import DataTable from "../../DataTable";

const fields = {
  Name: {
    value: item => item.name,
    sort: order => (a, b) =>
      a.name > b.name ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  ID: {
    value: item => item.id,
    sort: order => (a, b) =>
      a.id > b.id ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
  },
  "Expired?": {
    value: item => (item.isExpired ? "Yes" : "No"),
    sort: order => (a, b) =>
      a.isExpired > b.isExpired
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Created: {
    value: item => moment(item.createdAt).format("MM/DD/YYYY H:mm"),
    sort: order => (a, b) =>
      a.createdAt > b.createdAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/campaigns/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
  Promotions: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/campaigns/${item.id}/promotions`}
      >
        Promotions
      </Button>
    ),
  },
};

const CampaignListView = ({ campaigns }) => (
  <DataTable fields={fields} items={campaigns} />
);

export default CampaignListView;
