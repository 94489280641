import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  MenuItem
} from "@material-ui/core";
import React from "react";

import BetListLitem from "./Item"

const BetListView = ({ ticketId, bets, result, onResultChange, possibleResults }) =>
  <>
    {
      !ticketId &&
      <TextField
        fullWidth
        label={"Result"}
        select
        onChange={({ target: { value } }) => onResultChange(value)}
        value={result}
        variant={"outlined"}
        SelectProps={{ displayEmpty: true }}
        InputLabelProps={{ shrink: true }}
      >
        {
          possibleResults.map(pr => <MenuItem key={pr} value={pr}>{pr}</MenuItem>)
        }
      </TextField>
    }
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Sport</TableCell>
            <TableCell align="center">Subject</TableCell>
            <TableCell align="center">Event</TableCell>
            <TableCell align="center">Result</TableCell>
            <TableCell align="center">User</TableCell>
            <TableCell align="center">Ticket</TableCell>
            <TableCell align="center">Actions</TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {bets.map(bet => <BetListLitem key={bet.id} bet={bet} />)}
        </TableBody>
      </Table>
    </TableContainer>
  </>

export default BetListView;