import React from "react";
import LocaleList from "../../../../Components/Locale/List";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const LocaleListRoute = ({ history }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/locales"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <Grid container justify={"flex-end"}>
          <Grid item xs={12} md={3}>
            <Button
              component={Link}
              to={`/admin/locales/new`}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Add Locale
            </Button>
          </Grid>
        </Grid>
        <LocaleList />
      </ContentCell>
    </PageContainer>
  </>
);

export default LocaleListRoute;
