import React from "react";
import FeaturedListView from "./FeaturedListView";
import { useQuery } from "@apollo/client";
import Listing from "shared/api/Listing";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({}));

export default () => {
  const classes = useStyles();

  const {
    error,
    data: { searchListings: { items: listings = [] } = {} } = {},
  } = useQuery(Listing.queries.search, {
    pollInterval: 3000,
    variables: {
      limit: 3,
      sort: {
        direction: "desc",
        field: "featuredAt",
      },
      filter: {
        and: [
          {
            status: {
              eq: "ACTIVE",
            },
          },
          {
            featuredAt: {
              exists: true,
            },
          },
        ],
      },
    },
  });

  !!error && console.log(error);

  return <FeaturedListView classes={classes} listings={listings} />;
};
