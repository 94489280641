import React, { useContext, useMemo } from "react";
import Link from "shared/api/Link";
import { DataTableNew as DataTable } from "gunner-react/web";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
// import AdCell from '../Components/Layout/AdCell'
import Tabs from "Components/Admin/Components/Tabs";
import { CurrentUserContext } from "gunner-react";
import { Grid, Button } from "@material-ui/core";
import { Link as DomLink } from "react-router-dom";
import LinkDeleteButton from "Components/Link/DeleteButton";

// const ConvertWatchingItemToTicketItem = ({object, id, filter = item => true}) =>
//   <TicketListItem object={{...object.listing.ticket, listing: object.listing}} id={object.listing.listingTicketId} filter={filter} />

// const ConvertListingItemToTicketItem = args =>
//   console.log(args) ||
//   null

const fields = {
  Position: {
    value: item => item.position,
    // nowrap: true,
    sort: null,
    rowProps: {},
  },
  Title: {
    value: item => <a href={item.url}>{item.title}</a>,
    // nowrap: true,
    sort: null,
    rowProps: {},
  },
  Description: {
    value: item => item.description,
    // nowrap: true,
    sort: null,
    rowProps: {},
  },
  Photo: {
    value: item => (
      <a
        href={item.url}
        style={{ display: "block", width: 100, height: "auto" }}
      >
        <img
          alt="photo"
          src={item.photoUrl}
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </a>
    ),
    // nowrap: true,
    sort: null,
    rowProps: {},
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={DomLink}
        to={`/admin/links/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
  Delete: {
    hideLabel: true,
    sort: null,
    value: item => <LinkDeleteButton id={item.id} />,
  },
};

export default ({ history, location: { search = "" } = {} }) => {
  const currentUser = useContext(CurrentUserContext);

  const query = useMemo(
    () => ({
      query: Link.queries.listByPosition,
      dataKey: "listLinksByPosition",
      variables: {
        active: "YES",
      },
    }),
    []
  );

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/links"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <Grid container spacing={4} justify={"flex-end"}>
            <Grid item xs={12} md={3}>
              <Button
                component={DomLink}
                to={`/admin/links/new`}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Add Link
              </Button>
            </Grid>
          </Grid>
          <DataTable
            fields={fields}
            dataListParams={{
              query,
            }}
          />
        </ContentCell>
      </PageContainer>
    </>
  );
};
