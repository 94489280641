import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import Link from "shared/api/Link";

export default ({
  id,
  buttonProps = { size: "small", color: "secondary" },
}) => {
  const [_deleteLink] = useMutation(Link.mutations.delete, {
    variables: {
      input: {
        id,
      },
    },
    refetchQueries: [
      {
        query: Link.queries.listByPosition,
        variables: {
          active: "YES",
        },
      },
    ],
  });

  const handleDelete = () => !!window.confirm("Are you sure?") && _deleteLink();

  return (
    <Button onClick={handleDelete} {...buttonProps}>
      Delete
    </Button>
  );
};
