import React from "react";
import SportNew from "../../../../Components/Sport/New";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const SportNewRoute = ({ history }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/sports"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <SportNew />
      </ContentCell>
    </PageContainer>
  </>
);

export default SportNewRoute;
