import React, { useContext } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { CurrentUserContext } from "gunner-react";

//done

const useStyles = makeStyles(theme => ({
  adCell: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& img": {
      maxWidth: "100%",
      height: "auto",
      // marginTop: theme.spacing(3)
    },
  },
}));

const AdCell = ({ children }) => {
  const classes = useStyles();
  const currentUser = useContext(CurrentUserContext);

  return (
    <Grid className={classes.adCell} item md={3}>
      <Hidden smDown>
        <Link
          to={
            !currentUser
              ? `/account/deposit?utm_source=internal&utm_medium=promo&utm_campaign=free100`
              : `/account/referrals?utm_source=internal&utm_medium=promo&utm_campaign=referrals`
          }
        >
          {!currentUser && (
            <img src={require("../../assets/css/ad.gif")} alt="Ad" />
          )}
          {!!currentUser && (
            <img src={require("../../assets/css/ad-logged-in.jpg")} alt="Ad" />
          )}
        </Link>
      </Hidden>
    </Grid>
  );
};

export default AdCell;
