import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import queryString from "query-string";
import {
  Button,
  Hidden,
  CircularProgress,
  Typography,
  Box,
  TextField,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { uniqBy } from "ramda";
// import { uniqWith, eqBy } from 'ramda'

// import SportTabs from '../../Sport/Components/Tabs';
import LayoutTabs from "../../Layout/Tabs";
import PriceRangeFilter from "../Components/PriceRangeFilter";
import SortOptions from "../Components/SortOptions";
import Search from "../Components/Search";
import ListingList from "../Components/List";
import ContentCell from "../../Layout/ContentCell";
import PageContainer from "../../Layout/PageContainer";
import AdCell from "../../Layout/AdCell";

import { CurrentUserContext } from "gunner-react";
import Sport from "shared/api/Sport";
import Listing from "shared/api/Listing";
import OptionsRow from "../../Layout/OptionsRow";
import Tabs from "../../Admin/Components/Tabs";
import ListingRotator from "../Rotator";
import ListingListView from "../List/ListingListView";
import ListingBetTypeFilter from "../BetTypeFilter";
import environment from "environment";

// const tabSports = [
//   'NFL',
//   'CFB',
//   'NBA',
//   'CBB',
//   'MLB',
//   'NHL',
//   'GOLF'
// ]

const List = ({ history, location: { pathname, search = "" } = {} }) => {
  const currentUser = useContext(CurrentUserContext);
  const searchParams = queryString.parse(search);
  const [token, setToken] = useState(null);
  const [sortDirection, setSortDirection] = useState(
    searchParams.sortDirection || "DESC"
  );
  const [status, setStatus] = useState("ACTIVE");
  const [rating, setRating] = useState();
  const [sortField, setSortField] = useState(
    searchParams.sortField || "createdAt"
  );
  const [sportAbbrev, setSportAbbrev] = useState();
  const [searchString, setSearchString] = useState();
  const [searchExact, setSearchExact] = useState(
    searchParams.searchExact?.toString?.() === "true"
  );
  const [featuredOnly, setFeaturedOnly] = useState(
    searchParams.featuredOnly || "0"
  );
  const [listings, setListings] = useState([]);
  const [priceHigh, setPriceHigh] = useState(
    searchParams.priceHigh === undefined
      ? 10000000 * 100
      : parseInt(searchParams.priceHigh)
  );
  const [priceLow, setPriceLow] = useState(
    searchParams.priceLow === undefined ? 0 : parseInt(searchParams.priceLow)
  );
  const [betType, setBetType] = useState("All");

  const setPriceRange = ([low, high]) => [
    setPriceHigh(high),
    setTimeout(() => setPriceLow(low), 10),
  ];

  // const [_updateListing] = useMutation(Listing.mutations.update, {
  //   variables: {
  //     input: {
  //       id: "00911eb7-080f-49f8-bb2c-ea746be5e2ae",
  //       updatedAt: (new Date()).toISOString()
  //     }
  //   }
  // })

  // const client = useApolloClient();

  // client.subscribe({ query: gql(`subscription OnCreateTest {
  //   onCreateTest {
  //     __typename
  //     id
  //   }
  // }`) }).subscribe({
  //   next: data => {
  //     console.log(data);
  //   },
  //   error: error => {
  //     console.warn(error);
  //   }
  // });

  //   const createTest = /* GraphQL */ `
  //     mutation CreateTest($input: CreateTestInput!) {
  //       createTest(input: $input) {
  //         __typename
  //         id
  //       }
  //     }
  // `

  //   useEffect(() => {
  //     API.graphql(graphqlOperation(`subscription OnCreateTest {
  //         onCreateTest {
  //         __typename
  //         id
  //       }
  //     }`))
  //     .subscribe({
  //       next: (todoData) => {
  //         console.log("todoData", todoData);
  //         // Do something with the data
  //       },
  //       error: console.log,
  //     })

  //     setTimeout(() =>
  //       API.graphql(graphqlOperation(createTest, { input: { id: (new Date()).toISOString(), name: (new Date()).toISOString() }}))
  //         .then(console.log)
  //         .catch(console.log)
  //     , 8000);
  // }, [])

  // API.graphql(
  //   {
  //     authMode: 'AWS_IAM',
  //     query: `subscription OnUpdateListing {
  //     onUpdateListing {
  //       __typename
  //       id
  //     }
  //   }`}
  // ).subscribe({
  //     next: (todoData) => {
  //       console.log("todoData", todoData);
  //       // Do something with the data
  //     },
  //     error: console.log,
  //     complete: console.log
  // });

  // const entry = useSubscription(Listing.subscriptions.onUpdate);
  // console.log("entry", entry)

  // useEffect(() => {

  // }, [])

  // setTimeout(() =>
  //   _updateListing()
  //     .then(console.log)
  //     .catch(console.log)
  // , 8000);

  // }, [])

  // console.log("EEEE", entry);

  // const entry = useQuery(Listing.queries.list, {});

  // console.log("ENTRY", entry)

  const {
    error: sportsError,
    data: { listSports: { items: sportsUnsorted = [] } = {} } = {},
  } = useQuery(Sport.queries.list, {
    variables: {
      limit: 50,
    },
  });

  const sports = sportsUnsorted
    .slice()
    .sort((a, b) => (a.position > b.position ? 1 : -1));

  const otherSportAbbrev = (
    sports.find(sport => sport.abbrev === "OTHER") || {}
  ).abbrev;

  const sportsString = JSON.stringify(sports);
  const tabSports = sports.map(sport => sport.abbrev).slice(0, 7);

  const otherSportAbbrevs = useMemo(() => [
    otherSportAbbrev,
    ...JSON.parse(sportsString)
      .filter(sport => !tabSports.includes(sport.abbrev.toUpperCase()))
      .map(sport => sport.abbrev),
  ], [otherSportAbbrev, sportsString, tabSports]);

  // const pharse = `"${decodeURIComponent((searchString??"").replace(/ /g, "~").toLowerCase())}.*"`;

  // console.log(pharse)

  // const searchPhrase = decodeURIComponent("NBA%20Finals")

  // console.log(searchPhrase)

  const handleSearchChange = useCallback(text => [
    setSearchString(text),
    setSearchExact(false),
  ], []);

  const searchStringCopy = (searchString??"").slice();

  const filter = useMemo(() => 
      JSON.stringify({
        and: [
          !rating
            ? null
            : {
                valueRating: {
                  eq: rating,
                },
              },
          {
            status: {
              [status !== "ALL" ? "match" : "exists"]:
                status !== "ALL" ? `${status}` : true,
            },
          },
          {
            askingPrice: {
              gt: priceLow,
            },
          },
          {
            askingPrice: {
              lt: priceHigh,
            },
          },
          {
            searchString: {
              // [!searchString ? "exists" : searchExact ? 'matchPhrase' : 'match']: !searchString ? true : decodeURIComponent(searchString) //TODO: redesign
              [!!searchStringCopy && / /.test(decodeURIComponent(searchStringCopy))
                ? "match"
                : !!searchStringCopy
                ? "regexp"
                : "exists"]: !!searchStringCopy
                ? `${decodeURIComponent(searchStringCopy) || ""}${
                    / /.test(decodeURIComponent(searchStringCopy)) ? "" : ".*"
                  }`.toLowerCase()
                : true,
            },
            // searchString: {
            //   match: searchPhrase
            // }
          },
          {
            "cachedTicket.sport.abbrev": {
              [!!sportAbbrev && sportAbbrev === "Other"
                ? "regexp"
                : !!sportAbbrev
                ? "eq"
                : "exists"]:
                !!sportAbbrev && sportAbbrev === "Other"
                  ? otherSportAbbrevs
                      .map(abbrev => `(${abbrev})`)
                      .join("|")
                      .toLowerCase()
                  : !!sportAbbrev
                  ? sportAbbrev
                  : true,
            },
          },
          {
            "cachedTicket.betType": {
              [!betType || betType === "All"
                ? "exists"
                : /NO/.test(betType ?? "")
                ? "ne"
                : "eq"]:
                !betType || betType === "All"
                  ? true
                  : betType.replace("NO-", ""),
            },
          },
          featuredOnly === "1"
            ? {
                featuredAt: {
                  exists: true,
                },
              }
            : null,
        ].filter(item => !!item),
      })
  , [betType, featuredOnly, otherSportAbbrevs, priceHigh, priceLow, rating, searchStringCopy, sportAbbrev, status])

  console.log(JSON.parse(filter))

  const {
    refetch,
    error,
    loading,
    data: {
      [`searchListingsRaw${environment.use_v2 ? "2" : ""}`]: { nextToken, items: searchItems = [] } = {},
    } = {},
  } = useQuery(Listing.queries.searchRaw, {
    variables: {
      nextToken: token,
      sort: {
        direction: sortDirection.toLowerCase(),
        field: sortField,
      },
      limit: 25,
      filter
    }
  });

  const {
    refetch: refetchFeatured,
    error: featuredError,
    data: { searchListings: { items: featuredListings = [] } = {} } = {},
  } = useQuery(Listing.queries.search, {
    pollInterval: 3000,
    variables: {
      limit: 10,
      sort: {
        direction: "desc",
        field: "featuredAt",
      },
      filter: {
        and: [
          {
            status: {
              eq: "ACTIVE",
            },
          },
          {
            featuredAt: {
              exists: true,
            },
          },
        ],
      },
    },
  });

  const {
    data: { searchListings: { items: recentlySoldListings = [] } = {} } = {},
  } = useQuery(Listing.queries.search, {
    pollInterval: 3000,
    variables: {
      limit: 10,
      sort: {
        direction: "desc",
        field: "updatedAt",
      },
      filter: {
        and: [
          // {
          //   status: {
          //     eq: "ACTIVE"
          //   }
          // },
          {
            recentlySold: {
              eq: true,
            },
          },
        ],
      },
    },
  });

  !!error && console.error(error);
  !!featuredError && console.error(featuredError);

  // useEffect(() => {
  //   !!error &&
  //   !!featuredError && (() => [localStorage.clear(), refetchFeatured(), refetch()])()

  // }, [!!error, !!featuredError])

  // useEffect(() => {
  //   !listings.length &&
  //   !!searchItems.length &&
  //   setTimeout(() => setListings(searchItems), 10)
  // }, [listings.length, searchItems.length]);

  useEffect(() => {
    setListings([]);
    setToken(null);
    //refetch();
    // history.push(
    //   "?" +
    //     queryString.stringify({
    //       ...searchParams,
    //       sortDirection,
    //     })
    // );
  }, [sortDirection]);

  useEffect(() => {
    setListings([]);
    setToken(null);
    //refetch();
    // history.push(
    //   "?" +
    //     queryString.stringify({
    //       ...searchParams,
    //       sortField,
    //     })
    // );
  }, [sortField]);

  useEffect(() => {
    setListings([]);
    setToken(null);
    //refetch();
    // history.push(
    //   "?" +
    //     queryString.stringify({
    //       ...searchParams,
    //       status,
    //     })
    // );
  }, [status]);

  useEffect(() => {
    setListings([]);
    setToken(null);
    //refetch();
    // history.push(
    //   "?" +
    //     queryString.stringify({
    //       ...searchParams,
    //       sportAbbrev,
    //     })
    // );
  }, [sportAbbrev]);

  useEffect(() => {
    setListings([]);
    setToken(null);
    //refetch();
    // history.push(
    //   "?" +
    //     queryString.stringify({
    //       ...searchParams,
    //       sortField,
    //     })
    // );
  }, [sortField]);

  useEffect(() => {
    setListings([]);
    setToken(null);
    //refetch();
    // history.push(
    //   "?" +
    //     queryString.stringify({
    //       ...searchParams,
    //       priceHigh,
    //     })
    // );
  }, [priceHigh]);

  useEffect(() => {
    setListings([]);
    setToken(null);
    //refetch();
    // history.push(
    //   "?" +
    //     queryString.stringify({
    //       ...searchParams,
    //       priceLow,
    //     })
    // );
  }, [priceLow]);

  useEffect(() => {
    setListings([]);
    setToken(null);
    //refetch();
    // history.push(
    //   "?" +
    //     queryString.stringify({
    //       ...searchParams,
    //       searchString,
    //     })
    // );
  }, [searchString]);

  useEffect(() => {
    setListings([]);
    setToken(null);
    //refetch();
    // history.push(
    //   "?" +
    //     queryString.stringify({
    //       ...searchParams,
    //       featuredOnly,
    //     })
    // );
  }, [featuredOnly]);

  useEffect(() => {
    sortField === "createdAt" && setSortDirection("DESC");
  }, [sortField]);

  // useEffect(() => {
  //   !!token //If there's a token, we're paginating, otherwise, we're starting fresh
  //     ? setListings([...listings, ...searchItems])
  //     : setListings(searchItems);
  // }, [listings, searchItems, token]);

  const searchItemsString = JSON.stringify(searchItems);

  useEffect(() => {
    const searchItems = JSON.parse(searchItemsString);
    /// If there are no listings, but there are search items....
    !!searchItems.length && !listings.length && setListings(searchItems);
  }, [listings.length, searchItemsString]);


  useEffect(() => {
    const searchItems = JSON.parse(searchItemsString);
    !!token //If there's a token, we're paginating, otherwise, we're starting fresh
      ? setListings(listings => [...listings, ...searchItems])
      : setListings(searchItems);
  }, [searchItemsString, token]);

  return (
    <>
      {!/admin/.test(pathname) ? (
        <LayoutTabs
          selectedValue={sportAbbrev}
          onChange={setSportAbbrev}
          options={[
            ...[{ label: "All", value: null }],
            ...sports
              .filter(sport => !otherSportAbbrevs.includes(sport.abbrev))
              .slice()
              // .sort((a,b) => tabSports.indexOf(a.abbrev.toUpperCase()) > tabSports.indexOf(b.abbrev.toUpperCase()) ? 1 : -1)
              .map(sport => ({ label: sport.abbrev, value: sport.abbrev })),
            ...[{ label: "Other", value: "Other" }],
          ]}
        />
      ) : (
        <Tabs
          history={history}
          currentPath={"/admin/listings"}
          onChange={path => history.push(path)}
        />
      )}
      <PageContainer>
        <ContentCell fullWidth={/admin/.test(pathname)}>
          <OptionsRow>
            <SortOptions
              onChangeField={setSortField}
              onChangeDirection={setSortDirection}
              sortDirection={sortDirection}
              sortField={sortField}
              adminView={/admin/.test(pathname)}
            />
            <Search
              searchString={searchString}
              onChange={handleSearchChange}
              onChangeField={setSortField}
              onChangeDirection={setSortDirection}
              sortDirection={sortDirection}
              sortField={sortField}
            />
          </OptionsRow>
          <OptionsRow>
            <PriceRangeFilter
              onChange={setPriceRange}
              priceRange={[priceLow, priceHigh]}
            />
            <ListingBetTypeFilter onChange={setBetType} betType={betType} />
          </OptionsRow>

          {!!currentUser &&
            currentUser.groups.includes("Admins") &&
            /admin/.test(pathname) && (
              <Box mb={4}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={"Status"}
                      select
                      onChange={({ target: { value } }) => setStatus(value)}
                      value={status}
                      variant={"outlined"}
                      SelectProps={{ displayEmpty: true }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="ALL">ALL</MenuItem>
                      <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                      <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                      <MenuItem value="PENDING">PENDING</MenuItem>
                      <MenuItem value="SOLD">SOLD</MenuItem>
                      <MenuItem value="DROPPED">DROPPED</MenuItem>
                      <MenuItem value="ARCHIVED">ARCHIVED</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={"Featured Only?"}
                      select
                      onChange={({ target: { value } }) =>
                        setFeaturedOnly(value)
                      }
                      value={featuredOnly}
                      variant={"outlined"}
                      SelectProps={{ displayEmpty: true }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
            )}

          {!!currentUser && currentUser.groups.includes("Admins") && (
            <Box mb={4}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label={"Rating"}
                    select
                    onChange={({ target: { value } }) => setRating(value)}
                    value={rating}
                    variant={"outlined"}
                    SelectProps={{ displayEmpty: true }}
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value={null}>ALL</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          )}

          {(sportAbbrev === "ALL" || !sportAbbrev) && !searchString && (
            <Hidden mdUp>
              <ListingList
                currentUser={currentUser}
                history={history}
                listings={[...featuredListings, ...recentlySoldListings]}
              />
              {/* <ListingList currentUser={currentUser} history={history} listings={featuredListings} /> */}
            </Hidden>
          )}

          {!/admin/.test(pathname) && (
            <Hidden smDown>
              {/* <FeaturedList 
                currentUser={currentUser} 
                history={history} 
                listings={[...featuredListings].slice(0,3)}
              /> */}
              <ListingRotator />
            </Hidden>
          )}

          {!/admin/.test(pathname) && (
            <ListingList
              currentUser={currentUser}
              history={history}
              listings={uniqBy(listing => listing.id, listings)}
              adminView={/admin/.test(pathname)}
            />
          )}

          {/admin/.test(pathname) && (
            <ListingListView
              listings={uniqBy(listing => listing.id, listings)}
            />
          )}

          {!loading && !listings.length && !searchItems.length && (
            <Typography align="center" variant="h5">
              Sorry, no tickets for sale! Check back soon!
            </Typography>
          )}

          {!!loading ? (
            <CircularProgress color="secondary" />
          ) : !!nextToken && !!listings.length ? (
            <Button
              fullWidth
              variant={"contained"}
              onClick={() => setToken(nextToken)}
            >
              Show More
            </Button>
          ) : null}
        </ContentCell>
        {!/admin/.test(pathname) && (
          <AdCell>
            <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
          </AdCell>
        )}
      </PageContainer>
    </>
  );
};

export default List;
