import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid, MenuItem, Box } from "@material-ui/core";
import HelpLabelContainer from "../../Layout/HelpLabel";

const useStyles = makeStyles(theme => ({
  mailingAddress: {
    // '& .fieldsWrapper > div': {
    //   marginBottom: theme.spacing(2)
    // }
  },
}));

const AccountInfo = ({ accountInfo, onChange }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.mailingAddress}>
      <Grid item xs={12} className="fieldsWrapper">
        <Box mb={2}>
          <TextField
            className="formControl"
            onChange={({ target: { value } }) => onChange("type", value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={"Account Type"}
            value={(accountInfo.type || "").toUpperCase()}
            select
            variant={"outlined"}
          >
            {["Checking", "Savings", "Business"].map(type => (
              <MenuItem key={type} value={type.toUpperCase()}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box mb={2}>
          <TextField
            className="formControl"
            onChange={({ target: { value } }) =>
              onChange("routingNumber", value)
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={"Routing Number"}
            value={accountInfo.routingNumber || ""}
            variant={"outlined"}
            helperText={
              <HelpLabelContainer
                modalTitle="Account and Routing Numbers"
                ModalBody={
                  <Box flex={1}>
                    <img
                      src={require("../../../assets/images/checks.jpg")}
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </Box>
                }
              />
            }
          />
        </Box>
        <Box mb={2}>
          <TextField
            className="formControl"
            onChange={({ target: { value } }) =>
              onChange("accountNumber", value)
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={"Account Number"}
            value={accountInfo.accountNumber || ""}
            variant={"outlined"}
            helperText={
              <HelpLabelContainer
                modalTitle="Account and Routing Numbers"
                ModalBody={
                  <Box flex={1}>
                    <img
                      src={require("../../../assets/images/checks.jpg")}
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </Box>
                }
              />
            }
          />
        </Box>
        <Box mb={2}>
          <TextField
            className="formControl"
            onChange={({ target: { value } }) =>
              onChange("accountNumberConfirm", value)
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={"Confirm Account #"}
            value={accountInfo.accountNumberConfirm || ""}
            variant={"outlined"}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AccountInfo;
