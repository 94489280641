import { useContext } from "react";
import Bid from "shared/api/Bid";
import { useQuery } from "@apollo/client";
import { CurrentUserContext } from "gunner-react";

export default ({ listingId, skip = false }) => {
  const currentUser = useContext(CurrentUserContext);

  const {
    data: {
      listBidsByListingIdAndBidderIdAndUpdatedAt: { items: existingBids } = {},
    } = {},
  } = useQuery(Bid.queries.listByListingIdAndBidderIdAndUpdatedAt, {
    skip: !listingId || !currentUser?.id || !!skip,
    variables: {
      bidListingId: listingId,
      bidBidderIdUpdatedAt: {
        beginsWith: {
          bidBidderId: currentUser?.id,
        },
      },
    },
  });

  return existingBids?.find?.(bid => bid.status === "PENDING");
};
