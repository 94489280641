import React from "react";
import { Helmet } from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

// import MainNavigation from '../MainNavigation';
import Header from "./Header";
import Footer from "./Footer";
import RadioDotCom from "Components/RadioDotCom";

export default ({
  location,
  data,
  children,
  showNav,
  classes,
  currentUser,
}) => (
  <>
    <CssBaseline />
    <Helmet
      encodeSpecialCharacters={true}
      title="PropSwap"
      meta={[
        { name: `description`, content: `PropSwap` },
        {
          name: `keywords`,
          content: `propswap, sports betting, secondary market`,
        },
        { name: "og:type", content: `website` },
        { name: "og:title", content: `PropSwap` },
        {
          name: "og:image",
          content: require("../../assets/images/playstore.png"),
        },
        {
          name: "og:url",
          content: `${process.env.REACT_APP_base_url}${location.pathname}`,
        },
      ]}
      link={[
        {
          rel: "canonical",
          value: `${process.env.REACT_APP_base_url}${location.pathname}`,
        },
      ]}
    />
    <Header currentUser={currentUser} />
    <Container fixed className={classes.root}>
      <RadioDotCom />
      {children}
    </Container>
    <Footer />
  </>
);
