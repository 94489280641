import React, { useEffect } from "react";

import { Cache } from "aws-amplify";

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Box } from "@material-ui/core";
import Ticket from "shared/api/Ticket";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { withoutKeys, useInterval } from "gunner-react";
import { nonMutationFields } from "..";

const FieldCheckbox = ({ ticket, field, label }) => {
  const [data, setData] = useState(ticket);
  // const [isFirstLoad, setIsFirstLoad] = useState(true);
  // const fieldChanged = useMemo(() => ! [data[field]])

  const [updateTicket] = useMutation(Ticket.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(data, nonMutationFields),
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateTicket: {
        ...data,
        updatedAt: new Date().toISOString(),
      },
    },
  });

  useEffect(() => {
    data[field] !== ticket[field] && updateTicket();
  }, [data[field], ticket[field]]);

  return (
    <FormControl>
      <FormGroup
        onChange={({ target: { checked } }) =>
          setData({
            ...data,
            [field]: !!checked,
            updatedAt: new Date().toISOString(),
          })
        }
        row
      >
        <FormControlLabel
          control={<Checkbox checked={!!data[field]} color="primary" />}
          label={label}
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  );
};

const SelectCheckbox = ({ ticket, label }) => {
  const wasChecked =
    (JSON.parse(Cache.getItem("CheckedTickets")) || []).indexOf(ticket.id) > -1;
  const [isChecked, setIsChecked] = useState(wasChecked);

  useInterval(() => {
    setIsChecked(
      (JSON.parse(Cache.getItem("CheckedTickets")) || []).indexOf(ticket.id) >
        -1
    );
  }, 2000);

  const handleChecked = () => [
    Cache.setItem(
      "CheckedTickets",
      JSON.stringify([
        ...(JSON.parse(Cache.getItem("CheckedTickets")) || []),
        ...[ticket.id],
      ])
    ),
    setIsChecked(true),
  ];

  const handleUnChecked = () => [
    Cache.setItem(
      "CheckedTickets",
      JSON.stringify(
        (JSON.parse(Cache.getItem("CheckedTickets")) || []).filter(
          t => t !== ticket.id
        )
      )
    ),
    setIsChecked(false),
  ];

  // useEffect(() => {
  //   setIsChecked(wasChecked);
  // }, [wasChecked])

  useEffect(() => {
    !!isChecked && handleChecked();

    !isChecked && handleUnChecked();
  }, [isChecked]);

  return (
    <FormControl>
      <FormGroup
        onChange={({ target: { checked } }) => setIsChecked(checked)}
        row
      >
        <FormControlLabel
          control={<Checkbox checked={!!isChecked} color="primary" />}
          label={label}
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  );
};

const FieldSelectBox = ({ ticket, field, options }) => {
  const [data, setData] = useState(ticket);
  // const [isFirstLoad, setIsFirstLoad] = useState(true);
  // const fieldChanged = useMemo(() => ! [data[field]])

  const [updateTicket] = useMutation(Ticket.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(data, nonMutationFields),
      },
    },
    // optimisticResponse: {
    //   __typename: "Mutation",
    //   updateTicket: {
    //     ...data,
    //     updatedAt: (new Date()).toISOString()
    //   }
    // }
  });

  useEffect(() => {
    data[field] !== ticket[field] &&
      updateTicket().then(() => setData({ ...ticket, ...data }));
  }, [data[field]]);

  return (
    <Box mt={-3}>
      <TextField
        select
        fullWidth
        variant="outlined"
        margin="dense"
        label={null}
        value={data.result || "UNDECIDED"}
        onChange={({ target: { value } }) =>
          setData({
            ...data,
            [field]: value,
            updatedAt: new Date().toISOString(),
          })
        }
      >
        {options.map(result => (
          <MenuItem key={result} value={result}>
            {result}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export { FieldSelectBox, FieldCheckbox, SelectCheckbox };
