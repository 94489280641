import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  "@global": {
    'div[class*="MuiOutlinedInput-root"] legend': {
      display: "none !important",
    },
    fieldset: {
      height: "auto !important",
    },
    "fieldset legend span": {
      display: "none !important",
    },
    ".MuiOutlinedInput-notchedOutline legend": {
      display: "none !important",
    },
    body: {
      backgroundImage: `url(${require("../../assets/css/background.jpg")})`,
      backgroundColor: "#383837",
      backgroundAttachment: "fixed",
      backgroundPosition: "center top",
      backgroundRepeat: "repeat-x",
    },
    ".responsive-media": {
      "max-width": "100%",
      height: "auto",
    },
    ".video-responsive": {
      overflow: "hidden",
      "padding-bottom": "56.25%",
      position: "relative",
      height: 0,
      "& iframe": {
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        position: "absolute",
      },
    },
    ".embed-youtube iframe": {
      "max-width": "100%",
    },
    'div[class*="Toast__toast"]': {
      zIndex: "2000",
      position: "fixed",
    },
    ".Toast__toast___2YWKB": {
      zIndex: "2000",
      position: "fixed",
    },
    a: {
      color: theme.palette.primary.main,
      "text-decoration": "none",
    },
    // 'li, p': {
    //   'color': 'rgba(0, 0, 0, 0.87)',
    //   'font-size': '1.125rem',
    //   'font-weight': 400,
    //   'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
    //   'line-height': '1.46429em'
    // },
    "p > img": {
      maxWidth: "100%",
      height: "auto",
    },
    "*": {
      "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    // 'strong': {
    //   ...theme.typography.body1
    // },
    // 'h3': {
    //   ...theme.typography.subheading1,
    //   color: theme.typography.h2.color
    // }
  },
  [theme.breakpoints.down("sm")]: {
    "@global": {
      body: {
        overflowX: "hidden",
      },
    },
  },
  [theme.breakpoints.up("md")]: {
    "@global": {
      '[class*="Toast__toast"]': {
        zIndex: "2000",
      },
      ".Toast__toast___2YWKB": {
        zIndex: "2000",
      },
      body: {
        // 'overflowY': 'hidden'
      },
    },
  },
}));
