import Transaction from "shared/api/Transaction";
import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { CurrentUserContext } from "gunner-react";
import moment from "moment";

export default ({ transactionId }) => {
  const currentUser = useContext(CurrentUserContext);
  const { data: { getTransaction } = {} } = useQuery(Transaction.queries.get, {
    skip: !transactionId,
    variables: {
      id: transactionId,
    },
  });

  const { data: { transactionsByUserId: { items = [] } = {} } = {} } = useQuery(
    Transaction.queries.listByUserId,
    {
      skip: !getTransaction?.transactionUserId,
      pollInterval: 3000,
      variables: {
        transactionUserId: getTransaction?.transactionUserId,
        sortDirection: "DESC",
        limit: 200,
        updatedAt: {
          gt: getTransaction?.createdAt,
        },
      },
    }
  );

  return (
    getTransaction?.transactionUserId === currentUser?.id &&
    !items.find(item => item.type === "BUY") &&
    getTransaction?.type === "CC" &&
    !getTransaction?.transactionRedemptionId &&
    !getTransaction?.transactionPromotionId &&
    moment(getTransaction?.createdAt).toDate() >
      moment().subtract(48, "hours").toDate()
  );
};
