import { useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import React, { useState } from "react";

import BetListView from "./BetListView"
import { betListItemQuery, betListItemByTicketIdQuery } from "./Item/BetListItemContainer";

const possibleResults = [
  "UNDECIDED",
  "WIN",
  "LOSS",
  "PUSH",
]

const BetListContainer = ({ticketId}) => {
  const [ result, setResult ] = useState("UNDECIDED");
  const { error, fetchMore, data: { [!!ticketId ? "listBetsByTicketId" : "listBetsByResult"]: { nextToken, items: bets = [] } = {} } = {} } = useQuery(!!ticketId ? betListItemByTicketIdQuery : betListItemQuery, {
    variables: !!ticketId ? { ticketId } : {
      result,
      sortDirection: "DESC"
    }
  });

  !!error && console.error(error);

  return <>
      <BetListView ticketId={ticketId} result={result} onResultChange={setResult} possibleResults={possibleResults} bets={bets} />
      {
        !!nextToken && <Button 
          color="secondary" 
          variant="contained" 
          onClick={() => fetchMore({ variables: { nextToken }})} 
        >Load More</Button>
      }
    </>
};

export default BetListContainer;