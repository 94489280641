import React from "react";
import { useQuery } from "@apollo/client";
import PromotionListView from "./PromotionListView";
import Promotion from "shared/api/Promotion";
import { CircularProgress } from "@material-ui/core";

const PromotionListContainer = ({ campaignId }) => {
  const {
    error,
    loading,
    data: { listPromotionsByCampaignId: { items: promotions = [] } = {} } = {},
  } = useQuery(Promotion.queries.listByCampaignId, {
    variables: {
      limit: 100,
      promotionCampaignId: campaignId,
    },
  });

  !!error && console.log(error);

  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <PromotionListView promotions={promotions} />
  );
};

export default PromotionListContainer;
