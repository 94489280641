import gql from "graphql-tag";
import environment from "environment";

const ExchangeTransaction = {};

ExchangeTransaction.fragments = {
  public: gql`
    fragment ExchangeTransactionPublic on ExchangeTransaction {
      id
      createdAt
      updatedAt
      userId
      ticketId
      providerId
      amount
      type
    }
  `,
};

ExchangeTransaction.queries = {
  listByProviderIdAndUserId: gql`
    query ListExchangeTransactionsByProviderIdAndUserId(
      $providerId: ID!
      $userIdCreatedAt: ModelExchangeTransactionByProviderIdAndUserIdCompositeKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelExchangeTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listExchangeTransactionsByProviderIdAndUserId(
        providerId: $providerId
        userIdCreatedAt: $userIdCreatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ExchangeTransactionPublic
        }
        nextToken
      }
    }
    ${ExchangeTransaction.fragments.public}
  `,
  listByProviderId: gql`
    query ListExchangeTransactionsByProviderId(
      $providerId: ID!
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelExchangeTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listExchangeTransactionsByProviderId(
        providerId: $providerId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ExchangeTransactionPublic
        }
        nextToken
      }
    }
    ${ExchangeTransaction.fragments.public}
  `,
  get: gql`
    query GetExchangeTransaction($id: ID!) {
      getExchangeTransaction(id: $id) {
        ...ExchangeTransactionPublic
      }
    }
    ${ExchangeTransaction.fragments.public}
  `,
  list: gql`
    query ListExchangeTransactions(
      $filter: ModelExchangeTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listExchangeTransactions(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ExchangeTransactionPublic
        }
        nextToken
      }
    }
    ${ExchangeTransaction.fragments.public}
  `,
};

ExchangeTransaction.mutations = {
  update: gql`
    mutation UpdateExchangeTransaction(
      $input: UpdateExchangeTransactionInput!
    ) {
      updateExchangeTransaction(input: $input) {
        ...ExchangeTransactionPublic
      }
    }
    ${ExchangeTransaction.fragments.public}
  `,
  create: gql`
    mutation CreateNewExchangeTransaction(
      $input: CreateNewExchangeTransactionInput!
    ) {
      createNewExchangeTransaction(input: $input) {
        ...ExchangeTransactionPublic
      }
    }
    ${ExchangeTransaction.fragments.public}
  `,
};

export default ExchangeTransaction;
