import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import MenuLeftIcon from "mdi-material-ui/MenuLeft";

const useStyles = makeStyles(theme => ({
  backLink: {
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightBold,
    //  color: theme.palette.text.primary,
    marginLeft: theme.spacing(-1),
    display: "flex",
    alignItems: "center",
    //  '& svg': {
    //    position: 'relative',
    //    top: '7px'
    //  }
  },
}));

const BackLink = ({ url }) => {
  const classes = useStyles();
  return (
    <Link to={url}>
      <Typography className={classes.backLink} variant={"caption"}>
        <MenuLeftIcon />
        Back
      </Typography>
    </Link>
  );
};

export default BackLink;
