import React from "react";
import ContentCell from "../Components/Layout/ContentCell";
import PageContainer from "../Components/Layout/PageContainer";
// import AdCell from '../Components/Layout/AdCell'
import Tabs from "Components/Admin/Components/Tabs";
import BetList from "Components/Bet/List";


export default ({ history, location: { search = "" } = {} }) => {

  return (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/bets"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <BetList />
        </ContentCell>
      </PageContainer>
    </>
  );
};
