import React from "react";
import CampaignEdit from "../../../../Components/Campaign/Edit";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const CampaignEditRoute = ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/campaigns"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <CampaignEdit id={params.id} />
      </ContentCell>
    </PageContainer>
  </>
);

export default CampaignEditRoute;
