import gql from "graphql-tag";
import environment from "environment";
// import Ticket from "./Ticket"

const Referral = {};

Referral.fragments = {
  public: gql`
    fragment ReferralPublic on Referral {
      id
      createdAt
      updatedAt

      stage
      redeemed
      expired
      code
      bonusCode
      referrerId
      referreeId
      conversionTemplateId
      referreeBounusAmount
      referreeDepositAmount
      referrerBounusAmount
      conversionTemplate {
        bonusCode
        mapping {
          type
          threshold
          referrerBonus
          referreeBonus
        }
        active
      }
    }
  `,
};

Referral.mutations = {
  update: gql`
    mutation UpdateReferral($input: UpdateReferralInput!) {
      updateReferral(input: $input) {
        ...ReferralPublic
      }
    }
    ${Referral.fragments.public}
  `,
  create: gql`
    mutation CreateReferral($input: CreateReferralInput!) {
      createReferral(input: $input) {
        ...ReferralPublic
      }
    }
    ${Referral.fragments.public}
  `,
};

Referral.queries = {
  listByCodeAndReferreeId: gql`
    query ListReferralsByCodeAndReferreeId(
      $code: String!
      $referreeId: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelReferralFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listReferralsByCodeAndReferreeId(
        code: $code
        referreeId: $referreeId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ReferralPublic
        }
      }
    }
    ${Referral.fragments.public}
  `,
  listByReferrerId: gql`
    query ListReferralsByReferrerId(
      $referrerId: ID!
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelReferralFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listReferralsByReferrerId(
        referrerId: $referrerId
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ReferralPublic
        }
      }
    }
    ${Referral.fragments.public}
  `,
  list: gql`
    query ListReferrals(
      $filter: ModelReferralFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...ReferralPublic
        }
      }
    }
    ${Referral.fragments.public}
  `,
  get: gql`
    query GetReferral($id: ID!) {
      getReferral(id: $id) {
        ...ReferralPublic
      }
    }
    ${Referral.fragments.public}
  `,
};


export default Referral;
