import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// import FormLabel from '@material-ui/core/FormLabel';
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    fixedDecimalScale
    decimalScale={2}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    thousandSeparator
    prefix="$"
  />
);

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: 0,
    width: "100%",
  },
  group: {
    // margin: theme.spacing(1, 0),
    flexDirection: "row",
  },
  column: {
    flex: 1,
    display: "flex",
    "flex-direction": "column",
  },
}));

const Amount = ({ amount = "2500", onChange }) => {
  const classes = useStyles();
  const [showOther, setShowOther] = useState(false);

  const handleChange = event =>
    event.target.value === "other"
      ? [setShowOther(true), onChange(null)]
      : [setShowOther(false), onChange(event.target.value)];

  useEffect(() => {
    amount === "500" && setShowOther(true);
  }, [amount]);

  return (
    <>
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="amount"
            name="amount"
            className={classes.group}
            value={!!showOther ? "other" : amount}
            onChange={handleChange}
          >
            <div className={classes.column}>
              <FormControlLabel
                value={"2500"}
                control={<Radio />}
                label="$25.00"
              />
              <FormControlLabel
                value={"5000"}
                control={<Radio />}
                label="$50.00"
              />
              <FormControlLabel
                value={"10000"}
                control={<Radio />}
                label="$100.00"
              />
            </div>
            <div className={classes.column}>
              <FormControlLabel
                value={"25000"}
                control={<Radio />}
                label="$250.00"
              />
              <FormControlLabel
                value={"50000"}
                control={<Radio />}
                label="$500.00"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </div>
          </RadioGroup>
        </FormControl>
      </div>
      {!!showOther && (
        <TextField
          onChange={({ target: { value } }) =>
            onChange(parseInt(value * 100.0))
          }
          fullWidth
          helperText="$5.00 minimum"
          label={"Other"}
          value={!!amount ? parseFloat(amount) / 100.0 : ""}
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputMode: "decimal",
            type: "text",
          }}
          inputMode="decimal"
          inputProps={{ inputMode: "decimal" }}
          type="text"
          variant="outlined"
        />
      )}
    </>
  );
};

export default Amount;
