import React from "react";
import CampaignNew from "../../../../Components/Campaign/New";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const CampaignNewRoute = ({ history }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/campaigns"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <CampaignNew />
      </ContentCell>
    </PageContainer>
  </>
);

export default CampaignNewRoute;
