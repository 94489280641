import React from "react";
import { DataTable } from "gunner-react/web";
import ExchangeTransaction from "shared/api/ExchangeTransaction";
import moment from "moment";
import { formatPennies } from "shared/Util/money";

const fields = {
  userId: {
    value: item => item.userId,
    nowrap: true,
    sort: order => (a, b) =>
      a.userId > b.userId
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  ticketId: {
    value: item => item.ticketId,
    nowrap: true,
    sort: order => (a, b) =>
      a.ticketId > b.ticketId
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  type: {
    value: item => item.type,
    nowrap: true,
    sort: order => (a, b) =>
      a.type > b.type ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
    rowProps: {},
  },
  amount: {
    value: item => formatPennies(item.amount),
    nowrap: true,
    sort: order => (a, b) =>
      a.amount > b.amount
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Date: {
    value: item => item.ticketId,
    sort: null,
    value: item => moment(item.createdAt).format("MM/DD/YYYY H:mm"),
    sort: order => (a, b) =>
      a.createdAt > b.createdAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
  },
};

export default ({ providerId }) => (
  <DataTable
    fields={fields}
    search={null}
    dataListParams={{
      query: ExchangeTransaction.queries.list,
      dataKey: "listExchangeTransactions",
      variables: {
        filter: {
          providerId: {
            eq: providerId,
          },
        },
      },
    }}
  />
);
