import React from "react";
import { useQuery } from "@apollo/client";

import Listing from "shared/api/Listing";
import Ticket from "shared/api/Ticket";

import FeaturedListItemView from "./FeaturedListItemView";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CurrentUserContext } from "gunner-react";
import { useContext } from "react";

const useStyles = makeStyles(theme => ({
  listing: {
    borderRadius: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    marginBottom: theme.spacing(4),
    "& .ratings": {
      display: "flex",
      justifyContent: "center",
    },
    "& .header": {
      backgroundColor: "rgba(255, 255, 255, 0.75)",
      padding: theme.spacing(0.5, 1),
    },
    "& .titleRow": {
      "& a": {
        textTransform: "uppercase",
      },
      "& h4": {
        textTransform: "none !important",
        fontWeight: theme.typography.fontWeightRegular,
      },
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(-1),
      "&:after": {
        height: `1px`,
        content: '""',
        backgroundColor: theme.palette.text.disabled,
        display: "block",
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
      },
    },
  },
}));

export default ({ listing }) => {
  const { data: { getListing } = {} } = useQuery(Listing.queries.get, {
    variables: { id: listing.id },
  });
  const { data: { getTicket } = {} } = useQuery(Ticket.queries.get, {
    variables: { id: listing.listingTicketId },
  });

  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const currentUser = useContext(CurrentUserContext);

  return (
    <FeaturedListItemView
      classes={classes}
      listing={getListing ?? listing}
      ticket={getTicket ?? listing.cachedTicket}
      bids={((getListing ?? listing)?.bids?.items ?? []).filter(
        bid => bid.status !== "CANCELLED" && bid.status !== "REJECTED"
      )}
      history={history}
      matches={matches}
      currentUser={currentUser}
    />
  );
};
