import React from "react";

import { useQuery } from "@apollo/client";

import ListingForm from "../Components/Listing/Form";
import Ticket from "shared/api/Ticket";
import Listing from "shared/api/Listing";
import PageContainer from "../Components/Layout/PageContainer";
import ContentCell from "../Components/Layout/ContentCell";
import Tabs from "../Components/Admin/Components/Tabs";

const ListingEdit = ({ history, match: { params } }) => {
  const { data: { getTicket: ticket } = {} } = useQuery(Ticket.queries.get, {
    variables: {
      id: params.ticketId,
    },
  });

  const { data: { getListing: listing } = {} } = useQuery(Listing.queries.get, {
    variables: {
      id: params.id,
    },
  });

  return !ticket || !listing ? null : (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/listings"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <ListingForm ticket={ticket} listing={listing} />
        </ContentCell>
      </PageContainer>
    </>
  );
};

export default ListingEdit;
