import React from "react";
import LocaleEdit from "../../../../Components/Locale/Edit";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const LocaleEditRoute = ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/locales"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <LocaleEdit id={params.id} />
      </ContentCell>
    </PageContainer>
  </>
);

export default LocaleEditRoute;
