import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/client";
import NumberFormat from "react-number-format";

import Listing from "shared/api/Listing";
import formatOdds from "shared/Util/formatOdds";
import RadioButtons from "../../Layout/RadioButtons";
import NumberFormatCustom from "../../Layout/NumberFormatCustom";
import Modal from "../../Layout/Modal";
import { CurrentUserContext } from "gunner-react";
import { DateTimePicker } from "@material-ui/pickers";
import { commissionAdjust, commissionWaiverRedemption } from "shared/Config";
import ListingWaiverToggle from "Components/Listing/WaiverToggle";

const Edit = ({ onClose, match: { params } }) => {
  const currentUser = useContext(CurrentUserContext);
  const [modalOpen, setModalOpen] = useState(true);
  const [saving, setSaving] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [notes, setNotes] = useState(null);
  const [expiresAt, setExpiresAt] = useState(undefined);
  const [newAskingPrice, setNewAskingPrice] = useState(null);
  const [newMinimumBidPrice, setNewMinimumBidPrice] = useState(null);
  const [commissionWaiverApplied, setCommissionWaiverApplied] = useState(false);

  const { loading, data: { getListing: listing = {} } = {} } = useQuery(
    Listing.queries.get,
    {
      variables: {
        id: params.id,
      },
    }
  );

  const ticket = listing.ticket;

  const mutation =
    newStatus === "ACTIVE" ? "reactivateListing" : "pauseListing";

  const payout =
    (!commissionWaiverApplied
      ? parseFloat(newAskingPrice) * commissionAdjust
      : parseFloat(newAskingPrice) > 100000
      ? 100000 + (parseFloat(newAskingPrice) - 100000) * commissionAdjust
      : parseFloat(newAskingPrice)) / 100.0;

  const [_updateListing] = useMutation(
    newStatus === "ACTIVE"
      ? Listing.mutations.reactivate
      : Listing.mutations.pause,
    {
      variables: {
        input: {
          id: listing.id,
          askingPrice: newAskingPrice,
          minimumBidPrice: newMinimumBidPrice,
          expiresAt: expiresAt || null,
          notes: notes || undefined,
          commissionWaiverApplied,
          postedFrom: listing?.postedFrom ?? "WEB",
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        [mutation]: {
          ...listing,
          status: newStatus,
          askingPrice: newAskingPrice,
          minimumBidPrice: newMinimumBidPrice,
          expiresAt: expiresAt || null,
          notes: notes || undefined,
          commissionWaiverApplied,
        },
      },
      refetchQueries: [
        {
          query: Listing.queries.listBySellerIdWithBids,
          variables: {
            listingSellerId: currentUser.id,
            sortDirection: "DESC",
            limit: 500,
            status: {
              eq: "ACTIVE",
            },

            bidsLimit: 1000,
            bidsSortDirection: "DESC",
          },
        },
        {
          query: Listing.queries.listBySellerIdWithBids,
          variables: {
            listingSellerId: currentUser.id,
            sortDirection: "DESC",
            limit: 500,
            status: {
              eq: "INACTIVE",
            },

            bidsLimit: 1000,
            bidsSortDirection: "DESC",
          },
        },
        {
          query: Listing.queries.listByStatus,
          variables: {
            status: "ACTIVE",
            sortDirection: "DESC",
            limit: 50,
          },
        },
        {
          query: Listing.queries.listByStatus,
          variables: {
            status: "INACTIVE",
            sortDirection: "DESC",
            limit: 50,
          },
        },
      ],
      
    }
  );

  useEffect(() => {
    !modalOpen && onClose();
  }, [modalOpen, onClose]);

  useEffect(() => {
    !!listing.status && !newStatus && setNewStatus(listing.status);
  }, [listing.status, newStatus]);

  useEffect(() => {
    !!listing.expiresAt &&
      expiresAt === undefined &&
      setExpiresAt(listing.expiresAt);
  }, [listing.expiresAt, expiresAt]);

  useEffect(() => {
    !!listing.notes && setNotes(listing.notes);
  }, [listing.notes, notes]);

  useEffect(() => {
    !!listing &&
      listing?.status !== "NEW" &&
      setNewAskingPrice(listing.askingPrice);
  }, [listing]);

  useEffect(() => {
    !!listing && setNewMinimumBidPrice(listing.minimumBidPrice);
  }, [listing]);

  // useEffect(() => {
  //   !!listing.askingPrice &&
  //   !newAskingPrice &&
  //   setNewAskingPrice(listing.askingPrice)
  // }, [listing.askingPrice, newAskingPrice])

  // useEffect(() => {
  //   !!listing.minimumBidPrice &&
  //   !newMinimumBidPrice &&
  //   setNewMinimumBidPrice(listing.minimumBidPrice)
  // }, [listing.minimumBidPrice, newMinimumBidPrice])

  useEffect(() => {
    !!saving &&
      _updateListing()
        .then(({ data: { [mutation]: updatedListing } }) => [
          setModalOpen(false),
          setSaving(false),
        ])
        .catch(error => [
          console.log(error),
          window.alert(error.message),
          setModalOpen(true),
          setSaving(false),
        ]);
  }, [_updateListing, mutation, saving]);

  return (
    <Modal
      opened={!!modalOpen}
      title={
        !!loading || !ticket ? null : (
          <>
            {ticket.subject} <br />
            <Box color="secondary.main" component="span">
              {ticket.eventDescription}
            </Box>
          </>
        )
      }
      body={
        !!loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <div style={{ paddingTop: 8, overflow: "hidden" }}>
            <Grid container alignItems="center" justify="center" spacing={2}>
              <Grid item xs={12}>
                <form noValidate autoComplete="off">
                  <Box mb={4}>
                    <TextField
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputMode: "decimal",
                        type: "text",
                      }}
                      onChange={({ target: { value } }) =>
                        setNewAskingPrice(parseInt(parseFloat(value) * 100.0))
                      }
                      value={parseFloat(newAskingPrice) / 100.0}
                      label="Sell Price"
                      fullWidth
                      variant="outlined"
                      helperText={`If Sold, you will receive ${
                        commissionWaiverApplied ? "100%" : "90%"
                      } of the Sale Price.`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputMode="decimal"
                      inputProps={{ inputMode: "decimal" }}
                      type="text"
                    />
                  </Box>
                  <Box mb={4}>
                    <TextField
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputMode: "decimal",
                        type: "text",
                      }}
                      onChange={({ target: { value } }) =>
                        setNewMinimumBidPrice(parseInt(value * 100.0))
                      }
                      value={parseFloat(newMinimumBidPrice) / 100.0}
                      label="Minimum Bid"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputMode="decimal"
                      inputProps={{ inputMode: "decimal" }}
                      type="text"
                    />
                    {currentUser?.groups?.includes?.("Admins") &&
                      currentUser?.availablePoints >=
                        commissionWaiverRedemption && (
                        <ListingWaiverToggle
                          listingId={params.id}
                          onChange={setCommissionWaiverApplied}
                        />
                      )}
                  </Box>
                  <Box mb={4}>
                    {newStatus === "ACTIVE" && (
                      <DateTimePicker
                        clearable
                        disablePast
                        className="formControl"
                        fullWidth={true}
                        label={"Recommended: Set Listing Expiration"}
                        onChange={value => setExpiresAt(value)}
                        value={expiresAt || null}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="dialog"
                        inputVariant="outlined"
                      />
                    )}
                  </Box>
                </form>
              </Grid>
              <Grid container item xs={12} justify="center">
                <Grid item xs={6} md={4}>
                  <Typography variant={"subtitle2"}>Payout</Typography>
                  <Typography variant="h6">
                    <NumberFormat
                      fixedDecimalScale
                      decimalScale={2}
                      value={Math.abs(payout)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography variant={"subtitle2"}>Odds</Typography>
                  <Typography variant="h6">
                    {formatOdds(
                      parseFloat(ticket.collectAmount),
                      newAskingPrice
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant={"subtitle2"}>List For Sale</Typography>
                  <RadioButtons
                    selectedValue={newStatus === "NEW" ? "INACTIVE" : newStatus}
                    onChange={value => setNewStatus(value)}
                    options={[
                      { label: "List", value: "ACTIVE" },
                      { label: "Unlist", value: "INACTIVE" },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} justify="center">
              <Grid item xs={12}>
                {currentUser?.groups?.includes?.("Admins") && (
                  <TextField
                    multiline
                    rows={5}
                    className="formControl"
                    fullWidth
                    label={"Notes"}
                    onChange={({ target: { value } }) => setNotes(value)}
                    value={notes || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )
      }
      saving={saving}
      onClose={() => setModalOpen(false)}
      saveButton={{
        disabled:
          !newAskingPrice ||
          !!saving ||
          !ticket ||
          ticket.collectAmount <= newAskingPrice ||
          newAskingPrice <= newMinimumBidPrice,
        text: !!saving ? "Saving..." : "Save",
        onClick: () => setSaving(true),
        ButtonProps: {
          fullWidth: true,
        },
      }}
    />
  );
};

export default Edit;
