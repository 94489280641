import { useMemo } from "react";
import Ticket from "shared/api/Ticket";
import Listing from "shared/api/Listing";
import { CurrentUserContext } from "gunner-react";
import { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useDataObject } from "gunner-react";

export default ({ ticketId: passedTicketId, ticket: passedTicket }) => {
  return false;
};
