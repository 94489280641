import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import SportIcon from "../../../../Sport/Components/Icon";
import { Hidden } from "@material-ui/core";

import Rating from "../../../../Rating";
import BidButton from "../../../../Bid/Components/CreateButton";
import BuyButton from "../../../Components/BuyButton";

export default ({
  listing,
  ticket,
  classes,
  bids,
  matches,
  history,
  currentUser,
}) => (
  <Card className={classes.listing} elevation={0}>
    <CardHeader
      className={"header"}
      disableTypography={true}
      title={
        <Grid container spacing={0} justify={"space-around"}>
          <Grid item xs={6} container justify="flex-start" alignItems="center">
            <Typography variant={"subtitle1"}>{ticket.sport.abbrev}</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end" alignItems="center">
            <Typography component={"p"} align={"right"} variant={"subtitle1"}>
              {ticket.betType}
            </Typography>
            <Hidden mdDown>
              <SportIcon sport={ticket.sport.abbrev.toLowerCase()} />
            </Hidden>
          </Grid>
        </Grid>
      }
    />
    <CardContent>
      <Grid container spacing={1}>
        <Grid className={"titleRow"} item xs={12}>
          <Typography noWrap variant={"h6"} component={"h3"}>
            <Link
              to={`/tickets/${listing.listingTicketId}/listings/${listing.id}`}
            >
              {ticket.subject}
            </Link>
          </Typography>
          <Typography noWrap variant={"subtitle2"} component={"h4"}>
            {ticket.eventDescription}
          </Typography>
        </Grid>
        <Grid container spacing={0} justify="center" item xs={12}>
          <Grid item xs={5}>
            <Typography noWrap variant={"subtitle2"}>
              Odds
            </Typography>
            <Typography noWrap variant={"body2"}>
              {listing.newOdds}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography noWrap variant={"subtitle2"}>
              Collect
            </Typography>
            <Typography noWrap variant={"body2"}>
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={parseFloat(ticket.collectAmount) / 100.0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0} justify="center" item xs={12}>
          <Grid item xs={5}>
            <Typography noWrap variant={"subtitle2"}>
              Price
            </Typography>
            <Typography noWrap variant={"body2"}>
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={parseFloat(listing.askingPrice) / 100.0}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant={"subtitle2"}>Min/High&nbsp;Bid</Typography>
            <Typography noWrap variant={"body2"}>
              {!bids[0] ? (
                !!listing?.minimumBidPrice ? (
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={parseFloat(listing?.minimumBidPrice) / 100.0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                ) : (
                  <>N/A</>
                )
              ) : (
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={
                    parseFloat(
                      bids
                        .slice()
                        .sort((a, b) => (a.offer > b.offer ? -1 : 1))[0].offer
                    ) / 100.0
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0} justify="center" item xs={12}>
          <Grid item md={10} lg={5}>
            <BuyButton
              fullWidth={matches}
              listing={listing}
              currentUser={currentUser}
              onSuccess={listing => history.push(`/account/tickets`)}
              onClick={() =>
                history.push(
                  `/tickets/${listing.listingTicketId}/listings/${listing.id}`
                )
              }
            />
          </Grid>
          <Grid item md={10} lg={5}>
            <BidButton
              fullWidth={matches}
              listing={listing}
              currentUser={currentUser}
              highAmount={
                !!bids[0]
                  ? bids.slice().sort((a, b) => (a.offer > b.offer ? -1 : 1))[0]
                      .offer
                  : 0
              }
              onSuccess={bid =>
                history.push(
                  `/tickets/${listing.listingTicketId}/listings/${bid.bidListingId}`
                )
              }
              onClick={() =>
                history.push(
                  `/tickets/${listing.listingTicketId}/listings/${listing.id}`
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={0} justify="center" item xs={12}>
          <Grid item xs={10}>
            <div className="ratings">
              <Rating rating={listing.ratingOverride || 1} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
