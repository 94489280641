import React from "react";
import Tabs from "../Components/Tabs";

import AdCell from "../../Layout/AdCell";
import ContentCell from "../../Layout/ContentCell";
import PageContainer from "../../Layout/PageContainer";
import PageHeader from "../../Layout/PageHeader";
import { Typography, Grid, Box } from "@material-ui/core";

const About = ({ history }) => {
  return (
    <>
      <Tabs
        history={history}
        currentPath={"/pages/about"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell>
          <PageHeader alt>How did it Start?</PageHeader>
          <Typography paragraph>
            Ian and Luke met in the dorms while on campus at sunny University of
            Arizona. PropSwap wasn’t born however until a phone conversation in
            October 2013, where the two shared their frustration with the lack
            of ability to sell bets they owned. For two years, they worked with
            lawyers, gaming professionals, web developers, to ultimately lead
            them here — when PropSwap could finally open their doors for
            business! They look forward to providing this revolutionary service
            to sports bettors for years to come.
          </Typography>
          <Grid container spacing={8}>
            <Grid contain item xs={12} md={6}>
              <Grid container item xs={12} justify="center">
                <Box mt={4} mb={4}>
                  <img
                    src={require("../../../assets/css/ian.png")}
                    alt="Ian Epstein"
                  />
                </Box>
              </Grid>
              <PageHeader alt component={"h2"}>
                Ian Epstein
              </PageHeader>
              <Typography paragraph>
                Voted “Most Likely to be a Billionaire by Age 25” by his high
                school senior class, Ian was only a few billion short of
                achieving that goal by the quarter-century mark. Selling glow
                sticks at Chicago street festivals at the age of 13 and ticket
                scalping by the age of 15, entrepreneurship is nothing new to
                Ian.
              </Typography>
              <Typography paragraph>
                During a college spring break trip to Vegas in 2010 Ian walked
                into the MGM Grand Sportsbook, soaked in the sights & sounds,
                and made the declaration to himself that this was the industry
                he was going to make his mark in.
              </Typography>
              <Typography paragraph>
                In 2012 Ian moved to Las Vegas to accept a position at the
                sports betting firm Cantor Gaming. He left the company in 2015
                to pursue PropSwap full time.
              </Typography>
            </Grid>
            <Grid contain item xs={12} md={6}>
              <Grid container item xs={12} justify="center">
                <Box mt={4} mb={4}>
                  <img
                    src={require("../../../assets/css/luke.png")}
                    alt="Luke Pergande"
                  />
                </Box>
              </Grid>
              <PageHeader alt component={"h2"}>
                Luke Pergande
              </PageHeader>
              <Typography paragraph>
                Born in Chicago, Luke entered the world talking financial
                markets. His first transaction came at the age of two in 1990,
                when he bought 10,000 shares of Apple with a loan from his
                grandfather. It’s been rated by the Wall Street Journal as one
                of the best trades executed by a toddler in recent memory.
              </Typography>
              <Typography paragraph>
                Joking aside, the real journey began 6 years ago, spending a
                wide-eyed summer grabbing coffee, running trades to and from the
                pit, at the Chicago Options Exchange.
              </Typography>
              <Typography paragraph>
                His most recent 3.5 years were spent working at Bloomberg,
                talking with the hedge fund managers by day, trading stocks by
                night. Parlaying this background into the world of sports
                wagering, and starting the first marketplace for betting slips,
                was a logical next step.
              </Typography>
            </Grid>
          </Grid>
        </ContentCell>
        <AdCell>
          <img src={require("../../../assets/css/ad.gif")} alt="Ad" />
        </AdCell>
      </PageContainer>
    </>
  );
};

export default About;
