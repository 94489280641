import React from "react";

import LayoutTabs from "../../Layout/Tabs";

const Tabs = ({ currentPath, onChange }) => {
  const options = [
    { label: "Login", value: "/sign-in", selected: currentPath !== "/sign-up" },
    { label: "Register", value: "/sign-up" },
  ];

  return (
    <LayoutTabs
      options={options}
      selectedValue={currentPath}
      onChange={onChange}
    />
  );
};

export default Tabs;
