import { Box, Button, TextField } from "@material-ui/core";
import React from "react";

export default ({ user, onChange, amount, onSubmit, submitting }) =>
  console.log(
    submitting,
    amount,
    Math.floor((user.availablePoints ?? 0) / 250)
  ) || (
    <Box component="form">
      <TextField
        fullWidth
        label={"Bonus Cash"}
        onChange={({ target: { value } }) => onChange(parseInt(value || 0))}
        value={amount || ""}
        type="number"
        variant={"outlined"}
        InputLabelProps={{ shrink: true }}
        helperText={`You have ${
          user.availablePoints
        } available points, which can be converted into up to $${Math.floor(
          user.availablePoints / 250
        )} in bonus cash.`}
      />
      <Button
        onClick={onSubmit}
        disabled={
          !!submitting ||
          Math.floor((user.availablePoints ?? 0) / 250) < amount ||
          (amount ?? 0) <= 0
        }
        variant="contained"
        color="secondary"
      >
        Convert
      </Button>
    </Box>
  );
