import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useContext } from "react";
import { CurrentUserContext } from "gunner-react";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: 0,
    width: "100%",
  },
  group: {
    // margin: theme.spacing(1, 0),
    flexDirection: "row",
  },
  column: {
    flex: 1,
    display: "flex",
    "flex-direction": "column",
  },
  method: {
    marginLeft: theme.spacing(-5),
    marginTop: theme.spacing(0),
    transform: "scale(0.7)",
  },
}));

const Method = ({ method = "CHECK", onChange }) => {
  const classes = useStyles();
  const currentUser = useContext(CurrentUserContext);
  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="method"
            name="method"
            className={classes.group}
            value={method}
            onChange={({ target: { value } }) =>
              console.log("method", value) || onChange(value)
            }
          >
            <div className={classes.column}>
              <FormControlLabel
                value={"CHECK"}
                control={<Radio />}
                label="Paper Check"
              />
              <FormControlLabel
                value={"ACH_STANDARD"}
                control={<Radio />}
                label="Direct Deposit"
              />
            </div>
          </RadioGroup>
        </FormControl>
        {method === "CHECK" && (
          <Typography variant={"body2"} component="p" paragraph>
            You will be sent a paper check to the mailing address you listed in
            Step 3.
          </Typography>
        )}
        {method === "ACH_STANDARD" && (
          <Typography variant={"body2"} component="p" paragraph>
            Your funds will be deposited directly into the bank account
            specified in Step 3 within 3-5 business days.
          </Typography>
        )}
        <Typography variant={"body2"} component="p" paragraph>
          Please reach out to us at info@propswap.com with any questions.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Method;
