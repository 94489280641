import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Edit from "../../Listing/Screens/Edit";
import New from "../../Listing/Screens/New";
import Ticket from "shared/api/Ticket";
import Listing from "shared/api/Listing";
import { useMemo } from "react";
import { useDataObject } from "gunner-react";

export default ({
  ticket: passedTicket,
  ticketId,
  listing: passedListing,
  listingId,
}) => {
  const [action, setAction] = useState(null);

  const ticket = useDataObject({
    objectId: ticketId,
    object: passedTicket,
    query: Ticket.queries.get,
  });
  const listing = useDataObject({
    objectId: listingId,
    object: passedListing,
    query: Listing.queries.get,
  });

  const handleSelect = action => () => setAction(action);

  return useMemo(
    () =>
      (ticket.result === "UNDECIDED" || !ticket.result) &&
      (!listing || ["ACTIVE", "INACTIVE", "NEW"].includes(listing?.status)) ? (
        <>
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={handleSelect(!!listing ? "edit" : "list")}
          >
            {!!listing
              ? listing.status === "ACTIVE"
                ? `Edit`
                : "Sell"
              : `List`}
          </Button>
          {action === "edit" && (
            <Edit
              match={{ params: { id: listing?.id } }}
              onClose={() => setAction(null)}
            />
          )}
          {action === "list" && (
            <New
              match={{ params: { ticketId: ticket.id } }}
              onClose={() => setAction(null)}
            />
          )}
        </>
      ) : null,

    [ticket, listing, action]
  );
};
