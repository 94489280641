import React from "react";
import { DataTable } from "gunner-react/web";
import Listing from "shared/api/Listing";
import Sportsbook from "shared/api/Sportsbook";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { formatPennies } from "shared/Util/money";
import formatOdds from "shared/Util/formatOdds";
import moment from "moment";
import { gql, useMutation } from "@apollo/client";

const gquery = gql`
query RebateListSearchListings(
      $filter: SearchableListingFilterInput
      $sort: [SearchableListingSortInput]
      $limit: Int
      $nextToken: String
    ) {
      searchListings(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...RebateListingPublic
        }
        nextToken
      }
    }
fragment RebateListingPublic on Listing {
  id
  createdAt
  updatedAt
  expiresAt
  soldAt
  bidCount
  salesPrice
  askingPrice
  minimumBidPrice
  newOdds
  newOddsCalculated
  status
  ratingOverride
  featuredAt
  listingTicketId
  listingBuyerId
  listingSellerId
  searchString
  score
  recentlySold
  postedFrom
  boughtFrom
  valueRating
  rebateStatus
  watchingsCount
  notes
  commissionWaiverApplied
  pendingBidsCount
  cachedBid {
    id
    createdAt
    updatedAt
    offer
    counter
    status
    bidListingId
    bidBidderId
    bidder {
      id
      displayName
      profilePicUri
      status
    }
  }
  cachedTicket {
    id
    createdAt
    updatedAt
    odds
    oddsCalculated
    photoUrl
    betType
    eventDescription
    subject
    winAmount
    betAmount
    collectAmount
    additionalInfo
    ticketId
    bookName
    isMobile
    creatorId
    source
    result
    inPropswapsPossession
    reviewed
    ticketUserId
    ticketSportId
    ticketOriginalOwnerId
    ticketLocaleId
    ticketSportsbookId
    ticketEventId
    ticketOptionId
    hasShipped
    views
    providerTicketId
    providerId
    disableValues
    sport {
      id
      abbrev
      name
    }
  }
  seller {
    id
    displayName
  }
  ticket {
    option {
      id
      name
    }
    event {
      id
      description
    }
  }
}
`;

const query = {
  query: gquery,
  variables: {
    withSeller: true,
    filter: {
      and: [
        {
          rebateStatus: { eq: "ELIGIBLE" },
        },
        {
          or: [{ status: { eq: "SOLD" } }, { status: { eq: "PENDING" } }],
        },
      ],
    },
  },
};

const ReedemButton = ({ listing }) => {
  const [markRedeemed] = useMutation(Listing.mutations.update, {
    variables: {
      input: {
        id: listing?.id,
        rebateStatus: "REDEEMED",
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateListing: {
        ...listing,
        rebateStatus: "REDEEMED",
      },
    },
  });

  return (
    <Button onClick={markRedeemed} size="small" color="secondary">
      Mark Redeemed
    </Button>
  );
};

const fields = {
  Event: {
    value: item => console.log("item", item) || (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/tickets/${item.cachedTicket?.id}`}
      >
        {item.ticket?.event?.description}
      </Button>
    ),
    nowrap: true,
    sort: order => (a, b) =>
      a.ticket?.event?.description > b.ticket?.event?.description
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Team: {
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/tickets/${item.cachedTicket?.id}/listings/${item.id}/edit`}
      >
        {item.ticket?.option?.name}
      </Button>
    ),
    nowrap: true,
    sort: order => (a, b) =>
      a.cachedTicket?.ticket?.name > b.ticket?.option?.name
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Seller: {
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/users/${item.listingSellerId}/edit`}
      >
        {item.seller?.displayName}
      </Button>
    ),
    nowrap: true,
    sort: order => (a, b) =>
      a.seller?.displayName > b.seller?.displayName
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Collects: {
    value: item => formatPennies(item.cachedTicket?.collectAmount),
    nowrap: true,
    sort: order => (a, b) =>
      a.cachedTicket?.collectAmount > b.cachedTicket?.collectAmount
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  "Sale Price": {
    value: item => formatPennies(item.salesPrice),
    nowrap: true,
    sort: order => (a, b) =>
      a.salesPrice > b.salesPrice
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Odds: {
    value: item => formatOdds(item.cachedTicket?.collectAmount, item?.salesPrice),
    nowrap: true,
    sort: null,
    rowProps: {},
  },
  Created: {
    value: item => moment(item.createdAt).format("MM/DD/YYYY H:mm"),
    nowrap: true,
    sort: order => (a, b) =>
      a.createdAt > b.createdAt
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item =>
      item.rebateStatus === "ELIGIBLE" ? <ReedemButton listing={item} /> : null,
  },
};

export default ({}) => (
  <DataTable
    fields={fields}
    search={null}
    dataListParams={{
      query: query.query,
      dataKey: "searchListings",
      variables: query.variables,
      clientFilter: item =>
        item.rebateStatus === "ELIGIBLE" &&
        ["SOLD", "PENDING"].includes(item.status),
    }}
  />
);
