import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  optionsRow: {
    marginBottom: theme.spacing(3),
  },
}));

const OptionsRow = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.optionsRow}
      container
      alignItems="flex-start"
      alignContent="space-between"
      justify="space-between"
      spacing={0}
    >
      {children}
    </Grid>
  );
};

export default OptionsRow;
