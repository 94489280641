import React from "react";

import Form from "../Form";
import { useQuery } from "@apollo/client";
import ConversionTemplate from "shared/api/ConversionTemplate";

export default ({ id }) => {
  const { data: { getConversionTemplate } = {} } = useQuery(
    ConversionTemplate.queries.get,
    { variables: { id } }
  );

  return !getConversionTemplate ? null : (
    <Form conversionTemplate={getConversionTemplate} />
  );
};
