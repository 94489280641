import React from "react";
import Grid from "@material-ui/core/Grid";
import PageHeader from "../../../Layout/PageHeader";
import FeaturedListItem from "./Item";

export default ({ classes, listings }) => (
  <Grid className={classes.featuredList} spacing={1} container>
    <Grid item xs={12}>
      <PageHeader alt>Featured Deals</PageHeader>
    </Grid>
    {listings.map(listing => (
      <Grid container item xs={4} key={listing.id}>
        <FeaturedListItem listing={listing} />
      </Grid>
    ))}
  </Grid>
);
