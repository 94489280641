import { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";

import { CurrentUserContext } from "gunner-react";

import Ticket from "shared/api/Ticket";
import Listing from "shared/api/Listing";

const useCanEditTicket = ({ ticketId }) => {
  const currentUser = useContext(CurrentUserContext);
  const { data: { getTicket: ticket } = {} } = useQuery(Ticket.queries.get, {
    variables: { id: ticketId },
  });
  const { data: { listingsByTicketId: { items: listings } = {} } = {} } =
    useQuery(Listing.queries.listByTicketId, {
      variables: { listingTicketId: ticketId },
    });
  const [canEditTicket, setCanEditTicket] = useState(false);

  useEffect(() => {
    setCanEditTicket(
      !!currentUser &&
        !!ticket && //If the current user is the original and current owner, and hasn't listed the ticket yet, he can edit it
        ((currentUser.id === ticket.ticketOriginalOwnerId &&
          currentUser.id === ticket.ticketUserId &&
          (listings || []).length === 0) ||
          currentUser.groups.includes("Admins"))
    );
  }, [currentUser, listings, ticket]);

  return [canEditTicket, { ...ticket, listings }];
};

export default useCanEditTicket;
