import React from "react";

import Form from "../Form";
import { useQuery } from "@apollo/client";
import Redirect from "shared/api/Redirect";

export default ({ id }) => {
  const { data: { getRedirect } = {} } = useQuery(Redirect.queries.get, {
    variables: { id },
  });

  return !getRedirect ? null : <Form redirect={getRedirect} />;
};
