import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Redirect } from "react-router-dom";

import Form from "../../Form";
import Provider from "shared/api/Provider";
import { withoutKeys } from "gunner-react";
import {
  Typography,
  List,
  ListItem,
  Box,
  TextField,
  Button,
  ListItemText,
} from "@material-ui/core";

const ManagerForm = ({ providerId }) => {
  const [emailToAdd, setEmailToAdd] = useState("");

  const result = useQuery(Provider.queries.listEmailsOfManagersForProvider, {
    variables: {
      id: providerId,
    },
  });

  const [assignUserToProvider, { data: { loading } = {} }] = useMutation(
    Provider.mutations.assignUserToProvider,
    {
      variables: {
        email: emailToAdd,
        id: providerId,
      },
      refetchQueries: [
        {
          query: Provider.queries.listEmailsOfManagersForProvider,
          variables: {
            id: providerId,
          },
        },
      ],
    }
  );

  return (
    <Box mt={2}>
      <Typography variant="h3">Managers</Typography>
      <List>
        {(result.data?.listEmailsOfManagersForProvider ?? []).map(
          (email, i) => (
            <ListItem key={i}>
              <ListItemText>{email}</ListItemText>
            </ListItem>
          )
        )}
      </List>
      <Box
        component="form"
        mt={2}
        noValidate
        autoComplete="off"
        display="flex"
        flexDirection="row"
        // justifyContent="space-between"
        alignItems="center"
      >
        <Box mr={2}>
          <TextField
            required
            size={"small"}
            type="email"
            variant={"outlined"}
            InputLabelProps={{ shrink: true }}
            label="Email Address"
            value={emailToAdd}
            onChange={({ target: { value } }) => setEmailToAdd(value)}
          />
        </Box>
        <Button
          variant="contained"
          disabled={!emailToAdd || !!loading}
          onClick={assignUserToProvider}
          color="primary"
        >
          Add
        </Button>
      </Box>
    </Box>
  );
};

export default ({ provider }) => {
  const [formData, setFormData] = useState(
    !!provider
      ? {
          ...provider,
          sellerFee: (provider.sellerFee ?? 0) * 100.0,
          buyerFee: (provider.buyerFee ?? 0) * 100.0,
        }
      : {}
  );
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: ["featuredAt"].includes(field)
        ? !!value
          ? new Date().toISOString()
          : null
        : /allow/.test(field)
        ? !!value
        : value,
    });

  const [_createProvider] = useMutation(Provider.mutations.create, {
    variables: {
      input: {
        ...formData,
        sellerFee: !!formData.sellerFee ? formData.sellerFee / 100.0 : 0,
        buyerFee: !!formData.buyerFee ? formData.buyerFee / 100.0 : 0,
      },
    },
    refetchQueries: [
      {
        query: Provider.queries.list,
        variables: {},
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      createProvider: {
        ...formData,
        __typename: "Provider",
      },
    },
  });

  const [_updateProvider] = useMutation(Provider.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, ["__typename", "sport", "group"]),
        sellerFee: !!formData.sellerFee ? formData.sellerFee / 100.0 : 0,
        buyerFee: !!formData.buyerFee ? formData.buyerFee / 100.0 : 0,
      },
    },
    refetchQueries: [
      {
        query: Provider.queries.list,
        variables: {},
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updateProvider: {
        ...formData,
        __typename: "Provider",
      },
    },
  });

  //   sportId: ID!

  //   activeAt: AWSDateTime!
  //   featuredAt: AWSDateTime!

  const fields = {
    name: {
      label: "Name",
      regex: /\w+/,
      required: false,
      errorMessage: "You are required enter a name",
    },
    allowOnPropSwap: {
      label: "Allow on PropSwap?",
      regex: /\w+/,
      required: false,
      type: "checkbox",
    },
    allowOnOtherProviders: {
      label: "Allow on Other Providers?",
      regex: /\w+/,
      required: false,
      type: "checkbox",
    },
    allowAcrossLocales: {
      label: "Allow Across Locales?",
      regex: /\w+/,
      required: false,
      type: "checkbox",
    },
    sellUrl: {
      label: "Sell URL",
      regex: /\w+/,
      required: true,
    },
    buyUrl: {
      label: "Buy URL",
      regex: /\w+/,
      required: true,
    },
    winUrl: {
      label: "Win URL",
      regex: /\w+/,
      required: true,
    },
    creditUrl: {
      label: "Credit URL",
      regex: /\w+/,
      required: true,
    },
    oauthUrl: {
      label: "oAuth URL",
      regex: /\w+/,
      required: true,
    },
    sellerFee: {
      label: "Seller Fee",
      regex: /\w+/,
      required: true,
      type: "number",
    },
    buyerFee: {
      label: "Buyer Fee",
      regex: /\w+/,
      required: false,
      type: "number",
    },
  };

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateProvider : _createProvider)() //if this is an existing provider, update it, otherwise, create it
        .then(
          ({
            data: {
              [!!formData.id ? "updateProvider" : "createProvider"]: provider,
            },
          }) => setSubmitSuccess(true)
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [doSubmit]);

  return !!submitSuccess ? (
    <Redirect push to={`/admin/exchange/providers`} />
  ) : (
    <>
      <Form
        submitting={false}
        fields={fields}
        onChange={handleChange}
        data={formData}
        onSubmit={() => setDoSubmit(true)}
        buttonLabel={"Submit"}
      />
      {!!formData.id && <ManagerForm providerId={formData.id} />}
    </>
  );
};
