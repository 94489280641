import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  listing: {
    borderRadius: 0,
    [theme.breakpoints.up("lg")]: {
      borderBottom: `1px solid ${theme.palette.background.default}`,
      marginBottom: theme.spacing(4),
    },
    "& .content": {
      display: "flex",
    },
    "& .header": {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0.5, 1),
    },
    "& .featured": {
      color: theme.palette.common.white,
      background: theme.palette.text.primary,
      padding: theme.spacing(0.5),
      margin: theme.spacing(-1),
      marginRight: theme.spacing(2),
    },
    "& .titleRow": {
      "& a": {
        textTransform: "uppercase",
      },
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-start",
        display: "flex",
      },
    },
    "& .actionRow": {
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(2),
      },
    },
  },
}));
