import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import Form from "../../Form";
import Group from "shared/api/Group";
import { withoutKeys } from "gunner-react";

const fields = {
  name: {
    label: "Name",
    regex: /\w+/,
    required: true,
    type: "text",
    errorMessage: "You are required enter a name",
  },
  active: {
    label: "Active?",
    regex: /\w+/,
    required: false,
    type: "checkbox",
  },
};

const GroupFormContainer = ({ group }) => {
  const [formData, setFormData] = useState(group || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === "active" ? !!value : value,
    });

  const [_createGroup] = useMutation(Group.mutations.create, {
    variables: {
      input: {
        ...formData,
      },
    },
    refetchQueries: [
      {
        query: Group.queries.listBySportId,
        variables: { limit: 100, sportId: formData.sportId },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      createGroup: {
        ...formData,
        __typename: "Group",
      },
    },
  });

  const [_updateGroup] = useMutation(Group.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, ["__typename", "sport"]),
      },
    },
    refetchQueries: [
      {
        query: Group.queries.listBySportId,
        variables: { limit: 100, sportId: formData.sportId },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updateGroup: {
        ...formData,
        __typename: "Group",
      },
    },
  });

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateGroup : _createGroup)() //if this is an existing group, update it, otherwise, create it
        .then(
          ({
            data: { [!!formData.id ? "updateGroup" : "createGroup"]: group },
          }) => setSubmitSuccess(true)
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [doSubmit]);

  return !!submitSuccess ? (
    <Redirect push to={`/admin/sports/${formData.sportId}/groups`} />
  ) : (
    <Form
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  );
};

export default GroupFormContainer;
