import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Redirect } from "react-router-dom";

import { Form } from "gunner-react/web";
import Campaign from "shared/api/Campaign";
import { withoutKeys } from "gunner-react";

const fields = {
  name: {
    label: "Name",
    regex: /\w+/,
    required: true,
    type: "text",
    errorMessage: "You are required enter a name",
  },
  isExpired: {
    label: "Expired?",
    regex: /\w+/,
    type: "checkbox",
    errorMessage: "Required",
    value: value => !!value,
  },
};

export default ({ campaign }) => {
  const [formData, setFormData] = useState(campaign || {});
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: field === "isExpired" ? !!value : value,
    });

  console.log(formData);

  const [_createCampaign] = useMutation(Campaign.mutations.create, {
    variables: {
      input: {
        ...formData,
      },
    },
    refetchQueries: [
      {
        query: Campaign.queries.list,
        variables: { limit: 100 },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      createCampaign: {
        ...formData,
        __typename: "Campaign",
      },
    },
  });

  const [_updateCampaign] = useMutation(Campaign.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, ["__typename"]),
      },
    },
    refetchQueries: [
      {
        query: Campaign.queries.list,
        variables: { limit: 100 },
      },
    ],
    optimisticResponse: {
      __typename: "Mutation",
      updateCampaign: {
        ...formData,
        __typename: "Campaign",
      },
    },
  });

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateCampaign : _createCampaign)() //if this is an existing campaign, update it, otherwise, create it
        .then(
          ({
            data: {
              [!!formData.id ? "updateCampaign" : "createCampaign"]: campaign,
            },
          }) => setSubmitSuccess(true)
        )
        .catch(e => [
          console.log(e),
          setDoSubmit(false),
          setSubmitSuccess(false),
          window.alert(e.message),
        ]);
  }, [doSubmit]);

  return !!submitSuccess ? (
    <Redirect push to={"/admin/campaigns"} />
  ) : (
    <Form
      submitting={false}
      fields={fields}
      onChange={handleChange}
      data={formData}
      onSubmit={() => setDoSubmit(true)}
      buttonLabel={"Submit"}
    />
  );
};
