import React from "react";
import InnerTabs from "../../Layout/InnerTabs";

const UserSubTabsView = ({ selected, history, userId }) => (
  <InnerTabs
    selected={selected}
    items={[
      { label: "Account", value: "account" },
      { label: "User", value: "users" },
      { label: "Transactions", value: "transactions" },
      { label: "Listings", value: "listings" },
      { label: "Tickets", value: "tickets" },
      { label: "Recommendations", value: "recommendations" },
    ]}
    onChange={value =>
      ["transactions", "listings", "tickets", "recommendations"].includes(value)
        ? history.push(`/admin/users/${userId}/${value}`)
        : history.push(`/admin/${value}/${userId}/edit`)
    }
  />
);

export default UserSubTabsView;
