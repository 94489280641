import {
  TableRow,
  TableCell,
  Button,
  Box,
  Checkbox,
} from "@material-ui/core";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";

export const ticketSettledListItemFragment = gql`
  fragment TicketSettledListItemFragment on Ticket {
    id
    eventDescription
    ticketUserId
    result
    subject
    isSettled
    sport {
      name
    }
  }
`;


const TicketSettledListItemView = ({ 
  ticket,
  onIsSettledClick,
  loading
}) => 
  <TableRow>
    <TableCell>
      {ticket.sport.name}
    </TableCell>
    <TableCell>
      {ticket?.option?.name ?? ticket.subject}
    </TableCell>
    <TableCell>
      {ticket?.event?.description ?? ticket.eventDescription}
    </TableCell>
    <TableCell>
      {ticket.result}
    </TableCell>
    <TableCell>
      <Button
        color="secondary"
        component={Link}
        to={`/admin/users/${ticket.ticketUserId}/edit`}
        variant="text"
        size="small"
      >
        View
      </Button>
    </TableCell>
    <TableCell>
      <Button
        color="secondary"
        component={Link}
        to={`/tickets/${ticket.id}`}
        variant="text"
        size="small"
      >
        View
      </Button>
    </TableCell>
    <TableCell 
      style={{
        whiteSpace: "nowrap"
      }}
    >
      <Checkbox
        checked={ticket.isSettled === "true"}
        onChange={onIsSettledClick}
        value={ticket.isSettled}
      />
    </TableCell>
  </TableRow>

export default TicketSettledListItemView;