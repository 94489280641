import gql from "graphql-tag";
import environment from "environment";
import User from "./User";
// import Ticket from "./Ticket"

const Transaction = {};

Transaction.fragments = {
  public: gql`
    fragment TransactionPublic on Transaction {
      id
      createdAt
      updatedAt
      transactionId
      amount
      source
      type
      status
      note
      transactionUserId
      balanceSnapshot
      pendingBalanceSnapshot
      promotionalBalanceSnapshot
      transactionPromotionId
      transactionRedemptionId
    }
  `,
};

Transaction.mutations = {
  update: gql`
    mutation UpdateTransaction($input: UpdateTransactionInput!) {
      updateTransaction(input: $input) {
        ...TransactionPublic
      }
    }
    ${Transaction.fragments.public}
  `,
  create: gql`
    mutation CreateTransaction($input: CreateTransactionInput!) {
      createTransaction(input: $input) {
        ...TransactionPublic
      }
    }
    ${Transaction.fragments.public}
  `,
  delete: gql`
    mutation DeleteTransaction($input: DeleteTransactionInput!) {
      deleteTransaction(input: $input) {
        ...TransactionPublic
      }
    }
    ${Transaction.fragments.public}
  `,
};

Transaction.queries = {
  listByUserIdAndType: gql`
    query TransactionsByUserIdAndType(
      $transactionUserId: ID!
      $type: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      transactionsByUserIdAndType(
        transactionUserId: $transactionUserId
        type: $type
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TransactionPublic
        }
        nextToken
      }
    }
    ${Transaction.fragments.public}
  `,
  listByPromotionIdUserId: gql`
    query ListTransactionsByPromotionIdUserId(
      $transactionPromotionId: ID!
      $transactionUserId: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTransactionsByPromotionIdUserId(
        transactionPromotionId: $transactionPromotionId
        transactionUserId: $transactionUserId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TransactionPublic
        }
        nextToken
      }
    }
    ${Transaction.fragments.public}
  `,
  get: gql`
    query GetTransaction($id: ID!) {
      getTransaction(id: $id) {
        ...TransactionPublic
        user {
          ...UserPublic
          ...UserPrivate
        }
      }
    }
    ${Transaction.fragments.public}
    ${User.fragments.public}
    ${User.fragments.private}
  `,
  list: gql`
    query ListTransactions(
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...TransactionPublic
        }
      }
    }
    ${Transaction.fragments.public}
  `,
  listByTransactionIdUserId: gql`
    query TransactionsByTransactionIdUserId(
      $transactionId: String!
      $transactionUserId: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      transactionsByTransactionIdUserId(
        transactionId: $transactionId
        transactionUserId: $transactionUserId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TransactionPublic
        }
        nextToken
      }
    }
    ${Transaction.fragments.public}
  `,
  listByStatusUpdatedAt: gql`
    query TransactionsByStatusUpdatedAt(
      $status: TransactionStatus!
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      transactionsByStatusUpdatedAt(
        status: $status
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TransactionPublic
        }
        nextToken
      }
    }
    ${Transaction.fragments.public}
  `,
  listByUserId: gql`
    query TransactionsByUserId(
      $transactionUserId: ID!
      $updatedAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelTransactionFilterInput
      $limit: Int
      $nextToken: String
    ) {
      transactionsByUserId(
        transactionUserId: $transactionUserId
        updatedAt: $updatedAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...TransactionPublic
        }
        nextToken
      }
    }
    ${Transaction.fragments.public}
  `,
};

export default Transaction;
