import React from "react";
import SuggestedPriceView from "./SuggestedPriceView";
import Bovado from "shared/api/Bovado";
import Event from "shared/api/Event";
import { useQuery } from "@apollo/client";

export default ({
  groupId,
  eventId,
  participantId,
  participantName,
  wagerInPennies,
  format,
  event,
}) => {
  const { data: { getOddsDataForEventAndOption: odds } = {}, error } = useQuery(
    Bovado.queries.getOddsDataForEventAndOption,
    {
      variables: {
        bovadoGroupId: groupId,
        bovadoEventId: eventId,
        bovadoMarketId: event?.bovadoMarketId ?? undefined,
        bovadoParticipantId: participantId,
        bovadoParticipantName: participantName,
      },
    }
  );

  return !odds || !wagerInPennies ? null : (
    <SuggestedPriceView
      wagerInPennies={wagerInPennies}
      format={format}
      odds={odds}
    />
  );
};
