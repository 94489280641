import { useEffect } from "react";
import User from "shared/api/User";
import { useQuery } from "@apollo/client";
import { Auth } from "aws-amplify";

export default ({ cognitoUser }) => {
  const isCurrent =
    !cognitoUser ||
    !!cognitoUser?.signInUserSession?.idToken?.payload?.realUserId;

  useEffect(() => {
    !isCurrent &&
      Auth.signOut().then(data => {
        try {
          window.location.reload(true);
        } catch (e) {
          console.log(e);
        }
      });
    // .catch(err => window.location.reload(true))
  }, [isCurrent]);

  // const { loading, error, data: {usersByEmail: {items: users} = {}} = {}} = useQuery(User.queries.listByEmail, {
  //   skip: !cognitoUser?.attributes?.email,
  //   variables: {
  //     email: cognitoUser?.attributes?.email,
  //     withPrivate: true,
  //     limit: 1
  //   },
  //   pollInterval: 3000
  // });

  // const user = !!users && Array.isArray(users) ? users[0] : users;

  const {
    loading,
    error,
    data: { getUser: user } = {},
  } = useQuery(User.queries.get, {
    skip: !cognitoUser?.signInUserSession?.idToken?.payload?.realUserId,
    variables: {
      id: cognitoUser?.signInUserSession?.idToken?.payload?.realUserId,
      withPrivate: true,
    },
    pollInterval: 3000,
  });

  return {
    loading,
    error,
    user: !user
      ? user
      : {
          ...user,
        },
  };
};
