import React, { useState, useEffect } from "react";

import TicketForm from "../../Ticket/Form";
import PageContainer from "../../Layout/PageContainer";
import ContentCell from "../../Layout/ContentCell";
import Tabs from "../Components/Tabs";
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Ticket from "shared/api/Ticket";
import useCanEditTicket from "react-shared/Hooks/useCanEditTicket";
import Bet from "shared/api/Bet";
import BetList from "Components/Bet/List";
import { Component } from "react-image-crop";

const TicketEdit = ({ history, match: { params } }) => {
  const [canEditTicket, hookTicket] = useCanEditTicket({ ticketId: params.id });
  const { data: { getTicket: ticket } = {} } = useQuery(Ticket.queries.get, {
    notifyOnNetworkStatusChange: true,
    pollInterval: 5000,
    variables: { id: params.id },
  });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [doDelete, setDoDelete] = useState(false);
  const [deleteSuccessful, setDeleteSuccessful] = useState(false);

  const [_deleteTicket] = useMutation(Ticket.mutations.delete, {
    variables: {
      input: {
        id: params.id,
      },
    },
  });

  useEffect(() => {
    !!confirmDelete &&
      Promise.resolve(
        window.confirm(
          "Do not delete this ticket if there are listings associated with it as it will cause errors. Are you sure you want to proceed?"
        )
      ).then(confirmed => setDoDelete(confirmed));
  }, [confirmDelete]);

  useEffect(() => {
    !!doDelete &&
      _deleteTicket().then(() => [
        setConfirmDelete(false),
        setDoDelete(false),
        setDeleteSuccessful(true),
      ]);
  }, [doDelete]);

  return !canEditTicket ? null : deleteSuccessful ? (
    <Redirect to="/admin/tickets" />
  ) : (
    <>
      <Tabs
        history={history}
        currentPath={"/admin/tickets"}
        onChange={path => history.push(path)}
      />
      <PageContainer>
        <ContentCell fullWidth>
          <TicketForm ticket={ticket} />
          {!!canEditTicket && (
            <Box mt={4}>
              <Button
                onClick={() => setConfirmDelete(true)}
                color="primary"
                variant="contained"
              >
                Delete Ticket
              </Button>
            </Box>
          )}

          <Box mt={4} style={{ overflow: "auto" }}>
            <BetList ticketId={params.id} />
          </Box>

          <Box mt={4} style={{ overflow: "auto" }}>
            <Typography variant="h6">Ownership History</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th">
                    <Typography size="inherit" noWrap>
                      Seller Id
                    </Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography size="inherit" noWrap>
                      Buyer Id
                    </Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography size="inherit" noWrap>
                      Sale Price
                    </Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography size="inherit" noWrap>
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell component="th">
                    <Typography size="inherit" noWrap>
                      Last Updated
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(hookTicket.listings || []).map(listing => (
                  <TableRow key={listing.id}>
                    <TableCell>{listing.listingSellerId}</TableCell>
                    <TableCell>{listing.listingBuyerId}</TableCell>
                    <TableCell>
                      <Link
                        to={`/tickets/${listing.listingTicketId}/listings/${listing.id}`}
                      >
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={parseFloat(listing.salesPrice || 0) / 100.0}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/tickets/${listing.listingTicketId}/listings/${listing.id}`}
                      >
                        {listing.status}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {moment(listing.updatedAt).format("MM/DD/YYYY H:mm")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </ContentCell>
      </PageContainer>
    </>
  );
};

export default TicketEdit;
