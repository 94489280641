import gql from "graphql-tag";

const Cognito = {};

Cognito.queries = {
  dump: gql`
    query Dump {
      dump
    }
  `,
};

export default Cognito;