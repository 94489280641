import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({}));

const CreditCardSelect = ({
  currentUser,
  selectedCardInfo,
  onChange,
  bluesnapVaultedShopper,
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <RadioGroup
        className={classes.group}
        value={
          !selectedCardInfo
            ? ""
            : JSON.stringify({
                cardType: selectedCardInfo.cardType,
                cardLastFourDigits: selectedCardInfo.cardLastFourDigits,
              })
        }
        onChange={({ target: { value, checked } }) => onChange(value)}
      >
        {(
          ((bluesnapVaultedShopper || {}).paymentSources || {})
            .creditCardInfo || []
        ).map((ccInfo, i) => (
          <FormControlLabel
            key={i}
            value={JSON.stringify({
              cardType: ccInfo.creditCard.cardType,
              cardLastFourDigits: ccInfo.creditCard.cardLastFourDigits,
            })}
            control={<Radio />}
            label={`${ccInfo.creditCard.cardType} ${ccInfo.creditCard.cardLastFourDigits}`}
          />
        ))}
        <FormControlLabel
          value={"new"}
          control={<Radio />}
          label={`Enter New Card`}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default CreditCardSelect;
