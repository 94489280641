import React from "react";
import PageContainer from "Components/Layout/PageContainer";
import ContentCell from "Components/Layout/ContentCell";
import Tabs from "Components/Admin/Components/Tabs";
import NotificationsNew from "Components/Notifications/New";

export default ({ history, match: { params } }) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/campaigns"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <NotificationsNew />
      </ContentCell>
    </PageContainer>
  </>
);
