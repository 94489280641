import React, { useState, useEffect, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

import { useMutation } from "@apollo/client";

import { withoutBlanks } from "gunner-react";
import { CurrentUserContext } from "gunner-react";
import { Typography } from "@material-ui/core";
import Transaction from "shared/api/Transaction";
import User from "shared/api/User";

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    fixedDecimalScale
    decimalScale={2}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    thousandSeparator
    prefix="$"
  />
);

const UserTransactionForm = ({
  doSave,
  user,
  onError,
  onSuccess,
  onValidChange,
}) => {
  const currentUser = useContext(CurrentUserContext);
  const classes = useStyles();
  const [transactionData, setTransactionData] = useState({
    transactionId: currentUser.id,
    amount: 0,
    source: "admin",
    type: "MANUAL",
    status: "APPROVED",
    note: "",
    transactionUserId: user.id,
  });
  const isValid = !!transactionData.amount;

  const [_createTransaction] = useMutation(Transaction.mutations.create, {
    variables: {
      input: {
        ...withoutBlanks(transactionData),
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      createTransaction: {
        ...transactionData,
        __typename: "Transaction",
      },
    },
    refetchQueries: [
      {
        query: Transaction.queries.listByUserId,
        variables: {
          limit: 50,
          transactionUserId: user.id,
          sortDirection: "DESC",
        },
      },
      {
        query: User.queries.get,
        variables: {
          id: user.id,
        },
      },
    ],
  });

  useEffect(() => {
    onValidChange(isValid);
  }, [isValid]);

  useEffect(() => {
    !!doSave && _createTransaction().then(onSuccess);
  }, [doSave]);

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Typography paragraph>
        Balance:
        <NumberFormat
          fixedDecimalScale
          decimalScale={2}
          value={parseFloat(user.balance) / 100.0}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      </Typography>
      <Typography paragraph>
        Promotional Balance:
        <NumberFormat
          fixedDecimalScale
          decimalScale={2}
          value={parseFloat(user.promotionalBalance) / 100.0}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      </Typography>
      <TextField
        variant="outlined"
        required
        disabled
        label="Transaction Id"
        className={classes.textField}
        value={transactionData.transactionId}
        margin="normal"
        fullWidth
      />

      <TextField
        variant="outlined"
        placeholder={`$0.00`}
        required
        fullWidth
        label={`Amount`}
        className={classes.textField}
        value={transactionData.amount / 100.0}
        onChange={({ target: { value } }) =>
          setTransactionData({
            ...transactionData,
            amount: parseInt(value * 100),
          })
        }
        margin="normal"
        InputProps={{
          inputComponent: NumberFormatCustom,
          inputMode: "decimal",
          type: "text",
        }}
        inputMode="decimal"
        inputProps={{ inputMode: "decimal" }}
        type="text"
        helperText={`The amount of the transaction will be added or subtracted from the user's balance`}
      />

      <TextField
        variant="outlined"
        label="Note"
        multiline
        rows={5}
        className={classes.textField}
        value={transactionData.note || ""}
        onChange={({ target: { value } }) =>
          setTransactionData({
            ...transactionData,
            note: value,
          })
        }
        margin="normal"
        fullWidth
      />

      <FormControl fullWidth margin="normal">
        <FormControlLabel
          control={
            <Checkbox
              checked={
                transactionData.type === "PROMO" &&
                transactionData.status !== "PENDING"
              }
              onChange={({ target: { checked } }) =>
                setTransactionData({
                  ...transactionData,
                  type: !!checked ? "PROMO" : "MANUAL",
                  status: "APPROVED",
                })
              }
              value="checkedA"
            />
          }
          label={`Promotional?`}
        />
        <FormHelperText>
          If you want to change the user's promotional balance, check this box.
          Otherwise, this will change the user's normal balance.
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <FormControlLabel
          control={
            <Checkbox
              checked={
                transactionData.status === "PENDING" &&
                transactionData.type === "MANUAL"
              }
              onChange={({ target: { checked } }) =>
                setTransactionData({
                  ...transactionData,
                  status: !!checked ? "PENDING" : "APPROVED",
                  type: "MANUAL",
                })
              }
              value="checkedA"
            />
          }
          label={`Pending?`}
        />
        <FormHelperText>
          If you want to change the user's pending balance, check this box.
          Otherwise, this will change the user's normal balance.
        </FormHelperText>
      </FormControl>
    </form>
  );
};

export default UserTransactionForm;
