import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  pageHeader: {
    textTransform: "uppercase",
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, 1),
    background: theme.palette.background.default,
    "&.alt": {
      background: "transparent",
      padding: theme.spacing(0.5, 0),
    },
    "&.gutterTop": {
      marginTop: theme.spacing(4),
    },
    "&.gutterBottom": {
      marginBottom: theme.spacing(4),
    },
  },
}));

const PageHeader = ({ children, gutterBottom, gutterTop, alt, ...rest }) => {
  const classes = useStyles();
  return (
    <Typography
      className={classNames(classes.pageHeader, {
        gutterBottom,
        gutterTop,
        alt,
      })}
      variant={"h4"}
      component={rest && rest.component ? rest.component : "h1"}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default PageHeader;
