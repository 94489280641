import { Table, TableBody, TableContainer } from "@material-ui/core";
import React from "react";
import EventOptionsListItem from "./Item";

const EventOptionsListView = ({event, optionGroups}) =>
  <TableContainer>
    <Table size="small">
      <TableBody>
        {
          optionGroups.map(og => 
            <EventOptionsListItem key={og.id} event={event} optionGroup={og} />
          )
        }
      </TableBody>
    </Table>
  </TableContainer>


export default EventOptionsListView;