import React from "react";
import { Button } from "@material-ui/core";

export default ({ onClick, children, ...buttonProps }) => (
  <>
    <Button onClick={onClick} {...buttonProps}>
      {children}
    </Button>
  </>
);
