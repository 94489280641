import React from "react";
import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";

export default ({ items, eventTypeId }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Market Type</TableCell>
        <TableCell>Market Count</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {(items ?? []).map(item => (
        <TableRow key={item.marketType}>
          <TableCell>{item.marketType}</TableCell>
          <TableCell>{item.marketCount}</TableCell>
          <TableCell>
            <Button
              component={Link}
              to={`/admin/fanduel?eventTypeId=${eventTypeId}&marketType=${item.marketType}`}
              fullWidth
              size={"small"}
              variant={"contained"}
              color={"secondary"}
            >
              Markets
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
