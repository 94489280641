import React from "react";
import { DataTable } from "gunner-react/web";
import Provider from "shared/api/Provider";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { formatPennies } from "shared/Util/money";

const fields = {
  Name: {
    value: item => item.name,
    nowrap: true,
    sort: order => (a, b) =>
      a.name > b.name ? (order === "desc" ? -1 : 1) : order === "asc" ? -1 : 1,
    rowProps: {},
  },
  "Allow on PropSwap?": {
    value: item => (!!item.allowOnPropSwap ? "Yes" : "No"),
    nowrap: true,
    sort: order => (a, b) =>
      a.allowOnPropSwap > b.allowOnPropSwap
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  "Allow on Other Providers?": {
    value: item => (!!item.allowOnOtherProviders ? "Yes" : "No"),
    nowrap: true,
    sort: order => (a, b) =>
      a.allowOnOtherProviders > b.allowOnOtherProviders
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  "Allow Across Locales?": {
    value: item => (!!item.allowAcrossLocales ? "Yes" : "No"),
    nowrap: true,
    sort: order => (a, b) =>
      a.allowAcrossLocales > b.allowAcrossLocales
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Balance: {
    value: item => formatPennies(item.balance, "$"),
    nowrap: true,
    sort: order => (a, b) =>
      a.balance > b.balance
        ? order === "desc"
          ? -1
          : 1
        : order === "asc"
        ? -1
        : 1,
    rowProps: {},
  },
  Transactions: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/exchange/providers/${item.id}/transactions`}
      >
        Transactions
      </Button>
    ),
  },
  Edit: {
    hideLabel: true,
    sort: null,
    value: item => (
      <Button
        size="small"
        color="secondary"
        component={Link}
        to={`/admin/exchange/providers/${item.id}/edit`}
      >
        Edit
      </Button>
    ),
  },
};

export default ({ showInactive }) => (
  <DataTable
    fields={fields}
    search={null}
    dataListParams={{
      query: Provider.queries.list,
      dataKey: "listProviders",
    }}
  />
);
