import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";

import { withRouter } from "react-router-dom";

import { CurrentUserContext } from "gunner-react";

import { betTypes } from "shared/Config";
import { withoutKeys } from "gunner-react";

import GenericForm from "../Form";
import { nonMutationFields, adminOnlyFields } from "./index";
import { List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import Ticket from "shared/api/Ticket";
import Sport from "shared/api/Sport";
import SportsbookLocale from "shared/api/SportsbookLocale";
import OptionGroup from "shared/api/OptionGroup";
import Event from "shared/api/Event";
import Locale from "shared/api/Locale";
import formatOdds from "shared/Util/formatOdds";
import Toast from "../Toast";

const sourceTypes = {
  USER: "User",
  ADMIN: "Admin",
};

const resultTypes = {
  WIN: "Win",
  LOSS: "Loss",
  PUSH: "Push",
  UNDECIDED: "Undecided",
};

const eventDescriptionHelperTextMap = {
  STRAIGHT: [
    "NY Giants @ DAL Cowboys",
    "LSU @ Alabama",
    "Andy Murray vs. Roger Federer",
  ],
  PARLAY: [
    "2-Leg Parlay (NY Giants @ DAL Cowboys Spread & Total",
    "2 Leg Parlay (NY Giants @ DAL Cowboys, PIT Steelers @ BAL Ravens)",
    "3 Leg Parlay (LSU @ Alabama, USC @ UCLA, Michigan State @ Ohio State)",
  ],
  FUTURE: [
    "To Win 2018 NFL Championship",
    "To Win AFC West",
    "To Win 2018 Heisman Trophy",
  ],
  PROP: [
    "Regular Season Wins",
    "Yes/No To Make Playoffs",
    "Tom Brady Passing Yards",
  ],
};

const subjectHelperTextMap = {
  STRAIGHT: ["DAL Cowboys -3", "Over 46", "DAL Cowboys ML"],
  PARLAY: [
    "DAL Cowboys -3, BAL Ravens +3",
    "Alabama -7, UCLA ML, Ohio State -4.5",
    "DAL Cowboys ML, Over 46",
  ],
  FUTURE: [
    "IND Colts (Professional Sports Team). Please abbreviate the team’s city to 3 letters.",
    "Alabama (College or School). Only the college name/state is needed. Entering “University” or “College” is unnecessary.",
    "Jordan Spieth (Player Name). First & Last name required.",
  ],
  PROP: [
    "SEA Seahawks OVER 10 (Professional Sports Team). Please abbreviate the team’s city to 3 letters",
    "Florida State YES Playoffs ((College or School). Only the college name/state is needed. Entering “University” or “College” is unnecessary",
    "Jordan Spieth (Player Name). First & Last name required.",
  ],
};

const SubjectHelperText = ({ betType }) =>
  !betType ? null : (
    <List dense subheader={<ListSubheader>Examples:</ListSubheader>}>
      {(subjectHelperTextMap[betType] || []).map((example, i) => (
        <ListItem key={i}>
          <ListItemText primary={example} />
        </ListItem>
      ))}
    </List>
  );

const EventDescriptionHelperText = ({ betType }) =>
  !betType ? null : (
    <List
      dense
      subheader={
        <ListSubheader>
          Please follow the examples below. Do NOT include numbers here.
        </ListSubheader>
      }
    >
      {(eventDescriptionHelperTextMap[betType] || []).map((example, i) => (
        <ListItem key={i}>
          <ListItemText primary={example} />
        </ListItem>
      ))}
    </List>
  );

const Form = ({ ticket = {}, history }) => {
  const stringifiedTicket = JSON.stringify(ticket);
  const {
    error: sportsError,
    data: { listSports: { items: sports = [] } = {} } = {},
  } = useQuery(Sport.queries.list, { variables: { limit: 100 } });
  const {
    error: localesError,
    data: { listLocales: { items: locales = [] } = {} } = {},
  } = useQuery(Locale.queries.list, { variables: { limit: 100 } });

  const currentUser = useContext(CurrentUserContext);
  const [doSubmit, setDoSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [formData, setFormData] = useState({
    ...withoutKeys(
      ticket,
      currentUser.groups.includes("Admins") ? [] : adminOnlyFields
    ),
    winAmount: !!ticket.winAmount ? parseFloat(ticket.winAmount) / 100.0 : null,
    betAmount: !!ticket.betAmount ? parseFloat(ticket.betAmount) / 100.0 : null,
    collectAmount: !!ticket.collectAmount
      ? parseFloat(ticket.collectAmount) / 100.0
      : null,
  });

  useEffect(() => {
    const ticket = JSON.parse(stringifiedTicket)
    setFormData({
      ...withoutKeys(
        ticket,
        currentUser.groups.includes("Admins") ? [] : adminOnlyFields
      ),
      winAmount: !!ticket.winAmount ? parseFloat(ticket.winAmount) / 100.0 : null,
      betAmount: !!ticket.betAmount ? parseFloat(ticket.betAmount) / 100.0 : null,
      collectAmount: !!ticket.collectAmount
        ? parseFloat(ticket.collectAmount) / 100.0
        : null,
    })
  }, [stringifiedTicket])

  const { data: { listEventsBySportId: { items: events = [] } = {} } = {} } =
    useQuery(Event.queries.listBySportId, {
      skip: !formData?.ticketSportId,
      variables: {
        limit: 2000,
        sportId: formData?.ticketSportId,
        filter: {
          active: {
            eq: true,
          },
        },
      },
    });

  const selectedEvent =
    !!formData?.ticketEventId &&
    events.find(event => event.id === formData?.ticketEventId);

  const {
    error: optionError,
    data: { listOptionGroupsByGroupId: { items: optionGroups = [] } = {} } = {},
  } = useQuery(OptionGroup.queries.listByGroupId, {
    skip: !selectedEvent?.groupId,
    variables: {
      limit: 2000,
      groupId: selectedEvent?.groupId,
    },
  });

  // console.log(optionError, selectedEvent)

  const {
    error: listError,
    data: {
      listSportsbookLocalesByLocaleId: { items: sportsbookLocales = [] } = {},
    } = {},
  } = useQuery(SportsbookLocale.queries.listByLocaleId, {
    skip: !formData?.ticketLocaleId,
    variables: {
      limit: 100,
      localeId: formData?.ticketLocaleId,
    },
  });

  const fields = {
    photoUrl: {
      label: "Photo",
      regex: /\w+/,
      required: true,
      type: "media",
      errorMessage: "You are required to upload a photo",
      helperText: "Take or upload a photo of your ticket.",
    },
    ticketLocaleId: {
      label: "State / Locale",
      regex: /\w+/,
      required: true,
      type: "select",
      options: locales.map(locale => ({
        value: locale.id,
        label: `${locale.state} / ${locale.name}`,
      })),
      errorMessage: "You are required to select a locale",
      helperText: "Please Select the State and Locale this ticket came from",
    },
    ticketSportId: {
      label: "Sport",
      regex: /\w+/,
      required: true,
      type: "select",
      options: sports.map(sport => ({ value: sport.id, label: sport.abbrev })),
      errorMessage: "You are required to select a sport",
    },
    betType: {
      label: "Bet Type",
      regex: /\w+/,
      required: true,
      type: "text",
      options: Object.entries(betTypes).map(([key, value]) => ({
        value: key,
        label: value,
      })),
      errorMessage: "You are required to select a bet type",
    },
    betAmount: {
      label: "Bet Amount",
      regex: /\d+/,
      required: true,
      type: "money",
      errorMessage: "You are required to enter a bit amount",
      helperText: "The amount this ticket cost",
      // value: value => !!value ? parseFloat(value) / 100.0 : ""
    },
    winAmount: {
      label: "Win Amount",
      regex: /\d+/,
      required: true,
      type: "money",
      errorMessage: "You are required to enter a win amount",
      helperText: "The amount this ticket wins",
      // value: value => !!value ? parseFloat(value) / 100.0 : ""
    },
    collectAmount: {
      label: "Collects",
      regex: /\d+/,
      required: true,
      type: "money",
      disabled: true,
      errorMessage: "You are required to enter a collect amount",
      helperText: "This is calculated win you enter your bet and win amounts",
      // value: value => !!value ? parseFloat(value) / 100.0 : ""
    },
    odds: {
      label: "Odds",
      // eslint-disable-next-line
      regex: /[\d \-\.]+/,
      required: true,
      type: "text",
      disabled: true,
      errorMessage: "You are required to enter the odds",
      helperText: "This is calculated win you enter your bet and win amounts",
    },
    eventDescription: {
      label: "Event Description",
      regex: /\w+/,
      required: true,
      type: "text",
      errorMessage: "You are required to enter an event description",
      helperText: <EventDescriptionHelperText betType={formData.betType} />,
      disabled: true
    },
    subject: {
      label: "Subject",
      regex: /\w+/,
      required: true,
      type: "text",
      errorMessage: "You are required to enter a subject",
      placeholder: "More info",
      helperText: <SubjectHelperText betType={formData.betType} />,
      disabled: true
    },
    ticketSportsbookId: {
      label: "Sportsbook",
      regex: /.+/,
      required: false,
      options: sportsbookLocales
        .map(sl => sl.sportsbook)
        .map(sportsbook => ({ value: sportsbook.id, label: sportsbook.name })),
    },
    ticketEventId: {
      label: "Event",
      regex: /.+/,
      required: false,
      options: events
        .filter(event => !!event.active)
        .map(event => ({ value: event.id, label: event.description })),
    },
    ticketOptionId: {
      label: "Option",
      regex: /.+/,
      required: false,
      options: optionGroups
        .map(og => og.option)
        .filter(option => !!option?.active)
        .map(option => ({ value: option.id, label: option.name })),
    },
    ticketId: {
      label: "(Optional) Ticket Id",
      regex: /.+/,
      required: false,
      type: "text",
      helperText: "Enter the id printed on this ticket",
    },
    additionalInfo: {
      label: "(Optional) Additional Info",
      regex: /.+/,
      required: false,
      type: "text",
      helperText: "Info such as what happens in a push, etc",
      rows: 4,
    },
    // source: {
    //   label: "Source",
    //   regex: /\w*/,
    //   type: "text",
    //   options: Object.entries(sourceTypes).map(([key, value]) => ({
    //     value: key,
    //     label: value,
    //   })),
    //   helperText: "If you're creating this for someone else, select Admin.",
    // },
    ticketUserId: {
      label: "Owner Id",
      regex: /\w*/,
      type: "text",
      helperText:
        "If you're creating this for someone else, enter their userId here.",
    },
    result: {
      label: "Result",
      regex: /\w*/,
      options: Object.entries(resultTypes).map(([key, value]) => ({
        value: key,
        label: value,
      })),
      helperText: "What was the result of this ticket?",
    },
    isMobile: {
      label: "Mobile?",
      regex: /.*/,
      options: [
        { label: "No", value: false },
        { label: "Yes", value: true },
      ],
      helperText: "Is this a mobile Ticket?",
    },
    inPropswapsPossession: {
      label: "In Propswap's Possession?",
      regex: /.*/,
      options: [
        { label: "No", value: false },
        { label: "Yes", value: true },
      ],
      helperText: "Do we currently have this ticket?",
    },
    reviewed: {
      label: "Reviewed?",
      regex: /.*/,
      options: [
        { label: "No", value: false },
        { label: "Yes", value: true },
      ],
      helperText:
        "Did someone review this ticket to make sure it's appropriate?",
    },
    hasShipped: {
      label: "Has Shipped?",
      regex: /.*/,
      options: [
        { label: "No", value: false },
        { label: "Yes", value: true },
      ],
    },
  };

  const handleChange = (field, value) =>
    setFormData({
      ...formData,
      [field]: value,
      collectAmount:
        parseFloat(formData.winAmount) + parseFloat(formData.betAmount),
    });

  const [_createTicket] = useMutation(Ticket.mutations.create, {
    variables: {
      input: {
        ...withoutKeys(formData, nonMutationFields),
        creatorId: currentUser.id,
        winAmount: parseInt(formData.winAmount * 100.0), //convert these to cents
        betAmount: parseInt(formData.betAmount * 100.0),
        collectAmount: parseInt(formData.collectAmount * 100.0),
        oddsCalculated:
          parseFloat(formData.winAmount) / parseFloat(formData.betAmount),
        ticketUserId: formData.ticketUserId || currentUser.id,
        ticketOriginalOwnerId: formData.ticketUserId || currentUser.id,
        result: "UNDECIDED",
        eventDescription: (formData.eventDescription??"").trim(),
        subject: (formData.subject??"").trim()
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      createTicket: {
        ...formData,
        creatorId: currentUser.id,
        winAmount: parseInt(formData.winAmount * 100),
        betAmount: parseInt(formData.betAmount * 100),
        collectAmount: parseInt(formData.collectAmount * 100),
        oddsCalculated:
          parseFloat(formData.winAmount) / parseFloat(formData.betAmount),
        ticketUserId: formData.ticketUserId || currentUser.id,
        ticketOriginalOwnerId: formData.ticketUserId || currentUser.id,
        __typename: "Ticket",
        result: "UNDECIDED",
      },
    },
    refetchQueries: [
      {
        query: Ticket.queries.listByUserId,
        variables: {
          limit: 50,
          ticketUserId: currentUser.id,
        },
      },
    ],
  });

  const [_updateTicket] = useMutation(Ticket.mutations.update, {
    variables: {
      input: {
        ...withoutKeys(formData, nonMutationFields),
        creatorId: currentUser.id,
        winAmount: parseInt(formData.winAmount * 100.0), //convert these to cents
        betAmount: parseInt(formData.betAmount * 100.0),
        collectAmount: parseInt(formData.collectAmount * 100.0),
        oddsCalculated:
          parseFloat(formData.winAmount) / parseFloat(formData.betAmount),
        ticketUserId: formData.ticketUserId || currentUser.id,
        eventDescription: (formData.eventDescription??"").trim(),
        subject: (formData.subject??"").trim()
      },
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateTicket: {
        ...formData,
        creatorId: currentUser.id,
        winAmount: parseInt(formData.winAmount * 100),
        betAmount: parseInt(formData.betAmount * 100),
        collectAmount: parseInt(formData.collectAmount * 100),
        oddsCalculated:
          parseFloat(formData.winAmount) / parseFloat(formData.betAmount),
        ticketUserId: formData.ticketUserId || currentUser.id,
        __typename: "Ticket",
      },
    },
  });

  !!localesError && console.log(localesError);
  !!sportsError && console.log(sportsError);

  useEffect(() => {
    setFormData({
      ...formData,
      collectAmount:
        parseInt(formData.winAmount) + parseInt(formData.betAmount),
      odds: formatOdds(
        parseInt(formData.winAmount) + parseInt(formData.betAmount),
        formData.betAmount
      ),
    });
  }, [formData.winAmount, formData.betAmount]);

  useEffect(() => {
    !!formData?.ticketSportId &&
      ticket?.ticketSportsId !== formData?.ticketSportsId &&
      setFormData({
        ...formData,
        ticketEventId: null,
        ticketOptionId: null,
      });
  }, [formData?.ticketSportId, ticket?.ticketSportsId]);

  useEffect(() => {
    !!formData?.ticketLocaleId &&
      ticket?.ticketLocaleId !== formData?.ticketLocaleId &&
      setFormData({
        ...formData,
        ticketSportsbookId: null,
      });
  }, [formData?.ticketLocaleId, ticket?.ticketLocaleId]);

  useEffect(() => {
    !!doSubmit &&
      (!!formData.id ? _updateTicket : _createTicket)()
        .then(() => [setDoSubmit(false), setSubmitSuccess(true)])
        .catch(e => [
          console.log(e),
          window.alert(e.message),
          setDoSubmit(false),
          setSubmitSuccess(false),
        ]);
  }, [doSubmit]);

  return (
    <>
      {!!submitSuccess && (
        <Toast
          open={!!submitSuccess}
          onClose={() => setSubmitSuccess(false)}
          message="Ticket Saved!"
        />
      )}
      <GenericForm
        submitting={doSubmit}
        fields={fields}
        onChange={handleChange}
        data={formData}
        onSubmit={() => setDoSubmit(true)}
        buttonLabel={!!ticket.id ? "Update Ticket" : "Post Ticket"}
      />
    </>
  );
};

export default withRouter(Form);
