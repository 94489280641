import gql from "graphql-tag";

const ConversionTemplate = {};

ConversionTemplate.fragments = {
  public: gql`
    fragment ConversionTemplatePublic on ConversionTemplate {
      id
      createdAt
      updatedAt

      bonusCode
      mapping {
        type
        threshold
        referrerBonus
        referreeBonus
      }
      active
    }
  `,
};

ConversionTemplate.mutations = {
  update: gql`
    mutation UpdateConversionTemplate($input: UpdateConversionTemplateInput!) {
      updateConversionTemplate(input: $input) {
        ...ConversionTemplatePublic
      }
    }
    ${ConversionTemplate.fragments.public}
  `,
  create: gql`
    mutation CreateConversionTemplate($input: CreateConversionTemplateInput!) {
      createConversionTemplate(input: $input) {
        ...ConversionTemplatePublic
      }
    }
    ${ConversionTemplate.fragments.public}
  `,
};

ConversionTemplate.queries = {
  listTemplatesByBonusCode: gql`
    query ListConversionTemplatesByBonusCode(
      $bonusCode: String
      $createdAt: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelConversionTemplateFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listConversionTemplatesByBonusCode(
        bonusCode: $bonusCode
        createdAt: $createdAt
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          ...ConversionTemplatePublic
        }
      }
    }
    ${ConversionTemplate.fragments.public}
  `,
  get: gql`
    query GetConversionTemplate($id: ID!) {
      getConversionTemplate(id: $id) {
        ...ConversionTemplatePublic
      }
    }
    ${ConversionTemplate.fragments.public}
  `,
  list: gql`
    query ListConversionTemplates(
      $filter: ModelConversionTemplateFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listConversionTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          ...ConversionTemplatePublic
        }
        nextToken
      }
    }
    ${ConversionTemplate.fragments.public}
  `,
  
};

export default ConversionTemplate;
