import React from "react";
import PromotionList from "../../../../Components/Promotion/List";
import PageContainer from "../../../../Components/Layout/PageContainer";
import ContentCell from "../../../../Components/Layout/ContentCell";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Tabs from "../../../../Components/Admin/Components/Tabs";

const PromotionListRoute = ({
  history,
  match: {
    params: { campaignId },
  },
}) => (
  <>
    <Tabs
      history={history}
      currentPath={"/admin/campaigns"}
      onChange={path => history.push(path)}
    />
    <PageContainer>
      <ContentCell fullWidth>
        <Grid container justify={"flex-end"}>
          <Grid item xs={12} md={3}>
            <Button
              component={Link}
              to={`/admin/campaigns/${campaignId}/promotions/new`}
              fullWidth
              variant="contained"
              color="secondary"
            >
              Add Promotion
            </Button>
          </Grid>
        </Grid>
        <PromotionList campaignId={campaignId} />
      </ContentCell>
    </PageContainer>
  </>
);

export default PromotionListRoute;
