import gql from "graphql-tag";
import Sport from "./Sport";

const Notifications = {};

Notifications.mutations = {
  create: gql`
    mutation CreateNotification($input: CreateNotificationInput!) {
      createNotification(input: $input)
    }
  `,
};

export default Notifications;
