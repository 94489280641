import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import User from "shared/api/User";

const useHasAvailableFunds = ({
  userId,
  amountInCents,
  allowPromotional = true,
}) => {
  const { data: { getUser: user = {} } = {} } = useQuery(User.queries.get, {
    skip: !userId,
    variables: { withPrivate: true, id: userId || "<>" },
  });
  const [hasAvailableFunds, setHasAvailableFunds] = useState(false);

  // !!error && console.log(error);

  const theUserId = (user || {}).id;
  const balance = user?.balance;
  const promotionalBalance = user?.promotionalBalance;

  useEffect(() => {
    setHasAvailableFunds(
      (balance ?? 0) + (!!allowPromotional ? promotionalBalance || 0 : 0) >=
        amountInCents
    );
  }, [
    theUserId,
    amountInCents,
    user,
    allowPromotional,
    balance,
    promotionalBalance,
  ]);

  return hasAvailableFunds;
};

export default useHasAvailableFunds;
