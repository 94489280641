import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import AlertCircleIcon from "mdi-material-ui/AlertCircle";
import formatOdds from "shared/Util/formatOdds";
import { Redirect, Link } from "react-router-dom";
import useExistingBid from "react-shared/Hooks/useExistingBid";

import { useMutation, useQuery } from "@apollo/client";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Grid,
  Box,
} from "@material-ui/core";

import Listing from "shared/api/Listing";
import Bid from "shared/api/Bid";
import { Typography } from "@material-ui/core";
import Modal from "../../Layout/Modal";
import Disclosure from "../../Ticket/Components/Disclosure";
import useHasAvailableFunds from "react-shared/Hooks/useHasAvailableFunds";

// const useStyles = makeStyles(theme => ({

// }));

const BidButton = ({
  listing,
  currentUser,
  amount,
  highAmount = 0,
  onClick,
  disabled,
  onSuccess = bid => null,
  onFailure = () => null,
  ...rest
}) => {
  const [doBid, setDoBid] = useState(false);
  const [bidAmount, setBidAmount] = useState(amount);
  const [bidConfirm, setBidConfirm] = useState(false);
  const [canBid, setCanBid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [agree, setAgree] = useState(false);
  const [mobileAgree, setMobileAgree] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const hasAvailableFunds = useHasAvailableFunds({
    userId: (currentUser || {}).id,
    amountInCents: bidAmount,
  });

  // const {data: {listBidsByListingIdAndBidderIdAndUpdatedAt: {items: existingBids } = {}} = {}} = useQuery(Bid.queries.listByListingIdAndBidderIdAndUpdatedAt, {
  //   skip: !listing?.id,
  //   variables: {
  //     bidListingId: listing?.id,
  //     bidBidderIdUpdatedAt: {
  //       beginsWith: {
  //         bidBidderId: currentUser?.id
  //       }
  //     }
  //   }
  // })

  const existingBid = useExistingBid({ listingId: listing?.id });

  const [_createBid] = useMutation(Bid.mutations.create, {
    variables: {
      input: {
        offer: parseInt(bidAmount),
        bidListingId: listing.id,
        bidBidderId: (currentUser || {}).id,
      },
    },
    refetchQueries: [
      {
        query: Listing.queries.get,
        variables: {
          id: listing.id,
          bidLimit: 1000,
          bidsSortDirection: "DESC",
        },
      },
      {
        query: Bid.queries.listByListingIdAndBidderIdAndUpdatedAt,
        variables: {
          bidListingId: listing?.id,
          bidBidderIdUpdatedAt: {
            beginsWith: {
              bidBidderId: currentUser?.id,
            },
          },
        },
      },
      {
        query: Bid.queries.listByBidderIdWithListingAndTicket,
        variables: {
          bidBidderId: (currentUser || {}).id,
          statusUpdatedAt: {
            beginsWith: {
              status: "PENDING",
            },
          },
          sortDirection: "DESC",
          limit: 1000,
          bidsSortDirection: "DESC",
        },
      },
    ],
  });

  const [_cancelBid] = useMutation(Bid.mutations.updateBidStatus, {
    variables: {
      input: {
        id: existingBid?.id,
        status: "CANCELLED",
      },
    },
    refetchQueries: [
      {
        query: Bid.queries.listByBidderIdWithListingAndTicket,
        variables: {
          bidBidderId: currentUser?.id,
          statusUpdatedAt: {
            beginsWith: {
              status: "PENDING",
            },
          },
          sortDirection: "DESC",
          limit: 1000,
          bidsSortDirection: "DESC",
        },
      },
      {
        query: Bid.queries.listByListingIdAndBidderIdAndUpdatedAt,
        variables: {
          bidListingId: listing?.id,
          bidBidderIdUpdatedAt: {
            beginsWith: {
              bidBidderId: currentUser?.id,
            },
          },
        },
      },
    ],
  });

  useEffect(() => {
    setBidAmount(amount);
  }, [amount]);

  useEffect(() => {
    !bidAmount &&
      !!doBid &&
      Promise.resolve(
        parseFloat(
          (window.prompt("How much would you like to bid?") || "").replace(
            "$",
            ""
          )
        )
      )
        .then(amount =>
          !!amount
            ? Promise.resolve(amount)
            : amount === false
            ? Promise.reject({ message: "" })
            : Promise.reject({
                message: "You must enter a valid dollar amount.",
              })
        )
        .then(amount => setBidAmount(parseInt(amount * 100.0)))
        .catch(e => [
          // !!e.message ? window.alert(e.message) : null,
          setDoBid(false),
        ]);
  }, [bidAmount, doBid]);

  // useEffect(() => {
  //   !!doBid &&
  //   !!existingBid &&
  //   Promise.resolve(
  //     window.confirm(`You have a pending bid on this listing for $${(parseFloat(existingBid.offer || 0)/100.0).toFixed(2)}. Would you like to cancel this bid and enter a new one?`)
  //   )
  //     .then(response =>
  //       !!response ? (
  //         _cancelBid()
  //           .then(console.log)
  //       ) : (
  //         setDoBid(false)
  //       )
  //     )

  // }, [doBid, JSON.stringify(existingBid)])

  useEffect(() => {
    setCanBid(
      !currentUser
        ? false
        : currentUser.id === listing.listingSellerId
        ? false
        : listing.status !== "ACTIVE"
        ? false
        : true
    );
  }, [(currentUser || {}).id, listing.updatedAt]);

  useEffect(() => {
    !!bidConfirm &&
      !!bidAmount &&
      new Promise((resolve, reject) =>
        bidAmount <= highAmount
          ? reject({ message: "You must bid more than the highest bidder" })
          : !hasAvailableFunds
          ? reject({
              message:
                "You don't have enough money in your wallet for that bid.",
            })
          : resolve(null)
      )
        .then(() =>
          _createBid()
            .then(({ data: { createNewBid: bid } }) => [
              setBidConfirm(false),
              setSubmitting(false),
              setDoBid(false),
              onSuccess(bid),
            ])
            .catch(e => [
              console.log(e),
              window.alert(e.message),
              setBidAmount(null),
              setSubmitting(false),
              setBidConfirm(false),
              setDoBid(false),
              onFailure(),
            ])
        )
        .catch(({ message }) => [
          window.alert(message),
          setBidAmount(null),
          setSubmitting(false),
          setBidConfirm(false),
        ]);
  }, [bidConfirm, bidAmount, highAmount, hasAvailableFunds]);

  return !canBid && !onClick ? null : !!redirectTo ? (
    <Redirect push to={redirectTo} />
  ) : (
    <>
      {!doBid ? null : !hasAvailableFunds ||
        bidAmount >= listing.askingPrice ||
        bidAmount < (listing.minimumBidPrice || 0) ||
        bidAmount <= highAmount ? (
        <Modal
          body={
            <Box pl={1} pr={1} style={{ overflow: "hidden" }}>
              <Grid container alignItems="center" justify="center" spacing={4}>
                <Grid container item xs={12} justify="center">
                  <AlertCircleIcon style={{ fontSize: 80 }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" variant="body1" paragraph>
                    {!hasAvailableFunds ? (
                      <>
                        In order to submit a Bid, you must have sufficient funds
                        in your account.
                        <br />
                        <br />
                        Please note: PropSwap offers a Deposit Back Guarantee!
                        If you Deposit money into your account and do not buy
                        any tickets within 48 hours of the Deposit, you can
                        request a full 100% Refund back to your credit card
                        (including all fees).
                      </>
                    ) : bidAmount >= listing.askingPrice ? (
                      "You cannot bid more than the asking price."
                    ) : bidAmount <= highAmount ? (
                      "Your bid must be higher than the current high bid."
                    ) : bidAmount < (listing.minimumBidPrice || 0) ? (
                      <>
                        Your bid must be higher than the minimum bid amount
                        of&nbsp;
                        <NumberFormat
                          fixedDecimalScale
                          decimalScale={2}
                          value={
                            parseFloat(listing.minimumBidPrice || 0) / 100.0
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </>
                    ) : (
                      "Something went wrong"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          }
          onClose={() => setDoBid(false)}
          submitting={submitting}
          opened={!!doBid}
          saveButton={{
            text: !hasAvailableFunds ? "Deposit" : "Close",
            onClick: () =>
              bidAmount > hasAvailableFunds
                ? setRedirectTo(`/account/deposit`)
                : setDoBid(false),
            ButtonProps: {
              fullWidth: true,
            },
          }}
        />
      ) : !!existingBid ? (
        <Modal
          body={
            <Box pl={1} pr={1} style={{ overflow: "hidden" }}>
              <Grid container alignItems="center" justify="center" spacing={4}>
                <Grid container item xs={12} justify="center">
                  <AlertCircleIcon style={{ fontSize: 80 }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" variant="body1" paragraph>
                    You currently have a Pending bid on this ticket for $
                    {(parseFloat(existingBid.offer || 0) / 100.0).toFixed(2)}.
                    Would you like to cancel this bid and submit a new one?
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          }
          onClose={() => setDoBid(false)}
          opened={!!doBid}
          saveButton={{
            text: "Continue",
            onClick: _cancelBid,
          }}
          secondaryButton={{
            text: "Close",
            onClick: () => setDoBid(false),
          }}
        />
      ) : (
        <Modal
          title={
            <Box display="flex" justifyContent="space-between" mr={2} ml={2}>
              <div>
                <strong>Bid:</strong>&nbsp;
                <Box color="secondary.main" component="span">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={parseFloat(amount || 0) / 100.0}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </Box>
              </div>
              <div>
                <strong>Odds:</strong>{" "}
                {formatOdds(listing.ticket.collectAmount, amount)}
              </div>
            </Box>
          }
          subTitle={
            <>
              <strong>Available Balance:</strong>&nbsp;
              <NumberFormat
                fixedDecimalScale
                decimalScale={2}
                value={
                  parseFloat(
                    currentUser.balance + currentUser.promotionalBalance
                  ) / 100.0
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
              <br />
              <strong>Remaining Balance:</strong>&nbsp;
              <Box color="secondary.main" component="span">
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={
                    parseFloat(
                      currentUser.balance +
                        currentUser.promotionalBalance -
                        amount
                    ) / 100.0
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Box>
            </>
          }
          body={
            <form noValidate autoComplete="off">
              <Typography paragraph>
                The Bid remains active until either: <br />
                a) You cancel the bid
                <br />
                b) The seller accepts or rejects the bid
              </Typography>
              <Disclosure
                ticket={listing.ticket}
                onAgree={setMobileAgree}
                agree={mobileAgree}
              />
              <FormControl>
                <FormGroup
                  onChange={({ target: { checked } }) => setAgree(!!checked)}
                  row
                >
                  <FormControlLabel
                    control={<Checkbox checked={!!agree} color="primary" />}
                    label={
                      <>
                        I have read and agree to the{" "}
                        <Link target="_blank" to="/pages/terms">
                          Terms and Conditions
                        </Link>
                      </>
                    }
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </form>
          }
          onClose={() => setDoBid(false)}
          submitting={submitting}
          opened={!!doBid}
          saveButton={{
            text: "Bid",
            disabled: !agree || !mobileAgree,
            onClick: () => setBidConfirm(true),
            ButtonProps: {
              fullWidth: true,
            },
          }}
        />
      )}

      <Button
        disabled={!!doBid || !!disabled}
        onClick={evt =>
          !!onClick ? onClick() : [evt.stopPropagation(), setDoBid(true)]
        }
        // size="small"
        variant={rest.variant || "contained"}
        {...rest}
      >
        Bid
      </Button>
    </>
  );
};

export default BidButton;
