import React from "react";

import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";

import Form from "../Form";

import Campaign from "shared/api/Campaign";

const CampaignEditContainer = ({ id }) => {
  const { loading, data: { getCampaign: campaign } = {} } = useQuery(
    Campaign.queries.get,
    { variables: { id } }
  );

  return !!loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Form campaign={campaign} />
  );
};

export default CampaignEditContainer;
