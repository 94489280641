import React from "react";
import { useQuery } from "@apollo/client";
import LocaleListView from "./LocaleListView";
import Locale from "shared/api/Locale";
import { CircularProgress } from "@material-ui/core";

const LocaleListContainer = () => {
  const { loading, data: { listLocales: { items: locales = [] } = {} } = {} } =
    useQuery(Locale.queries.list, { variables: { limit: 100 } });
  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <LocaleListView locales={locales} />
  );
};

export default LocaleListContainer;
