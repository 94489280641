import React, { useState, useEffect } from "react";

import Option from "shared/api/Option";
import OptionGroup from "shared/api/OptionGroup";
import { useQuery, useMutation } from "@apollo/client";

import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Box,
  Button,
} from "@material-ui/core";
import Modal from "../../../Layout/Modal";
import OptionEdit from "../Edit";

const OptionCheckbox = ({ option, groupId }) => {
  const id = `${option.id}-${groupId}`;
  const { data: { getOptionGroup } = {} } = useQuery(OptionGroup.queries.get, {
    variables: { id },
  });
  const [action, setAction] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [createOptionGroup] = useMutation(OptionGroup.mutations.create, {
    variables: {
      input: {
        id,
        groupId,
        optionId: option.id,
      },
    },
    refetchQueries: [
      {
        query: OptionGroup.queries.get,
        variables: { id },
      },
    ],
  });

  const [deleteOptionGroup] = useMutation(OptionGroup.mutations.delete, {
    variables: {
      input: {
        id,
      },
    },
    refetchQueries: [
      {
        query: OptionGroup.queries.get,
        variables: { id },
      },
    ],
  });

  useEffect(() => {
    action === "create" &&
      createOptionGroup()
        .then(() => setAction(null))
        .catch(e => [
          window.alert(JSON.stringify(e)),
          console.log(e),
          setAction(null),
        ]);

    action === "delete" &&
      deleteOptionGroup()
        .then(() => setAction(null))
        .catch(e => [
          window.alert(JSON.stringify(e)),
          console.log(e),
          setAction(null),
        ]);
  }, [action]);

  return (
    <>
      <Box flex display="flex">
        <FormControl>
          <FormGroup
            onChange={({ target: { checked } }) =>
              setAction(!!checked ? "create" : "delete")
            }
            row
          >
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!!action}
                  checked={!!getOptionGroup}
                  color="primary"
                />
              }
              label={`${
                !option.active ? `${option.name} (inactive)` : option.name
              } - ${option.viewCount ?? 0} Views - ${option.id}`}
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setShowModal(true)}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <Modal
        title={"Edit Option"}
        body={
          <OptionEdit id={option?.id} onSuccess={() => [setShowModal(false)]} />
        }
        onClose={() => setShowModal(false)}
        submitting={false}
        opened={!!showModal}
        saveButton={false}
      />
    </>
  );
};

export default ({ sportId, groupId, showInactive }) => {
  const { error, data: { listOptionsBySportId: { items = [] } = {} } = {} } =
    useQuery(Option.queries.listBySportId, {
      variables: {
        sportId,
        limit: 2000,
        ...(!!showInactive ? {} : { filter: { active: { eq: true } } }),
      },
    });

  !!error && console.log(error);

  return items
    .slice()
    .filter(item => !!showInactive || !!item.active)
    .sort((a, b) => (!!a.active > !!b.active ? -1 : 1))
    .map(option => (
      <OptionCheckbox key={option.id} groupId={groupId} option={option} />
    ));
};
